import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Injectable()
export class CustomPaginator implements MatPaginatorIntl {
  public pageCount = 0;
  public changes = new Subject<void>();
  public nextPageLabel = this.translateService.instant("TABLES.GENERAL.PAGINATION.NEXT_PAGE");
  public previousPageLabel = this.translateService.instant("TABLES.GENERAL.PAGINATION.PREVIOUS_PAGE");
  public firstPageLabel = this.translateService.instant("TABLES.GENERAL.PAGINATION.FIRST_PAGE");
  public lastPageLabel = this.translateService.instant("TABLES.GENERAL.PAGINATION.LAST_PAGE");
  public itemsPerPageLabel = this.translateService.instant("TABLES.GENERAL.PAGINATION.ITEMS_PER_PAGE") + ":";
  public items = this.translateService.instant("TABLES.GENERAL.PAGINATION.ITEMS");
  public of = this.translateService.instant("TABLES.GENERAL.PAGINATION.OF");

  public constructor(private readonly translateService: TranslateService) {}

  public getRangeLabel(page: number, pageSize: number, length: number): string {
    return `${this.items} ${(page + 1) * pageSize - pageSize + 1} - ${pageSize * (page + 1) > length ? length : pageSize * (page + 1)} ${this.of.toLowerCase()} ${length}`;
  }
}
