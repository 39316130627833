import { Environment } from "../app/classes/flow/base/Environment";

export const ENVIRONMENT: Environment = {
  PRODUCTION: false,
  ORGANISATION: "BespaartEnergie dev",
  API_URL: "https://dev.api.epf.bespaartenergie.net/",
  CLIENT_ID: "068be95c-8e56-4cdb-8be0-5347038603f5",
  AUTHORITY: "https://bespaartenergiedev.b2clogin.com/bespaartenergiedev.onmicrosoft.com/B2C_1_signupandsignin",
  KNOWN_AUTHORITY: "https://bespaartenergiedev.b2clogin.com",

  SOCIALS: {
    SLOGAN: "Dev bespaart energie",
    LINKS: {
      MAIN: "https://www.emmengeeftenergie.nl/",
      PRIVACY: "https://www.emmengeeftenergie.nl/Beheer/Privacyverklaring/default.aspx",
      DISCLAIMER: "",
    },
  },

  EXTRA_TRANSLATIONS_SUFFIX: [],
  MODULES: ["RETAILER", "REPLACEMENT_CLAIMS", "EMPTY_PDF", "TIME_SLOTS", "EDIT_ENERGY_CONSULT", "NOTE", "MAIL_TIMELINE", "CUSTOM_SPLIT_HEADER_LOGO"],
  AREA_OF_OPERATIONS: {
    MATCHES: [{ type: "include", key: "gemeentenaam", value: ["EMMEN"] }],
  },
  ALLOWED_SPECIALTIES: [1],
  CUSTOM_HEADER_LOGOS: [
    {
      urls: [
        {
          name: "COACH_EMMEN",
          url: "https://dev.coach.epf.bespaartenergie.net/",
          urlType: "external",
          roles: ["coordinator"],
          default: false,
        },
        {
          name: "",
          url: "/",
          urlType: "internal",
          roles: ["guest", "resident"],
          default: false,
        },
        {
          name: "",
          url: "/content",
          urlType: "internal",
          roles: [],
          default: true,
        },
      ],
      imageRoute: "assets/organisation/images/custom_header_logo/emmengeeftenergie-logo.png",
    },
    {
      urls: [
        {
          name: "FIXTEAM_EMMEN",
          url: "/",
          urlType: "internal",
          roles: ["coordinator"],
          default: false,
        },
        {
          name: "",
          url: "/",
          urlType: "internal",
          roles: ["guest", "resident"],
          default: false,
        },
        {
          name: "",
          url: "/content",
          urlType: "internal",
          roles: [],
          default: true,
        },
      ],
      imageRoute: "assets/organisation/images/custom_header_logo/fixteam-logo.png",
    },
  ],
};
