<div id="container">
  <div id="box">
    <img src="/assets/organisation/images/logo.png" alt="Logo" />
    <h3>{{ "COMPONENTS.UNDER_CONSTRUCTION.HEADING" | translate }}</h3>
    <p>{{ "COMPONENTS.UNDER_CONSTRUCTION.DESCRIPTION" | translate }}</p>
    <div class="d-flex justify-content-end flex-direction-row w-100">
      <button (click)="goToDashboard()" mat-raised-button><mat-icon>refresh</mat-icon>{{ "COMPONENTS.EVERYWHERE.RELOADING" | translate }}</button>
    </div>
  </div>
</div>
