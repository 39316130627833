export function convertDate(d: Date) {
  const dateString = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
  const timeString = `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}:${d.getSeconds().toString().padStart(2, "0")}`;
  const dateTimeString = dateString + " " + timeString;
  return dateTimeString;
}

export function reverseDate(d: string) {
  const reverseDate = new Date(d);
  const difference = reverseDate.getHours() - reverseDate.getUTCHours();
  reverseDate.setHours(reverseDate.getHours() + difference);
  return convertDate(reverseDate);
}
