import { Injectable } from "@angular/core";
import { Retailer } from "../classes/flow/session/impl/Retailer";
import { GraphQLService } from "./graphql.service";
import { EmptyResult } from "../classes/system/Result";
import { User } from "../classes/flow/session/impl/User";
@Injectable({
  providedIn: "root",
})
export class RetailersService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  public async addRetailer(retailers: Retailer): Promise<EmptyResult> {
    const result = await this.graphqlService.query(`mutation {
      addRetailer(retailer: {
        firstName: "${retailers.firstName}",
        lastName: "${retailers.lastName}",
        email: "${retailers.email}",
        postalCode: "${retailers.postalCode}",
        city: "${retailers.city}",
        iban: "${retailers.iBAN}"
      }) {
        messages {
          message
          type
        }
      }
    }`);

    return {
      messages: this.graphqlService.constructMessages(result.data.addRetailer.messages),
    };
  }

  /**
   * Query to check the values from the retailer of the parameters "id"
   * @param user checks the values of the retailer by using the user parameters ID
   * @returns the values from the retailer
   */
  public async getRetailerByUser(user: Retailer): Promise<Retailer> {
    const query = `query {
        retailers {
          value(where: {userId: {eq: ${user.userId}}}) {
            id
            email
            firstName
            lastName
            userId
            toegangDatumVanaf
            toegangDatumTM
            active
            registered
            emailSubscription
            balance
            available
            changes {
              lastChange {
                userId
                time
              }
              fullDetails{
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages {
            message
          }
        }
      }`;
    const res = await this.graphqlService.query(query);

    return res.data.retailers.value?.map((retailer: any) => {
      return new Retailer({
        id: retailer.id,
        userId: retailer.userId,
        roles: user.roles,
        email: retailer.email,
        firstName: retailer.firstName,
        lastName: retailer.lastName,
        city: retailer.city,
        postalCode: retailer.postalCode,
        iBAN: retailer.iBAN,
        balance: retailer.balance,
        accessStartingDate: retailer.toegangDatumVanaf,
        accessEndDate: retailer.toegangDatumTM,
        registered: retailer.registered,
        changes: this.graphqlService.createChangesObject(retailer.changes),
      });
    })?.[0];
  }

  /**
   * query to get all the retailers
   * @returns all the retailers which will be put in a table on the retailer tab
   */
  public async getAllRetailers(): Promise<Array<Retailer>> {
    const res = await this.graphqlService.query(`
      query {
        retailers {
          messages {
            message
          }
          value {
            id
            email
            firstName
            lastName
            toegangDatumTM
            toegangDatumVanaf
            userId
            registered
            balance
            active
            available
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
        }
      }
    `);
    return res.data.retailers.value?.map((retailer: any) => {
      return new Retailer({
        id: retailer.id,
        userId: retailer.userId,
        email: retailer.email,
        firstName: retailer.firstName,
        lastName: retailer.lastName,
        balance: retailer.balance,
        accessEndDate: retailer.toegangDatumTM,
        accessStartingDate: retailer.toegangDatumVanaf,
        registered: retailer.registered,
        available: retailer.available,
      });
    });
  }

  public async getAvailableRetailers(): Promise<Retailer[]> {
    const res = await this.graphqlService.query(`
      query {
        retailers {
          messages {
            message
          }
          value(where: { available: { eq: true } }, order: { firstName: ASC }) {
            id
            email
            firstName
            lastName
          }
        }
      }
    `);
    return res.data.retailers.value?.map((retailer: any) => {
      return new Retailer({
        id: retailer.id,
        email: retailer.email,
        firstName: retailer.firstName,
        lastName: retailer.lastName,
      });
    });
  }

  public async addPaymentRequest(user: User, paymentAmount: string, paymentReference: string) {
    const retailer = user as Retailer;

    /** Query which creates a retailer payment */
    const mutation = `mutation createRetailerPaymentRequest {
      addretailerPaymentRequest(
       paymentRequest: {
        retailerId: ${retailer.id},
        paymentAmount: "${paymentAmount}",
        referenceNumber: "${paymentReference}",
       }
      ){
        value {
          id
        }
        messages{
          message
          type
        }
      }
    }`;
    const response = await this.graphqlService.query(mutation);

    return {
      messages: this.graphqlService.constructMessages(response.data.addretailerPaymentRequest.messages),
    };
  }

  /**
   * query to get all the transactions
   * @returns all the transactions which will be put in a table on the retailer dashboard tab
   */
  async paymentRequests(user: Retailer, paid: boolean): Promise<{
    paid: boolean;
    paidOn: string;
    paymentAmount: number;
    referenceNumber: string;
    skipped: boolean;
    timeStamp: string;
  }[]> {
    const response = await this.graphqlService.query(
      `query retrieveRetailersTransactions{
        retailers {
          value(where: { id: { eq: ${user.id} }}) {
            firstName
            paymentRequests(where: { paid: { eq: ${paid} }}) {
              paid
              paidOn
              paymentAmount
              referenceNumber
              skipped
              timeStamp
            }
          }
          messages {
            message
          }
        }
      }`
    );

    return response.data.retailers.value.map((retailer: any) => retailer.paymentRequests)[0];
  }
}
