 <div class="text-start my-2">
    <label>{{label | translate}}</label>

 </div>
 <div class="d-flex justify-content-start gap-3 mb-4 flex-wrap">
  <button type="button" mat-raised-button color="accent" (click)="openQRDialog()" [disabled]="disabled"><mat-icon>qr_code</mat-icon>{{"COMPONENTS.QR_INPUT.SCAN_QRCODE" | translate}}</button>

  <ng-container *ngIf="control.controls.qr.dirty && control.controls.qr.touched">
    <!-- QR Code is invalid and user can not continue -->
    <ng-container *ngIf="control.controls.qr.hasError('invalidQR') && control.controls.qr.errors?.['invalidQR'] !== 'FORMS.ERRORS.QR.NO_MATCH'">
      <span class="d-flex align-items-center gap-1 text-danger" *ngIf="control.controls.qr.value">
        <mat-icon class="text-danger">close</mat-icon>&nbsp; {{ control.controls.qr.errors?.['invalidQR'] ?? 'FORMS.ERRORS.QR.INVALID' | translate}}
      </span>
    </ng-container>
    <!-- QR Code doesn't match, but user is allowed to continue -->
    <ng-container *ngIf="control.controls.qr.errors?.['invalidQR'] === 'FORMS.ERRORS.QR.NO_MATCH'">
      <span class="d-flex align-items-center gap-1 text-warning" *ngIf="control.controls.qr.value">
        <mat-icon class="text-warning">done</mat-icon>&nbsp; {{ control.controls.qr.errors?.['invalidQR'] ?? 'FORMS.ERRORS.QR.INVALID' | translate}}
      </span>
    </ng-container>
    <!-- QR Code is valid -->
    <ng-container *ngIf="control.controls.qr.valid">
      <span class="d-flex align-items-center gap-1 text-success" *ngIf="control.controls.qr.value">
        <mat-icon class="text-success">done</mat-icon>&nbsp; {{ "COMPONENTS.QR_INPUT.SCANNED_QRCODE" | translate}}
      </span>
    </ng-container>
  </ng-container>
</div>
