import { Injectable } from "@angular/core";
import { Resident } from "../classes/flow/session/impl/Resident";
import { User } from "../classes/flow/session/impl/User";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class ResidentService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  public async getResidentById(id: number): Promise<Resident> {
    const res = await this.graphqlService.query(`
          query {
            residents {
              value(where: { id: { eq: ${id} } }) {
                firstName
                lastName
                email
                phoneNumber
                postalCode
                houseNumber
                houseNumberSuffix
                id
                changes {
                  fullDetails {
                    key
                    value {
                      userId
                      time
                    }
                  }
                  lastChange {
                    userId
                    time
                  }
                }
              }
              messages {
                message
              }
            }
          }
        `);
    const resident = res.data["residents"].value[0];
    return new Resident({
      firstName: resident.firstName,
      lastName: resident.lastName,
      phoneNumber: resident.phoneNumber,
      email: resident.email,
      postalCode: resident.postalCode,
      houseNumber: resident.houseNumber,
      houseNumberSuffix: resident.houseNumberSuffix,
      id: resident.id,
    });
  }

  /**
   * Gets a resident by user
   * @param user The user
   * @returns The resident under a specific user
   */
  public async getResidentByUser(user: User): Promise<Resident> {
    const res = await this.graphqlService.query(
      `query GetResidentByUser {
          residents {
            value(where: {userId: {eq: ${user.userId}}}) {
              id
              firstName
              lastName
              email
              phoneNumber
              houseNumber
              houseNumberSuffix
              postalCode
              userId
              toegangDatumTM
              toegangDatumVanaf
              changes {
                lastChange {
                  userId
                  time
                }
                fullDetails{
                  key
                  value {
                    userId
                    time
                  }
                }
              }
              changes {
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
                lastChange {
                  userId
                  time
                }
              }
            }
            messages{
              message
            }
          }
        }`
    );
    return res.data.residents.value?.map((resident: any) => {
      return new Resident({
        id: resident.id,
        userId: resident.userId,
        roles: user.roles,
        firstName: resident.firstName,
        lastName: resident.lastName,
        phoneNumber: resident.phoneNumber,
        email: resident.email,
        postalCode: resident.postalCode,
        houseNumber: resident.houseNumber,
        houseNumberSuffix: resident.houseNumberSuffix,
        accessStartingDate: resident.toegangDatumVanaf,
        accessEndDate: resident.toegangDatumTM,
        changes: this.graphqlService.createChangesObject(resident.changes),
      });
    })?.[0];
  }
}
