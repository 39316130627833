import { AfterContentInit, AfterViewInit, Component, inject, Input, OnInit, ViewChild } from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { RequestState, RequestStates } from "../../../classes/flow/request/RequestStates";
import { Coordinates, EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { AreaAction } from "../../../classes/flow/AreaActions/AreaAction";
import { AreaActionService } from "../../../services/area-action.service";
import { ApplicationService } from "../../../services/application.service";
import { StorageService } from "../../../services/storage.service";
import { mapDefaults } from "../../../defaults/map";
import { Coach } from "../../../classes/flow/session/impl/Coach";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoachService } from "../../../services/coach.service";
import { EnergyConsultService } from "../../../services/energy-consult.service";

export interface PlanningDataSet {
  coaches: Coach[];
  date: Date;
  visible: boolean;
  color: string;
  consults: (EnergyConsult & { location?: string; marker?: { position: { lat: number; lng: number } } })[];
  lines: any;
}

export interface CustomMarker {
  position: google.maps.LatLngLiteral;
  id: string;
  mapMarker?: MapMarker;
  state: RequestState;
  options: {
    zIndex: number;
  };
}

export interface AreaActionCustomMarker {
  position: google.maps.LatLngLiteral;
  id: number;
  options: {
    zIndex: number;
  };
}

@Component({
  selector: "app-consult-map-view",
  templateUrl: "./consult-map-view.component.html",
  styleUrls: ["./consult-map-view.component.less"],
})
export class ConsultMapViewComponent implements OnInit, AfterContentInit, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) marker!: MapMarker;

  @Input("filteredRequests") set filteredRequestsSetter(filteredRequests: EnergyConsult[]) {
    this.filteredRequests = filteredRequests;
    this.setFilteredMarkers();
  }

  public legendOverlay = false;
  public planningOverlay = true;

  public coaches: Coach[];
  public loading: boolean;
  public coachService: CoachService;
  public energyConsultService: EnergyConsultService;
  public colors: { [key: number]: string };

  public setQueriedData: { coaches: Coach[]; date: Date }[] = [];
  public form: FormGroup<{ coaches: FormControl<Coach[] | null>; date: FormControl<Date | null> }>;
  public sets: PlanningDataSet[];

  public filteredRequests!: EnergyConsult[];

  public markerPositions: CustomMarker[] = [];
  public mapLayers: [google.maps.LatLngLiteral[]] = [[]];
  public areaActionMarkers: AreaActionCustomMarker[] = [];

  public mapSettings: {
    zoom: number;
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions;
  };

  public selectedCustomMarker: CustomMarker | null = null;
  public selectedMarker: CustomMarker | null = null;
  public selectedRequest: EnergyConsult | null = null;

  public areaActions: AreaAction[] = [];
  public currentAreaAction: AreaAction | null = null;

  public legendItems: { stateName: RequestStates; iconFile: string; coordinatorOnly: boolean }[] = [];

  constructor(public readonly applicationService: ApplicationService, private readonly areaActionService: AreaActionService, private readonly storageService: StorageService) {
    this.mapSettings = mapDefaults;
    this.sets = [];

    this.coachService = inject(CoachService);
    this.energyConsultService = inject(EnergyConsultService);

    this.form = new FormGroup({
      coaches: new FormControl<Coach[] | null>(null, Validators.required),
      date: new FormControl<Date | null>(new Date(), Validators.required),
    });
    this.coaches = [];

    this.loading = false;

    this.colors = {
      0: "#0D52F9", //route color
    };
    // TODO new routes using routing endpoint results
  }

  ngAfterContentInit(): void {
    return;
  }

  async ngOnInit(): Promise<void> {
    this.setLegendItems();
    this.coaches = await this.coachService.getActiveCoaches();
  }

  public ngAfterViewInit(): void {
    const storedData = this.storageService.fetch("usersettings.map.planningView.setQueriedData", "localStorage");

    if (Array.isArray(storedData)) {
      this.setQueriedData = storedData.map((set: any) => {
        return {
          coaches: set.coaches.map((coach: any) => new Coach(coach)),
          date: new Date(set.date),
        };
      });

      this.initializeSetsFromStoredData();
    }
    this.setOverlaysBasedOnLocalStorage();

    const zoom = this.storageService.fetch<number>("usersettings.map.mapView.zoom", "localStorage");
    if (zoom) this.mapSettings.zoom = zoom;
    const center = this.storageService.fetch<google.maps.LatLngLiteral>("usersettings.map.mapView.center", "localStorage");
    if (center) this.mapSettings.center = center;
  }

  private async initializeSetsFromStoredData(): Promise<void> {
    for (const set of this.setQueriedData) {
      this.form.controls.date.setValue(set.date);
      this.form.controls.coaches.setValue(set.coaches);
      await this.addSet(false);
    }
  }

  public async addSet(clearForm = true): Promise<void> {
    if (this.form.invalid || !this.form.controls.coaches.value || !(this.form.controls.coaches.value.length > 0) || !this.form.controls.date.value) return;
    this.loading = true;
    const newSet = { coaches: this.form.controls.coaches.value, date: this.form.controls.date.value };
    const isSetAlreadyQueried = this.setQueriedData.some((set) => {
      return JSON.stringify(set.coaches) === JSON.stringify(newSet.coaches) && new Date(set.date).getTime() === new Date(newSet.date).getTime();
    });

    if (!isSetAlreadyQueried) {
      this.setQueriedData.push(newSet);
    }

    this.storageService.store("usersettings.map.planningView.setQueriedData", this.setQueriedData, "localStorage");

    const consults = (
      await this.energyConsultService.getConsultsByCoachByDay(
        this.form.controls.coaches!.value.map((c) => c.id!),
        this.form.controls.date.value
      )
    )
      .sort((a, b) => (a.appointmentDate?.getTime() ?? 0) - (b.appointmentDate?.getTime() ?? 0))
      .map((ec) => {
        (ec as EnergyConsult & { location?: string }).location = ec.getLocation(this.applicationService);
        return ec;
      })
      .filter((ec) => ec.state.name !== "Disapproved" && ec.state.name !== "PendingDeleted" && ec.state.name !== "PendingReject" && ec.state.name !== "Canceled");

    for (const ec of consults as (EnergyConsult & { location?: string; marker?: { position: { lat: number; lng: number } } })[]) {
      const lat = Number(ec.extraProperties?.houseCoordinates?.Latitude);
      const lng = Number(ec.extraProperties?.houseCoordinates?.Longitude);
      if (!isNaN(lat) && !isNaN(lng))
        ec.marker = {
          position: {
            lat: Number(ec.extraProperties?.houseCoordinates?.Latitude),
            lng: Number(ec.extraProperties?.houseCoordinates?.Longitude),
          },
        };
    }

    this.sets = [
      ...this.sets,
      {
        coaches: this.form.controls.coaches.value,
        date: this.form.controls.date.value,
        visible: true,
        consults: consults,
        color: this.colors[this.sets.length] ?? "black",
        lines: {
          path: (consults as (EnergyConsult & { location?: string; marker?: { position: { lat: number; lng: number } } })[]).map((c) => c.marker?.position).filter((m) => m),
          icons: [{ icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2.5 }, repeat: "46px" }],
        },
      },
    ];
    this.loading = false;
    if (clearForm) {
      this.clearForm();
    }
  }

  public removeSet(index: number): void {
    this.setQueriedData = this.setQueriedData.filter((val, i) => i !== index);
    this.storageService.store("usersettings.map.planningView.setQueriedData", this.setQueriedData, "localStorage");

    this.sets = this.sets.filter((val, i) => i !== index);
    this.sets = this.sets.map((set, index) => {
      return {
        ...set,
        color: this.colors[index],
      };
    });
  }

  public clearForm(): void {
    this.form.reset({ date: new Date() });
    this.form.reset({ coaches: null });
  }
  // loadMap() {
  //   this.map.googleMap!.setCenter(this.center);
  //   this.map.googleMap!.setZoom(this.zoom);
  // }

  public openInfo(marker: CustomMarker) {
    this.selectedMarker = marker;
    this.selectedRequest = this.filteredRequests.filter((request) => String(request.id) === this.selectedMarker?.id)[0];
  }

  public closeInfo(): void {
    this.selectedRequest = null;
  }

  public onLeftArrowClick() {
    this.setPostalCodeLayers(false);
  }
  public onRightArrowClick() {
    this.setPostalCodeLayers(true);
  }

  //todo create popout for selector of actions
  public onMiddleAreaClick() {
    // todo create selector for all areaActions
  }
  public async getAreaActions() {
    this.applicationService.setLoading(true);
    this.areaActions = await this.areaActionService.all();
    this.areaActions.sort((a, b) => a.dateFrom.getTime() - b.dateFrom.getTime());
    this.applicationService.setLoading(false);
  }

  public setFilteredMarkers() {
    const bottomToTop = [RequestStates.DONE, RequestStates.FILLED_IN, RequestStates.NEW, RequestStates.PICKED, RequestStates.DATE];

    this.markerPositions = this.filteredRequests
      .filter((element) => element.extraProperties?.houseCoordinates || element.extraProperties?.postalcodeCoordinates)
      .map((element) => {
        const coordinates: Coordinates = element.extraProperties?.houseCoordinates
          ? element.extraProperties.houseCoordinates
          : element.extraProperties!.postalcodeCoordinates!;

        return {
          position: {
            lat: Number(coordinates.Latitude),
            lng: Number(coordinates.Longitude),
          },
          id: String(element.id),
          state: element.state,
          options: {
            zIndex: bottomToTop.indexOf(element.state.name),
          },
        };
      });
  }

  public async setOverlaysBasedOnLocalStorage(): Promise<void> {
    this.legendOverlay = this.storageService.fetch("usersettings.map.mapView.legendOverlay", "localStorage") ?? false;
    this.planningOverlay = this.storageService.fetch("usersettings.map.planningView.planningOverlay", "localStorage") ?? true;
  }

  public setPostalCodeLayers(next: boolean) {
    if (this.currentAreaAction != null) {
      const currentIndex = this.areaActions.findIndex((obj) => obj.id === this.currentAreaAction!.id);
      const nextAreaAction = this.areaActions[currentIndex + 1];
      const prevAreaAction = this.areaActions[currentIndex - 1];

      if (next && nextAreaAction) {
        this.currentAreaAction = nextAreaAction;
      } else if (prevAreaAction && next == false) {
        this.currentAreaAction = prevAreaAction;
      }

      if (this.mapLayers.length > 0) {
        this.mapLayers = this.currentAreaAction.extraProperties?.coordinates ? [this.currentAreaAction.extraProperties.coordinates] : [[]];
      }
    } else {
      const action = this.areaActions.find((action) => action.extraProperties?.coordinates !== undefined);
      if (action && action.extraProperties?.coordinates) {
        this.currentAreaAction = action;
        this.mapLayers = [action.extraProperties.coordinates];
      } else {
        this.mapLayers = [[]];
      }
    }
  }

  public setAreaActionMarkers() {
    const allAreaActionMarkers: AreaActionCustomMarker[] = [];
    this.areaActions.forEach((element) => {
      if (!element.extraProperties?.AreaCoordinates) return;
      allAreaActionMarkers.push({
        position: {
          lat: Number(element.extraProperties!.AreaCoordinates!.lat),
          lng: Number(element.extraProperties!.AreaCoordinates!.lng),
        },
        id: element.id!,
        options: {
          zIndex: 1,
        },
      });
    });
    this.areaActionMarkers = allAreaActionMarkers;
  }

  private setLegendItems() {
    const order = [
      RequestStates.NEW,
      RequestStates.PICKED,
      RequestStates.DATE,
      RequestStates.FILLED_IN,
      RequestStates.DONE,
      RequestStates.DISAPPROVED,
      RequestStates.CANCELED,
      RequestStates.PENDING_REJECT,
      RequestStates.PENDING_DELETED,
    ];
    const coordinatorOnly = [RequestStates.CANCELED, RequestStates.PENDING_DELETED, RequestStates.DISAPPROVED];
    this.legendItems = Object.keys(RequestStates).map((key) => {
      const state = RequestStates[key as keyof typeof RequestStates];
      return {
        stateName: state,
        iconFile: state.toLowerCase(),
        coordinatorOnly: coordinatorOnly.includes(state),
      };
    });
    this.legendItems.sort((a, b) => order.indexOf(a.stateName) - order.indexOf(b.stateName));
  }

  public zoomChangedEventHandler(): void {
    this.storageService.store("usersettings.map.mapView.zoom", this.map.getZoom(), "localStorage");
  }

  public centerChangedEventHandler(): void {
    this.storageService.store("usersettings.map.mapView.center", this.map.getCenter(), "localStorage");
  }

  public toggleLegendOverlay(): void {
    this.legendOverlay = !this.legendOverlay;
    this.storageService.store("usersettings.map.mapView.legendOverlay", this.legendOverlay, "localStorage");
  }
  public togglePlanningOverlay(): void {
    this.planningOverlay = !this.planningOverlay;
    this.storageService.store("usersettings.map.planningView.planningOverlay", this.planningOverlay, "localStorage");
  }
}
