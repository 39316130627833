import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ReplacementService } from "../../services/replacement.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ReplacementClaim } from "../../classes/flow/replacementClaim/ReplacementClaim";
import { ReplacementClaimOrdered } from "../../classes/flow/replacementClaim/ReplacementClaimOrdered";
import { alphaValidator } from "../../validators/alpha";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-replacement-claim-reject-form",
  templateUrl: "./replacement-claim-reject-form.component.html",
  styleUrls: ["./replacement-claim-reject-form.component.less"],
})
export class ReplacementClaimRejectFormComponent {
  claimType: "CLAIM" | "ORDER" | "DELIVERY";

  claim: any;

  rejectForm: FormGroup = new FormGroup({
    message: new FormControl("", [Validators.required, Validators.minLength(5), alphaValidator, Validators.maxLength(200)]),
    confirm: new FormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private application: ApplicationService,
    private replacementClaim: ReplacementService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogRef: MatDialogRef<ReplacementClaimRejectFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { claim?: ReplacementClaim; order?: ReplacementClaimOrdered; delivery?: ReplacementClaimOrdered; type: string }
  ) {
    if (this.data.type == "order") {
      this.claimType = "ORDER";
      this.claim = data.order;
    } else if (this.data.type == "delivery") {
      this.claimType = "DELIVERY";
      this.claim = data.delivery;
    } else {
      this.claimType = "CLAIM";
      this.claim = data.claim;
    }
  }

  async reject() {
    this.application.setLoading(true);
    if (this.claimType === "CLAIM") {
      if (this.data.claim?.id) {
        const data = { claimId: this.data.claim.id, cancelReason: this.rejectForm.controls["message"].value };
        await this.replacementClaim.cancelClaim(data);
      }
    }

    if (this.claimType === "ORDER") {
      if (this.data.order?.orderId) {
        const data = { orderId: this.data.order?.orderId, rejectReason: this.rejectForm.controls["message"].value };
        await this.replacementClaim.rejectOrderClaim(data);
      }
    }

    if (this.claimType === "DELIVERY") {
      if (this.data.delivery?.deliveryId) {
        const data = { deliveryId: this.data.delivery?.deliveryId, rejectReason: this.rejectForm.controls["message"].value };
        await this.replacementClaim.rejectDeliveryClaim(data);
      }
    }

    this.dialogRef.close();
    this.router.navigate(["content"]);
    this.application.setLoading(false);
  }
}
