import { AfterContentInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EnergyConsult } from "../../../../classes/flow/request/EnergyConsult";
import { ApplicationService } from "../../../../services/application.service";
import { EnergyConsultService } from "../../../../services/energy-consult.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { UserService } from "../../../../services/user.service";
import { UtilitiesService } from "../../../../services/utilities.service";
import { GraphQLService } from "../../../../services/graphql.service";
import { DialogService } from "../../../../services/dialog.service";
import { RequestState, RequestStates } from "../../../../classes/flow/request/RequestStates";
import { downloadCSVFile } from "../../../../helpers/downloadCSV";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ENVIRONMENT } from "../../../../../environments/environment";
import { StorageService } from "../../../../services/storage.service";
import { ConsultListViewComponent } from "../../../../components/consult-views/consult-list-view/consult-list-view.component";
import { ConsultMapViewComponent } from "../../../../components/consult-views/consult-map-view/consult-map-view.component";
import { ConsultGridViewComponent } from "../../../../components/consult-views/consult-grid-view/consult-grid-view.component";
import { DashboardDataService } from "../../../../services/dashboard-data.service";

@Component({
  selector: "app-screen-dashboard",
  templateUrl: "./dashboard.html",
  styleUrls: ["./dashboard.less"],
})
export class DashboardComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(ConsultListViewComponent) listViewComponent?: ConsultListViewComponent;
  @ViewChild(ConsultMapViewComponent) mapViewComponent?: ConsultMapViewComponent;
  @ViewChild(ConsultGridViewComponent) gridViewComponent?: ConsultGridViewComponent;

  protected static readonly TITLE = "Dashboard ";

  public currentSortMethod!: "date" | "lastStateChangeDate";
  public sortAscending!: boolean;
  public viewMode: "grid" | "list" | "map" | "planning" = "grid";

  public infoContent = "";
  public allRequestStates: RequestState[] = [];
  public csvDownloadForm = new FormGroup({
    states: new FormControl<number[]>([], [Validators.required]),
    dateFrom: new FormControl<Date | null>(null, [Validators.required]),
    dateTo: new FormControl<Date | null>(null, [Validators.required]),
    allStates: new FormControl<boolean>(false),
    allDates: new FormControl<boolean>(false),
  });
  private csvStateSubscription?: Subscription;
  private csvDateSubscription?: Subscription;
  public toggleOtherCoachesRequestsSubscription?: Subscription;
  public todaysDate: Date;
  public minimalDate: Date;

  public filterForm = new FormGroup({
    filterInputControl: new FormControl<string>(""),
    otherCoaches: new FormControl<boolean>(false),
  });

  public constructor(
    public readonly applicationService: ApplicationService,
    private readonly requestService: EnergyConsultService,
    private readonly route: ActivatedRoute,
    public readonly userService: UserService,
    private readonly utilityService: UtilitiesService,
    private readonly translateService: TranslateService,
    private readonly snackService: SnackbarService,
    private readonly graphQLService: GraphQLService,
    private readonly dialogService: DialogService,
    private readonly storageService: StorageService,
    public dataService: DashboardDataService
  ) {
    this.todaysDate = new Date();
    this.minimalDate = new Date();

    this.minimalDate.setFullYear(this.minimalDate.getFullYear() - 10);
  }

  public ngOnInit(): void {
    this.applicationService.updateTitle(DashboardComponent.TITLE);

    if (this.applicationService.session.activeRole.name === "coordinator" || this.applicationService.session.activeRole.name === "coach") {
      this.filterForm.controls.filterInputControl.setValue(this.storageService.fetch("usersettings.filters.dashboard.inputfilter", "localStorage") ?? "");
      this.viewMode = this.storageService.fetch("usersettings.viewMode", "localStorage") ?? "grid";
    }

    const [sortMethod, sortAsc] = [
      <"date" | "lastStateChangeDate">this.storageService.fetch("usersettings.sorting.method", "localStorage") ?? "lastStateChangeDate",
      <boolean>this.storageService.fetch("usersettings.sorting.ascending", "localStorage") ?? true,
    ];

    this.currentSortMethod = sortMethod;
    this.sortAscending = sortAsc;

    if (this.applicationService.session.activeRole.name === "coach" && ENVIRONMENT.MODULES.includes("SHOW_ANOTHER_COACHES_ECS")) {
      this.toggleOtherCoachesRequestsSubscription = this.filterForm.controls.otherCoaches.valueChanges.subscribe((value) => {
        const coachID = this.applicationService.session.user?.id;
        if (value !== true && coachID) {
          this.dataService.consults = this.dataService.allConsults.filter((ec) => {
            if (ec.isActiveCoach(coachID) || ec.state.name === RequestStates.NEW) return true;
            return false;
          });
        } else {
          this.dataService.consults = this.dataService.allConsults;
        }
        this.filterRequests();
      });
    }

    this.csvStateSubscription = this.csvDownloadForm.controls.allStates.valueChanges.subscribe((value) => {
      if (value) {
        this.csvDownloadForm.controls.states.disable({ emitEvent: false });
        this.csvDownloadForm.controls.states.patchValue(
          this.allRequestStates.map((requestState) => requestState.id!),
          { emitEvent: false }
        );
      } else {
        if (this.csvDownloadForm.controls.states.disabled) this.csvDownloadForm.controls.states.enable({ emitEvent: false });
      }
    });

    this.csvDateSubscription = this.csvDownloadForm.controls.allDates.valueChanges.subscribe((value) => {
      if (value) {
        this.csvDownloadForm.controls.dateFrom.disable({ emitEvent: false });
        this.csvDownloadForm.controls.dateTo.disable({ emitEvent: false });
        this.csvDownloadForm.controls.dateFrom.patchValue(null, { emitEvent: false });
        this.csvDownloadForm.controls.dateTo.patchValue(null), { emitEvent: false };
      } else {
        if (this.csvDownloadForm.controls.dateFrom.disabled) this.csvDownloadForm.controls.dateFrom.enable({ emitEvent: false });
        if (this.csvDownloadForm.controls.dateTo.disabled) this.csvDownloadForm.controls.dateTo.enable({ emitEvent: false });
      }
    });
  }

  ngOnDestroy(): void {
    this.csvStateSubscription?.unsubscribe();
    this.csvDateSubscription?.unsubscribe();
    this.toggleOtherCoachesRequestsSubscription?.unsubscribe();
  }

  async ngAfterContentInit() {
    this.allRequestStates = await this.requestService.getRequestStates();
    this.csvDownloadForm.controls.states.patchValue(this.allRequestStates.map((requestState) => requestState.id!));
    const id = this.route.snapshot.paramMap.get("id") ?? "";
    const data = id ? id.split("_") : [];
    this.applicationService.setLoading(true);
    data.length > 0 ? await this.initRequests(data[0], data[1]) : await this.initRequests("", "");
    this.initFilter();
    this.filterForm.controls.otherCoaches.setValue(false);
    this.filterRequests();
    this.sortRequests();
    this.dataService.filteredConsults = this.pushBookmarkedToFront(this.dataService.filteredConsults);

    if (this.viewMode === "map") {
      //this.mapViewComponent?.loadMap(); //only call after initfilter() has been called
      this.mapViewComponent?.setFilteredMarkers();
    }

    if (ENVIRONMENT.MODULES.includes("AREA_ACTIONS")) {
      this.mapViewComponent?.getAreaActions();

      if (ENVIRONMENT.MODULES.includes("AREA_ACTIONS_MAP_LAYERS")) {
        this.mapViewComponent?.setPostalCodeLayers(true);
      }
      this.mapViewComponent?.setAreaActionMarkers();
    }
    this.applicationService.setLoading(false);
  }

  /**
   * Initializes filter per role
   */
  private initFilter() {
    this.applicationService.setLoading(true);
    const stateFilters = this.utilityService.initStateFilter(this.dataService.allConsults);
    this.dataService.filterGroups = [
      { name: "state", filters: stateFilters, activeFilters: [], description: this.translateService.instant("COMPONENTS.DASHBOARD.FILTERS.STATE") },
    ];

    if (this.applicationService.session.activeRole.name === "coach") {
      const specialtyFilters = this.utilityService.initSpecialtyFilter(this.dataService.consults);
      this.dataService.filterGroups.push({
        name: "specialty",
        filters: specialtyFilters,
        activeFilters: [],
        description: this.translateService.instant("COMPONENTS.DASHBOARD.FILTERS.SPECIALTY"),
      });
    }

    //set Active Filters
    if (this.dataService.filterGroups.length > 1) {
      this.dataService.filterGroups[0] = this.utilityService.getSavedFiltersFromStorage("state", this.dataService.filterGroups[0]);
      this.dataService.filterGroups[1] = this.utilityService.getSavedFiltersFromStorage("specialty", this.dataService.filterGroups[1]);
    } else {
      this.dataService.filterGroups[0] = this.utilityService.getSavedFiltersFromStorage("state", this.dataService.filterGroups[0]);
    }
    this.filterRequests();
    this.applicationService.setLoading(false);
  }

  /**
   * Filters the requests
   */
  public filterRequests() {
    this.dataService.filteredConsults = this.utilityService.filterRequests(this.dataService.filterGroups, this.dataService.consults);
    this.dataService.filteredConsults = this.pushBookmarkedToFront(this.dataService.filteredConsults);
    this.storageService.store("usersettings.filters.dashboard.inputfilter", this.filterForm.controls.filterInputControl.value, "localStorage");
    if (this.filterForm.controls.filterInputControl.value !== null && this.filterForm.controls.filterInputControl.value !== "") this.FilterByInput();
    this.listViewComponent?.setShown(this.dataService.filterGroups);
    if (this.dataService.filterGroups.length > 1) {
      this.utilityService.setSavedFiltersInStorage("state", this.dataService.filterGroups[0]);
      this.utilityService.setSavedFiltersInStorage("specialty", this.dataService.filterGroups[1]);
    } else {
      this.utilityService.setSavedFiltersInStorage("state", this.dataService.filterGroups[0]);
    }
  }

  /**
   * pushes all bookmarked requests to front and retains order
   * @param array energy consult array (Filtered requests)
   * @returns adjusted array
   */
  private pushBookmarkedToFront(array: EnergyConsult[]): EnergyConsult[] {
    const bookmarked: EnergyConsult[] = [];
    for (let index = 0; index < array.length; index++) {
      if (array[index].bookmarked) {
        bookmarked.push(array.splice(index, 1)[0]);
        index--;
      }
    }
    array.unshift(...bookmarked);
    return array;
  }

  /**
   * checks the value of the input field and then filters filteredRequest
   * @param field has the value of the input field
   * currently filters on {
   *  coach {
   *    fullname
   *    email
   *  }
   *  resident {
   *    fullname
   *    email
   *  }
   *  postalcode
   *  housenumber
   *  housenumbersuffix
   *  streetname
   *  town
   *  message
   * }
   */
  public FilterByInput() {
    const field = this.filterForm.controls.filterInputControl.value;
    if (!field) return;
    this.dataService.filteredConsults = this.dataService.filteredConsults.filter((econsult) => {
      let areaCheck = false;

      econsult.areaActions?.forEach((aa) => {
        const areaActionValue = [
          aa.municipality!.toLocaleLowerCase() +
            aa.area!.toLocaleLowerCase() +
            aa.actionType!.toLocaleLowerCase() +
            aa.zipCodeFrom.toLocaleLowerCase() +
            aa.zipCodeTo.toLocaleLowerCase() +
            aa.dateFrom.toLocaleDateString() +
            aa.dateTo.toLocaleDateString(),
        ];
        if (areaActionValue!.toString().toLowerCase().includes(field.toLowerCase())) {
          areaCheck = true;
        }
      });

      if (
        (econsult.extraProperties?.addressdetails?.municipality?.toLowerCase().includes(field.toLowerCase()) &&
          econsult.extraProperties?.addressdetails?.municipality?.length >= field.length) ||
        (econsult.coach?.fullName.toLowerCase().includes(field.toLowerCase()) && econsult.coach?.fullName.length >= field.length) ||
        (econsult.coach?.email.toLowerCase().includes(field.toLowerCase()) && econsult.coach?.email.length >= field.length) ||
        (econsult.resident?.fullName.toLowerCase().includes(field.toLowerCase()) && econsult.resident?.fullName.length >= field.length) ||
        (econsult.resident?.email.toLowerCase().includes(field.toLowerCase()) && econsult.resident?.email.length >= field.length) ||
        (econsult.postalCode.toLowerCase().includes(field.toLowerCase()) && econsult.postalCode.length >= field.length) ||
        ((String(econsult.houseNumber).toLowerCase() + econsult.houseNumberSuffix?.toLowerCase()).includes(field.toLowerCase()) && econsult.message.length >= field.length) ||
        (econsult.houseNumberSuffix?.toLowerCase().includes(field.toLowerCase()) && econsult.houseNumberSuffix.length >= field.length) ||
        (econsult.extraProperties?.streetname?.toLowerCase().includes(field.toLowerCase()) && econsult.extraProperties.streetname.length >= field.length) ||
        (econsult.extraProperties?.addressdetails?.town?.toLowerCase().includes(field.toLowerCase()) && econsult.extraProperties.addressdetails.town.length >= field.length) ||
        (econsult.message.toLowerCase().includes(field.toLowerCase()) && econsult.message.length >= field.length) ||
        areaCheck ||
        (["gekopieerd", "gekopieërd", "copied", "ckd"].includes(field.toLowerCase()) && econsult.ckd && econsult.ckdInfo) ||
        field.length == 0
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  /**
   * SortMethod to sort request on either the date it was made (Date), or Sort by date of changes in progress(lastStateChangeDate).
   * @param sortMethod Current sorting method used.
   * @param Date Sorting request on the date it was made.
   * @param lastStateChangeDate Sorts by date of changes in progress.
   * @returns Date or lastStateChangeDate.
   */
  public sortRequests(sortMethod?: "date" | "lastStateChangeDate") {
    if (sortMethod) {
      if (sortMethod !== this.currentSortMethod) {
        this.sortAscending = true;
      }
      this.currentSortMethod = sortMethod;
      this.storageService.store("usersettings.sorting.method", this.currentSortMethod, "localStorage");
      this.storageService.store("usersettings.sorting.ascending", this.sortAscending, "localStorage");
    }

    this.utilityService.sortRequestsBy(this.currentSortMethod, this.sortAscending, this.dataService.filteredConsults);
    this.dataService.filteredConsults = this.pushBookmarkedToFront(this.dataService.filteredConsults);
    this.listViewComponent?.sort(this.currentSortMethod, this.sortAscending);
    this.gridViewComponent?.resetAfterSort();
    if (this.sortAscending) {
      this.sortAscending = false;
    } else {
      this.sortAscending = true;
    }
  }

  //Opens the dialog
  public openDialog(dialog: TemplateRef<unknown>) {
    this.dialogService.open({
      template: dialog,
    });
  }

  /**
   * Return requests depending on states
   * @returns
   */
  public async initRequests(id: string, role: string) {
    if (this.dataService.state !== "fetched") {
      this.applicationService.setLoading(true);
      await this.dataService.refresh();
      this.applicationService.setLoading(false);
    }
  }

  public async refresh() {
    this.dataService.state = "unfetched";
    await this.ngAfterContentInit();
  }

  /**
   *
   * @param requests
   * @returns total amount of new requests
   */
  public getNewRequest(requests: EnergyConsult[]) {
    const totalNewRequests = requests.filter((request) => request.state.name === RequestStates.NEW);
    return totalNewRequests;
  }
  public getNewRequestByType() {
    const requests = this.getNewRequest(this.dataService.consults);
    const requestCountPerSpecialty = new Map();

    requests.forEach((request) => {
      const count = requestCountPerSpecialty.get(request.specialty.name) || 0;
      requestCountPerSpecialty.set(request.specialty.name, count + 1);
    });
    const array = Array.from(requestCountPerSpecialty, ([name, value]) => ({ name, value }));
    return array;
  }
  public async coachReminder() {
    // eslint-disable-next-line quotes
    const res = await this.graphQLService.query(`mutation sendReminderMail{
      sendNewReportCounts {
        value {
          email
        }
        messages{
          message
        }
      }
    }`);
    if (res.data["send_report_as_pdf"]?.message) {
      throw new Error("Something went wrong");
    } else {
      this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.MAIL"));
    }
  }

  public setViewMode(mode: "grid" | "list" | "map" | "planning") {
    this.applicationService.setLoading(true);
    this.viewMode = mode;
    this.storageService.store("usersettings.viewMode", this.viewMode.toString(), "localStorage");
    setTimeout(() => {
      this.applicationService.setLoading(false);
    }, 0);
  }

  public async downloadCSV(event: Event) {
    event.preventDefault();
    if (!this.csvDownloadForm.valid) return;
    const value = this.csvDownloadForm.value;
    try {
      //get data of areaactions per consult
      const aaMappedToEC: { id: number; aaID: number }[] = [];
      ENVIRONMENT.MODULES.includes("AREA_ACTIONS")
        ? this.dataService.consults
          .filter((ec) => ec.areaActions !== undefined && ec.areaActions.length > 0)
          .forEach((ec) => {
            for (const aaID of ec.areaActions!) {
              aaMappedToEC.push({ id: ec.id, aaID: aaID.id! });
            }
          })
        : undefined;
      const csvString = await this.requestService.getAllConsultsCSV({
        states: value.allStates ? this.allRequestStates.map((requestState) => requestState.id!) : value.states ?? [],
        dateFrom: !value.allDates && value.dateFrom && value.dateTo ? value.dateFrom : undefined,
        dateTo: !value.allDates && value.dateFrom && value.dateTo ? value.dateTo : undefined,
        actionsLinkedToEC: aaMappedToEC,
      });
      if (csvString == null) throw new Error("empty csv");
      downloadCSVFile(csvString, "csv_" + new Date().toLocaleDateString());
    } catch {
      this.snackService.error();
    }
  }
}
