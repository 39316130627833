import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";
import { BehaviorSubject } from "rxjs";
import { SnackbarService } from "./snackbar.service";
import { User } from "../classes/flow/session/impl/User";
import { Retailer } from "../classes/flow/session/impl/Retailer";
import { Changes } from "../classes/flow/base/Changes";
import { convertDate } from "../helpers/convertDate";
import { ReplacementClaim } from "../classes/flow/replacementClaim/ReplacementClaim";
import { ReplacementClaimApplianceDelivery } from "../classes/flow/replacementClaim/ReplacementClaimApplianceDelivery";
import { ReplacementClaimOrdered } from "../classes/flow/replacementClaim/ReplacementClaimOrdered";
import { ReplacementClaimApplianceOrder } from "../classes/flow/replacementClaim/replacementClaimApplianceOrder";
import { ReplacementClaimAppliance } from "../classes/flow/replacementClaim/ReplacementClaimAppliance";
import { ReplacementClaimReturnAppliance } from "../classes/flow/replacementClaim/ReplacementClaimReturnAppliance";

@Injectable({
  providedIn: "root",
})
export class ReplacementService {
  claims: {
    replacementClaimsOpen: ReplacementClaimOrdered[];
    replacementClaimsOrdered: ReplacementClaimOrdered[];
    replacementClaimsDelivered: ReplacementClaimOrdered[];
    replacementClaimsOwned: ReplacementClaimOrdered[];
  } = {
      replacementClaimsOpen: [],
      replacementClaimsOrdered: [],
      replacementClaimsDelivered: [],
      replacementClaimsOwned: [],
    };
  claimsBehaviorSubject: BehaviorSubject<any> = new BehaviorSubject(this.claims);

  public constructor(private readonly graphqlService: GraphQLService, public snackbar: SnackbarService) {}

  public async addReplacementClaim(claim: {
    state?: number;
    coachId?: number;
    houseNumber?: number;
    houseNumberSuffix?: string;
    postalCode?: string;
    residentId?: number;
    rejectReason?: string;
    cancelReason?: string;
    message?: string;
    retailerIdPreferred?: number;
    bsn?: string;
  }): Promise<number | null> {
    const result = await this.graphqlService.query(`
      mutation {
        addReplacementClaim(
          replacementClaim: {
            ${claim.state ? `claimStateId: ${claim.state}` : ""}
            ${claim.coachId ? `coachId: ${claim.coachId}` : ""}
            ${claim.houseNumber ? `houseNumber: ${claim.houseNumber}` : ""}
            ${claim.houseNumberSuffix ? `houseNumberSuffix: "${claim.houseNumberSuffix}"` : ""}
            ${claim.postalCode ? `postalCode: "${claim.postalCode}"` : ""}
            ${claim.residentId ? `residentId: ${claim.residentId}` : ""}
            ${claim.retailerIdPreferred ? `retailerIdPreferred: ${claim.retailerIdPreferred}` : ""}
            ${claim.rejectReason ? `rejectReason: "${claim.rejectReason}"` : ""}
            ${claim.cancelReason ? `cancelReason: "${claim.cancelReason}"` : ""}
            ${claim.message ? `message: "${claim.message}"` : ""}
            ${claim.bsn ? `bsn: "${claim.bsn}"` : ""}
          }
        ) {
          value {
            id
          }
          messages {
            message
          }
        }
      }
  `);
    if (result.errors) {
      this.snackbar.error();
      return null;
    }
    const claimId = result?.data?.addReplacementClaim?.value?.id;
    if (!claimId) {
      this.snackbar.error();
      return null;
    }
    return claimId;
  }

  public async addReplacementClaimAppliance(appliance: {
    replacementClaimId?: number;
    applianceCategoryId?: number;
    description?: string;
    hOID?: string;
  }): Promise<number | null> {
    const result = await this.graphqlService.query(`
      mutation {
        addReplacementClaimAppliance(
          replacementClaim: {
            ${appliance.replacementClaimId ? `replacementClaimId: ${appliance.replacementClaimId}` : ""}
            ${appliance.applianceCategoryId ? `applianceCategoryId: ${appliance.applianceCategoryId}` : ""}
            ${appliance.description ? `description: "${appliance.description}"` : ""}
            ${appliance.hOID ? `hOID: "${appliance.hOID}"` : ""}
          }
        ) {
          value {
            id
          }
          messages {
            message
          }
        }
      }
  `);
    if (result.errors) {
      this.snackbar.error();
      return null;
    }
    const claimId = result?.data?.addReplacementClaimAppliance?.value?.id;
    if (!claimId) {
      this.snackbar.error();
      return null;
    }
    return claimId;
  }

  public async addReplacementClaimReturnAppliance(appliance: {
    replacementClaimId?: number;
    applianceCategoryId?: number;
    returnStateId?: number;
    description?: string;
    hOID?: string;
    rejectReason?: string;
  }): Promise<number | null> {
    const result = await this.graphqlService.query(`
      mutation {
        addReplacementClaimReturnAppliance(
          replacementClaim: {
            ${appliance.replacementClaimId ? `replacementClaimId: ${appliance.replacementClaimId}` : ""}
            ${appliance.applianceCategoryId ? `applianceCategoryId: ${appliance.applianceCategoryId}` : ""}
            ${appliance.returnStateId ? `returnStateId: ${appliance.returnStateId}` : ""}
            ${appliance.description ? `description: "${appliance.description}"` : ""}
            ${appliance.hOID ? `hOID: "${appliance.hOID}"` : ""}
            ${appliance.rejectReason ? `rejectReason: "${appliance.rejectReason}"` : ""}
          }
        ) {
          value {
            id
          }
          messages {
            message
          }
        }
      }
  `);
    if (result.errors) {
      this.snackbar.error();
      return null;
    }
    const claimId = result?.data?.addReplacementClaimReturnAppliance?.value?.id;
    if (!claimId) {
      this.snackbar.error();
      return null;
    }
    return claimId;
  }

  public async updateReplacementClaim(
    id: number,
    claim: {
      claimStateId?: number;
      coachId?: number;
      houseNumber?: number;
      residentId?: number;
      retailerIdPreferred?: number;
      cancelReason?: string;
      hOID?: string;
      houseNumberSuffix?: string;
      postalCode?: string;
      rejectReason?: string;
      changes: Changes;
    }
  ): Promise<number | null> {
    const mutation = `
      mutation {
        updateReplacementClaim(
          input: {
            id: ${id}
            set: {
              ${claim.claimStateId ? `claimStateId: ${claim.claimStateId}` : ""}
              ${claim.coachId ? `coachId: ${claim.coachId}` : ""}
              ${claim.houseNumber ? `houseNumber: ${claim.houseNumber}` : ""}
              ${claim.residentId ? `residentId: ${claim.residentId}` : ""}
              ${claim.retailerIdPreferred ? `retailerIdPreferred: ${claim.retailerIdPreferred}` : ""}
              ${claim.cancelReason ? `cancelReason: "${claim.cancelReason}"` : ""}
              ${claim.hOID ? `hOID: "${claim.hOID}"` : ""}
              ${claim.houseNumberSuffix ? `houseNumberSuffix: "${claim.houseNumberSuffix}"` : ""}
              ${claim.postalCode ? `postalCode: "${claim.postalCode}"` : ""}
              ${claim.rejectReason ? `rejectReason: "${claim.rejectReason}"` : ""}
              changes: ${this.graphqlService.hasuraArrayObjectCleaner(claim.changes)}
            }
          }
        ) {
          value {
            id
          }
          messages {
            message
          }
        }
      }
  `;

    const result = await this.graphqlService.query(mutation);
    if (result.errors) {
      this.snackbar.error();
      return null;
    }
    const claimId = result?.data?.updateReplacementClaim?.value?.id;
    if (!claimId) {
      this.snackbar.error();
      return null;
    }
    return claimId;
  }

  async queryReplacementClaimsOpen() {
    const result = await this.graphqlService.query(
      `query {
        replacementClaims {
          value(where: {claimStateId: { eq: 3 , neq: 7} }) {
            id
            postalCode
            houseNumber
            houseNumberSuffix
            message
            claimState {
              id
              name
            }
            resident {
              id
              firstName
              lastName
              postalCode
              houseNumber
              houseNumberSuffix
              email
              phoneNumber
            }
            appliances {
              description
              applianceCategory
            }
            retailerIdPreferred
            statusLastChanged
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );

    const tempClaims = result["data"]["replacementClaims"]["value"];
    const mappedClaims: ReplacementClaim[] = tempClaims.map((claim: any) => {
      return new ReplacementClaim(claim);
    });
    this.claims.replacementClaimsOpen = mappedClaims;

    this.claimsBehaviorSubject.next(this.claims);
  }

  async queryReplacementClaimsOwned(user: User) {
    const retailer = user as Retailer;
    const orderClaims = await this.graphqlService.query(`
      query retailerOwned {
        replacementClaimRetailerOrdereds {
          value(
            where: {
              and: [{ retailerId: { eq: ${retailer.id} }, claimStateId: { nin: [6, 7, 8, 9] } }]
            }
          ) {
              id,
              claimStateId,
              claimState,
              streetName,
              postalCode,
              houseNumber,
              houseNumberSuffix,
              coachID,
              message,
              orderId,
              orderStateId,
              orderState,
              retailerId,
              deliveryStateId
              deliveryState,
              deliveryId,
              tijGepland,
              datGepland,
              typeName,
              categoryName,
              residentId,
              firstName,
              lastName,
              residentEmailZakelijk,
              residentTelefoonnrZakelijk,
            }
            messages{
              message
            }
          }
        }
      `);

    const tempClaims = orderClaims["data"]["replacementClaimRetailerOrdereds"]["value"];
    const mappedClaims: ReplacementClaimOrdered[] = tempClaims.map((claim: any) => {
      return new ReplacementClaimOrdered(claim);
    });
    this.claims.replacementClaimsOwned = mappedClaims.sort((a, b) => {
      return b.claimStateId! - a.claimStateId!;
    });
    this.claimsBehaviorSubject.next(this.claims);
  }

  public async getCategories() {
    const result = await this.graphqlService.query(`
      query{
        applianceCategories {
          value {
            id,
            name,
            typeId,
            expired
          }
        }
      }
    `);
    return result.data.applianceCategories.value;
  }

  public async getTypes() {
    const result = await this.graphqlService.query(`
      query{
        applianceTypes {
          value {
            id,
            name,
            code
          }
        }
      }
    `);
    return result.data.applianceTypes.value;
  }

  public async getClaimById(id: number) {
    const result = await this.graphqlService.query(`
    query getClaimById {
      replacementClaimRetailerOrdereds {
            value(where: {
              and: [{ id: { eq: ${id} }}]
            }){
                id
                categoryName
                postalCode
                houseNumber
                houseNumberSuffix
                message
                residentEmailZakelijk
                residentTelefoonnrZakelijk
                coachID
                residentId
                claimStateId
                claimState
                retailerId
                orderStateId
                orderState
                tijGepland
                datGepland
                deliveryStateId
                deliveryState
                typeName
                deliveryId
                orderId
                streetName
              }
              messages{
                message
              }
            }
      }`);

    return result.data.replacementClaimRetailerOrdereds.value;
  }

  async acceptReplacementClaim(replacementClaim: ReplacementClaim) {
    await this.graphqlService.query(
      `mutation acceptReplacementClaim {
        updateReplacementClaim(input: {id: ${replacementClaim.id}, set: {
          claimStateId: 4,
          changes: ${this.graphqlService.hasuraArrayObjectCleaner(replacementClaim.changes)}}}) {
          value {
            id
            claimStateId
            claimState {
              id
              name
            }
          }
          messages{
            message
          }
        }
      }`
    );
  }

  async scheduleReplacementClaim(date: Date, time: Date, orderId: number) {
    const mutation = `mutation addDelivery {
        addReplacementClaimApplianceDelivery(
          replacementClaim: {
            replacementClaimApplianceOrderId: ${orderId},
            plannedDate: "${convertDate(date)}",
            plannedTime: "${convertDate(time)}",
            deliveryStateId: 4,
          }
          ) {
            value {
              id
              replacementClaimApplianceOrderId
            }
            messages {
            message
          }
        }
      }`;
    const result = await this.graphqlService.query(mutation);
    return result.data.addReplacementClaimApplianceDelivery.value;
  }

  async cancelClaim(data: { claimId: number; cancelReason: string }) {
    const mutation = `mutation {
      updateReplacementClaim(
        input: {
          id: ${data.claimId}
          set: {
            claimStateId: 9
            cancelReason: "${data.cancelReason}"
            postalCode: "1234AB"
            houseNumber: 56
          }
        }
      ) {
        value {
          cancelReason
        }
      }
    }`;
    const changesRes: any = await this.graphqlService.query(mutation);
    if (changesRes) {
      this.snackbar.open("Vervangingsaanvraag geannuleerd");
    }
  }

  async rejectDeliveryClaim(data: { deliveryId: number; rejectReason: string }) {
    const changesRes: any = await this.graphqlService.query(
      `
        query getDeliveryChanges {
          replacementClaimApplianceDelivery {
            value(where: {id: {eq: ${data.deliveryId}}}) {
              id
              plannedTime
              plannedDate
              changes {
                lastChange {
                  userId
                  time
                }
                fullDetails {
                  key
                  value {
                    time
                    userId
                  }
                }
              }
            }
          }
        }
      `
    );
    const changes: Changes = changesRes["data"]["replacementClaimApplianceDelivery"]["value"][0]["changes"];
    const plannedTime: any = changesRes["data"]["replacementClaimApplianceDelivery"]["value"][0]["plannedTime"];
    const plannedDate: any = changesRes["data"]["replacementClaimApplianceDelivery"]["value"][0]["plannedDate"];
    const claimResponse = await this.graphqlService.query(`
    mutation updateDeliveryState {
      updateReplacementClaimApplianceDelivery(input: {
        id: ${data.deliveryId},
        set: {
          deliveryStateId: 6,
          plannedDate: "${plannedDate}",
          plannedTime: "${plannedTime}",
          rejectReason: "${data.rejectReason}",
          changes: ${`{
            fullDetails: ${this.graphqlService.hasuraArrayObjectCleaner(changes.fullDetails)}
          }`}}}) {
            value {
              id
            }
          }
        }`);
    if (claimResponse) {
      return true;
    }
    return false;
  }

  async rejectOrderClaim(data: { orderId: number; rejectReason: string }) {
    const changesRes: any = await this.graphqlService.query(
      `
      query getOrderChanges {
        replacementClaimApplianceOrders {
          value(where: {id: {eq: ${data.orderId}}}) {
            id
            changes {
              fullDetails{
                key
                value {
                  userId
                  time
                }
              }
               lastChange {
                 userId
                 time
               }
            }
          }
          messages{
            message
          }
        }
      }
      `
    );
    const changes: Changes = changesRes["data"]["replacementClaimApplianceOrders"]["value"][0]["changes"];
    const claimResponse = await this.graphqlService.query(`
    mutation updateOrderState {
      updateReplacementClaimApplianceOrder(input: {
        id: ${data.orderId},
        set: {
          orderStateID: 3,
          rejectReason: "${data.rejectReason}",
          changes: ${`{
            fullDetails: ${this.graphqlService.hasuraArrayObjectCleaner(changes.fullDetails)}
          }`}}}) {
            value {
              id
            }
          }
        }`);
    if (claimResponse) {
      return true;
    }
    return false;
  }

  public async getReplacementClaim(id: number): Promise<ReplacementClaim | null> {
    const claimResponse = await this.graphqlService.query(`
      query {
        replacementClaims {
          value(where: { id: { eq: ${id} } }) {
            id
            postalCode
            houseNumber
            houseNumberSuffix
            streetName
            message
            claimState {
              id
              name
            }
            resident {
              id
              firstName
              lastName
              postalCode
              houseNumber
              houseNumberSuffix
              email
              phoneNumber
            }
            appliances {
              id
              applianceCategory
              description
            }
            retailerIdPreferred
            statusLastChanged
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
        }
      }
    `);
    const claim = claimResponse.data.replacementClaims.value[0];
    if (!claim) return null;
    return new ReplacementClaim(claim);
  }

  public async getReplacementClaims(): Promise<ReplacementClaim[] | null> {
    const claimResponse = await this.graphqlService.query(`
      query {
        replacementClaims {
          value {
            id
            postalCode
            houseNumber
            houseNumberSuffix
            streetName
            message
            claimState {
              id
              name
            }
            resident {
              id
              firstName
              lastName
              postalCode
              houseNumber
              houseNumberSuffix
              email
              phoneNumber
            }
            appliances {
              id
              applianceType
              applianceCategory
              description
            }
            retailerIdPreferred
            statusLastChanged
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
        }
      }
    `);
    const claim = claimResponse.data.replacementClaims.value;
    if (!claim) return null;
    return claim.map((claim: any) => new ReplacementClaim(claim));
  }

  public async getReplacementClaimApplianceOrder(id: number): Promise<ReplacementClaimApplianceOrder | null> {
    const orderResponse = await this.graphqlService.query(`
    query {
      replacementClaimApplianceOrders {
        value(where: { id: { eq: ${id} } }) {
          id
          orderStateID
          retailerID
          replacementClaimApplianceID
          description
          retailerID
          hOID
          changes {
            fullDetails {
              key
              value {
                userId,
                time
              }
            }
            lastChange {
              userId,
              time
            }
          }
        }
        messages {
          message
        }
      }
    }
  `);
    const order = orderResponse.data.replacementClaimApplianceOrders.value[0];
    if (!order) return null;
    return new ReplacementClaimApplianceOrder(order);
  }

  public async getReplacementClaimApplianceDelivery(id: number): Promise<ReplacementClaimApplianceDelivery | null> {
    const deliveryResponse = await this.graphqlService.query(`
    query {
      replacementClaimApplianceDelivery {
        value(where: { id: { eq: ${id} } }) {
          id,
          replacementClaimApplianceOrderId,
          deliveryStateId,
          description,
          plannedDate,
          plannedTime,
          hOID,
          changes {
            fullDetails {
              key
              value {
                userId,
                time
              }
            }
            lastChange {
              userId,
              time
            }
          }
        }
        messages {
          message
        }
      }
    }
  `);
    const delivery = deliveryResponse.data.replacementClaimApplianceDelivery.value[0];
    if (!delivery) return null;
    return new ReplacementClaimApplianceDelivery(delivery);
  }

  public async updateReplacementClaimAppliance(
    id: number,
    appliance: {
      applianceCategoryId?: number;
      hOID?: string;
      description?: string;
      changes: Changes;
    }
  ) {
    await this.graphqlService.query(`
      mutation {
        updateReplacementClaimAppliance(input: {
          id: ${id},
          set: {
            ${appliance.applianceCategoryId ? `applianceCategoryId: ${appliance.applianceCategoryId}` : ""}
            ${appliance.hOID ? `hOID: "${appliance.hOID}"` : ""}
            ${appliance.description ? `description: "${appliance.description}"` : ""}
            changes: {
              fullDetails: ${this.graphqlService.hasuraArrayObjectCleaner(appliance.changes.fullDetails)}
            }
          }
        }) {
          value {
            id
          }
        }
      }
    `);
  }

  public async updateReplacementClaimReturnAppliance(
    id: number,
    appliance: {
      applianceCategoryId?: number;
      returnStateId?: number;
      hOID?: string;
      hOIDScanned?: string;
      description?: string;
      rejectReason?: string;
      changes: Changes;
    }
  ) {
    await this.graphqlService.query(`
      mutation {
        updateReplacementClaimReturnAppliance(input: {
          id: ${id},
          set: {
            ${appliance.applianceCategoryId ? `applianceCategoryId: ${appliance.applianceCategoryId}` : ""}
            ${appliance.returnStateId ? `returnStateId: ${appliance.returnStateId}` : ""}
            ${appliance.hOID ? `hOID: "${appliance.hOID}"` : ""}
            ${appliance.hOIDScanned ? `hOIDScanned: "${appliance.hOIDScanned}"` : ""}
            ${appliance.description ? `description: "${appliance.description}"` : ""}
            ${appliance.rejectReason ? `rejectReason: "${appliance.rejectReason}"` : ""}
            changes: {
              fullDetails: ${this.graphqlService.hasuraArrayObjectCleaner(appliance.changes.fullDetails)}
            }
          }
        }) {
          value {
            id
          }
        }
      }
    `);
  }

  public async updateReplacementClaimApplianceDelivery(
    id: number,
    delivery: {
      deliveryStateId?: number;
      description?: string;
      hOID?: string;
      rejectReason?: string;
      plannedDate?: Date;
      plannedTime?: Date;
      changes: Changes;
    }
  ) {
    await this.graphqlService.query(`
      mutation {
        updateReplacementClaimApplianceDelivery(input: {
          id: ${id},
          set: {
            ${delivery.deliveryStateId ? `deliveryStateId: ${delivery.deliveryStateId}` : ""}
            ${delivery.description ? `description: "${delivery.description}"` : ""}
            ${delivery.hOID ? `hOID: "${delivery.hOID}"` : ""}
            ${delivery.rejectReason ? `rejectReason: "${delivery.rejectReason}"` : ""}
            ${delivery.plannedDate ? `plannedDate: "${convertDate(delivery.plannedDate)}"` : ""}
            ${delivery.plannedTime ? `plannedTime: "${convertDate(delivery.plannedTime)}"` : ""}
            changes: {
              fullDetails: ${this.graphqlService.hasuraArrayObjectCleaner(delivery.changes.fullDetails)}
            }
          }
        }) {
          value {
            id
          }
        }
      }
    `);
  }

  public async getClaimAppliance(claimId: number): Promise<ReplacementClaimAppliance | null> {
    const result = await this.graphqlService.query(`
      query {
        replacementClaimAppliance {
          value (where: {replacementClaimId: {eq: ${claimId}} }){
            id
            replacementClaimId
            applianceCategoryId
            applianceCategory
            description
            hOID
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
        }
      }
    `);
    return (
      result.data.replacementClaimAppliance.value.map((appliance: any) => {
        return new ReplacementClaimAppliance(appliance);
      })?.[0] ?? null
    );
  }

  public async getClaimReturnAppliances(claimId: number): Promise<ReplacementClaimReturnAppliance[]> {
    const result = await this.graphqlService.query(`
      query {
        replacementClaimReturnAppliance {
          value (where: {replacementClaimId: {eq: ${claimId}} }){
            id
            replacementClaimId
            applianceCategoryId
            applianceCategory
            description
            returnStateId
            hOID
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
        }
      }
    `);
    return (
      result.data.replacementClaimReturnAppliance.value.map((appliance: any) => {
        return new ReplacementClaimReturnAppliance(appliance);
      }) ?? []
    );
  }
}
