<div id="menuContainer" #menuContainer>
  <div class="row d-flex justify-content-center align-items-center">
    <div id="closeContainer" class="col d-flex align-self-center">
      <a class="icon" (keydown)="hide($event)" (click)="hide()" [tabIndex]="items.length + 1" #closeButton>
        <mat-icon style="color: white">close</mat-icon>
      </a>
    </div>

    <div class="row">
      <div class="col">
        <nav>
          <ul #ulContainer>
            <ng-container *ngIf="items.length > 0">
              <li class="item d-flex" *ngFor="let item of items; let i = index">
                <a (keydown)="navigate(item.href || item.route, $event)" (click)="navigate(item.href || item.route)" [tabindex]="i+1">
                  <span class="icon">
                    <mat-icon>{{item.icon}}</mat-icon>
                  </span>
                  <span class="label">{{ item.label === "MUNICIPALITY" ? applicationService.municipalitySlogan : ("MENU.ITEMS." + item.label) | translate}}</span>
                </a>
              </li>
            </ng-container>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div id="menuUnderlay" (click)="active = false"></div>
</div>
