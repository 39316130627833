<div class="container" *ngIf="this.applicationService.initialized; else loading">
  <div class="row">
    <div class="col-md-12">
      <h2>{{ "FORMS.NEW_ENERGY_CONSULT.TITLE" | translate }}</h2>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <ul>
        <li>{{ "FORMS.NEW_ENERGY_CONSULT.DESCRIPTION.STEPS.FIRST" | translate }}</li>
        <li>{{ "FORMS.NEW_ENERGY_CONSULT.DESCRIPTION.STEPS.SECOND" | translate }}</li>
        <li>{{ "FORMS.NEW_ENERGY_CONSULT.DESCRIPTION.STEPS.THIRD" | translate }}</li>
      </ul>
      <br />
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.EMAIL" | translate }}</mat-label>
              <input matInput formControlName="email" placeholder="jan@gmail.com" type="email" />
              <app-error [control]="formGroup.controls['email']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.FIRST_NAME" | translate }}</mat-label>
              <input matInput formControlName="firstName" placeholder="Jan" type="text" />
              <app-error [control]="formGroup.controls['firstName']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.LAST_NAME" | translate }}</mat-label>
              <input matInput formControlName="lastName" placeholder="Jansen" type="text" />
              <app-error [control]="formGroup.controls['lastName']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <ng-container *appExcludeModule="'PHONE_NUMBER_REQUIRED'">
            <div class="col-12">
              <mat-checkbox class="mb-3" color="primary" formControlName="usePhoneNumber">{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.USE_PHONE_NUMBER" | translate }}</mat-checkbox>
            </div>
          </ng-container>
          <div class="col-12" *ngIf="formGroup.controls.usePhoneNumber.value">
            <mat-form-field appearance="outline" [hideRequiredMarker]="!phoneNumberRequired">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PHONE_NUMBER" | translate }}</mat-label>
              <input matInput formControlName="phoneNumber" type="tel" />
              <app-error [control]="formGroup.controls['phoneNumber']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" formGroupName="address">
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.POSTAL_CODE" | translate }}</mat-label>
              <input matInput formControlName="postalCode" placeholder="1234AB" type="text" />
              <app-error [control]="addressFormGroup.controls.postalCode"></app-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.HOUSE_NUMBER" | translate }}</mat-label>
              <input matInput formControlName="houseNumber" placeholder="12" type="text" />
              <app-error [control]="addressFormGroup.controls.houseNumber"></app-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.HOUSE_NUMBER_SUFFIX" | translate }}</mat-label>
              <input matInput formControlName="houseNumberSuffix" placeholder="A" type="text" />
              <app-error [control]="addressFormGroup.controls.houseNumberSuffix"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="errorMessageTracker.addressFound === false" class="row">
          <div class="col text-warning d-flex align-items-center pb-1"><mat-icon>warning</mat-icon>{{ "FORMS.NEW_ENERGY_CONSULT.ADDRESS_NOT_FOUND" | translate }}</div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.STREETNAME" | translate }}</mat-label>
              <input matInput formControlName="streetName" type="text" />
              <app-error [control]="formGroup.controls.streetName"></app-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.TOWN" | translate }}</mat-label>
              <input matInput formControlName="town" type="text" />
              <app-error [control]="formGroup.controls.town"></app-error>
            </mat-form-field>
          </div>
        </div>
        <ng-container *appModule="'AREA_ACTIONS'">
          <ng-container *appExcludeModule="'HIDE_AREA_ACTIONS_NEW_REQUEST_PAGE'">
            <div class="row">
              <ng-container *ngIf="addressFormGroup.controls.postalCode.value?.length; then zipcodetable"></ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *appModule="'SHOW_SPECIALTIES_NEW_REQUEST'">
          <div class="row" *ngIf="allSpecialties.length > 1">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.SPECIALIZATION" | translate }}</mat-label>
                <mat-select formControlName="specialties">
                  <mat-option *ngFor="let specialty of allSpecialties" [value]="specialty">{{ specialty.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.EXTRA_MESSAGE.LABEL" | translate }}</mat-label>
              <textarea
                rows="3"
                [placeholder]="'FORMS.NEW_ENERGY_CONSULT.FIELDS.EXTRA_MESSAGE.PLACEHOLDER' | translate"
                matInput
                formControlName="extraMessage"
                type="text"
              ></textarea>
              <span class="charCounter">
                <span>{{ formGroup.controls.extraMessage.value?.length ?? 0 }}</span>
                <span>/ 250</span>
              </span>
              <app-error [control]="formGroup.controls['extraMessage']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *appModule="'TIME_SLOTS'">
          <div class="col-12">
            <hr />
            <h4 class="mb-3">{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.PREFERRED_DATES" | translate }}</h4>
            <app-time-slots-input #timeSlotPicker [formgroup]="formGroup" [areaActionMessage]="areaActionMessage | async"></app-time-slots-input>
            <hr />
          </div>
        </div>
      </form>
      <button mat-raised-button color="primary" [disabled]="!formGroup.valid" (click)="saveNewEnergyConsultForm()">{{ "FORMS.NEW_ENERGY_CONSULT.SAVE" | translate }}</button>
    </div>
  </div>
</div>

<ng-template #popup>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE" | translate }} 🎉</h3>
        <!-- <p>{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE_DESCRIPTION_1" | translate }}</p> -->
        <p>{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE_DESCRIPTION_2" | translate }}</p>
        <p>{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE_DESCRIPTION_3" | translate }}</p>
        <!-- <button mat-raised-button (click)="userService.login()">{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE_LOGIN" | translate }}</button> -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>

<ng-template #zipcodetable>
  <div class="col pb-5">
    <p *ngIf="areaActionsDataFiltered.length">{{ "TABLES.AREA_ACTIONS_OVERVIEW.INTRO" | translate }}</p>
    <app-table
      *ngIf="areaActionsDataFiltered.length && areaActionsData.length; else message"
      id="AREA_ACTIONS_OVERVIEW"
      [data]="areaActionsDataFiltered"
      [headers]="areaActionsColumns"
    ></app-table>
    <p *ngIf="messageSearchForMore">{{ "TABLES.AREA_ACTIONS_OVERVIEW.SEARCH_MORE" | translate }}</p>
  </div>
  <ng-template #message>
    <ng-container *ngIf="applicationService.loading">
      <p *ngIf="!areaActionsData.length">{{ "TABLES.AREA_ACTIONS_OVERVIEW.LOADING" | translate }}</p>
      <mat-spinner style="height: 2rem; width: 2rem" *ngIf="!areaActionsData.length"></mat-spinner>
    </ng-container>
    <p *ngIf="areaActionsData.length">{{ "TABLES.AREA_ACTIONS_OVERVIEW.NOT_AVAILABLE" | translate }}</p>
    <p *ngIf="!areaActionsData.length">{{ "TABLES.AREA_ACTIONS_OVERVIEW.NONE_AVAILABLE" | translate }}</p>
  </ng-template>
</ng-template>

<ng-template #areaRerouteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <ng-container *ngIf="data.custom && data.custom.text; else areaScopeDefaultDialogContent">
          <h3 class="title">{{ "FORMS.NEW_ENERGY_CONSULT.DIALOGS.CUSTOM." + (data.custom.title ?? "TITLE") | translate }}</h3>
          <p *ngFor="let text of data.custom.text" style="margin-bottom: 0">
            {{ "FORMS.NEW_ENERGY_CONSULT.DIALOGS.CUSTOM." + text | translate }}
          </p>
        </ng-container>
        <ng-template #areaScopeDefaultDialogContent>
          <h3 class="title">{{ "FORMS.NEW_ENERGY_CONSULT.DIALOGS.AREA_OUT_OF_SCOPE.TITLE" | translate }}</h3>
          <p style="margin-bottom: 0">
            {{ "FORMS.NEW_ENERGY_CONSULT.DIALOGS.AREA_OUT_OF_SCOPE.MESSAGE_1" | translate }}
          </p>
          <ng-container *ngIf="data.link && data.link?.url !== null">
            <p>
              {{ "FORMS.NEW_ENERGY_CONSULT.DIALOGS.AREA_OUT_OF_SCOPE.MESSAGE_2" | translate }}
            </p>
            <a [href]="data.link.url" target="_blank">{{ data.link.value ?? "Link" }}</a>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
