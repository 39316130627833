import { HttpClient, HttpBackend } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { map, of, catchError, forkJoin } from "rxjs";

export class CustomTranslateHttpLoader implements TranslateLoader {
  constructor(private _handler: HttpBackend, public files: { prefix: string; suffix: string }[]) {}

  getTranslation(lang: string) {
    const requests = this.files.map((file) => {
      const path = file.prefix + lang + file.suffix;
      return new HttpClient(this._handler).get(path).pipe(
        catchError(() => {
          return of({});
        })
      );
    });

    return forkJoin(requests).pipe(
      map((e) => {
        return this.deepMergeObject(...e);
      })
    );
  }

  deepMergeObject(targetObject = {}, sourceObject = {}) {
    const copyTargetObject = JSON.parse(JSON.stringify(targetObject));
    const copySourceObject = JSON.parse(JSON.stringify(sourceObject));
    Object.keys(copySourceObject).forEach((key) => {
      if (typeof copySourceObject[key] === "object" && !Array.isArray(copySourceObject[key])) {
        copyTargetObject[key] = this.deepMergeObject(copyTargetObject[key], copySourceObject[key]);
      } else {
        copyTargetObject[key] = copySourceObject[key];
      }
    });

    return copyTargetObject;
  }
}
