import { Component } from "@angular/core";

@Component({
  selector: "app-replacement-claim-deliver",
  templateUrl: "./replacement-claim-deliver.component.html",
  styleUrls: ["./replacement-claim-deliver.component.less"]
})
export class ReplacementClaimDeliverComponent {

}
