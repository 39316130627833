import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { Module } from "../classes/flow/base/Module";
import { ENVIRONMENT } from "../../environments/environment";

@Directive({
  selector: "[appExcludeModule]",
})
export class ExcludeModuleDirective {
  constructor(private template: TemplateRef<unknown>, private container: ViewContainerRef) {}

  @Input() set appExcludeModule(module: Module) {
    if (!ENVIRONMENT.MODULES.includes(module)) {
      this.container.createEmbeddedView(this.template);
    } else {
      this.container.clear();
    }
  }
}
