import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { ApplicationService } from "../services/application.service";
import { RoleName } from "../classes/flow/session/Session";

@Directive({
  selector: "[appIsRole]"
})
/**
 * Structural directive to check whether the currently logged in user currently has his active role set to one of the given roles when using this directive.
 * The component on which the directive is used will be either included in the html or not, according to the previous check.
 */
export class RoleDirective {
  @Input() set appIsRole(role: RoleName | RoleName[]) {
    if(role === this.application.session.activeRole.name || role.includes(this.application.session.activeRole.name)) {
      this.container.createEmbeddedView(this.template);
    } else {
      this.container.clear();
    }
  }

  constructor(
    private template: TemplateRef<unknown>,
    private container: ViewContainerRef,
    private application: ApplicationService
  ) { }
}
