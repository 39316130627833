import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-radio-image",
  templateUrl: "./radio-image.component.html",
  styleUrls: ["./radio-image.component.less"],
})
export class RadioImageComponent {
  @Input() id: number;

  @Input() imagePath: string;

  @Input() label: string;

  @Input() disabled: boolean;

  @Input() selected: boolean;

  @Output() applianceSelect: EventEmitter<number>;

  constructor() {
    this.id = 0;
    this.imagePath = "";
    this.label = "";
    this.disabled = false;
    this.selected = false;
    this.applianceSelect = new EventEmitter();
  }

  clickHandler() {
    this.applianceSelect.emit(this.id);
  }
}
