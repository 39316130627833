import { Injectable, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "../../environments/environment";
import { SnackbarService } from "./snackbar.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class BookmarkService {
  private httpClient: HttpClient;
  private snack: SnackbarService;
  private translate: TranslateService;

  public status: "unfetched" | "fetching" | "fetched";
  private bookmarks: {
    workspace?: string;
    role?: string;
    group?: string;
    grouporder?: string;
    link: string;
    ID?: string;
    Description: string;
    ContentType?: string;
  }[];
  public timeout: NodeJS.Timeout | null;

  constructor() {
    this.httpClient = inject(HttpClient);
    this.snack = inject(SnackbarService);
    this.translate = inject(TranslateService);

    this.status = "unfetched";
    this.bookmarks = [];
    this.timeout = null;

    this.fetchData();
  }

  private async fetchData(): Promise<void> {
    this.status = "fetching";
    try {
      const getResult = await firstValueFrom(this.httpClient.get<[{ bookmarks: string }]>(ENVIRONMENT.API_URL + "bookmarks", {}));
      this.bookmarks =
        JSON.parse(getResult[0].bookmarks).bookmarkgroups[0]?.bookmarks.map((val: { Description: string; link: string; group: string }) => {
          return {
            Description: val.Description,
            link: val.link,
            group: val.group,
          };
        }) ?? [];
      this.status = "fetched";
    } catch {
      this.status = "unfetched";
    }
  }

  /**
   *
   * @param id energyconsult id
   * @param action add or remove the bookmark
   */
  public async setBookmark(id: number, action: "add" | "remove"): Promise<void> {
    switch (action) {
      case "add":
        this.bookmarks.push({
          Description: "",
          link: "" + id,
          group: "energyconsults",
        });
        break;
      case "remove":
        this.bookmarks = this.bookmarks.filter((bookmark) => {
          return bookmark.link !== `${id}`;
        });
        break;
      default:
        break;
    }

    if (this.status === "unfetched") {
      this.fetchData();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      this.postBookmarks();
      this.timeout = null;
    }, 3000);
  }

  private async postBookmarks(): Promise<void> {
    if (this.status !== "fetched") {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.postBookmarks();
        this.timeout = null;
      }, 3000);
    }

    try {
      await firstValueFrom(
        this.httpClient.post(
          ENVIRONMENT.API_URL + "bookmarks",
          {
            bookmarks: {
              ID: "0",
              Description: "",
              bookmarkgroups: [
                {
                  ID: "0",
                  Description: "",
                  bookmarks: this.bookmarks,
                },
              ],
            },
          },
          {}
        )
      );
      this.snack.open(this.translate.instant("COMPONENTS.DASHBOARD.BOOKMARKS.SNACK_SUCCESS"));
    } catch (error) {
      this.snack.open(this.translate.instant("COMPONENTS.DASHBOARD.BOOKMARKS.SNACK_ERROR"), "Fout");
    }
  }
}
