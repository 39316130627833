/* eslint-disable no-fallthrough */
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnergyConsult } from "../classes/flow/request/EnergyConsult";
import { Request } from "../classes/flow/request/Request";
import { RequestStates } from "../classes/flow/request/RequestStates";
import { Filter, FilterGroup } from "../components/badge-filters/badge-filters";
import { StorageService } from "./storage.service";
@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  public constructor(private readonly translateService: TranslateService, private readonly storageService: StorageService) {}

  /**
   * @param state
   * @returns  current states value
   */
  public getStateValue(state: string): number {
    switch (state) {
      case "New":
        return 0;

      case "PendingReject":
        return 1;

      case "Done":
        return 2;

      case "Deleted":
        return 999;

      default:
        return 3;
    }
  }

  /**
   * uses the given id and request state to save these to the filters array
   * @param givenId
   * @param requestState
   * @param requests
   */
  private initFilter(givenId: number, requestState: RequestStates, requests: EnergyConsult[]): Filter<EnergyConsult> {
    const filter = new Filter(givenId, "COMPONENTS.TILE.STATUSES." + requestState, (request: Request) => request.state.name === requestState);
    filter.setAmount(requests);
    return filter;
  }

  public initSpecialtyFilter(energyConsults: EnergyConsult[]): Filter<EnergyConsult>[] {
    const specialties = [...new Map(energyConsults.map((energyConsult) => [energyConsult.specialty.name, energyConsult.specialty])).values()];
    const filters = specialties.map((specialty) => {
      const filter = new Filter(specialty.id, specialty.name, (energyConsult: EnergyConsult) => energyConsult.specialty.name == specialty.name);
      filter.setAmount(energyConsults);
      return filter;
    });
    // this.sortFilters(filters);
    return filters;
  }

  public sortRequestsBy(sortMethod: "date" | "lastStateChangeDate", sortState: boolean, requests: EnergyConsult[]) {
    if (sortMethod === "date") {
      const sorted = requests.sort((a, b) => a.requestDate.getTime() - b.requestDate.getTime());
      if (sortState === true) {
        return sorted;
      } else {
        return sorted.reverse();
      }
    } else if (sortMethod === "lastStateChangeDate") {
      const sorted = requests.sort((a, b) => a.lastStateChangeDate!.getTime() - b.lastStateChangeDate!.getTime());
      if (sortState === true) {
        return sorted;
      } else {
        return sorted.reverse();
      }
    }
  }

  /**
   *
   * @param requests
   * @returns
   */
  public filterRequestsByState(requests: EnergyConsult[]) {
    return requests.sort((a, b) => this.getStateValue(a.state.name) - this.getStateValue(b.state.name));
  }

  /**
   * Initializes filter per role
   * @param requests All requests to filter
   */
  public initStateFilter(requests: EnergyConsult[]): Filter<EnergyConsult>[] {
    let states = [...new Set(requests.map((request) => request.state.name))];
    const statesOrder = Object.values(RequestStates);
    states = states.sort((a, b) => statesOrder.indexOf(a) - statesOrder.indexOf(b));
    const filters = states.map((state, index) => this.initFilter(index, state, requests));
    this.sortFilters(filters);
    return filters;
  }

  private sortFilters(filters: Filter<any>[]) {
    filters.sort((a, b) => (a.id == b.id ? 0 : a.id > b.id ? 1 : -1));
  }

  /**
   * Filters the requests
   */
  public filterRequests<T>(filters: FilterGroup<T>[], entities: T[]): T[] {
    if (filters.length > 1) {
      this.updateFilterAmounts(filters, entities);
    }
    const activeFilters = filters.map((f) => f.activeFilters).filter((f) => !!f);
    return entities.filter((request) => {
      const boolArrayPerGroup = activeFilters.every((filters) => {
        const boolArrayPerFilter = filters?.map((filter) => {
          if (filter?.filterFunction(request)) {
            return true;
          }
          return false;
        });
        return boolArrayPerFilter?.includes(true);
      });
      return boolArrayPerGroup;
    });
  }

  /**
   * Updates the amounts shown for the filters
   */
  private updateFilterAmounts<T>(filters: FilterGroup<T>[], entities: T[]) {
    filters.forEach((filterGroup) => {
      const otherActiveFilters = filters.filter((filter) => filter != filterGroup).map((group) => group.activeFilters);

      const entitiesBasedOnOtherFilters = entities.filter((entity) => {
        const boolsPerFilter = otherActiveFilters.map((filters) => {
          return filters!.map((filter) => filter?.filterFunction(entity)).includes(true);
        });
        return boolsPerFilter.includes(true);
      });

      filterGroup.requestAmount = entitiesBasedOnOtherFilters.length;

      filterGroup.filters.forEach((filter) => {
        filter.setAmount(entitiesBasedOnOtherFilters);
      });
    });
  }

  /**
   * A function to load activefilters from localStorage
   * @param filterType string to define which filtergroup to load
   * @param filterGroup the filtergroup to return with adjusted activefilters
   * @returns the filtergroup
   */
  public getSavedFiltersFromStorage(filterType: string, filterGroup: FilterGroup<EnergyConsult>, location = "dashboard"): FilterGroup<EnergyConsult> {
    const savedFilters: string[] | null = this.storageService.fetch("usersettings.filters." + location + "." + filterType, "localStorage");

    if (savedFilters !== null && savedFilters.length !== 0) {
      //sets saved filters from localstorage in active filters
      filterGroup.activeFilters = filterGroup.filters.filter((existingFilter) => {
        return savedFilters.includes(existingFilter.title);
      });
      //if after that no filters are active => select all
      filterGroup.activeFilters.length === 0 ? (filterGroup.activeFilters = filterGroup.filters) : null;
    } else {
      if (filterType === "state") {
        //if no filters are saved => select all except 2
        filterGroup.activeFilters = filterGroup.filters.filter(
          (filter) =>
            filter.title != this.translateService.instant("COMPONENTS.TILE.STATUSES." + RequestStates.PENDING_DELETED) &&
            filter.title != this.translateService.instant("COMPONENTS.TILE.STATUSES." + RequestStates.CANCELED)
        );
      } else if (filterType === "specialty") {
        filterGroup.activeFilters = filterGroup.filters;
      }
    }

    // If only one filter, set as active filter
    if (filterGroup.filters.length === 1) {
      filterGroup.activeFilters = filterGroup.filters;
    }

    return filterGroup;
  }
  /**
   * sets the active filters in local storage
   * @param filterType string to define which filtergroup is getting saved
   * @param filterGroup the filtergroup of which to store the active filters
   */
  public setSavedFiltersInStorage(filterType: string, filterGroup: FilterGroup<EnergyConsult>, location = "dashboard"): void {
    this.storageService.store(
      "usersettings.filters." + location + "." + filterType,
      filterGroup.activeFilters?.map((filter) => filter?.title),
      "localStorage"
    );
  }
}
