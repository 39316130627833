import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-icon-toggle",
  templateUrl: "./icon-toggle.component.html",
  styleUrls: ["./icon-toggle.component.less"],
})
export class IconToggleComponent {
  @Input("toggleFormControl")
  public toggleFormControl!: FormControl;

  @Input("falseIconName")
  public falseIconName!: string;

  @Input("falseText")
  public falseText!: string;

  @Input("falseTooltip")
  public falseTooltip?: string;

  @Input("falseAriaLabel")
  public falseAriaLabel!: string;

  @Input("trueIconName")
  public trueIconName!: string;

  @Input("trueText")
  public trueText!: string;

  @Input("trueTooltip")
  public trueTooltip?: string;

  @Input("trueAriaLabel")
  public trueAriaLabel!: string;

  public toggleValue() {
    this.toggleFormControl.setValue(!this.toggleFormControl.value);
  }
}
