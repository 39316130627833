import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subscription, filter } from "rxjs";
import { QrScannerComponent } from "../../../../components/qr-scanner/qr-scanner.component";
import { ApplicationService } from "../../../../services/application.service";
import { ReplacementService } from "../../../../services/replacement.service";
import { MatStepper } from "@angular/material/stepper";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { ReplacementClaim } from "../../../../classes/flow/replacementClaim/ReplacementClaim";
import { Retailer } from "../../../../classes/flow/session/impl/Retailer";
import { RetailersService } from "../../../../services/retailers.service";
import { qrValidator } from "../../../../validators/qr";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-replacement-claim-edit",
  templateUrl: "./replacement-claim-edit.component.html",
  styleUrls: ["./replacement-claim-edit.component.less"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ReplacementClaimEditComponent implements OnInit {
  public form: ReplacementClaimEditControl;

  private claim!: ReplacementClaim;

  public categoryOptions: ApplianceCategory[];
  public typeOptions: ApplianceType[];
  public retailerOptions: Retailer[];

  // public applianceNewCategoryFilteredOptions: BehaviorSubject<ApplianceCategory[]>;
  public applianceTypeSubscription?: Subscription;
  public applianceReturnCategoryFilteredOptions: BehaviorSubject<ApplianceCategory[]>[];
  public applianceReturnTypeSubscriptions: Subscription[];

  public readonly minReturnAppliances: number = 0;
  public readonly maxReturnAppliances: number = 1;

  public editMode: boolean;

  @ViewChild("stepper", { static: false }) stepper!: MatStepper;

  constructor(
    private applicationService: ApplicationService,
    private fb: FormBuilder,
    private replacementService: ReplacementService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private retailerService: RetailersService
  ) {
    // Initialize form
    this.form = this.fb.group({
      newAppliance: this.fb.group({
        category: this.fb.control<number | null>(null, [Validators.required]),
        description: this.fb.control<string | null>(null, [Validators.required]),
      }),
      returnAppliances: this.fb.array<ReturnApplianceControl>([]),
      retailer: this.fb.group({
        retailerIdPreferred: this.fb.control<number | null>(null, [Validators.required]),
      }),
    });
    // Default options to empty array
    this.categoryOptions = [];
    this.typeOptions = [];
    this.retailerOptions = [];

    // this.applianceNewCategoryFilteredOptions = new BehaviorSubject<ApplianceCategory[]>(this.categoryOptions);
    this.applianceReturnCategoryFilteredOptions = [];
    this.applianceReturnTypeSubscriptions = [];
    this.editMode = true;
    // disable retailer by default
    this.form.controls.retailer.disable({ emitEvent: false });
  }

  async ngOnInit(): Promise<void> {
    this.applicationService.setLoading(true);

    const claim = await this.replacementService.getReplacementClaim(this.route.snapshot.params.replacementClaimId);
    if (!claim) {
      this.applicationService.setLoading(false);
      this.router.navigate(["/content"]);
      return;
    }
    this.claim = claim;

    // Get types and categories and retailers
    this.typeOptions = await this.replacementService.getTypes();
    this.categoryOptions = await this.replacementService.getCategories();
    this.categoryOptions = this.categoryOptions.map((category) => {
      return {
        ...category,
        name: `${category.name} ${this.getTypeNameById(category.typeId) ? "(" + this.getTypeNameById(category.typeId) + ")" : ""}`,
      };
    });
    this.retailerOptions = await this.retailerService.getAvailableRetailers();

    // Initialize category options
    // this.applianceNewCategoryFilteredOptions = new BehaviorSubject<ApplianceCategory[]>(this.categoryOptions);
    this.applianceReturnCategoryFilteredOptions = [];

    // Subscribe to new appliance type to select categories
    // this.form.controls.newAppliance.controls.type.valueChanges.subscribe((controlValue) => {
    //   this.form.controls.newAppliance.controls.category.reset();
    //   let options: ApplianceCategory[] = [];
    //   if (controlValue === null) options = this.categoryOptions;
    //   options = this.categoryOptions.filter((optionValue) => controlValue === optionValue.typeId);
    //   this.applianceNewCategoryFilteredOptions.next(options);
    // });

    const appliance = await this.replacementService.getClaimAppliance(claim.id);
    const returnAppliances = await this.replacementService.getClaimReturnAppliances(claim.id);

    if (appliance) {
      const categoryId = appliance.applianceCategoryId;
      this.form.controls.newAppliance.controls.category.patchValue(categoryId);
      this.form.controls.newAppliance.controls.description.patchValue(appliance.description ?? null);
      this.form.controls.newAppliance.disable();
    }

    if (returnAppliances.length) {
      for (let i = 0; i < returnAppliances.length; i++) {
        this.addReturnApplianceControl();
        const categoryId = returnAppliances[i].applianceCategoryId;
        const category = this.categoryOptions.filter((category) => category.id === categoryId)[0];
        const typeId = category.typeId;

        this.form.controls.returnAppliances.at(i).controls.type.patchValue(typeId);
        this.form.controls.returnAppliances.at(i).controls.category.patchValue(categoryId);
        this.form.controls.returnAppliances.at(i).controls.description.patchValue(returnAppliances[i].description ?? null);

        this.form.controls.returnAppliances.at(i).disable();
      }
    } else {
      // Add min return appliances by default
      for (let i = 0; i < this.minReturnAppliances; i++) {
        this.addReturnApplianceControl();
      }
    }

    // Patch values if claimState
    if (claim.state.id !== 2 && claim.state.id !== 1) {
      this.form.disable();
      this.editMode = false;
    }

    // If new and return appliances submitted, enable retailer
    if (!this.claim.retailerIdPreferred && this.editMode) {
      this.form.controls.retailer.enable({ emitEvent: false });
    }

    let hasSwitched = false;
    for (const step of this.stepper.steps) {
      if (step.stepControl.disabled) {
        step.interacted = true;
        step.completed = true;
      } else if(!hasSwitched) {
        hasSwitched = true;
        this.stepper.selected = step;
      }
    }

    this.applicationService.setLoading(false);
  }

  public selectAppliance(id: number) {
    this.form.controls.newAppliance.controls.category.patchValue(id);
  }

  private getReplacementClaimReturnControl(): ReturnApplianceControl {
    return this.fb.group({
      type: this.fb.control<number | null>(null, [Validators.required]),
      category: this.fb.control<number | null>(null, [Validators.required]),
      description: this.fb.control<string | null>(null),
      qr: this.fb.group({ qr: this.fb.control<string | null>(null, [Validators.required], [qrValidator.validator(this.http, this.applicationService)]) }),
    });
  }

  addReturnApplianceControl() {
    const newControl = this.getReplacementClaimReturnControl();
    const index = this.applianceReturnCategoryFilteredOptions.push(new BehaviorSubject<ApplianceCategory[]>(this.categoryOptions));

    // Check if previous steps enabled
    let disableNewControl = false;
    if (!this.form.controls.newAppliance.controls.description.disabled) {
      disableNewControl = true;
    }
    for (const control of this.form.controls.returnAppliances.controls) {
      if (!control.controls.description.disabled) {
        disableNewControl = true;
      }
    }
    // If so, disable new one
    if (disableNewControl) {
      newControl.disable({ emitEvent: false });
    } else {
      // If new one not disabled, disable final step
      this.form.controls.retailer.disable({ emitEvent: false });
    }

    this.form.controls.returnAppliances.push(newControl);

    const sub = newControl.controls.type.valueChanges.subscribe((controlValue) => {
      newControl.controls.category.reset();
      let options: ApplianceCategory[] = [];
      if (controlValue === null) options = this.categoryOptions;
      options = this.categoryOptions.filter((optionValue) => controlValue === optionValue.typeId);
      this.applianceReturnCategoryFilteredOptions[index - 1].next(options);
    });
    this.applianceReturnTypeSubscriptions.push(sub);
  }

  removeReturnAppliance(index: number) {
    // Enable next step if current deleted
    if (this.form.controls.returnAppliances.at(index + 1)) {
      this.form.controls.returnAppliances.at(index + 1).enable({ emitEvent: false });
    } else {
      this.form.controls.retailer.enable({ emitEvent: false });
    }
    this.form.controls.returnAppliances.removeAt(index);
    this.applianceReturnCategoryFilteredOptions = this.applianceReturnCategoryFilteredOptions.slice(index, index + 1);
    this.applianceReturnTypeSubscriptions[index].unsubscribe();
    this.applianceReturnTypeSubscriptions = this.applianceReturnTypeSubscriptions.slice(index, index + 1);
  }

  openQRDialog(returnControl: ReturnApplianceControl) {
    this.dialog
      .open(QrScannerComponent)
      .afterClosed()
      .pipe(filter((val) => !!val))
      .subscribe((e) => {
        returnControl.controls.qr.patchValue(e.split("data=")[1]);
        returnControl.controls.qr.markAsDirty();
        returnControl.controls.qr.markAsTouched();
      });
  }

  async submitReplacementClaimAppliance() {
    try {
      this.applicationService.setLoading(true);
      this.form.controls.newAppliance.disable({ emitEvent: false });
      const value = this.form.controls.newAppliance.value;

      const id = await this.replacementService.addReplacementClaimAppliance({
        replacementClaimId: this.claim.id,
        applianceCategoryId: value.category ?? undefined,
        description: value.description ?? undefined,
      });

      if (!id) {
        throw new Error("Apparaat niet gemaakt.");
      }

      this.stepper.selected!.completed = true;
      // Enable return appliance / retailer if submitted
      if (this.form.controls.returnAppliances.at(0)) {
        this.form.controls.returnAppliances.at(0).enable({ emitEvent: false });
      } else {
        this.form.controls.retailer.enable({ emitEvent: false });
      }

      this.stepper.next();
      this.applicationService.setLoading(false);
    } catch (error) {
      this.form.controls.newAppliance.enable({ emitEvent: false });
      this.applicationService.setLoading(false);
    }
  }

  async submitReplacementClaimReturnAppliance(index: number) {
    try {
      this.applicationService.setLoading(true);
      this.form.controls.returnAppliances.at(index).disable({ emitEvent: false });

      const value = this.form.controls.returnAppliances.at(index).value;

      const id = await this.replacementService.addReplacementClaimReturnAppliance({
        replacementClaimId: this.claim.id,
        applianceCategoryId: value.category ?? undefined,
        description: value.description ?? undefined,
        hOID: value.qr?.qr ?? undefined,
        returnStateId: 1,
      });

      if (!id) {
        throw new Error("Retour apparaat niet aangemaakt.");
      }

      this.stepper.selected!.completed = true;

      // enable next step or retailer if submitted
      if (this.form.controls.returnAppliances.at(index + 1)) {
        this.form.controls.returnAppliances.at(index + 1).enable({ emitEvent: false });
      } else {
        this.form.controls.retailer.enable({ emitEvent: false });
      }

      this.stepper?.next();
      this.applicationService.setLoading(false);
    } catch (error) {
      this.form.controls.returnAppliances.at(index).enable({ emitEvent: false });
      this.applicationService.setLoading(false);
    }
  }

  async submitReplacementClaimRetailer() {
    try {
      this.applicationService.setLoading(true);
      this.form.controls.retailer.disable({ emitEvent: false });

      const retailerIdPreferred = this.form.controls.retailer.controls.retailerIdPreferred.value ?? undefined;

      const id = await this.replacementService.updateReplacementClaim(this.claim.id, {
        changes: this.claim.changes,
        retailerIdPreferred: retailerIdPreferred,
        claimStateId: 3,
      });

      if (!id) {
        throw new Error("Voorkeur niet kunnen opslaan.");
      }

      this.applicationService.setLoading(false);
      this.router.navigate(["/content"]);
    } catch (error) {
      this.form.controls.retailer.enable({ emitEvent: false });
      this.applicationService.setLoading(false);
    }
  }

  getTypeNameById(id: number): string | null {
    for (const type of this.typeOptions) {
      if (type.id === id) return type.name;
    }
    return null;
  }
}

export type ReturnApplianceControl = FormGroup<{
  type: FormControl<number | null>;
  category: FormControl<number | null>;
  description: FormControl<string | null>;
  qr: FormGroup<{ qr: FormControl<string | null> }>;
}>;

export type NewApplianceControl = FormGroup<{
  category: FormControl<number | null>;
  description: FormControl<string | null>;
}>;

export type RetailerControl = FormGroup<{
  retailerIdPreferred: FormControl<number | null>;
}>;

export type ReplacementClaimEditControl = FormGroup<{
  newAppliance: NewApplianceControl;
  returnAppliances: FormArray<ReturnApplianceControl>;
  retailer: RetailerControl;
}>;

export interface ApplianceType {
  id: number;
  name: string;
  code: string;
}

export interface ApplianceCategory {
  id: number;
  name: string;
  typeId: number;
  expired: boolean;
}
