<div class="container">
  <div class="row">
    <div class="col-12 text-center">
      <h1>{{ "COMPONENTS.AREA_ACTIONS.HEADING" | translate}}</h1>
    </div>
    <div class="col-12">
      <ng-container *ngIf="!areaActionToEdit && !formVisible">
        <button mat-raised-button type="button" color="primary" (click)="addHandler()">{{ "COMPONENTS.AREA_ACTIONS.ADD_BUTTON" | translate }}</button>
      </ng-container>
      <ng-container *ngIf="formVisible">
        <app-area-actions-form [areaAction]="areaActionToEdit" (cancel)="cancelHandler()" (save)="saveHandler()"></app-area-actions-form>
      </ng-container>
    </div>
    <div class="col-12">
      <app-area-actions-overview [data]="areaActions" (edit)="editHandler($event)" (delete)="deleteHandler($event)"></app-area-actions-overview>
    </div>
  </div>
</div>

<ng-template #deleteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div id="questionnaire-delete-buttons" class="col-md">
        <h3 class="title">{{ "COMPONENTS.AREA_ACTIONS.DELETE.HEADING" | translate }}</h3>
        <p>{{ "COMPONENTS.AREA_ACTIONS.DELETE.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "FORMS.CANCEL" | translate }}</button>
        <button mat-raised-button color="warn" (click)="deleteAreaAction(data); dialog.close()">
          {{ "FORMS.DELETE" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
