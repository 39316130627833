<div class="container">
  <div class="row">
    <div class="row">
      <h1>{{ "FORMS.QUESTIONNAIRE.GENERAL_DESCRIPTION" | translate }}:</h1>
      <app-questionnaire-form *ngIf="generalInformation" [formGroup]="generalInformation"></app-questionnaire-form>
    </div>

    <hr class="my-3" />

    <h1>{{ "FORMS.QUESTIONNAIRE.QUESTIONS" | translate }}:</h1>
    <div class="row">
      <!-- <div class="col-6">
        <ng-container [ngTemplateOutlet]="_questions" [ngTemplateOutletContext]="{ data: questions, indexPrefix: '' }"> </ng-container>
        <div class="row">
          <div class="col-12">
            <button mat-raised-button class="me-3" (click)="newMainQuestion()">{{ "FORMS.QUESTIONNAIRE.NEW_MAIN_QUESTION" | translate }}</button>
            <app-async-action-button [condition]="!formGroups.length" [eventHandlerFunction]="saveQuestionnaire">{{ "FORMS.SAVE" | translate }}</app-async-action-button>
          </div>
        </div>
      </div> -->
      <div class="col-12">
        <app-questionnaire-fresh [editableState]="editableState" />
      </div>
    </div>
  </div>

  <!-- <ng-template #_questions let-questions="data" let-indexPrefix="indexPrefix">
    <ng-container *ngIf="toQuestions(questions); let questions">
      <div cdkDropList (cdkDropListDropped)="reorderQuestion($event)">
        <div [cdkDragData]="questions" cdkDrag class="row" *ngFor="let question of questions; let index = index">
          <div *ngIf="question.active" class="container">
            <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="mb-3">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{ getFormGroup(question.id!).get("name")?.value }}
                  </mat-panel-title>
                  <mat-panel-description>
                    <mat-icon class="ms-auto" cdkDragHandle>swap_vert</mat-icon>
                  </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row">
                  <div class="col-12">
                    <form [formGroup]="getFormGroup(question.id!)">
                      <input style="display: none" formControlName="index" [value]="setIndex(question.id!, indexPrefix + (index + 1))" />

                      <div *ngIf="question.logic">
                        <div *ngIf="getFormGroup(question.logic.parentQuestionId!).controls['questionType'].value.prefix === 'sel' || getFormGroup(question.logic.parentQuestionId!).controls['questionType'].value.prefix === 'sem'; else condition">
                          <mat-form-field appearance="outline">
                            <mat-label>{{ "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.SELECT_OPTION_CONDITION" | translate }}</mat-label>
                            <mat-select [compareWith]="compareSelectOptions" formControlName="selOption_id">
                              <mat-option *ngFor="let id of getSelOptions(getFormGroup(question.logic.parentQuestionId!))" [value]="id">
                                {{
                                getSelOptionsFormControl(getFormGroup(question.logic.parentQuestionId!), id)?.value ??
                                "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.FILL_IN_SELECT_OPTION" | translate
                                }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <ng-template #condition>
                          <mat-form-field appearance="outline">
                            <mat-label>{{ "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.NEXT_ANSWER" | translate }}</mat-label>
                            <mat-select [compareWith]="compareEntities" formControlName="operator">
                              <mat-option *ngFor="let operatorType of operatorTypes" [value]="operatorType">{{
                                "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.CONDITIONS." + operatorType.name | uppercase | translate
                                }}</mat-option>
                            </mat-select>
                            <app-error [control]="getFormGroup(question.id!).controls['operator']"></app-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>{{ "FORMS.VALUE" | translate }}</mat-label>
                            <input *ngIf="!checkIfParentIsDate(question)" matInput formControlName="compare_answer" />
                            <input *ngIf="checkIfParentIsDate(question)" matInput formControlName="compare_answer" type="date" />
                            <app-error [control]="getFormGroup(question.id!).controls['compare_answer']"></app-error>
                          </mat-form-field>
                        </ng-template>
                      </div>

                      <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>{{ "FORMS.QUESTIONNAIRE.QUESTION" | translate }}</mat-label>
                        <input matInput formControlName="name" placeholder="{{ 'FORMS.QUESTIONNAIRE.GENERAL_QUESTION.QUESTION_PLACEHOLDER' | translate }}" />
                        <mat-error *ngIf="getFormGroup(question.id!).controls['name'].errors?.pattern">{{
                          "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ERROR.SEMICOLON" | translate
                          }}</mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.DESCRIPTION" | translate }}</mat-label>
                        <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="15"></textarea>
                        <mat-error *ngIf="getFormGroup(question.id!).controls['description'].errors?.pattern">{{
                          "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ERROR.SEMICOLON" | translate
                          }}</mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="me-3">
                        <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.INPUT_FIELD.TYPE" | translate }}</mat-label>
                        <mat-select [compareWith]="compareEntities" formControlName="questionType">
                          <mat-option *ngFor="let questionType of questionTypes" [value]="questionType">{{
                            "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.INPUT_FIELD." + questionType.name | uppercase | translate
                            }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="me-3">
                        <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.TEMPORARY_DESIGNATION" | translate }}</mat-label>
                        <input matInput formControlName="placeholder" />
                        <mat-error *ngIf="getFormGroup(question.id!).controls['placeholder'].errors?.pattern">{{
                          "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ERROR.SEMICOLON" | translate
                          }}</mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="me-3">
                        <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ANSWER_REQUIRED" | translate }}</mat-label>
                        <mat-select formControlName="required">
                          <mat-option [value]="true">{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.REQUIRE_OPTIONS.REQUIRED" | translate }}</mat-option>
                          <mat-option [value]="false">{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.REQUIRE_OPTIONS.NOT_REQUIRED" | translate }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="me-3">
                        <mat-label>Controle</mat-label>
                        <mat-select [compareWith]="compareEntities" formControlName="validation">
                          <mat-option *ngFor="let validation of validations" [value]="validation">{{
                            "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.CHECK." + validation.name | uppercase | translate
                            }}</mat-option>
                        </mat-select>
                      </mat-form-field>

                      <ng-container *ngIf="question.options?.length">
                        <br />

                        <div *ngFor="let selOption of question.options; let index = index" class="div-form-field-padding">
                          <mat-form-field appearance="outline" class="form-field-no-padding">
                            <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.SELECT_OPTION" | translate : { index: index + 1 } }}</mat-label>
                            <input matInput [formControlName]="'selOption' + selOption.questionReference" />
                          </mat-form-field>
                          <button mat-icon-button (click)="deleteSelectOption(question, selOption.id!, selOption.questionReference!)" [disabled]="!(question.options && question.options.length > 2)">
                            <mat-icon>clear</mat-icon>
                          </button>
                        </div>

                        <button mat-icon-button matTooltip="{{ 'FORMS.QUESTIONNAIRE.TOOLTIP.NEW_OPTION' | translate }}" (click)="addSelectOption(question)">
                          <mat-icon>playlist_add</mat-icon>
                        </button>
                      </ng-container>

                      <ng-container *ngIf="getFormGroup(question.id!).controls['questionType'].value?.prefix === 'upp'">
                        <mat-form-field appearance="outline" class="form-field-no-padding">
                          <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.UNITPRICE" | translate }}</mat-label>
                          <input matInput currencyMask formControlName="unitPrice" [options]="{ prefix: '€ ', thousands: '.', decimal: ',', align: 'left' }" />
                        </mat-form-field>
                      </ng-container>

                    </form>
                  </div>

                  <div *ngIf="question.followUpQuestions.length" class="col-12">
                    <p>{{ "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTIONS" | translate }}:</p>
                    <ng-container [ngTemplateOutlet]="_questions" [ngTemplateOutletContext]="{ data: question.followUpQuestions, indexPrefix: indexPrefix + (index + 1) + '.' }"></ng-container>
                  </div>

                  <div class="col-12 d-flex justify-content-between">
                    <span>
                      <button *ngIf="getFormGroup(question.id!).controls['questionType'].value?.prefix !== 'upp'" mat-icon-button matTooltip="{{ 'FORMS.QUESTIONNAIRE.TOOLTIP.NEW_FOLLOW_UP_QUESTION' | translate }}" (click)="newFollowUpQuestion(question)">
                        <mat-icon>subdirectory_arrow_right</mat-icon>
                      </button>
                    </span>
                    <button mat-icon-button color="warn" matTooltip="{{ 'FORMS.QUESTIONNAIRE.TOOLTIP.DELETE_QUESTION' | translate }}" (click)="openDeleteDialog(question)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template> -->

  <ng-template #deleteDialog let-data="data">
    <div class="container-fluid">
      <div class="row">
        <div id="questionnaire-delete-buttons" class="col-md">
          <h3 class="title">{{ "FORMS.QUESTIONNAIRE.DELETE_QUESTION.TITLE" | translate }}</h3>
          <p>{{ "FORMS.QUESTIONNAIRE.DELETE_QUESTION.DESCRIPTION" | translate }}</p>
          <button mat-raised-button color="primary" (click)="dialog.close()">{{ "FORMS.CANCEL" | translate }}</button>
          <button class="text-wrap" mat-raised-button color="primary" (click)="deleteQuestionAndChildren(data); dialog.close()">
            {{ "FORMS.QUESTIONNAIRE.DELETE_QUESTION.ALL_QUESTIONS" | translate }}
          </button>
          <button mat-raised-button color="warn" (click)="deleteQuestion(data); dialog.close()">
            {{ "FORMS.QUESTIONNAIRE.DELETE_QUESTION.SINGLE_QUESTION" | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
