import { Changes } from "../base/Changes";
import { Entity } from "../base/Entity";

export class ReplacementClaimApplianceDelivery implements Entity {
  public id?: number;
  public deliveryStateId?: number;
  public replacementClaimApplianceOrderId?: number;
  public description?: string;
  public plannedDate?: string;
  public plannedTime?: string;
  public hOID?: string;
  public changes?: Changes;

  constructor(order: {
    id?: number;
    deliveryStateId?: number;
    replacementClaimApplianceOrderId?: number;
    description?: string;
    plannedDate?: string;
    plannedTime?: string;
    hOID?: string;
    changes?: Changes;
  }) {
    this.id = order.id;
    this.deliveryStateId = order.deliveryStateId;
    this.replacementClaimApplianceOrderId = order.replacementClaimApplianceOrderId;
    this.description = order.description;
    this.plannedDate = order.plannedDate;
    this.plannedTime = order.plannedTime;
    this.hOID = order.hOID;
    this.changes = order.changes;
  }
}
