<section #timeselected class="timeSelected">
  <div class="row w-100 align-items-center">
    <div class="d-flex">
      <div class="text col-6 d-flex align-items-end justify-content-end" (click)="this.pickerStage = 'hours'">
        <input
          type="number"
          class="inputTime"
          (beforeinput)="onBeforeInput($event)"
          [ngModel]="getFormattedNumber(selectedHour)"
          (ngModelChange)="selectedHour = $event; validateHour()"
          [ngStyle]="pickerStage === 'hours' ? { color: 'black', 'background-color': 'white' } : {}"
          min="-1"
          max="24"
          [readonly]="pickerStage !== 'hours'"
          (keydown)="preventArrowKeyChange($event)"
          [attr.aria-label]="'TIME_PICKER.HOURS' | translate"
        />
      </div>

      <div class="text text-center">
        <span>:</span>
      </div>

      <div class="text col-4 d-flex align-items-end justify-content-start" (click)="this.pickerStage = 'minutes'">
        <input
          type="number"
          class="inputTime"
          (beforeinput)="onBeforeInput($event)"
          [ngModel]="getFormattedNumber(selectedMinute)"
          (ngModelChange)="selectedMinute = $event; validateMinute()"
          [ngStyle]="pickerStage === 'minutes' ? { color: 'black', 'background-color': 'white', 'text-align': 'left' } : { 'text-align': 'left' }"
          min="-1"
          max="60"
          [readonly]="pickerStage !== 'minutes'"
          (keydown)="preventArrowKeyChange($event)"
          [attr.aria-label]="'TIME_PICKER.MINUTES' | translate"
        />
      </div>
    </div>
  </div>
</section>
<section
  #container
  class="clock"
  (pointerup)="calculateCursorAngle($event, false)"
  (pointermove)="calculateCursorAngle($event)"
  (pointerdown)="calculateCursorAngle($event, true)"
>
  <div *ngIf="pickerStage === 'hours'">
    <div class="col-md" mat-dialog-content>
      <!-- <div #container mat-dialog-content> -->
      <div *ngFor="let hour of hours; let i = index" class="d-flex justify-content-center align-items-center">
        <div>
          <div *ngIf="hourPositions[i]" [ngStyle]="{ top: hourPositions[i].y + 'px', left: hourPositions[i].x + 'px' }" (dblclick)="selectHour(hour)" class="hour">
            <button *ngIf="hour === hours[selectedHour]" class="selectedButton" mat-flat-button>
              {{ hour | number : "1.0-0" }}
            </button>
            <button *ngIf="hour !== hours[selectedHour]" class="clockButton" mat-flat-button>
              {{ hour | number : "1.0-0" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="not-selectable" *ngIf="pickerStage === 'minutes'">
    <div class="col-md" #container mat-dialog-content>
      <div *ngFor="let minute of minutes; let i = index" class="d-flex justify-content-center align-items-center">
        <div>
          <div *ngIf="minutePositions[i]" [ngStyle]="{ top: minutePositions[i].y + 'px', left: minutePositions[i].x + 'px' }" (click)="selectMinutes(minute)" class="minute">
            <button *ngIf="minute === minutes[selectedMinute] && minute % 5 === 0" class="selectedButton" mat-flat-button>
              {{ minute | number : "1.0-0" }}
            </button>
            <button *ngIf="minute !== minutes[selectedMinute] && minute % 5 === 0" class="clockButton" mat-flat-button>
              {{ minute | number : "1.0-0" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="containerMiddle">
    <div class="not-selectable clockMiddlePoint" [ngStyle]="{ top: containerMiddle.y * 1.75 - 2.5 + 'px', left: containerMiddle!.x - 3.5 + 'px' }"></div>
    <div
      *ngIf="pickerStage === 'minutes' && selectedMinute % 5 === 0"
      class="not-selectable clockHand"
      [style.transform]="'rotate(' + ((360 / 60) * selectedMinute - 180) + 'deg)'"
      [style.transform-origin]="'top'"
      [ngStyle]="{ top: containerMiddle.y * 1.75 + 2 + 'px', left: containerMiddle!.x - 1 + 'px', height: containerMiddle.x / 1.5 + 'px' }"
    ></div>
    <div
      *ngIf="pickerStage === 'minutes' && selectedMinute % 5 !== 0"
      class="not-selectable clockHand"
      [style.transform]="'rotate(' + ((360 / 60) * selectedMinute - 180) + 'deg)'"
      [style.transform-origin]="'top'"
      [ngStyle]="{ top: containerMiddle.y * 1.75 + 2 + 'px', left: containerMiddle!.x - 1 + 'px', height: containerMiddle.x / 1.25 + 'px' }"
    >
      <!-- Clockhand circle  -->
      <div class="minuteDot not-selectable"></div>
    </div>

    <!-- uren staan hier -->
    <div
      *ngIf="pickerStage === 'hours' && selectedHour < 12"
      class="not-selectable clockHand"
      [style.transform]="'rotate(' + ((360 / 12) * selectedHour - 180) + 'deg)'"
      [style.transform-origin]="'top'"
      [ngStyle]="{ top: containerMiddle.y * 1.75 + 2 + 'px', left: containerMiddle!.x - 1 + 'px', height: containerMiddle.x / 1.25 + 'px' }"
    ></div>
    <div
      *ngIf="pickerStage === 'hours' && selectedHour >= 12"
      class="not-selectable clockHand"
      [style.transform]="'rotate(' + ((360 / 12) * selectedHour - 180) + 'deg)'"
      [style.transform-origin]="'top'"
      [ngStyle]="{ top: containerMiddle.y * 1.75 + 2 + 'px', left: containerMiddle!.x - 1 + 'px', height: containerMiddle.x / 1.75 + 'px' }"
    ></div>
  </div>
</section>
<section class="buttons">
  <div class="d-flex justify-content-end">
    <div>
      <button mat-button (click)="dialogRef.close()">
        <span>{{ "TIME_PICKER.CANCEL" | translate | uppercase }}</span>
      </button>
    </div>
    <div class="align-self-center">
      <button mat-button (click)="okButtonHandler()">
        <span>{{ "TIME_PICKER.SAVE" | translate | uppercase }}</span>
      </button>
    </div>
  </div>
</section>
