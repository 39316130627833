export const downloadCSVFile = (csvString: string, name: string) => {
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob as any);
  a.href = url;
  a.download = name;
  document.body.append(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};
