import { formatCurrency } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, FormBuilder, Validators } from "@angular/forms";
import { Retailer } from "../../classes/flow/session/impl/Retailer";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-retailer-payout-form",
  templateUrl: "./retailer-payout-form.component.html",
  styleUrls: ["./retailer-payout-form.component.less"],
})
export class RetailerPayoutFormComponent implements OnInit {
  public form: FormGroup<{
    amount: FormControl<number | null>;
  }>;

  private readonly minAmount = 0.01;
  public readonly maxAmount;
  constructor(private fb: FormBuilder, private applicationService: ApplicationService) {
    const user = this.applicationService.session.user as Retailer;
    this.maxAmount = user.balance ?? 0;

    this.form = this.fb.group({
      amount: this.fb.control(0, [Validators.required, Validators.min(this.minAmount)]),
    });
  }

  ngOnInit(): void {
    this.form.controls.amount.addValidators(Validators.max(this.maxAmount / 100));
    this.form.updateValueAndValidity();
  }

  formatToEuro(amountInCents: number): string {
    return formatCurrency(amountInCents / 100, "nl", "€");
  }

  setAmountToMax() {
    this.form.controls.amount.patchValue(this.maxAmount / 100);
  }

  submitPayout() {
    console.log(this.form.controls.amount.value ? this.form.controls.amount.value * 100 : undefined);
  }
}
