<div class="mb-4">
  <div class="d-inline-flex flex-column gap-3">
    <canvas #signature id="signature-pad" width="300" height="150" class="mw-100"></canvas>
    <app-error [control]="formControl"></app-error>
    <div class="d-flex flex-wrap gap-2">
      <button type="button" mat-raised-button color="primary" [disabled]="!hasDrawn" (click)="saveSignature()">{{ "FORMS.SAVE" | translate }}</button>
      <button type="button" mat-raised-button color="warn" (click)="signaturePad!.clear()">{{ "FORMS.ERASE" | translate }}</button>
    </div>
  </div>
</div>
