import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ENVIRONMENT } from "../../../environments/environment";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-qrcode-landing",
  templateUrl: "./qrcode-landing.component.html",
  styleUrls: ["./qrcode-landing.component.less"],
})
export class QrcodeLandingComponent implements OnInit {
  public pincode: FormControl<string | null>;
  private data: string | undefined;
  public hashIsEntered: boolean;
  public response?: { name: string; value: any }[];

  constructor(private route: ActivatedRoute, private http: HttpClient, private readonly application: ApplicationService) {
    this.pincode = new FormControl<string | null>(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
    this.hashIsEntered = false;
  }

  ngOnInit(): void {
    this.data = this.route.snapshot.queryParams.data;
    this.hashIsEntered = !!this.data?.length;
  }

  async sendQR() {
    if (!this.pincode.valid) return;
    this.application.setLoading(true);
    const url = `${ENVIRONMENT.API_URL}graphql/endpoints/qrcode?qrcode=${this.data}&pincode=${this.pincode.value}`;
    const response = await firstValueFrom(this.http.post<QRCodeResponse>(url, {}));
    this.response = response.values
      .filter((value) => value.name.substring(0, 3).toLowerCase() !== "hid")
      .map((record) => {
        return { name: record.name, value: record.value.replaceAll("{{", "").replaceAll("}}", "") };
      });
    this.application.setLoading(false);
  }
}

type QRCodeResponse = {
  values: { name: string; value: any }[];
};
