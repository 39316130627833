<a mat-raised-button [routerLink]="[]" [fragment]="'content'" class="skipToContent">{{ "COMPONENTS.EVERYWHERE.SKIPCONTENTBUTTON" | translate }}</a>
<app-menu #menuComponent [items]="menu" [@menuAnimation]="menuComponent.active ? 'visible' : 'hidden'"></app-menu>
<app-global-loading></app-global-loading>
<ng-container *ngIf="!applicationService.initialized else root"> {{ "COMPONENTS.EVERYWHERE.LOADING" | translate }}... </ng-container>
<ng-template #root>
  <div id="application" class="w-100 d-flex flex-column">
    <app-header #header (openMenu)="menuComponent.show()" class="sticky-top container-fluid"></app-header>

    <div
      id="content"
      tabindex="0"
      class="container flex-grow-1"
      *ngIf="initialized"
      [ngClass]="{
          'blocking': applicationService.blocked
        }"
    >
      <div id="alertContainer d-flex h-auto align-items-start">
        <div>
          <div class="message warning" *ngIf="applicationService.session.user?.status === 'PendingDeleted'">
            <p>{{"COMPONENTS.EVERYWHERE.WARNING_ACCOUNT_DISABLED" | translate}}</p>
          </div>

          <div class="message warning" *ngIf="applicationService.session.user?.incomplete() && applicationService.session.activeRole.name === 'resident'">
            <p>
              {{"COMPONENTS.EVERYWHERE.WARNING_INCOMPLETE_ACCOUNT_START" | translate}}
              <a class="href" routerLink="/content/profile">{{"COMPONENTS.EVERYWHERE.WARNING_INCOMPLETE_ACCOUNT_OPEN" | translate}}</a>
              {{"COMPONENTS.EVERYWHERE.WARNING_INCOMPLETE_ACCOUNT_END" | translate}}
            </p>
          </div>
        </div>
      </div>

      <div class="row w-100 m-0">
        <router-outlet (activate)="applicationService.setLoading(false)" (deactivate)="applicationService.setLoading(true)"></router-outlet>
      </div>
    </div>
    <app-footer #footer></app-footer>
  </div>
</ng-template>
