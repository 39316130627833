import { HttpBackend } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
//import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CustomTranslateHttpLoader } from "../helpers/customTranslateHttpLoader";
import { registerLocaleData } from "@angular/common";

/* Custom Languages */
import netherlands from "@angular/common/locales/nl";
import english from "@angular/common/locales/en";
import { ENVIRONMENT } from "../../environments/environment";

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  const defaultPrefix = "assets/i18n/";
  const defaultSuffix = ".json";

  const files: { prefix: string; suffix: string }[] = [{ prefix: defaultPrefix, suffix: defaultSuffix }];
  ENVIRONMENT.EXTRA_TRANSLATIONS_SUFFIX.map((extraSuffix) => {
    files.push({ prefix: defaultPrefix, suffix: extraSuffix + defaultSuffix });
  });

  return new CustomTranslateHttpLoader(_httpBackend, files);
}

@NgModule({
  exports: [TranslateModule],
  providers: [],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: "nl",
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
  ],
})
export class LanguageModule {
  private languages: unknown[];

  public constructor() {
    this.languages = [netherlands, english];
    this.initLanguages();
  }

  private initLanguages() {
    for (const lang of this.languages) {
      registerLocaleData(lang);
    }
  }
}
