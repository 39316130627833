import { Component, Input } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { filter } from "rxjs";
import { QrScannerComponent } from "../qr-scanner/qr-scanner.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-qr-input",
  templateUrl: "./qr-input.component.html",
  styleUrls: ["./qr-input.component.less"],
})
export class QrInputComponent {
  @Input()
    control: FormGroup<{ qr: FormControl<string | null> }>;

  @Input() label: string;

  @Input()
    disabled: boolean;

  constructor(private dialog: MatDialog) {
    this.control = new FormGroup({ qr: new FormControl<string | null>(null) });
    this.disabled = false;
    this.label = "";
  }

  /**
   * Open a QR Scanner Dialog, and update control after a scan
   */
  openQRDialog() {
    this.dialog
      .open(QrScannerComponent)
      .afterClosed()
      .pipe(
        filter((val) => !!val), // Filter out negative values, null, undefined, empty string
        filter((val) => val !== this.control.controls.qr.value) // Filter out values that equal the current control value
      )
      .subscribe((val) => {
        this.control.controls.qr.markAsDirty(); // Mark field as dirty
        this.control.controls.qr.markAsTouched(); // Mark field as touched
        const params = new URLSearchParams(val.substring(val.indexOf("?")));
        const hOID = params.get("data");
        this.control.controls.qr.patchValue(hOID, { emitEvent: false }); // Update control value
        // this.control.controls.qr.updateValueAndValidity(); // Update value and validity
      });
  }
}
