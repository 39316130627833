import { Component, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplacementService } from "../../services/replacement.service";
import { SnackbarService } from "../../services/snackbar.service";
import { ApplicationService } from "../../services/application.service";
import { ReplacementClaim } from "../../classes/flow/replacementClaim/ReplacementClaim";
import { ReplacementClaimApplianceDelivery } from "../../classes/flow/replacementClaim/ReplacementClaimApplianceDelivery";
import { ReplacementClaimApplianceOrder } from "../../classes/flow/replacementClaim/replacementClaimApplianceOrder";
import { ReplacementClaimAppliance } from "../../classes/flow/replacementClaim/ReplacementClaimAppliance";
import { ReplacementClaimReturnAppliance } from "../../classes/flow/replacementClaim/ReplacementClaimReturnAppliance";
import { qrValidator } from "../../validators/qr";
import { HttpClient } from "@angular/common/http";
import { MatStepper } from "@angular/material/stepper";

@Component({
  selector: "app-replacement-claim-deliver-form",
  templateUrl: "./replacement-claim-deliver-form.component.html",
  styleUrls: ["./replacement-claim-deliver-form.component.less"],
})
export class ReplacementClaimDeliverFormComponent implements OnInit {
  public replacementClaim: ReplacementClaim | null;
  public replacementClaimApplianceOrder: ReplacementClaimApplianceOrder | null;
  public replacementClaimApplianceDelivery: ReplacementClaimApplianceDelivery | null;
  public replacementClaimAppliance: ReplacementClaimAppliance | null;
  public replacementClaimReturnAppliances: ReplacementClaimReturnAppliance[];

  public form: FormGroup<{
    newAppliance: FormGroup<{ qr: FormControl<string | null> }>;
    returnAppliances: FormArray<FormGroup<{ qr: FormControl<string | null> }>>;
    confirm: FormGroup<{
      stickers: FormControl<boolean | null>;
      undeliveredReturnAppliances: FormArray<FormControl<boolean | null>>;
    }>;
  }>;

  @ViewChild("stepper")
    stepper?: MatStepper;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private replacementService: ReplacementService,
    private snackbarService: SnackbarService,
    private applicationService: ApplicationService,
    private http: HttpClient
  ) {
    this.form = this.fb.group({
      newAppliance: this.fb.group({ qr: this.fb.control("", [Validators.required], [qrValidator.validator(this.http, this.applicationService)]) }),
      returnAppliances: this.fb.array<FormGroup>([]),
      confirm: this.fb.group({
        stickers: this.fb.control<boolean | null>(false, Validators.requiredTrue),
        undeliveredReturnAppliances: this.fb.array<FormControl<boolean | null>>([]),
      }),
    });

    this.replacementClaim = null;
    this.replacementClaimApplianceOrder = null;
    this.replacementClaimApplianceDelivery = null;
    this.replacementClaimAppliance = null;
    this.replacementClaimReturnAppliances = [];
  }

  async ngOnInit(): Promise<void> {
    try {
      this.applicationService.setLoading(true);
      const claimId: string | null = this.route.snapshot.params["replacementClaimId"];
      const deliveryId: string | null = this.route.snapshot.params["replacementClaimApplianceDeliveryId"];
      if (!claimId) throw new Error("No claim id given");
      if (!deliveryId) throw new Error("No delivery id given");
      this.replacementClaim = await this.replacementService.getReplacementClaim(Number(claimId));
      this.replacementClaimApplianceDelivery = await this.replacementService.getReplacementClaimApplianceDelivery(Number(deliveryId));

      if (this.replacementClaim === null) throw new Error("No claim found with this id");
      if (this.replacementClaimApplianceDelivery === null) throw new Error("No delivery found with this id");
      if (this.replacementClaim.state.id !== 5) throw new Error("Unable to deliver this claim");
      if (this.replacementClaimApplianceDelivery.deliveryStateId !== 4) throw new Error("Unable to deliver this order");

      this.replacementClaimApplianceOrder = await this.replacementService.getReplacementClaimApplianceOrder(
        this.replacementClaimApplianceDelivery.replacementClaimApplianceOrderId!
      );
      if (this.replacementClaimApplianceOrder === null) throw new Error("No order found with this id");

      if (this.applicationService.session.user?.id !== this.replacementClaimApplianceOrder.retailerId) throw new Error("This order belongs to another retailer");

      this.replacementClaimAppliance = await this.replacementService.getClaimAppliance(this.replacementClaim.id);
      this.replacementClaimReturnAppliances = await this.replacementService.getClaimReturnAppliances(this.replacementClaim.id);

      if (this.replacementClaimAppliance?.hOID) {
        this.form.controls.newAppliance.disable({ emitEvent: false });
      }

      for (let i = 0; i < this.replacementClaimReturnAppliances.length; i++) {
        this.form.controls.returnAppliances.push(
          this.fb.group({
            qr: this.fb.control("", null, [qrValidator.validator(this.http, this.applicationService, this.replacementClaimReturnAppliances[i].id)]),
          })
        );

        this.form.controls.confirm.controls.undeliveredReturnAppliances.push(this.fb.control<boolean | null>(false, Validators.requiredTrue));

        if (this.replacementClaimReturnAppliances[i].returnStateId !== 1 && this.replacementClaimReturnAppliances[i].returnStateId !== 2) {
          this.form.controls.returnAppliances.at(i).disable({ emitEvent: false });
          this.form.controls.confirm.controls.undeliveredReturnAppliances.at(i).disable({ emitEvent: false });
        }
      }

      await new Promise((resolve) =>
        setTimeout(() => {
          this.disableAndSelectSteps();
          resolve(true);
        }, 1000)
      );

      this.applicationService.setLoading(false);
    } catch (error) {
      this.router.navigate(["/content"]);
      this.applicationService.setLoading(false);
    }
  }

  disableAndSelectSteps() {
    if (this.stepper?.steps) {
      const steps = this.stepper.steps.toArray();
      let stepSelected = false;
      for (const step of steps) {
        if (step.stepControl.disabled) {
          step.completed = true;
          step.editable = false;
        } else {
          if (stepSelected) continue;
          stepSelected = true;
          step.select();
        }
      }
    }
  }

  async deliverNewAppliance() {
    try {
      this.applicationService.setLoading(true);
      if (!this.form.controls.newAppliance.valid) throw new Error();
      if (!this.replacementClaimAppliance?.id) throw new Error();
      if (this.replacementClaimAppliance?.hOID) throw new Error();
      await this.replacementService.updateReplacementClaimAppliance(this.replacementClaimAppliance.id, {
        applianceCategoryId: this.replacementClaimAppliance?.applianceCategoryId,
        hOID: this.form.controls.newAppliance.controls.qr.value!,
        changes: this.replacementClaimAppliance.changes!,
      });

      this.form.controls.newAppliance.disable({ emitEvent: false });
      this.disableAndSelectSteps();

    } catch (error) {
      this.snackbarService.error();
    }
    this.applicationService.setLoading(false);
  }

  async pickupReturnAppliance(i: number) {
    try {
      this.applicationService.setLoading(true);
      const returnApplianceControl = this.form.controls.returnAppliances.at(i);
      if (!returnApplianceControl.valid && returnApplianceControl.controls.qr.errors?.invalidQR !== "FORMS.ERRORS.QR.NO_MATCH") throw new Error();
      if (!this.replacementClaimReturnAppliances[i]?.id) throw new Error();
      if (!this.replacementClaimReturnAppliances[i]?.hOID) throw new Error();

      await this.replacementService.updateReplacementClaimReturnAppliance(this.replacementClaimReturnAppliances[i].id, {
        returnStateId: 3,
        applianceCategoryId: this.replacementClaimReturnAppliances[i]?.applianceCategoryId,
        hOIDScanned: returnApplianceControl.controls.qr.value!,
        changes: this.replacementClaimReturnAppliances[i].changes!,
      });

      returnApplianceControl.disable({ emitEvent: false });
      this.disableAndSelectSteps();

    } catch (error) {
      this.snackbarService.error();
    }
    this.applicationService.setLoading(false);
  }

  async finishDelivery() {
    try {
      this.applicationService.setLoading(true);
      if (!this.replacementClaimApplianceDelivery?.id) throw new Error();

      await this.replacementService.updateReplacementClaimApplianceDelivery(this.replacementClaimApplianceDelivery?.id, {
        deliveryStateId: 5,
        plannedDate: new Date(this.replacementClaimApplianceDelivery.plannedDate!),
        plannedTime: new Date(this.replacementClaimApplianceDelivery.plannedTime!),
        changes: this.replacementClaimApplianceDelivery.changes!,
      });

      this.router.navigate(["/content"]);
    } catch (error) {
      this.snackbarService.error();
    }
    this.applicationService.setLoading(false);
  }

  skipReturnAppliance() {
    if (!this.stepper) return;
    if (!this.stepper.selected) return;

    this.stepper.selected.completed = true;
    this.stepper.next();
  }

  stripCurlyBraces(str: string) {
    return str.replaceAll("{{", "").replaceAll("}}", "");
  }

  public allMissingRetourAppliancesChecked() {
    for (const control of this.form.controls.confirm.controls.undeliveredReturnAppliances.controls) {
      if (control.enabled && control.invalid) return false;
    }
    if (!this.form.controls.confirm.controls.stickers.valid) return false;
    return true;
  }
}
