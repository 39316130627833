export class Exporter {
  public toCSV(values: unknown[][], seperator = ";"): string {
    let content = `sep=${seperator}\n`;

    for (const row of values) {
      for (const value of row) {
        content += <string>value + seperator;
      }
      content += "\n";
    }

    return content;
  }

  public toXLS(values: unknown[][]): string {
    let content = `<html>
    <head>
		<meta charset="UTF-8" />
        <style>
			td {
				border: 1 solid gray;	
			}
		</style>
	</head>
	<body>
		<table>`;

    for (const row of values) {
      content += "<tr>";
      for (const value of row) {
        content += "<td>" + value + "</td>";
      }
      content += "</tr>";
    }

    content += `</table>
	    </body>
    </html>
	`;

    return content;
  }
}
