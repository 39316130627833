import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { OperatorType } from "../../../../classes/flow/Questionnaire/OperatorType";
import { Option } from "../../../../classes/flow/Questionnaire/Option";
import { Question } from "../../../../classes/flow/Questionnaire/Question";
import { QuestionType } from "../../../../classes/flow/Questionnaire/QuestionType";
import { Questionnaire } from "../../../../classes/flow/Questionnaire/Questionnaire";
import { Validation } from "../../../../classes/flow/Questionnaire/Validation";
import { ApplicationService } from "../../../../services/application.service";
import { DialogService } from "../../../../services/dialog.service";
import { QuestionService } from "../../../../services/question.service";
import { QuestionnaireService } from "../../../../services/questionnaire.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { ReportCategory } from "../../../../classes/flow/Questionnaire/ReportCategory";
import { ReportCategoryService } from "../../../../services/report-category.service";
import { QuestionnaireEditableState } from "../questionnaire/questionnaire.component";

@Component({
  selector: "app-questionnaire-fresh",
  templateUrl: "./questionnaire-fresh.component.html",
  styleUrls: ["./questionnaire-fresh.component.less"],
})
export class QuestionnaireFreshComponent implements OnInit {
  @ViewChild("deleteDialog")
  public deleteDialog?: TemplateRef<unknown>;

  @Input("editableState")
  public editableState: QuestionnaireEditableState = {
      general: false,
      questions: false,
      categories: false,
    };

  public questionnaire?: Questionnaire;
  public operatorTypes: OperatorType[];
  public validations: Validation[];
  public questionTypes: QuestionType[];
  public reportCategories: ReportCategory[];
  public questions: Question[];
  public questionData: QuestionData[];

  public defaultValidationValue: Validation | null;
  public defaultOperatorTypeValue: OperatorType | null;
  public defaultQuestionTypeValue: QuestionType | null;

  constructor(
    private readonly application: ApplicationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly dialog: DialogService,
    private readonly snack: SnackbarService,
    private readonly questionnaireService: QuestionnaireService,
    private readonly questionService: QuestionService,
    private readonly reportCategoryService: ReportCategoryService
  ) {
    this.questions = [];
    this.questionData = [];
    this.operatorTypes = [];
    this.validations = [];
    this.questionTypes = [];
    this.reportCategories = [];

    this.defaultValidationValue = null;
    this.defaultOperatorTypeValue = null;
    this.defaultQuestionTypeValue = null;
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.initQuestionnaire();
      await this.initializeQuestionData();

      this.initQuestions();
    } catch (error) {
      this.snack.error();
      this.router.navigate(["/content/coordinator/all-questionnaires"]);
    }
  }

  private async initializeQuestionData() {
    this.operatorTypes = await this.questionService.getAllOperatorTypes();
    this.validations = await this.questionService.getAllValidations();
    this.questionTypes = await this.questionService.getAllQuestionTypes();
    this.reportCategories = (await this.reportCategoryService.getAllReportCategories()).filter((rc) => rc.active);

    this.defaultValidationValue = this.validations.filter((v) => v.name === "none")[0] ?? null;
    this.defaultOperatorTypeValue = this.operatorTypes.filter((o) => o.name === "Equals")[0] ?? null;
    this.defaultQuestionTypeValue = this.questionTypes.filter((q) => q.prefix === "txt")[0] ?? null;
  }

  private async initQuestionnaire() {
    const questionnaireId = Number(this.route.snapshot.params.id);
    this.questionnaire = await this.questionnaireService.getQuestionnaireAndQuestionsById(questionnaireId);
    if (!this.questionnaire) throw new Error("No questionnaire found");
  }

  // Initialize questionData object
  private initQuestions() {
    if (!this.questionnaire || !this.questionnaire.questions) return;

    // Filter questions
    const mainQuestions = this.questionnaire.questions.filter((question) => !question.logic?.parentQuestionId);
    const followUpQuestions = this.questionnaire.questions.filter((question) => !!question.logic?.parentQuestionId);

    // Nest followup questions inside their parents
    for (const question of followUpQuestions) {
      const q = this.questionnaire!.questions!.find((parentQuestion) => parentQuestion.id === question.logic?.parentQuestionId);
      if (q) q.followUpQuestions.push(question);
    }

    // Set top layer of questions
    this.sortQuestionsRecursive(mainQuestions);

    this.questions = mainQuestions;
  }

  private sortQuestionsRecursive(questions: Question[]) {
    questions.sort((first, second) => {
      const firstIndex = (first.index?.split(".") ?? ["0"]).map((v) => Number(v));
      const secondIndex = (second.index?.split(".") ?? ["0"]).map((v) => Number(v));

      return firstIndex[firstIndex.length - 1] - secondIndex[secondIndex.length - 1];
    });

    for (const question of questions) {
      if (question.followUpQuestions.length) {
        this.sortQuestionsRecursive(question.followUpQuestions);
      }
    }
  }

  // Reorder question
  public reorderQuestion(event: CdkDragDrop<Question[]>) {
    moveItemInArray(event.item.data, event.previousIndex, event.currentIndex);
  }

  public openDeleteDialog(question: Question) {
    this.dialog.open({
      template: this.deleteDialog!,
      data: question,
    });
  }

  public async deleteQuestion(question: Question) {
    this.application.setLoading(true);

    // if (keepChildren) {
    //    if parentQuestionID === null > remove logic from all children
    //    else update logic to parentQuestionID from question
    // }

    await this.questionService.deleteQuestion(question.id!);
    await this.initQuestionnaire();
    this.initQuestions();

    this.application.setLoading(false);
  }

  public addMainQuestion() {
    if (!this.editableState.questions) return;
    this.questions.push({
      active: true,
      description: "",
      extraProperties: {},
      followUpQuestions: [],
      name: "",
      placeholder: "",
      required: true,
      questionType: this.defaultQuestionTypeValue!,
      validation: this.defaultValidationValue!,
    });
  }
}

interface QuestionData {
  question: Question;
  control: FormGroup<{
    name: FormControl<string | null>;
    active: FormControl<boolean | null>;
    description: FormControl<string | null>;
    questionType: FormControl<QuestionType | null>;
    placeholder: FormControl<string | null>;
    required: FormControl<boolean | null>;
    index: FormControl<string | null>;
    validation: FormControl<Validation | null>;
    operatorType: FormControl<OperatorType | null>;
    compareAnswer: FormControl<string | null>;
    compareOptionId: FormControl<number | null>;
    compareOptionReference: FormControl<string | null>;
    parentQuestionId: FormControl<number | null>;
    unitPrice: FormControl<number | null>;
  }>;
  subscription?: Subscription;
  options: QuestionOptionData[];
}

interface QuestionOptionData {
  option: Option;
  control: FormControl<string | null>;
}
