import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { ApplicationService } from "../../services/application.service";
import { DialogService } from "../../services/dialog.service";
import { EnergyConsultService } from "../../services/energy-consult.service";
import { ActivatedRoute } from "@angular/router";
import { RequestStates } from "../../classes/flow/request/RequestStates";
import { EnergyConsult } from "../../classes/flow/request/EnergyConsult";
import { ENVIRONMENT } from "../../../environments/environment";
import { Module } from "../../classes/flow/base/Module";
import { SnackbarService } from "../../services/snackbar.service";
import { BookmarkService } from "../../services/bookmark.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CoachEnergyConsultService } from "../../services/coach-energy-consult.service";
import { map, Observable, startWith } from "rxjs";
import { Coach } from "../../classes/flow/session/impl/Coach";
import { CoachService } from "../../services/coach.service";

export enum Action {
  ALL,
  OPEN,
  NONE,
}

@Component({
  selector: "app-energy-consult[energyConsult]",
  templateUrl: "./energy-consult.component.html",
  styleUrls: ["./energy-consult.component.less"],
})
export class EnergyConsultComponent implements OnInit {
  @Output() initRequests: EventEmitter<unknown> = new EventEmitter();

  @Input("energyConsult")
  public energyConsult!: EnergyConsult;
  public energyConsultId?: string;

  @Input("inspecting")
  public inspecting?: boolean;

  @Input("actions")
  public actions = Action.ALL;

  @ViewChild("deleteDialog")
  public deleteDialog!: TemplateRef<unknown>;

  @ViewChild("cancelDialog")
  public cancelDialog!: TemplateRef<unknown>;

  @ViewChild("noteDialog")
  public noteDialog!: TemplateRef<unknown>;

  //TODO should be put into generic component and used in this and coach-energyconsult component
  public formGroup = new FormGroup({
    date: new FormControl(new Date(), Validators.required),
    time: new FormControl("", Validators.required),
  });
  public changeCoachControl = new FormControl();
  public filteredOptions: Observable<Coach[]> | null = null;
  public coaches?: any;

  @Input("mapView")
  public mapView = false;

  public isEditingDate = false;

  public maxDate: Date;

  //till this point
  public constructor(
    public readonly applicationService: ApplicationService,
    private readonly energyConsultService: EnergyConsultService,
    public readonly dialogService: DialogService,
    private readonly route: ActivatedRoute,
    private readonly snackbar: SnackbarService,
    public readonly bookmarkService: BookmarkService,
    public readonly dialog: DialogService,
    private readonly coachService: CoachService,
    private readonly application: ApplicationService,
    private readonly coachEnergyConsultService: CoachEnergyConsultService
  ) {
    this.maxDate = new Date();
    this.maxDate.setFullYear(new Date().getFullYear() + 1);
  }

  public ngOnInit(): void {
    this.energyConsultId = this.route.snapshot.paramMap.get("id") ?? "";

    this.filteredOptions = this.changeCoachControl.valueChanges.pipe(
      startWith(""),
      map((value) => {
        const name = typeof value === "string" ? value : value?.name;
        return name ? this._filter(name as string) : this.coaches.slice();
      })
    );
  }

  /**
   * Checks if a energyConsult can be deleted
   */
  public get energyConsultCanBeDeleted() {
    return !this.checkAction(2) && this.energyConsult.canBeDeleted && this.applicationService.session.activeRole.name === "resident";
  }

  /**
   * Checks if a energyConsult can be canceled
   */
  public get energyConsultCanBeCanceled() {
    return (
      !this.checkAction(2) &&
      this.energyConsult.canBeCanceled &&
      (this.applicationService.session.activeRole.name === "resident" || this.energyConsult.state.name != RequestStates.NEW)
    );
  }

  /**
   * Gets the current state of the energyConsult
   */
  public get currentState() {
    return this.energyConsult.state.name;
  }

  /**
   * Open a cancel energyConsult Dialog
   * @param energyConsultId The id of the energyConsult
   */
  public cancelRequestDialog(energyConsultId: number) {
    this.dialogService.open({
      template: this.cancelDialog,
      data: {
        id: energyConsultId,
      },
    });
  }

  /**
   * Open a delete energyConsult Dialog
   * @param energyConsultId The id of the energyConsult
   */
  public deleteRequestDialog(energyConsultId: number): void {
    this.dialogService.open({
      template: this.deleteDialog,
      data: {
        id: energyConsultId,
      },
    });
  }

  /**
   * Cancel a energyConsult
   */
  public async cancelRequest() {
    const oldState = this.energyConsult.state.name;
    this.energyConsult.state.name = RequestStates.CANCELED;
    const Messages = await this.energyConsultService.setState(this.energyConsult);
    if (Messages.length > 0) {
      Messages.forEach((message: { message: string }) => {
        console.error(message);
        this.snackbar.open(message.message);
      });
      this.energyConsult.state.name = oldState;
    } else {
      this.initRequests.emit();
    }
  }

  /**
   * Delete a energyConsult
   */
  public async deleteRequest(): Promise<void> {
    if (this.energyConsult.canBeDeleted && this.energyConsult.state.name == RequestStates.NEW && !this.checkAction(Action.NONE)) {
      await this.energyConsultService.delete(this.energyConsult);
      this.initRequests.emit();
      return;
    } else if (this.energyConsult.canBeDeleted && !this.checkAction(Action.NONE)) {
      this.energyConsult.state.name = RequestStates.PENDING_REJECT;
    } else if (this.applicationService.session.activeRole.name === "resident") {
      this.energyConsult.state.name = RequestStates.PENDING_DELETED;
    }

    await this.energyConsultService.save(this.energyConsult, "");
    this.initRequests.emit();
  }

  /**
   * Checks if the given number is equals to the current action
   * @param number The number of the action
   * @returns True if the number is equals to the current action, otherwise false
   */
  public checkAction(number: number): boolean {
    return this.actions == number;
  }

  displayCoachName(): string | null {
    const coach = this.energyConsult.coach;
    const firstName = coach?.firstName;
    const lastName = coach?.lastName;
    if (!coach) return null;
    if (!firstName) return null;
    if (!lastName) return null;

    return `${firstName} ${lastName}`;
  }

  displayResidentName(): string | null {
    const resident = this.energyConsult.resident;
    const firstName = resident?.firstName;
    const lastName = resident?.lastName;
    if (!resident) return null;
    if (!firstName) return null;
    if (!lastName) return null;

    return `${firstName} ${lastName}`;
  }

  public includesModule(module: Module): boolean {
    return ENVIRONMENT.MODULES.includes(module);
  }

  public async toggleBookmark(): Promise<void> {
    this.energyConsult.bookmarked = !this.energyConsult.bookmarked;
    await this.bookmarkService.setBookmark(this.energyConsult.id, this.energyConsult.bookmarked ? "add" : "remove");
  }

  public showNoteDialog(): void {
    this.dialogService.open({
      template: this.noteDialog,
      data: {
        note: this.energyConsult.extraProperties?.note,
      },
    });
  }

  //TODO should be put into generic component and used in this and coach-energyconsult component
  public setEditingDate(isEditingDate: boolean): void {
    console.log("setEditingDate", isEditingDate);
    this.isEditingDate = isEditingDate;
  }

  public fillInPreferredTimeSlot(index: number) {
    try {
      this.formGroup.controls["date"].setValue(this.energyConsult?.extraProperties?.preferredTimeSlots?.at(index)?.startTime ?? null);
      this.formGroup.controls["time"].setValue(this.energyConsult?.extraProperties?.preferredTimeSlots?.at(index)?.startTime.toLocaleTimeString("en-GB").slice(0, 5) ?? null);
      this.formGroup.controls["date"].markAsTouched();
      this.formGroup.controls["time"].markAsTouched();
    } catch {
      // this.snackbarService.error();
    }
  }

  public async saveDate() {
    if (this.energyConsult) {
      const date: Date = this.getGivenDate();
      this.energyConsult.state.name = RequestStates.DATE;
      const res = await this.coachEnergyConsultService.saveDate(this.energyConsult, date.toISOString());

      if (!res.errors) {
        this.isEditingDate = false;
        this.energyConsult.appointmentDate = date;
      }
    }
  }
  private getGivenDate(): Date {
    const date: Date = this.formGroup.value.date!;
    date.setHours(+this.formGroup.value.time!.substr(0, 2), +this.formGroup.value.time!.substr(3, 2));
    return date;
  }

  public async assignCoach(coach: Coach) {
    this.application.setLoading(true);
    if (this.energyConsult) {
      await this.coachEnergyConsultService.AcceptEnergyConsult(this.energyConsult, coach);
      this.energyConsult.coach = coach;
      // this.initEnergyConsult(this.energyConsult.id);
      this.dialog.close();
    }
    this.application.setLoading(false);
  }
  public async changeCoachClickHandler(template: TemplateRef<any>) {
    this.changeCoachControl.patchValue(this.energyConsult?.coach);
    if (!this.coaches) {
      await this.getCoaches().then(() => {
        this.dialog.show(template);
      });
    } else {
      this.dialog.show(template);
    }
  }
  public async getCoaches() {
    this.application.setLoading(true);
    const res = await this.coachService.getAllCoaches();
    this.coaches = res.filter((coach) => {
      if (coach.specialties.map((specialty) => specialty.id).includes(this.energyConsult!.specialty.id)) return true;
      return false;
    });
    this.application.setLoading(false);
    console.error(this.coaches);
    return this.coaches;
  }

  public changeDisplayCoachName(coach: Coach) {
    if (coach) return coach.firstName + " " + coach.lastName;
    return "";
  }

  private _filter(name: string): Coach[] {
    const filterValue = name.toLowerCase();

    return this.coaches.filter(
      (coach: { firstName: string; lastName: string }) => coach.firstName.toLowerCase().includes(filterValue) || coach.lastName.toLowerCase().includes(filterValue)
    );
  }
  //till here
}
