import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApplicationService } from "../services/application.service";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { ENVIRONMENT } from "../../environments/environment";
import sha256 from "crypto-js/sha256";

@Injectable()
export class qrValidator {
  static validator(http: HttpClient, applicationService: ApplicationService, expectedId?: number): AsyncValidatorFn {
    return async (control: AbstractControl): Promise<ValidationErrors | null> => {
      try {
        if (control.untouched) return null;
        if (control.disabled) return null;
        applicationService.setLoading(true);
        const value = control.value;

        // Invalid QR
        if (typeof value !== "string") throw new Error("FORMS.ERRORS.QR.INVALID");

        const url = `${ENVIRONMENT.API_URL}graphql/endpoints/validateQR?qrcode=${value}`;
        const res = await firstValueFrom(http.post<QRCodeResponse>(url, {}));

        if (expectedId !== undefined) {
          // Check if QR response is not empty
          if (!res.values || !res.values.length) throw new Error("FORMS.ERRORS.QR.NO_MATCH");

          // Check if QR response has hID
          const hID = res.values.filter((value) => value.name === "hID")[0];
          if (!hID) throw new Error("FORMS.ERRORS.QR.NO_MATCH");

          // Check if hash of ExpectedId matches hID
          if (hID.value.toLowerCase() !== sha256(expectedId.toString()).toString().toLowerCase()) throw new Error("FORMS.ERRORS.QR.NO_MATCH");
        } else {
          // Check if QR response is empty
          if (res && res.values && res.values.length) throw new Error("FORMS.ERRORS.QR.REGISTERED");
        }

        applicationService.setLoading(false);
        return null;
      } catch (error) {
        applicationService.setLoading(false);
        if (error instanceof Error) {
          return { invalidQR: error.message };
        }

        if (expectedId) {
          return { invalidQR: "FORMS.ERRORS.QR.NO_MATCH" };
        }

        return { invalidQR: "FORMS.ERRORS.QR.INVALID" };
      }
    };
  }
}

type QRCodeResponse = {
  values: [{ name: string; value: any }];
};
