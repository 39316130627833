<div class="py-3">
  <div class="d-flex flex-row justify-content-end gap-3">
    <button mat-raised-button (click)="export('csv')">{{ "COMPONENTS.DASHBOARD_STATISTICS.EXPORT" | translate }} csv</button>
    <button mat-raised-button (click)="export('xls')">{{ "COMPONENTS.DASHBOARD_STATISTICS.EXPORT" | translate }} xls</button>
  </div>
  <h3 style="text-align: center">
    {{ "COMPONENTS.DASHBOARD_STATISTICS.TITLE_PREFIX" | translate }}
    <ng-container *ngFor="let mun of municipalities; let first = first; let last = last">{{ (!first ? (last ? " & " : ", ") : "") + mun }}</ng-container>
  </h3>
  <!-- 
        tab selection
    -->
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" (selectedTabChange)="handleTabChange($event)">
    <!-- 
        tab timeline    
    -->
    <mat-tab [label]="'COMPONENTS.DASHBOARD_STATISTICS.TABS.YEARS' | translate">
      <div class="row py-3">
        <div class="col-xxl-3 d-flex flex-column" style="text-align: right">
          <ng-container *ngIf="tabs.time.total">
            <ng-container
              [ngTemplateOutlet]="sidebarItem"
              [ngTemplateOutletContext]="{
                value: tabs.time.total.count,
                title: 'COMPONENTS.DASHBOARD_STATISTICS.SIDEBAR.CONSULTS' | translate,
                description: 'COMPONENTS.DASHBOARD_STATISTICS.SIDEBAR.CONSULTS_DESCR' | translate
              }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="sidebarItem"
              [ngTemplateOutletContext]="{
                value: tabs.time.total.averageTimeInDays,
                title: 'COMPONENTS.DASHBOARD_STATISTICS.SIDEBAR.TIME_DURATION' | translate,
                description: 'COMPONENTS.DASHBOARD_STATISTICS.SIDEBAR.TIME_DURATION_DESCR' | translate
              }"
            ></ng-container>
          </ng-container>
        </div>
        <div class="col-xxl-9 row flex-xxl-nowrap">
          <div class="col-xxl-4 py-4 px-2"><app-statistic-tile [statistic]="tabs.time.total"></app-statistic-tile></div>
          <ng-container *ngFor="let statistic of tabs.time.statisticsYear">
            <div class="col-xxl-4 py-4 px-2"><app-statistic-tile [statistic]="statistic"></app-statistic-tile></div>
          </ng-container>
        </div>
      </div>
    </mat-tab>
    <!-- 
        tab area actions
    -->
    <mat-tab [label]="'COMPONENTS.DASHBOARD_STATISTICS.TABS.ACTIONS' | translate" onclick="await this.loadAreaActions()">
      <div class="row py-3">
        <div class="col-xxl-3 d-flex flex-column" style="text-align: right">
          <ng-container
            [ngTemplateOutlet]="sidebarItem"
            [ngTemplateOutletContext]="{
              value: tabs.action.statistics.length,
              title: 'COMPONENTS.DASHBOARD_STATISTICS.SIDEBAR.AREA_ACTIONS' | translate,
              description: 'COMPONENTS.DASHBOARD_STATISTICS.SIDEBAR.AREA_ACTIONS_DESCR' | translate
            }"
          ></ng-container>
        </div>
        <div class="col-xxl-9 row flex-xxl-nowrap">
          <ng-container *ngFor="let statistic of tabs.action.statistics">
            <div class="col-xxl-4 py-4 px-2"><app-statistic-tile [statistic]="statistic"></app-statistic-tile></div>
          </ng-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #sidebarItem let-value="value" let-title="title" let-description="description">
  <div class="p-2">
    <h4>{{ value }}</h4>
    <div>{{ title }}</div>
    <div>{{ description }}</div>
  </div>
</ng-template>
