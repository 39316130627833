import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Option } from "../../classes/flow/Questionnaire/Option";
import { Logic, Question } from "../../classes/flow/Questionnaire/Question";
import { Questionnaire } from "../../classes/flow/Questionnaire/Questionnaire";
import { RequestStates } from "../../classes/flow/request/RequestStates";
import { ApplicationService } from "../../services/application.service";
import { CoachEnergyConsultService } from "../../services/coach-energy-consult.service";
import { DialogService } from "../../services/dialog.service";
import { EnergyConsultService } from "../../services/energy-consult.service";
import { GraphQLService } from "../../services/graphql.service";
import { QuestionService } from "../../services/question.service";
import { ReportService } from "../../services/report.service";
import { SnackbarService } from "../../services/snackbar.service";
import { specialCharValidator } from "../../validators/specialChars";
import { ReportComponent } from "../report/report.component";

@Component({
  selector: "app-view-report",
  templateUrl: "./view-report.component.html",
  styleUrls: ["./view-report.component.less"],
})
export class ViewReportComponent extends ReportComponent implements OnInit {
  @ViewChild("successfullyDisapprovedDialog")
  public successfullyDisapprovedDialog!: TemplateRef<unknown>;
  public disapprovalReason: FormControl = new FormControl("", [Validators.required, specialCharValidator]);

  public files: { name: string; base64: string }[] = [];

  constructor(
    router: Router,
    route: ActivatedRoute,
    graphService: GraphQLService,
    snackService: SnackbarService,
    dialogService: DialogService,
    reportService: ReportService,
    energyConsultService: EnergyConsultService,
    questionService: QuestionService,
    translateService: TranslateService,
    applicationService: ApplicationService,
    private coachEnergyConsultService: CoachEnergyConsultService
  ) {
    super(router, route, graphService, snackService, dialogService, reportService, energyConsultService, questionService, translateService, applicationService);
  }

  async ngOnInit() {
    this.applicationService.setLoading(true);
    await super.ngOnInit();

    this.files = await this.getAttachments();
    this.applicationService.setLoading(false);
  }

  /**
   * initial setup for the questionnaire
   */
  protected async retrieveReport() {
    if (this.energyConsult) {
      try {
        this.questionnaires = await this.reportService.getAllAnsweredQuestions(this.energyConsult.id);
        this.retrieveQuestionnaires();
      } catch (error) {
        this.snackService.open(this.translateService.instant("FORMS.REPORT.ERROR.COMPLETED"));
        this.router.navigate(["/content/resident/report", this.energyConsult.id]);
      }
    }
  }

  /**
   * Checks the compare answer with the answer
   * @param res The first answer
   * @param logic The logic of the second answer
   * @returns The result of the comparison
   */
  protected checkCompare(res: any, logic: Logic): boolean {
    switch (logic.operatorType?.operation) {
      case "===":
        return res === logic.compareAnswer;
      case "!=":
        return res != logic.compareAnswer;
      case ">":
        return parseInt(res) > parseInt(logic.compareAnswer!);
      case "<":
        return parseInt(res) < parseInt(logic.compareAnswer!);
    }
    return false;
  }

  /**
   * Gets an option of the given question
   * @param question The specific question
   * @returns The value of the particular option
   */
  public getSelectOption(question: Question): string {
    return question.options?.find((option: Option) => option.id == question.answer?.answer)?.value ?? "";
  }

  public getSelectOptionMultiple(question: Question): string {
    const returnAnswer = [];
    if (question.answer && question.answer.answer) {
      const answers = question.answer.answer.split(",").map((a) => Number(a));
      for (const answer of answers) {
        const answerOption = question.options?.find((option: Option) => option.id == answer)?.value;
        if (answerOption) returnAnswer.push(answerOption);
      }
    }

    return returnAnswer.join(", ");
  }

  public formatTime(time: string): string {
    return time
      .split("-")
      .map((timestamp) => timestamp.split(":"))
      .map((timestamp) => timestamp.map((time) => time.padStart(2, "0")).join(":"))
      .join("-");
  }

  /**
   * Sends a mail
   */
  public async sendRequestToMail() {
    if (this.energyConsult) {
      this.applicationService.setLoading(true);
      try {
        await this.energyConsultService.sendPDFToResident(this.energyConsult.id);
        this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.MAIL"));
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"));
      }
      this.applicationService.setLoading(false);
    }
  }

  /**
   * Approves the current energyConsult
   */
  public async approveRequest() {
    if (this.energyConsult) {
      try {
        this.energyConsult.state.name = RequestStates.DONE;
        await this.energyConsultService.setState(this.energyConsult);
        this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.COMPLETED"), "", 5000);
        this.router.navigate(["/content"]);
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"));
      }
    }
  }

  /**
   * Disapproves the current energyConsult
   */
  public async disapproveRequest() {
    if (this.energyConsult) {
      try {
        this.energyConsult.state.name = RequestStates.DISAPPROVED;
        this.energyConsult.disapprovalReason = this.disapprovalReason.value;
        this.energyConsult.state.name = RequestStates.DISAPPROVED;
        await this.energyConsultService.disapproveEnergyConsult(this.energyConsult);

        this.dialogService.close();
        this.dialogService.open({
          template: this.successfullyDisapprovedDialog,
        });
        this.router.navigate(["/content/resident/dashboard"]);
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"));
      }
    }
  }

  public getQuestionTotal(q: Question): number {
    return Number(q.extraProperties.unitPrice) * Number(q.answer?.answer);
  }

  public getQuestionnaireTotal(q: Questionnaire): number | null {
    let totalModified = false;
    let total = 0;

    if (q.questions) {
      for (const question of q.questions) {
        if (question.questionType.prefix === "upp") {
          totalModified = true;
          total += this.getQuestionTotal(question);
        }
      }
    }

    return totalModified ? total : null;
  }

  public async getAttachments() {
    const files: { name: string; base64: string }[] = [];
    try {
      if (!this.energyConsult) throw new Error("No energyconsult");
      let list = await this.coachEnergyConsultService.getAttachmentList(this.energyConsult);
      list = list.filter((file: any) => file.name.includes("-signature.png"));

      for (const item of list) {
        const file = await this.retrieveFile(item);
        if (file) files.push(file);
      }
    } catch (error) {
      this.snackService.error();
    }
    return files;
  }

  public async retrieveFile(file: { actionIDDelete: string; actionIDGet: string; extension: string; fileSize: number; name: string }) {
    const res = await this.coachEnergyConsultService.postRequest(
      "action",
      {
        FFWDActionID: file.actionIDGet,
      },
      {
        observe: "response",
        responseType: "blob",
      }
    );

    const base = await new Promise((resolve) => {
      this.applicationService.setLoading(true);
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        this.applicationService.setLoading(false);
      };
      reader.readAsDataURL(res.body);
    });

    if (!(typeof base === "string")) throw new Error("Invalid file");

    return { name: file.name, base64: "data:image/png;base64," + base.split(",")[1]! };
  }

  getSignatureByQuestion(question: Question): string | null {
    if (question.questionType.prefix !== "sig") return null;

    if (question.answer?.answer) return this.files.filter((f) => f.name === question.answer?.answer)[0]?.base64 ?? null;

    return this.files.filter((f) => f.name === `${question.id}-signature.png`)[0]?.base64 ?? null;
  }
}
