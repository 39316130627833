import { AfterViewInit, Component, inject, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Coach } from "../../../classes/flow/session/impl/Coach";
import { EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { CoachService } from "../../../services/coach.service";
import { EnergyConsultService } from "../../../services/energy-consult.service";
import { GoogleMap } from "@angular/google-maps";
import { StorageService } from "../../../services/storage.service";
import { mapDefaults } from "../../../defaults/map";
import { ApplicationService } from "../../../services/application.service";

interface PlanningDataSet {
  coaches: Coach[];
  date: Date;
  visible: boolean;
  color: string;
  consults: (EnergyConsult & { location?: string; marker?: { position: { lat: number; lng: number } } })[];
  lines: any;
}

@Component({
  selector: "app-consult-planning-view",
  templateUrl: "./consult-planning-view.component.html",
  styleUrls: ["./consult-planning-view.component.less"],
})
export class ConsultPlanningViewComponent implements OnInit, AfterViewInit {
  @ViewChild("planningMapID")
  public map!: GoogleMap;

  public coachService: CoachService;
  public energyConsultService: EnergyConsultService;
  public storageService: StorageService;
  private applicationService: ApplicationService;

  public form: FormGroup<{ coaches: FormControl<Coach[] | null>; date: FormControl<Date | null> }>;

  public sets: PlanningDataSet[];

  public coaches: Coach[];

  public mapSettings: {
    zoom: number;
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions;
  };

  public loading: boolean;

  public colors: { [key: number]: string };

  public setQueriedData: { coaches: Coach[]; date: Date }[] = [];

  // wip for custom google map icons
  // public anchorPoint: google.maps.Point = new google.maps.Point(20, 45);
  // public labelAnchorPoint: google.maps.Point = new google.maps.Point(100, 100);

  public constructor() {
    this.coachService = inject(CoachService);
    this.energyConsultService = inject(EnergyConsultService);
    this.storageService = inject(StorageService);
    this.applicationService = inject(ApplicationService);

    this.form = new FormGroup({
      coaches: new FormControl<Coach[] | null>(null, Validators.required),
      date: new FormControl<Date | null>(new Date(), Validators.required),
    });
    this.sets = [];
    this.coaches = [];

    this.mapSettings = mapDefaults;

    this.loading = false;

    this.colors = {
      0: "#0d6efd",
      1: "#ff2343",
      2: "#0d5eff",
    };
  }

  public async ngOnInit(): Promise<void> {
    this.coaches = await this.coachService.getActiveCoaches();
  }
  public ngAfterViewInit(): void {
    const storedData = this.storageService.fetch("usersettings.map.planningView.setQueriedData", "localStorage");

    if (Array.isArray(storedData)) {
      this.setQueriedData = storedData.map((set: any) => {
        return {
          coaches: set.coaches.map((coach: any) => new Coach(coach)),
          date: new Date(set.date),
        };
      });

      this.initializeSetsFromStoredData();
    }

    const zoom = this.storageService.fetch<number>("usersettings.map.planningView.zoom", "localStorage");
    if (zoom) this.mapSettings.zoom = zoom;
    const center = this.storageService.fetch<google.maps.LatLngLiteral>("usersettings.map.planningView.center", "localStorage");
    if (center) this.mapSettings.center = center;
  }

  private async initializeSetsFromStoredData(): Promise<void> {
    for (const set of this.setQueriedData) {
      this.form.controls.date.setValue(set.date);
      this.form.controls.coaches.setValue(set.coaches);
      await this.addSet(false);
    }
  }

  public clearForm(): void {
    this.form.reset({ date: new Date() });
    this.form.reset({ coaches: null });
  }

  public async addSet(clearForm = true): Promise<void> {
    if (this.form.invalid || !this.form.controls.coaches.value || !(this.form.controls.coaches.value.length > 0) || !this.form.controls.date.value) return;
    this.loading = true;
    const newSet = { coaches: this.form.controls.coaches.value, date: this.form.controls.date.value };
    const isSetAlreadyQueried = this.setQueriedData.some((set) => {
      return JSON.stringify(set.coaches) === JSON.stringify(newSet.coaches) && new Date(set.date).getTime() === new Date(newSet.date).getTime();
    });

    if (!isSetAlreadyQueried) {
      this.setQueriedData.push(newSet);
    }

    this.storageService.store("usersettings.map.planningView.setQueriedData", this.setQueriedData, "localStorage");

    const consults = (
      await this.energyConsultService.getConsultsByCoachByDay(
        this.form.controls.coaches!.value.map((c) => c.id!),
        this.form.controls.date.value
      )
    )
      .sort((a, b) => (a.appointmentDate?.getTime() ?? 0) - (b.appointmentDate?.getTime() ?? 0))
      .map((ec) => {
        (ec as EnergyConsult & { location?: string }).location = ec.getLocation(this.applicationService);
        return ec;
      })
      .filter((ec) => ec.state.name !== "Disapproved" && ec.state.name !== "PendingDeleted" && ec.state.name !== "PendingReject" && ec.state.name !== "Canceled");

    for (const ec of consults as (EnergyConsult & { location?: string; marker?: { position: { lat: number; lng: number } } })[]) {
      const lat = Number(ec.extraProperties?.houseCoordinates?.Latitude);
      const lng = Number(ec.extraProperties?.houseCoordinates?.Longitude);
      if (!isNaN(lat) && !isNaN(lng))
        ec.marker = {
          position: {
            lat: Number(ec.extraProperties?.houseCoordinates?.Latitude),
            lng: Number(ec.extraProperties?.houseCoordinates?.Longitude),
          },
        };
    }

    this.sets = [
      ...this.sets,
      {
        coaches: this.form.controls.coaches.value,
        date: this.form.controls.date.value,
        visible: true,
        consults: consults,
        color: this.colors[this.sets.length] ?? "black",
        lines: {
          path: (consults as (EnergyConsult & { location?: string; marker?: { position: { lat: number; lng: number } } })[]).map((c) => c.marker?.position).filter((m) => m),
          icons: [{ icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, scale: 2.5 }, repeat: "46px" }],
        },
      },
    ];
    this.loading = false;
    if (clearForm) {
      this.clearForm();
    }
  }

  public removeSet(index: number): void {
    this.setQueriedData = this.setQueriedData.filter((val, i) => i !== index);
    this.storageService.store("usersettings.map.planningView.setQueriedData", this.setQueriedData, "localStorage");

    this.sets = this.sets.filter((val, i) => i !== index);
    this.sets = this.sets.map((set, index) => {
      return {
        ...set,
        color: this.colors[index],
      };
    });
  }

  public zoomChangedEventHandler(): void {
    this.storageService.store("usersettings.map.planningView.zoom", this.map.getZoom(), "localStorage");
  }

  public centerChangedEventHandler(): void {
    this.storageService.store("usersettings.map.planningView.center", this.map.getCenter(), "localStorage");
  }
}
