import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, map, pairwise, startWith } from "rxjs";
import { v4 as generateUUID } from "uuid";
import { ENVIRONMENT } from "../../../../../environments/environment";
import { EnergyConsult } from "../../../../classes/flow/request/EnergyConsult";
import { RequestStates } from "../../../../classes/flow/request/RequestStates";
import { Coach } from "../../../../classes/flow/session/impl/Coach";
import { downloadFile } from "../../../../helpers/downloadFile";
import { AddressService } from "../../../../services/address.service";
import { ApplicationService } from "../../../../services/application.service";
import { CoachEnergyConsultService } from "../../../../services/coach-energy-consult.service";
import { CoachService } from "../../../../services/coach.service";
import { DialogService } from "../../../../services/dialog.service";
import { EnergyConsultService } from "../../../../services/energy-consult.service";
import { GraphQLService } from "../../../../services/graphql.service";
import { ReportService } from "../../../../services/report.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { UserService } from "../../../../services/user.service";
import { alphaValidator } from "../../../../validators/alpha";
import { houseNumberValidator } from "../../../../validators/houseNumber";
import { phoneNumberValidator } from "../../../../validators/phoneNumber";
import { postalCodeValidator } from "../../../../validators/postalCode";
import { HttpResponse } from "@angular/common/http";
import { trueAlphaNumeric } from "../../../../validators/trueAlphaNumeric";
import { MatButton } from "@angular/material/button";
import { DashboardDataService } from "../../../../services/dashboard-data.service";
@Component({
  selector: "app-coach-energy-consult",
  templateUrl: "./coach-energy-consult.component.html",
  styleUrls: ["./coach-energy-consult.component.less"],
})
export class CoachEnergyConsultComponent implements OnDestroy, AfterViewInit, OnInit {
  private id: string = generateUUID();
  public energyConsult?: EnergyConsult;
  public isEditingDate = false;
  public formGroup = new FormGroup({
    date: new FormControl(new Date(), Validators.required),
    time: new FormControl("", Validators.required),
  });
  public rejectReasonControl = new FormControl("", Validators.required);
  public noteControl = new FormControl("", Validators.required);

  public pdfSendDate?: string;
  public sendDateAvailable = false;
  public fileNames: Array<{ name: string; extension: string; actionIDGet: string; actionIDDelete: string; fileSize: number }> = [];
  public coaches?: any;
  public changeCoachControl = new FormControl();
  public showActions = false;

  public fileSelected$ = new BehaviorSubject<string | undefined>(undefined);

  @ViewChild("pdfGenerateSuccess")
  private pdfGenerateSuccess!: TemplateRef<unknown>;

  @ViewChild("savedNoteSuccess")
  private savedNoteSuccess!: TemplateRef<unknown>;

  @ViewChild("attachmentFileInput")
  private fileInput?: ElementRef<HTMLInputElement>;

  @ViewChild("editDialog")
  public editDialog!: TemplateRef<unknown>;

  @ViewChild("ckdSuccesDialog")
  public ckdSuccesDialog!: TemplateRef<unknown>;

  public maxDate: Date;

  public editFormGroup = new FormGroup({
    firstName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(2), alphaValidator]),
    lastName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(2), alphaValidator]),
    email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
    usePhoneNumber: new FormControl<boolean | null>(null),
    address: new FormGroup({
      postalCode: new FormControl<string | null>(null, [Validators.required, postalCodeValidator]),
      houseNumber: new FormControl<number | null>(null, [Validators.required, houseNumberValidator]),
      houseNumberSuffix: new FormControl<string | null>(null, [trueAlphaNumeric, Validators.maxLength(5)]),
    }),
    phoneNumber: new FormControl<string | null>({ disabled: true, value: null }, [Validators.required, phoneNumberValidator]),
    streetName: new FormControl<string | null>(null, [alphaValidator, Validators.required]),
    town: new FormControl<string | null>(null, [alphaValidator, Validators.required]),
  });
  public filteredOptions: Observable<Coach[]> | null = null;
  public editFormPNUseSub?: Subscription;
  public filterPostalCodeInput?: Subscription;
  public addressAutoFillSubscription?: Subscription;
  private fileTypes: string[] = ["image/apng", "image/avif", "image/gif", "image/jpeg", "image/png", "image/svg+xml", "image/webp", "application/pdf"];

  public constructor(
    public readonly application: ApplicationService,
    private readonly translateService: TranslateService,
    private readonly energyConsultService: EnergyConsultService,
    public readonly user: UserService,
    private readonly route: ActivatedRoute,
    public readonly dialog: DialogService,
    private readonly snackbarService: SnackbarService,
    private readonly coachService: CoachService,
    private readonly coachEnergyConsultService: CoachEnergyConsultService,
    private readonly reportService: ReportService,
    private router: Router,
    private readonly addressService: AddressService,
    private readonly graphlService: GraphQLService,
    private readonly dashboardDataService: DashboardDataService
  ) {
    this.maxDate = new Date();
    this.maxDate.setFullYear(new Date().getFullYear() + 1);
  }

  public ngOnInit(): void {
    this.filteredOptions = this.changeCoachControl.valueChanges.pipe(
      startWith(""),
      map((value) => {
        const name = typeof value === "string" ? value : value?.name;
        return name ? this._filter(name as string) : this.coaches.slice();
      })
    );
  }

  private _filter(name: string): Coach[] {
    const filterValue = name.toLowerCase();

    return this.coaches.filter(
      (coach: { firstName: string; lastName: string }) => coach.firstName.toLowerCase().includes(filterValue) || coach.lastName.toLowerCase().includes(filterValue)
    );
  }

  public ngAfterViewInit(): void {
    this.application.setLoading(true);
    const route = this.route.params.subscribe(async (params: Params) => {
      await this.initEnergyConsult(params.id);
      this.initializeFormGroup();
      this.checkSendReportDate();
      if (this.energyConsult!.state.name === "FilledIn" || this.energyConsult!.state.name === "Done" || this.energyConsult!.state.name === "PendingReject") {
        this.getfileNames();
        const contentIds = await this.coachEnergyConsultService.getEnergyConsultContentIds(params.id);
        this.energyConsult!.ffwdContentId = contentIds.ffwdcontentid;
        this.energyConsult!.ffwdContentPartId = contentIds.ffwdcontentpartid;
      }
    });
    this.application.subscribe(this.id, route);
    this.application.setLoading(false);
  }

  public ngOnDestroy(): void {
    this.application.unsubscribe(this.id);
    if (this.editFormPNUseSub !== undefined) this.editFormPNUseSub.unsubscribe();
    if (this.filterPostalCodeInput !== undefined) this.filterPostalCodeInput.unsubscribe();
    if (this.addressAutoFillSubscription !== undefined) this.addressAutoFillSubscription.unsubscribe();
  }

  public setEditingDate(isEditingDate: boolean): void {
    this.isEditingDate = isEditingDate;
  }

  public newFileSelected() {
    if (this.fileTypes.includes(this.fileInput!.nativeElement.files!.item(0)!.type)) {
      this.fileSelected$.next(this.fileInput?.nativeElement?.files?.item(0)?.name);
    } else {
      const extension = this.fileInput!.nativeElement.files!.item(0)!.name.split(".").at(-1);
      this.snackbarService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.FILEMANAGEMENT.FILE_TYPE_ERROR", { fileType: "." + extension }), "", 5000);
    }
  }

  /**
   * Queries for attachments metaData + action IDs
   * stores them in this.fileNames
   * can be recalled to update the shown files on the page
   */
  public async getfileNames() {
    this.application.setLoading(true);
    const res = await this.coachEnergyConsultService.getAttachmentList(this.energyConsult!);
    const fileObjects = res
      .map((item: { name: string; extension: string; actionIDGet: string; actionIDDelete: string; fileSize: number }) => {
        return { fileName: item.name, extension: item.extension, getId: item.actionIDGet, deleteId: item.actionIDDelete, fileSize: item.fileSize };
      })
      .filter((file: { fileName: string; extension: string; getId: string; deleteId: string; fileSize: number }) => {
        return !file.fileName.includes("-signature.png");
      });
    this.fileNames = fileObjects;
    this.application.setLoading(false);
  }

  /**
   * Uploads the given file object to the content endpoint
   * @param files value of the file input from html
   */
  public async uploadAttachment(files: FileList | null) {
    if (!files?.length) {
      return;
    }

    this.application.setLoading(true);
    let totalFileSize = 0;
    Array.from(files).forEach((file) => {
      totalFileSize += file.size;
    });
    this.fileNames.forEach((item) => {
      totalFileSize += item.fileSize;
    });
    if (totalFileSize > 18000000) {
      this.snackbarService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.FILEMANAGEMENT.TOO_LARGE"));
      this.application.setLoading(false);
      return;
    }

    for (const file of Array.from(files)) {
      const formData = new FormData();
      formData.append("contentid", this.energyConsult!.ffwdContentId!);
      formData.append("contentpartid", this.energyConsult!.ffwdContentPartId!);
      formData.append("attachment", "true");
      formData.append("file", file);
      const reponse: HttpResponse<object> = await this.coachEnergyConsultService.postRequest("content", formData, { observe: "response", withCredentials: true });
      if (reponse.status !== 200) {
        this.snackbarService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.FILEMANAGEMENT.ERROR"));
      }
    }
    await this.getfileNames();
    this.application.setLoading(false);
  }

  /**
   * Queries for file data and downloads it for the user
   * @param getId the download action id queried for earlier
   * @param fileName name to give the file
   */
  public async downloadTableFile(getId: string, fileName: string) {
    this.application.setLoading(true);
    const res = await this.coachEnergyConsultService.postRequest(
      "action",
      {
        FFWDActionID: getId,
      },
      {
        observe: "response",
        responseType: "blob",
      }
    );
    if (res instanceof Error) {
      this.snackbarService.error();
    }

    const objectURL = URL.createObjectURL(res.body);
    const a = document.createElement("a");
    a.href = objectURL;
    a.download = fileName;
    a.click();
    a.remove();
    URL.revokeObjectURL(objectURL);
    this.application.setLoading(false);
  }

  /**
   * Sends the id to the action endpoint to trigger delete action
   * @param deleteId the delete action id queried for earlier
   */
  public async deleteTableFile(deleteId: string) {
    this.application.setLoading(true);
    const res = await this.coachEnergyConsultService.postRequest("action", {
      FFWDActionID: deleteId,
    });
    if (res instanceof Error) {
      this.snackbarService.error();
    }
    await this.getfileNames();
    this.application.setLoading(false);
  }

  /**
   * Initializes the form group of the date
   */
  private initializeFormGroup() {
    const appointmentDate = this.energyConsult?.appointmentDate;
    const today = new Date();
    this.formGroup.patchValue({
      date: appointmentDate && appointmentDate < today ? today : appointmentDate,
      time: appointmentDate?.toTimeString().substring(0, 5) ?? "",
    });
  }

  /**
   * gets the date of when the report was send
   */
  private async checkSendReportDate() {
    if (this.energyConsult) {
      this.application.setLoading(true);
      const res = await this.getSendReportDate(this.energyConsult);
      if (res != null) {
        this.pdfSendDate = res;
        this.sendDateAvailable = true;
      } else {
        this.sendDateAvailable = false; // TODO: else this
      }
      this.application.setLoading(false);
    }
  }

  private async getSendReportDate(energyConsult: EnergyConsult) {
    this.application.setLoading(true);
    const res = await this.coachEnergyConsultService.getSendReportDate(energyConsult);
    if (res) {
      const dateRes = new Date(res);
      const dateString = dateRes.toISOString().slice(0, 19).replace("T", " ").toString();
      this.application.setLoading(false);
      return dateString;
    } else {
      this.application.setLoading(false);
      return null;
    }
  }

  private async setSendReportDate() {
    if (this.energyConsult) {
      this.application.setLoading(true);
      await this.coachEnergyConsultService.setSendReportDate(this.energyConsult);
      await this.initEnergyConsult(this.energyConsult.id);
    }
  }

  /**
   * Sends a mail and uses the loading component to prevent the user from clicking multiple times
   */
  public async sendPDFMail() {
    if (this.energyConsult) {
      try {
        this.application.setLoading(true);
        await this.energyConsultService.sendPDFToResident(this.energyConsult.id);
        this.openPDFSuccessDialog();
        await this.setSendReportDate();
        await this.checkSendReportDate();
      } catch (error) {
        this.snackbarService.open(this.translateService.instant("COMPONENTS.COACH_REQUEST.CLAIM.FAILURE_MESSAGE"));
      }
      this.application.setLoading(false);
    }
  }

  /**
   * Opens a dialog if the pdf is successfull generated
   */
  private openPDFSuccessDialog() {
    this.dialog.open({
      template: this.pdfGenerateSuccess,
    });
  }

  /**
   * Saves the chosen date
   */
  public async saveDate() {
    if (this.energyConsult) {
      this.application.setLoading(true);
      const date: Date = this.getGivenDate();
      this.energyConsult.state.name = RequestStates.DATE;
      const res = await this.coachEnergyConsultService.saveDate(this.energyConsult, date.toISOString());

      if (!res.errors) {
        this.isEditingDate = false;
        this.energyConsult.appointmentDate = date;
      }
      await this.initEnergyConsult(this.energyConsult.id);
      this.application.setLoading(false);
    }
  }

  /**
   * fills the preferred time slot into appointment input fiels
   * @param index index of time slot in array
   */
  public fillInPreferredTimeSlot(index: number) {
    try {
      this.formGroup.controls["date"].setValue(this.energyConsult?.extraProperties?.preferredTimeSlots?.at(index)?.startTime ?? null);
      this.formGroup.controls["time"].setValue(this.energyConsult?.extraProperties?.preferredTimeSlots?.at(index)?.startTime.toLocaleTimeString("en-GB").slice(0, 5) ?? null);
      this.formGroup.controls["date"].markAsTouched();
      this.formGroup.controls["time"].markAsTouched();
    } catch {
      this.snackbarService.error();
    }
  }

  /**
   * Claim a energyConsult
   */
  public async claimEnergyConsult() {
    if (this.energyConsult) {
      this.application.setLoading(true);
      const coach = <Coach>this.application.session.user;
      this.energyConsult.state.name = RequestStates.PICKED;

      const res = await this.coachEnergyConsultService.AcceptEnergyConsult(this.energyConsult);
      if (!res.errors) {
        this.energyConsult.coach = coach;
      }
      await this.initEnergyConsult(this.energyConsult.id);
      this.application.setLoading(false);
    }
  }

  /**
   * Recovers a energyConsult
   */
  public async recoverEnergyConsult() {
    if (this.energyConsult) {
      this.application.setLoading(true);
      this.energyConsult.coach = undefined;
      await this.setEnergyConsultState(RequestStates.NEW, "");
      this.application.setLoading(false);
    }
  }

  public async finishEnergyConsult() {
    if (this.energyConsult) {
      this.application.setLoading(true);
      await this.setEnergyConsultState(RequestStates.DONE, "");
      this.application.setLoading(false);
    }
  }

  /**
   * Deletes a energyConsult
   */
  public async deleteEnergyConsult() {
    this.application.setLoading(true);
    await this.setEnergyConsultState(RequestStates.PENDING_DELETED, "");
    this.application.setLoading(false);
  }

  /**
   * Sets the state of the energyConsult
   * @param state The state of the energyConsult
   * @param key The property of the energyConsult to update
   */
  private async setEnergyConsultState(state: RequestStates, key: string) {
    if (this.energyConsult) {
      this.application.setLoading(true);
      this.energyConsult.state.name = state;
      await this.energyConsultService.save(this.energyConsult, key);
      await this.initEnergyConsult(this.energyConsult.id);
      this.application.setLoading(false);
    }
  }

  /**
   * Saves the reject reason for the energyConsult
   */
  public async rejectEnergyConsult() {
    if (this.energyConsult && this.rejectReasonControl.valid) {
      this.application.setLoading(true);
      this.energyConsult.state.name = RequestStates.PENDING_REJECT;
      this.energyConsult.rejectReason = this.rejectReasonControl.value!;
      this.dialog.close();
      await this.coachEnergyConsultService.rejectEnergyConsult(this.energyConsult);
      await this.initEnergyConsult(this.energyConsult.id);
      this.application.setLoading(false);
    }
  }

  /**
   * Checks if it has an alert when the energyConsult is canceled, pending rejected or disapproved.
   */
  public get hasAlert(): boolean {
    return !!this.energyConsult && [RequestStates.PENDING_REJECT, RequestStates.CANCELED, RequestStates.DISAPPROVED].includes(this.energyConsult.state.name);
  }

  /**
   * Get the data from the energyConsult
   * Parse the date to locale
   */
  private async initEnergyConsult(id: number) {
    this.application.setLoading(true);
    const energyConsult = await this.energyConsultService.loadById(id);

    if (!energyConsult) {
      this.snackbarService.error();
      this.router.navigate([this.application.session.activeRole.name === "resident" ? "/" : "/content"]);
      return;
    }

    if (!energyConsult.isActive && this.application.session.activeRole.name !== "coordinator" && !ENVIRONMENT.MODULES.includes("SHOW_CONTACT_INFO_ON_NEW_REQUESTS")) {
      energyConsult.resident = undefined;
    }

    this.energyConsult = energyConsult;
    this.dashboardDataService.updateSingleEnergyConsult(energyConsult);

    if (this.energyConsult?.extraProperties?.note) {
      this.noteControl.setValue(this.energyConsult?.extraProperties?.note);
    }
    this.showActions = this.checkShowActions();
    this.application.setLoading(false);
  }

  /**
   * Gets the selected date from the date form
   * @returns The selected date
   */
  private getGivenDate(): Date {
    const date: Date = this.formGroup.value.date!;
    date.setHours(+this.formGroup.value.time!.substr(0, 2), +this.formGroup.value.time!.substr(3, 2));
    return date;
  }

  /**
   * Gets all coaches for the change coach autofill field
   * @returns Array with coaches
   */
  public async getCoaches() {
    this.application.setLoading(true);
    const res = await this.coachService.getAllCoaches();
    this.coaches = res.filter((coach) => {
      if (coach.specialties.map((specialty) => specialty.id).includes(this.energyConsult!.specialty.id)) return true;
      return false;
    });
    this.application.setLoading(false);
    return this.coaches;
  }

  /**
   * Assign a coach to a energyConsult (being a coordinator)
   * @param Coach the new coach
   */
  public async assignCoach(coach: Coach) {
    this.application.setLoading(true);
    if (this.energyConsult) {
      await this.coachEnergyConsultService.AcceptEnergyConsult(this.energyConsult, coach);
      this.energyConsult.coach = coach;
      this.initEnergyConsult(this.energyConsult.id);
      this.dialog.close();
    }
    this.application.setLoading(false);
  }

  /**
   * First call gets all coaches and opens dialog, other calls just open dialog
   * @param TemplateRef the dialog template name
   */
  public async changeCoachClickHandler(template: TemplateRef<any>) {
    this.changeCoachControl.patchValue(this.energyConsult?.coach);
    if (!this.coaches) {
      await this.getCoaches().then(() => {
        this.dialog.show(template);
      });
    } else {
      this.dialog.show(template);
    }
  }

  async changeNote(button: MatButton) {
    button.disabled = true;
    if (this.noteControl.getRawValue() == "") {
      this.deleteNote();
      return;
    } else {
      const jsonOBJ = { note: `${this.noteControl.getRawValue()}` };
      const jsonString = this.graphlService.hasuraArrayObjectCleaner(JSON.stringify(jsonOBJ));
      try {
        await this.energyConsultService.changeJson(this.energyConsult!, jsonString!);
        this.snackbarService.open(this.translateService.instant("COMPONENTS.COACH_REQUEST.DIALOGS.NOTE.SUCCESS_MESSAGE"), "", 5000);
      } catch {
        this.snackbarService.error();
      } finally {
        button.disabled = false;
      }
    }
  }
  /**
   * Opens a dialog if the pdf is successfull generated
   */
  private openNoteSuccessDialog() {
    this.dialog.open({
      template: this.savedNoteSuccess,
    });
  }
  deleteNote() {
    const jsonOBJ = { note: "" };
    const jsonString = this.graphlService.hasuraArrayObjectCleaner(JSON.stringify(jsonOBJ));
    this.energyConsultService.changeJson(this.energyConsult!, jsonString!);
  }

  /**
   * Concat first and last name
   * @returns String with fullname
   */
  public displayCoachName(coach: Coach) {
    if (coach) return coach.firstName + " " + coach.lastName;
    return "";
  }

  /**
   * sends the report as pdf to the coach for review
   */
  public async downloadReportAsPDF() {
    if (this.energyConsult) {
      try {
        this.application.setLoading(true);
        const pdf = await this.reportService.getPdfFile(this.energyConsult.id);
        downloadFile(pdf, this.translateService.instant("COMPONENTS.COACH_REQUEST.REPORT"), "application/pdf");
      } catch (error) {
        this.snackbarService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"), "", 5000);
      }
      this.application.setLoading(false);
    }
  }

  /**
   *
   * @param number
   * @returns
   */
  public formatSize(number: number) {
    return Intl.NumberFormat("en", {
      notation: "compact",
      style: "unit",
      unit: "byte",
      unitDisplay: "narrow",
    }).format(number);
  }

  /**
   * Open an edit energyConsult Dialog
   * @param energyConsultId The id of the energyConsult
   */
  public editRequestDialog(): void {
    if (this.energyConsult === undefined) return;
    this.editFormGroup.controls.firstName.setValue(this.energyConsult.resident?.firstName ?? null);
    this.editFormGroup.controls.lastName.setValue(this.energyConsult.resident?.lastName ?? null);
    this.editFormGroup.controls.email.setValue(this.energyConsult.resident?.email ?? null);
    this.editFormGroup.controls.usePhoneNumber.setValue(this.energyConsult.resident?.phoneNumber === null || this.energyConsult.resident?.phoneNumber === "" ? false : true);
    this.editFormGroup.controls.phoneNumber.setValue(this.energyConsult.resident?.phoneNumber ?? null);
    this.editFormGroup.controls.usePhoneNumber.value ? this.editFormGroup.controls.phoneNumber.enable() : this.editFormGroup.controls.phoneNumber.disable();
    this.editFormGroup.controls.address.controls.postalCode.setValue(this.energyConsult.postalCode ?? null);
    this.editFormGroup.controls.address.controls.houseNumber.setValue(this.energyConsult.houseNumber ?? null);
    this.editFormGroup.controls.address.controls.houseNumberSuffix.setValue(this.energyConsult.houseNumberSuffix ?? null);
    this.editFormGroup.controls.streetName.setValue(this.energyConsult.extraProperties?.streetname ?? null);
    this.editFormGroup.controls.town.setValue(this.energyConsult.extraProperties?.addressdetails?.town ?? null);

    if (this.editFormPNUseSub === undefined) {
      this.editFormPNUseSub = this.editFormGroup.controls.usePhoneNumber.valueChanges.subscribe((value) => {
        value ? this.editFormGroup.controls.phoneNumber.enable() : this.editFormGroup.controls.phoneNumber.disable();
      });
    }

    if (this.filterPostalCodeInput === undefined) {
      this.filterPostalCodeInput = this.editFormGroup.controls.address.controls.postalCode.valueChanges.pipe(startWith(""), pairwise()).subscribe(([prevValue, newValue]) => {
        if (prevValue === newValue) return;
        this.editFormGroup.controls.address.controls.postalCode.setValue(
          /^(?:[0-9]|$){1,4}(?:[a-z]|$){0,2}$/i.test(newValue ?? "") ? (newValue ?? "").toUpperCase() : (prevValue ?? "").toUpperCase()
        );
      });
    }

    if (this.addressAutoFillSubscription === undefined) {
      this.addressAutoFillSubscription = this.editFormGroup.controls.address.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => {
        this.autoFillAddress();
      });
    }

    this.dialog.open({
      template: this.editDialog,
      data: {
        EC: this.energyConsult,
      },
    });
  }

  private async autoFillAddress() {
    const addressCtrls = this.editFormGroup.controls.address.controls;
    const streetNameCtrl = this.editFormGroup.controls.streetName;
    const townCtrl = this.editFormGroup.controls.town;

    if (!addressCtrls.postalCode.valid || !addressCtrls.houseNumber.valid || !addressCtrls.houseNumberSuffix.valid) return;
    const postalCode = addressCtrls.postalCode.value;
    const houseNumber = addressCtrls.houseNumber.value;
    const houseNumberSuffix = addressCtrls.houseNumberSuffix.value;

    if (!postalCode || !houseNumber) {
      return;
    }

    const address = await this.addressService.lookup(postalCode, houseNumber.toString(), houseNumberSuffix);
    streetNameCtrl.patchValue(null, { emitEvent: false });
    townCtrl.patchValue(null, { emitEvent: false });

    streetNameCtrl.enable({ emitEvent: false });
    townCtrl.enable({ emitEvent: false });

    if (address) {
      streetNameCtrl.patchValue(address.streetName, { emitEvent: false });
      townCtrl.patchValue(address.town, { emitEvent: false });
    } else {
      streetNameCtrl.setErrors({ streetNameNotFound: true }, { emitEvent: false });
      townCtrl.setErrors({ townNotFound: true }, { emitEvent: false });
    }

    streetNameCtrl.markAsTouched();
    townCtrl.markAsTouched();
  }

  public async saveEditedRequest() {
    if (this.energyConsult === undefined) return;
    const newgroup = Object.entries(this.editFormGroup.controls).reduce((obj, [key, value]) => {
      if (key === "usePhoneNumber" || (key === "phoneNumber" && value.disabled)) return obj;
      return {
        [key]: value.value,
        ...obj,
      };
    }, {});

    try {
      await this.energyConsultService.saveAfterEdit({ ...newgroup, changes: this.energyConsult.changes, id: this.energyConsult.id });
    } catch {
      this.snackbarService.error();
    }

    this.initEnergyConsult(this.energyConsult.id);
  }

  public canEditConsult(): boolean {
    if (
      (this.application.session.activeRole.name === "coach" &&
        (this.energyConsult?.state.name === RequestStates.PICKED || this.energyConsult?.state.name === RequestStates.DATE)) ||
      (this.application.session.activeRole.name === "coordinator" &&
        (this.energyConsult?.state.name === RequestStates.NEW ||
          this.energyConsult?.state.name === RequestStates.PICKED ||
          this.energyConsult?.state.name === RequestStates.DATE))
    )
      return true;
    return false;
  }

  public checkShowActions(): boolean {
    if (
      this.application.session.user?.id !== undefined &&
      this.application.session.activeRole.name === "coach" &&
      !this.energyConsult?.isActiveCoach(this.application.session.user.id) &&
      this.energyConsult?.state.name !== RequestStates.NEW
    )
      return false;
    return true;
  }

  public async ckdEnergyConsult() {
    if (!this.energyConsult?.id) return;
    this.application.setLoading(true);
    try {
      const res = await this.energyConsultService.ckdEC(this.energyConsult.id);
      if (!res.data.ckd.value.requestIDNew) {
        this.snackbarService.error();
      } else {
        this.dialog.open({ template: this.ckdSuccesDialog, data: { newID: res.data.ckd.value.requestIDNew } });
        await this.initEnergyConsult(this.energyConsult.id);
      }
    } catch (error) {
      this.snackbarService.error();
    }
    this.application.setLoading(false);
  }
}
