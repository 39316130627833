import { AbstractControl, ValidationErrors } from "@angular/forms";

export const questionOperatorValidator = (pfx: string, type: string) => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (pfx === "txt") {
      if(!["integer", "decimal"].includes(type)) {
        const operator = control.value;
        return /^(===$|!=$)/.test(operator) ? null : {operator};
      }
    }
    return null;
  };
};