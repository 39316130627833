import { AbstractControl, ValidationErrors } from "@angular/forms";

/**
 * checks if string contains a backslash
 */
export const specialCharValidator = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (!value) return null;
  if (typeof value !== "string") return null;

  const illegalChars: string[] = ["\\"];
  let valid = true;
  for (const char of illegalChars) {
    if (value.includes(char)) {
      valid = false;
      break;
    }
  }

  return !valid ? { specialChars: true } : null;
};
