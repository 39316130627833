import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.less"],
})
export class FAQComponent implements OnInit {
  version!: string;
  constructor(public http: HttpClient) {}

  //defines the version in json and splits it up in arrays
  async ngOnInit() {
    try {
      this.version = (await firstValueFrom(this.http.get("assets/version.txt", { responseType: "text" as "json" }))) as string;
      this.version = this.version[0].slice(0, 8);
    } catch (e) {
      this.version = "DEV_LOCAL";
    }
  }
}
