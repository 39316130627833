import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { alphaValidator } from "../../../../validators/alpha";
import { CoachService } from "../../../../services/coach.service";
import { Coach } from "../../../../classes/flow/session/impl/Coach";
import { MessageService } from "../../../../services/message.service";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../../services/application.service";

@Component({
  selector: "app-new-coach",
  templateUrl: "./new-coach.component.html",
  styleUrls: ["./new-coach.component.less"],
})
export class NewCoachComponent {
  public formGroup: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    firstName: new FormControl("", [Validators.required, alphaValidator]),
    lastName: new FormControl("", [Validators.required, alphaValidator]),
  });

  constructor(
    private readonly coachService: CoachService,
    private readonly messageService: MessageService,
    private readonly applicationService: ApplicationService,
    private readonly router: Router
  ) {}

  /**
   * Saves the data of a new coach
   */
  public async saveNewCoach() {
    this.applicationService.setLoading(true);
    const result = await this.coachService.addCoach(
      new Coach({
        firstName: this.formGroup.value.firstName,
        lastName: this.formGroup.value.lastName,
        userEmail: this.formGroup.value.email,
      })
    );
    if (result) this.messageService.handle(result.messages, "/content/coordinator/users/all");
    this.applicationService.setLoading(false);
    this.router.navigate(["/content/coordinator/users/all"]);
  }
}
