import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
@Component({
  selector: "app-time-range-picker",
  templateUrl: "./time-range-picker.component.html",
  styleUrls: ["./time-range-picker.component.less"],
})
export class TimeRangePickerComponent implements OnInit, OnDestroy {
  @Input() control!: FormControl;
  @Input() savedTime?: string | undefined;
  @Input() questionId!: string;
  @Output() eventEmitter: EventEmitter<string>;
  public startTime: string = new Date(0, 0, 0, 10, 0, 0).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  public endTime: string = new Date(0, 0, 0, 11, 0, 0).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  public showWarningText = false;

  @ViewChild("startTimePicker") startTimePicker: ElementRef | null = null;
  @ViewChild("endTimePicker") endTimePicker: ElementRef | null = null;

  public timeGroup = new FormGroup({
    timeStart: new FormControl(this.startTime, [Validators.required]),
    timeEnd: new FormControl(this.endTime, [Validators.required]),
  });

  sub?: Subscription;

  constructor() {
    this.eventEmitter = new EventEmitter<string>();
  }
  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnInit() {
    /** a time value can be given so we format that into the date type */
    if (this.savedTime) {
      if (this.savedTime !== "") {
        const timeParts = this.savedTime.split("-");
        const startParts = timeParts[0].split(":");
        const endParts = timeParts[1].split(":");
        //check for 1:0 to convert to 01:00
        this.startTime = startParts[0].padStart(2, "0") + ":" + startParts[1].padStart(2, "0");
        this.endTime = endParts[0].padStart(2, "0") + ":" + endParts[1].padStart(2, "0");
        this.timeGroup.controls["timeStart"].setValue(this.startTime);
        this.timeGroup.controls["timeEnd"].setValue(this.endTime);
      }
    }
    this.sub = this.timeGroup.valueChanges.subscribe((values) => {
      this.changedInput(values.timeStart!, values.timeEnd!);
    });
  }

  /**
   * converts two strings to date objects to compare value
   * if it matches with the output string format hh:mm-hh:mm
   * then it emits an event with that value
   * @param startTime time input string hh:mm
   * @param endTime time input string hh:mm
   */

  changedInput(startTime: string, endTime: string) {
    if ((!startTime && !endTime) || startTime === endTime) {
      this.showWarningText = false;
      return;
    }
    this.startTime = startTime;
    this.endTime = endTime;
    if (startTime < endTime) {
      this.showWarningText = false;
    }
    if (startTime > endTime && endTime) {
      this.showWarningText = true;
      this.endTime = this.startTime;
      this.timeGroup.controls["timeEnd"].setValue(this.startTime);
    }

    const timeRange: string = this.startTime + "-" + this.endTime;
    if (/^(\d{1,2}):(\d{1,2})-(\d{1,2}):(\d{1,2})$/.test(timeRange)) {
      this.control.patchValue(timeRange);
      this.eventEmitter.emit(timeRange);
    }
  }
}
