<div class="container">
  <div class="row">
    <div class="col p-3">
      <p>{{ "FORMS.REPLACEMENT_CLAIM_DELIVER_FORM." + claimType | translate | titlecase }} info:</p>
      <ul>
        <li *ngIf="claim.claimStateId === 5 && claim['plannedTime']">
          {{ "TABLES.REPLACEMENT_OVERVIEW.DELIVERY_SCHEDULE" | translate }}: {{ claim["plannedTime"] | localDate : "d MMMM y, HH:mm" }}
        </li>
        <li *ngIf="claim['typeName']">Type: {{ claim["typeName"] }}</li>
        <li *ngIf="claim['categoryName']">Model: {{ claim["categoryName"] }}</li>
        <li *ngIf="claim['postalCode']">{{ "TABLES.REPLACEMENT_OVERVIEW.POSTALCODE" | translate }}: {{ claim["postalCode"] }}</li>
      </ul>

      <form [formGroup]="rejectForm" (ngSubmit)="reject()">
        <p>
          <mat-form-field>
            <mat-label>{{ "TABLES.PAYOUTOVERVIEW.DECLINE_REASON" | translate }} {{ "FORMS.REPLACEMENT_CLAIM_DELIVER_FORM." + claimType | translate }} </mat-label>
            <textarea matInput formControlName="message"></textarea>
            <app-error [control]="rejectForm.controls.message"></app-error>
          </mat-form-field>
        </p>
        <p>
          <mat-checkbox formControlName="confirm"
            >{{ "TABLES.PAYOUTOVERVIEW.CONFIRM_ONE" | translate }} {{ "FORMS.REPLACEMENT_CLAIM_DELIVER_FORM." + claimType | translate }}
            {{ "TABLES.PAYOUTOVERVIEW.CONFIRM_TWO" | translate }}</mat-checkbox
          >
        </p>
        <p>
          <button mat-raised-button type="submit" [disabled]="rejectForm.invalid">{{ "TABLES.REPLACEMENT_OVERVIEW.REJECT" | translate }}</button>
        </p>
      </form>
    </div>
  </div>
</div>
