import { AbstractControl, ValidationErrors } from "@angular/forms";

// index 0 -> multiply by 9, index 8 -> multiply by -1 etc.
const indexMultipliers = [9, 8, 7, 6, 5, 4, 3, 2, -1];

/**
 * Validate a dutch BSN number
 * @param control BSN control
 * @returns Errors or null
 * @author Jaimy
 */
export const bsnValidator = (control: AbstractControl): ValidationErrors | null => {
  // Check if only numbers & length 9
  if (!/^[0-9]{9}$/.test(control.value)) return { invalidBSN: true };

  // Split bsn and cast to digits
  const numbers: number[] = control.value.split("").map((v: string) => Number(v));

  // Multiply by right multiplier per index
  const multiplied = numbers.map((number, index) => number * indexMultipliers[index]);

  // Sum them up
  const result = multiplied.reduce((sum, a) => sum + a, 0);

  // If sum of array is divisible by 11, BSN is valid.
  return (result % 11 !== 0) ? { invalidBSN: true } : null;
};
