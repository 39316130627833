import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

/**
 * validates if a date is after today
 */
export const dateAfterToday = (): ValidatorFn => {
  return (inputDate: AbstractControl): ValidationErrors | null => {
    if (new Date() >= inputDate.value) {
      return { dateAfterToday: true };
    }
    return null;
  };
};
