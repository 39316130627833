import { ValidatorFn, AbstractControl, ValidationErrors, FormGroup } from "@angular/forms";

/**
 * validates if input time is after time from other input field
 * @param compareControlName formcontrol name of other input to compare
 */
export const timeAfterOther = (compareControlName: string): ValidatorFn => {
  return (endTimeInput: AbstractControl): ValidationErrors | null => {
    try {
      const formgroup: FormGroup = endTimeInput.parent as FormGroup;
      const a = formgroup.controls[compareControlName].value;
      const b = endTimeInput.value;
      if (a >= b) {
        return { timeAfterOther: true };
      }
    } catch {
      return null;
    }
    return null;
  };
};
