import { AfterViewInit, Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-timepicker-dialog",
  templateUrl: "./timepicker-dialog.component.html",
  styleUrls: ["./timepicker-dialog.component.less"],
})
export class TimepickerDialogComponent implements AfterViewInit {
  @ViewChild("container") container: ElementRef | undefined;
  @ViewChild("timeSelected") timeSelectedSection: ElementRef | undefined;
  @HostListener("window:keydown", ["$event"]) keyDownHandler(event: KeyboardEvent) {
    switch (event.key) {
      case "ArrowDown":
        this.selectedTimeMutator(false);
        break;
      case "ArrowUp":
        this.selectedTimeMutator(true);
        break;
      case "Enter":
        this.okButtonHandler();
        break;
      default:
        break;
    }
  }
  @Input("timeFormGroup") givenFormGroup!: FormGroup;
  @Input("givenFormControlName") givenFormControlName!: string;
  clockButtonSizes = { width: 36, height: 36 };
  protected pickerStage = "hours";
  protected selectedHour: number;
  protected selectedMinute: number;
  protected hours = Array.from({ length: 24 }, (_, i) => i);
  protected minutes = Array.from({ length: 60 }, (_, i) => i);
  private hourAngles: { angle: number }[] = [];
  protected hourPositions: { x: number; y: number }[] = [];
  private minuteAngles: { angle: number }[] = [];
  protected minutePositions: { x: number; y: number }[] = [];
  private cursorAngleFromMiddle = 0;
  private cursorHeld = false;
  protected containerMiddle: { x: number; y: number } | undefined;
  protected containerSize: { x: number; y: number } | undefined;
  constructor(public dialogRef: MatDialogRef<TimepickerDialogComponent>) {
    this.selectedHour = 0;
    this.selectedMinute = 0;
  }
  public ngAfterViewInit(): void {
    this.containerMiddle = this.getClockMiddle();
    this.calculatePositions();

    if (this.givenFormGroup.get(this.givenFormControlName)?.value != null) {
      const existing = this.givenFormGroup.get(this.givenFormControlName)?.value.split(":");
      if (existing[0] || existing[1]) {
        this.selectedHour = existing[0] ? existing[0] : 12;
        this.selectedMinute = existing[1];
      } else {
        this.selectedHour = 12;
      }
    }
  }
  public getFormattedNumber(number: number): string {
    return number.toString().padStart(2, "0");
  }
  private selectedTimeMutator(addition = true) {
    if (this.pickerStage == "hours") {
      if (addition) {
        this.selectedHour++;
      } else {
        this.selectedHour--;
      }
      switch (this.selectedHour) {
        case 24:
          this.selectedHour = 0;
          break;
        case -1:
          this.selectedHour = 23;
          break;
        default:
          break;
      }
    } else {
      if (addition) {
        this.selectedMinute++;
      } else {
        this.selectedMinute--;
      }
      switch (this.selectedMinute) {
        case 60:
          this.selectedMinute = 0;
          break;
        case -1:
          this.selectedMinute = 59;
          break;
        default:
          break;
      }
    }
  }

  public getClockMiddle(): { x: number; y: number } {
    const center = this.getContainerSize().width / 2;
    return { x: center, y: center };
  }
  // setting correct time value for held cursor
  public calculateCursorAngle(event: MouseEvent, cursorHeld = this.cursorHeld) {
    if (cursorHeld != this.cursorHeld) {
      this.cursorHeld = cursorHeld;
    }
    if (cursorHeld) {
      const center = this.containerMiddle;
      const containerRect = this.container!.nativeElement.getBoundingClientRect();
      const xFromCenter = event.clientX - containerRect.left - center!.x;
      const yFromCenter = event.clientY - containerRect.top - center!.y;
      const angleRadians = Math.atan2(yFromCenter, xFromCenter);

      const angleDegrees = angleRadians * (180 / Math.PI);
      const cursorDistanceFromMiddle = this.calculateDistanceBetweenCoordinates(center!, { x: xFromCenter + center!.x, y: yFromCenter + center!.y });
      this.cursorAngleFromMiddle = angleDegrees;
      const normalizedCursorAngle = (this.cursorAngleFromMiddle + 360) % 360;
      if (this.pickerStage == "hours") {
        const containersize = this.getContainerSize();
        const radiusOffset = containersize.width / 9;
        const radius = Math.min(containersize.width, containersize.height) / 2;
        const innerRadius = radius - radiusOffset;
        const toAddHours = cursorDistanceFromMiddle > innerRadius ? 0 : 12;
        const closestHour = this.findClosestAngle(normalizedCursorAngle, this.hourAngles);
        if (closestHour.index > 11) {
          closestHour.index = closestHour.index - 12;
        }
        this.selectedHour = closestHour.index + toAddHours;
      } else {
        const closestMinute = this.findClosestAngle(normalizedCursorAngle, this.minuteAngles);
        this.selectedMinute = closestMinute.index;
      }
    }
    const test = this.cursorAngleFromMiddle + 1;
    this.cursorAngleFromMiddle = test;
  }
  private calculateDistanceBetweenCoordinates(middle: { x: number; y: number }, cursor: { x: number; y: number }): number {
    return Math.sqrt(Math.pow(cursor.x - middle.x, 2) + Math.pow(cursor.y - middle.y, 2) * 1.0);
  }
  // logic for determening what mouse cursor is closest to
  private findClosestAngle(targetAngle: number, anglesArray: { angle: number }[]): { angle: number; index: number } {
    let closestIndex = 0;
    let closestAngle = anglesArray[0];
    let smallestDifference = Math.abs(targetAngle - closestAngle.angle);

    anglesArray.forEach((angleObj, index) => {
      const currentDifference = Math.abs(targetAngle - angleObj.angle);
      if (currentDifference < smallestDifference) {
        smallestDifference = currentDifference;
        closestAngle = angleObj;
        closestIndex = index;
      }
    });

    return { angle: closestAngle.angle, index: closestIndex };
  }

  private getContainerSize(): { width: number; height: number } {
    //sizes of the container
    const containerWidth: number = this.container!.nativeElement.offsetWidth as number;
    const containerHeight: number = containerWidth; // setting it as square seeing as section should be a square
    const containersize = { width: containerWidth, height: containerHeight };
    return containersize;
  }

  private calculatePositions(): void {
    const { width: buttonWidth, height: buttonHeight } = this.clockButtonSizes;
    const containerSize = this.getContainerSize();
    const topOffset = 106 + buttonHeight / 4; //magic number, should be remade responsive
    const radiusOffset = containerSize.width / 8; //magic number, should be remade responsive
    const radius = Math.min(containerSize.width, containerSize.height) / 2;

    const calculateAngle = (divisions: number, index: number): number => {
      return ((2 * Math.PI) / divisions) * index - Math.PI / 2;
    };

    const calculatePosition = (isOuterCircle: boolean, divisions: number, index: number, sizeOffset: number): { x: number; y: number } => {
      const angle = calculateAngle(divisions, index);
      const currentRadius = radius - (isOuterCircle ? sizeOffset / 2 : sizeOffset / 2 + radiusOffset);
      const x = containerSize.width / 2 + currentRadius * Math.cos(angle) - sizeOffset / 2;
      const y = containerSize.height / 2 + currentRadius * Math.sin(angle) - sizeOffset / 2 + topOffset;

      return { x, y };
    };

    this.hourPositions = this.hours.map((_, index) => {
      const isOuterCircle = index < 12;
      const divisions = isOuterCircle ? 12 : this.hours.length - 12;
      const angle = calculateAngle(divisions, index);
      const position = calculatePosition(isOuterCircle, divisions, index, buttonWidth);
      const angleInDegrees = (angle * (180 / Math.PI) + 360) % 360;
      this.hourAngles.push({ angle: angleInDegrees });
      return position;
    });

    this.minutePositions = this.minutes.map((_, index) => {
      const isOuterCircle = index % 5 !== 0;
      const angle = calculateAngle(60, index);
      const position = calculatePosition(isOuterCircle, 60, index, buttonHeight);
      const angleInDegrees = (angle * (180 / Math.PI) + 360) % 360;
      this.minuteAngles.push({ angle: angleInDegrees });
      return position;
    });
  }

  public selectHour(hour: number) {
    this.selectedHour = hour;
    this.pickerStage = "minutes";
  }
  public selectMinutes(minute: number) {
    this.pickerStage = "minutes";
    this.selectedMinute = minute;
  }

  public okButtonHandler() {
    //sets values that are currently selected and closes out when it on minute stage
    if (this.pickerStage === "hours") {
      this.selectHour(this.selectedHour);
    } else {
      this.selectMinutes(this.selectedMinute);
      this.closeDialog();
    }
  }

  public closeDialog() {
    this.givenFormGroup.get(this.givenFormControlName)?.patchValue(`${this.selectedHour.toString().padStart(2, "0")}:${this.selectedMinute.toString().padStart(2, "0")}`);
    this.dialogRef.close();
  }

  public validateHour() {
    if (this.selectedHour > 23) {
      this.selectedHour = 0;
    } else if (this.selectedHour < 0) {
      this.selectedHour = 23;
    }
  }

  public validateMinute() {
    if (this.selectedMinute > 59) {
      this.selectedMinute = 0;
    } else if (this.selectedMinute < 0) {
      this.selectedMinute = 59;
    }
  }
  public preventArrowKeyChange(event: KeyboardEvent) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  }

  public onBeforeInput(event: InputEvent): void {
    const invalidCharacters = ["e", "-", "+", ".", ","];
    const characters = event.data?.split("") ?? [];
    for (const character of characters) {
      if (invalidCharacters.includes(character)) {
        event.preventDefault();
        return;
      }
    }
  }
}
