import { Component, inject, OnInit } from "@angular/core";
import { ApplicationService } from "../../../../services/application.service";
import { AreaActionService } from "../../../../services/area-action.service";
import { Statistic } from "../../../../classes/flow/Questionnaire/Statistic";
import { StatisticService } from "../../../../services/statistic.service";
import { AreaAction } from "../../../../classes/flow/AreaActions/AreaAction";
import { Coordinator } from "../../../../classes/flow/session/impl/Coordinator";
import { downloadCSVFile } from "../../../../helpers/downloadCSV";
import { CurrencyPipe } from "@angular/common";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Exporter } from "../../../../helpers/exporter";

@Component({
  selector: "app-dashboard-statistics",
  templateUrl: "./dashboard-statistics.component.html",
  styleUrls: ["./dashboard-statistics.component.less"],
})
export class DashboardStatisticsComponent implements OnInit {
  private applicationService: ApplicationService;
  private statisticService: StatisticService;
  private areaActionService: AreaActionService;
  private currencyPipe: CurrencyPipe;

  public municipalities: string[];

  public tabs: {
    time: {
      statisticsYear: (Statistic | null)[];
      total: Statistic | null;
    };
    action: {
      statistics: (Statistic | null)[];
    };
  };

  public cachedAreaActions: AreaAction[];

  private earliestYear = 2023;
  private aaLoaded = false;

  public constructor() {
    this.applicationService = inject(ApplicationService);
    this.statisticService = inject(StatisticService);
    this.areaActionService = inject(AreaActionService);
    this.currencyPipe = new CurrencyPipe("nl");

    this.municipalities = this.applicationService.session.user instanceof Coordinator ? this.applicationService.session.user.municipalities : [];

    this.tabs = {
      time: {
        statisticsYear: [],
        total: null,
      },
      action: {
        statistics: [],
      },
    };

    this.cachedAreaActions = [];
  }

  public async ngOnInit(): Promise<void> {
    await this.loadYears();

    this.cachedAreaActions = await this.areaActionService.all(false, true);
    this.cachedAreaActions.forEach((val) => this.tabs.action.statistics.push(null));
  }

  public async loadYears(): Promise<void> {
    const currentYear = new Date().getFullYear() + 1;
    const toFetchYears = [];
    for (let year = this.earliestYear; year <= currentYear; year++) {
      toFetchYears.unshift(year);
    }

    toFetchYears.forEach((val) => this.tabs.time.statisticsYear.push(null));

    this.tabs.time.total = await this.statisticService.getStatistic();

    for (const [index, year] of toFetchYears.entries()) {
      this.tabs.time.statisticsYear[index] = await this.statisticService.getStatistic({
        startDate: new Date(new Date(new Date().setFullYear(year - 1, 0, 1)).setHours(4, 0, 0, 0)),
        endDate: new Date(new Date(new Date().setFullYear(year, 0, 1)).setHours(4, 0, 0, 0)),
      });
    }
  }

  public async loadAreaActions(): Promise<void> {
    this.aaLoaded = true;
    for (const [index, aa] of this.cachedAreaActions.entries()) {
      this.tabs.action.statistics[index] = await this.statisticService.getStatistic(
        {
          areaActionID: aa.id,
        },
        aa
      );
    }
  }

  public async handleTabChange(event: MatTabChangeEvent): Promise<void> {
    switch (event.index) {
      case 0:
        break;
      case 1:
        if (this.cachedAreaActions.length !== this.tabs.action.statistics.filter((stat) => stat).length || !this.aaLoaded) await this.loadAreaActions();
        break;

      default:
        break;
    }
  }

  public export(type: "csv" | "xls" = "csv"): void {
    const headers = ["periode", "type", "categorie", "aantal", "eenheid", "kosten totaal", "kosten gemiddeld", "gemiddelde doorloop tijd in dagen"];
    const values: unknown[][] = [];
    values.push(headers);

    /*
	  set total
	*/
    const total = this.tabs.time.total!;

    values.push([
      "Totaal",
      "Totaal",
      "Totaal",
      total.count,
      "aanvragen",
      this.transformCurrency(total.totalPrice),
      this.transformCurrency(total.averagePrice),
      total.averageTimeInDays,
    ]);

    for (const category of total.formatedReportCategories.filter((cat) => cat.type === "select")) {
      for (const type of category.selectValues ?? []) {
        values.push(["Totaal", category.name, type.value, type.answeredCount, "keer beantwoord", this.transformCurrency(type.cost)]);
      }

      for (const category of total.formatedReportCategories.filter((cat) => cat.type === "cost")) {
        for (const type of category.costValues ?? []) {
          values.push(["Totaal", "product", category.name, type.count, "producten", this.transformCurrency(type.totalPrice)]);
        }
      }
    }

    /*
	  set per year
	*/
    for (const statistic of this.tabs.time.statisticsYear) {
      if (!statistic) break;
      values.push([
        statistic.selection.timeRange?.start?.getFullYear(),
        "Totaal",
        "Totaal",
        statistic.count,
        "aanvragen",
        this.transformCurrency(statistic.totalPrice),
        this.transformCurrency(statistic.averagePrice),
        statistic.averageTimeInDays,
      ]);

      for (const category of statistic.formatedReportCategories.filter((cat) => cat.type === "select")) {
        for (const type of category.selectValues ?? []) {
          values.push([
            statistic.selection.timeRange?.start?.getFullYear(),
            category.name,
            type.value,
            type.answeredCount,
            "keer beantwoord",
            this.transformCurrency(type.cost),
          ]);
        }
      }

      for (const category of statistic.formatedReportCategories.filter((cat) => cat.type === "cost")) {
        for (const type of category.costValues ?? []) {
          values.push([statistic.selection.timeRange?.start?.getFullYear(), "product", category.name, type.count, "producten", this.transformCurrency(type.totalPrice)]);
        }
      }
    }

    const exporter = new Exporter();

    const name = "Export" + new Date().toLocaleDateString();

    switch (type) {
      case "csv": {
        const val = exporter.toCSV(values);

        downloadCSVFile(val, name);

        break;
      }
      case "xls":
        {
          const val = exporter.toXLS(values);

          const utf8Encode = new TextEncoder();
          const test = utf8Encode.encode(val);
          const blob = new Blob([test], { type: "application/vnd.ms-excel;charset=utf-8;" });

          const a = document.createElement("a");
          const url = window.URL.createObjectURL(blob as any);
          a.href = url;
          a.download = name + ".xls";
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        }

        break;
      default:
        console.error("invalid export type");
        break;
    }
  }

  private transformCurrency(value: number): string | null {
    return this.currencyPipe.transform(value, "EUR", "symbol", ".2-2", "nl") ?? null;
  }

  public async exportXLS(): Promise<void> {
    const htmlString = `<html>
	<head>
		<meta charset="UTF-8" />
		<style>
			.colored-tile {
				background-color: #ccffff;
				width: fit-content;
				height: 125px;
				text-align: left;
				vertical-align: top;
			}
			.value-tile {
				background-color: white;
				width: fit-content;
				text-align: left;
				vertical-align: top;
			}
			.indicator {
				vertical-align: bottom;
				text-align: right;
        				position: absolute;
			}
		</style>
	</head>
	<body>
		<table style="width: 100%">
			<thead></thead>
			<tbody>
				<tr>
					<td class="colored-tile">
						<p> <b> Aanpak energiearmoede </b> </p>
					</td>
					<td class="colored-tile">
						<span>
							<p>
								Het aantal huishoudens in huurwoningen aan wie energiebesparende
								voorzieningen zijn verstrekt die kunnen leiden tot vermindering
								van energiegebruik/de energierekening
							</p>
							<p class="indicator"> C55/01 </p>
						</span>
					</td>
					<td class="colored-tile">
						<p>
							Besteding (jaar T) van de onder indicator 01 gemoeide kosten
						</p>
					</td>
					<td class="colored-tile">
						<p>
							Cumulatieve besteding (t/m jaar T) van de onder indicator 01
							gemoeide kosten
						</p>
					</td>

					<td class="colored-tile">
						<p>
							Het aantal huishoudens in koopwoningen aan wie energiebesparende
							voorzieningen zijn verstrekt die kunnen leiden tot vermindering
							van energiegebruik/de energierekening
						</p>
					</td>
					<td class="colored-tile">
						<p>
							Besteding (jaar T) van de onder indicator 04 gemoeide kosten
						</p>
					</td>
					<td class="colored-tile">
						<p>
							Cumulatieve besteding (t/m jaar T) van de onder indicator 04
							gemoeide kosten
						</p>
					</td>
				</tr>
				<tr>
					<td class="colored-tile"> </td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p>0 </p>
					</td>
				</tr>
				<tr>
					<td class="colored-tile"> </td>
					<td class="colored-tile">
						<p>
							Het aantal huishoudens dat ondersteuning -bijvoorbeeld via advies
							aan huis- heeft gekregen in de vorm van advies over vermindering
							van het energiegebruik voor zijn specifieke woningen waar de
							bewoner direct zijn/haar energieverbruik heeft kunnen verminderen.
						</p>
					</td>
					<td class="colored-tile">
						<p>
							Besteding (jaar T) van de onder indicator 07 gemoeide kosten
						</p>
					</td>
					<td class="colored-tile">
						<p>
							Cumulatieve besteding (t/m jaar T) van de onder indicator 07
							gemoeide kosten
						</p>
					</td>
					<td class="colored-tile"> </td>
					<td class="colored-tile"> </td>
					<td class="colored-tile"> </td>
				</tr>
				<tr>
					<td class="colored-tile"> </td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile"> <p> 0 </p> </td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="colored-tile"> </td>
					<td class="colored-tile"> </td>
					<td class="colored-tile"> </td>
				</tr>
				<tr>
					<td class="colored-tile"> </td>
					<td class="colored-tile">
						<p> Het aantal kleine gasbesparende maatregelen. </p>
					</td>
					<td class="colored-tile">
						<p> Het aantal kleine elektriciteitsbesparende maatregelen. </p>
					</td>
					<td class="colored-tile">
						<p> Het aantal grote(re) gasbesparende maatregelen. </p>
					</td>
					<td class="colored-tile">
						<p> Het aantal grote(re) elektriciteitsbesparende maatregelen. </p>
					</td>
					<td class="colored-tile">
						<p>
							Het aantal gegeven adviezen met directe verlaging
							energiegebruik/energierekening tot gevolg.
						</p>
					</td>
					<td class="colored-tile">
						<p> Het aantal overige energierekeing verlagende maatregelen. </p>
					</td>
				</tr>
				<tr>
					<td class="colored-tile"> </td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile"> <p> 0 </p> </td
					><td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
					<td class="value-tile">
						<p> 0 </p>
					</td>
				</tr>
			</tbody>
		</table>
	</body>
</html>

`;

    const utf8Encode = new TextEncoder();
    const test = utf8Encode.encode(htmlString);
    const blob = new Blob([test], { type: "application/vnd.ms-excel;charset=utf-8;" });

    const a = document.createElement("a");
    const url = window.URL.createObjectURL(blob as any);
    a.href = url;
    a.download = "export.xls";
    document.body.append(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  }
}
