<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column text-center">
      <h2>{{ "FORMS.NEW_ENERGY_CONSULT_EXISTING_RESIDENT.TITLE" | translate }}</h2>
      <br />
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col-12 row w-100">
            <mat-form-field appearance="outline" id="messageToRetailer">
              <mat-label>{{ "COMPONENTS.TILE.EXTRA_MESSAGE" | translate }}</mat-label>
              <input
                rows="3"
                [placeholder]="'FORMS.NEW_ENERGY_CONSULT_EXISTING_RESIDENT.EXTRA_MESSAGE.PLACEHOLDER' | translate"
                matInput
                formControlName="extraMessage"
                type="text"
              />
              <app-error [control]="formGroup.controls['extraMessage']"></app-error>
            </mat-form-field>

            <div class="row">
              <div class="col-12 row w-100">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.SPECIALIZATION" | translate }}</mat-label>
                  <mat-select formControlName="specialties">
                    <mat-option *ngFor="let specialty of allSpecialties" [value]="specialty">{{ specialty.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="col-12">
        <button mat-raised-button [disabled]="!formGroup.valid" (click)="saveNewCoachVisit()">
          {{ "FORMS.NEW_REPLACEMENTCLAIMS.FIELDS.SAVE" | translate }}
        </button>
      </div>

      <ng-template #popup let-data="data">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md">
              <h3 class="title">{{ data }}</h3>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<!-- asdfasdf -->
