import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplacementService } from "../../services/replacement.service";
import { ReplacementClaim } from "../../classes/flow/replacementClaim/ReplacementClaim";
import { ApplicationService } from "../../services/application.service";
import { ReplacementClaimApplianceOrder } from "../../classes/flow/replacementClaim/replacementClaimApplianceOrder";
import { SnackbarService } from "../../services/snackbar.service";

@Component({
  selector: "app-replacement-claim-schedule-form",
  templateUrl: "./replacement-claim-schedule-form.component.html",
  styleUrls: ["./replacement-claim-schedule-form.component.less"],
})
export class ReplacementClaimScheduleFormComponent implements OnInit {
  public scheduleForm;
  public minDate;
  public maxDate;
  public replacementClaim: ReplacementClaim | null;
  public replacementClaimApplianceOrder: ReplacementClaimApplianceOrder | null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private replacementService: ReplacementService,
    private applicationService: ApplicationService,
    private snackbarService: SnackbarService
  ) {
    this.scheduleForm = this.fb.group({
      date: this.fb.control(new Date(), Validators.required),
      time: this.fb.control("", Validators.required),
    });
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    this.replacementClaim = null;
    this.replacementClaimApplianceOrder = null;
  }

  async ngOnInit(): Promise<void> {
    try {
      this.applicationService.setLoading(true);
      const claimId: string | null = this.route.snapshot.params["replacementClaimId"];
      const orderId: string | null = this.route.snapshot.params["replacementClaimApplianceOrderId"];
      if (!claimId) throw new Error("No claim id given");
      if (!orderId) throw new Error("No order id given");
      this.replacementClaim = await this.replacementService.getReplacementClaim(Number(claimId));
      this.replacementClaimApplianceOrder = await this.replacementService.getReplacementClaimApplianceOrder(Number(orderId));

      if (this.replacementClaim === null) throw new Error("No claim found with this id");
      if (this.replacementClaimApplianceOrder === null) throw new Error("No order found with this id");
      if (this.replacementClaim.state.id !== 4) throw new Error("Unable to schedule this claim");
      if (this.replacementClaimApplianceOrder.orderStateId !== 1 && this.replacementClaimApplianceOrder.orderStateId !== 2) throw new Error("Unable to schedule this order");
      if (this.applicationService.session.user?.id !== this.replacementClaimApplianceOrder.retailerId) throw new Error("This order belongs to another retailer");

      this.applicationService.setLoading(false);
    } catch (error) {
      this.snackbarService.error();
      this.router.navigate(["/content"]);
      this.applicationService.setLoading(false);
    }
  }

  async scheduleClaim() {
    const formData = this.scheduleForm.value;
    const hours = Number(formData.time?.substring(0, 2));
    const minutes = Number(formData.time?.substring(3, 5));
    formData.date?.setHours(hours, minutes, 0, 0);

    this.applicationService.setLoading(true);

    try {
      await this.replacementService.scheduleReplacementClaim(formData.date!, formData.date!, this.replacementClaimApplianceOrder!.id!);
      this.applicationService.setLoading(false);
      // this.router.navigate(["/content/retailer/replacement-claim", this.replacementClaim?.id, "deliver", response.id]);
      this.router.navigate(["/content"]);
    } catch (error) {
      this.snackbarService.error();
      this.applicationService.setLoading(false);
    }
  }
}
