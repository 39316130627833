import { Component, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormattedReportCategory, Statistic } from "../../classes/flow/Questionnaire/Statistic";
import { TranslateService } from "@ngx-translate/core";

interface StatustChartDataItem {
  count: number;
  percentage: number;
  label: string;
}

@Component({
  selector: "app-statistic-tile",
  templateUrl: "./statistic-tile.component.html",
  styleUrls: ["./statistic-tile.component.less"],
})
export class StatisticTileComponent implements OnChanges {
  @Input("statistic")
  public statistic: Statistic | null;

  private translateService: TranslateService;

  public statusChartData: { [key: string]: StatustChartDataItem };

  public reportCategoriesData?: { items: FormattedReportCategory[]; selects: FormattedReportCategory[] };

  constructor() {
    this.translateService = inject(TranslateService);

    this.statistic = null;
    this.statusChartData = {
      new: { count: 0, percentage: 0, label: this.translateService.instant("COMPONENTS.DASHBOARD_STATISTICS.CONSULT_STATUS.NEW") },
      active: { count: 0, percentage: 0, label: this.translateService.instant("COMPONENTS.DASHBOARD_STATISTICS.CONSULT_STATUS.ACTIVE") },
      done: { count: 0, percentage: 0, label: this.translateService.instant("COMPONENTS.DASHBOARD_STATISTICS.CONSULT_STATUS.DONE") },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.keys(changes).includes("statistic")) {
      if (!this.statistic) return;
      /**
       * state
       */
      const data = {
        new: { count: 0, percentage: 0, label: this.translateService.instant("COMPONENTS.DASHBOARD_STATISTICS.CONSULT_STATUS.NEW") },
        active: { count: 0, percentage: 0, label: this.translateService.instant("COMPONENTS.DASHBOARD_STATISTICS.CONSULT_STATUS.ACTIVE") },
        done: { count: 0, percentage: 0, label: this.translateService.instant("COMPONENTS.DASHBOARD_STATISTICS.CONSULT_STATUS.DONE") },
      };

      for (const stat of this.statistic!.stateStatistics) {
        if ([2].includes(stat.idfRequestStateID)) {
          data.new.count += stat.count;
          data.new.percentage += stat.percentageOfTotalCount;
        }
        if ([3, 4, 10, 11].includes(stat.idfRequestStateID)) {
          data.active.count += stat.count;
          data.active.percentage += stat.percentageOfTotalCount;
        }
        if ([5, 7, 9, 12].includes(stat.idfRequestStateID)) {
          data.done.count += stat.count;
          data.done.percentage += stat.percentageOfTotalCount;
        }
      }

      this.statusChartData = data;

      /**
       * report categories
       */
      this.reportCategoriesData = {
        items: this.statistic!.formatedReportCategories.filter((item) => item.type === "cost"),
        selects: this.statistic!.formatedReportCategories.filter((item) => item.type === "select"),
      };
    }
  }
}
