import { Component, inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ReportCategory } from "../../../../classes/flow/Questionnaire/ReportCategory";
import { ReportCategoryService } from "../../../../services/report-category.service";
import { Header } from "../../../../components/table/Header";
import { ApplicationService } from "../../../../services/application.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService } from "../../../../services/dialog.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { TableComponent } from "../../../../components/table/table.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-report-categories",
  templateUrl: "./report-categories.component.html",
  styleUrls: ["./report-categories.component.less"],
})
export class ReportCategoriesComponent implements OnInit {
  @ViewChild("table")
  public table!: TableComponent;

  @ViewChild("deleteDialog")
  public deleteDialog!: TemplateRef<unknown>;

  private applicationService: ApplicationService;
  private reportCategoryService: ReportCategoryService;
  public dialog: DialogService;
  private snack: SnackbarService;
  private translateService: TranslateService;

  public reportCategories: ReportCategory[];
  public tableColumns: Header[];

  public form: FormGroup<{
    name: FormControl<string | null>;
    description: FormControl<string | null>;
    active: FormControl<boolean | null>;
  }>;
  public selectedItem: ReportCategory | null;
  public showForm: boolean;

  public constructor() {
    this.applicationService = inject(ApplicationService);
    this.reportCategoryService = inject(ReportCategoryService);
    this.dialog = inject(DialogService);
    this.snack = inject(SnackbarService);
    this.translateService = inject(TranslateService);

    this.reportCategories = [];
    this.tableColumns = [];

    this.form = new FormGroup({
      name: new FormControl<string | null>(null, [Validators.required]),
      description: new FormControl<string | null>(null, [Validators.required]),
      active: new FormControl<boolean>(false, [Validators.required]),
    });
    this.selectedItem = null;
    this.showForm = false;
  }

  async ngOnInit(): Promise<void> {
    this.applicationService.setLoading(true);
    //get reportcategories
    this.reportCategories = await this.reportCategoryService.getAllReportCategories();
    this.tableColumns = [
      { visualName: "NAME", mappedTo: "name" },
      { visualName: "DESCRIPTION", mappedTo: "description" },
      { visualName: "ACTIVE", mappedTo: "active" },
      {
        visualName: "EDIT",
        click: {
          clickHandler: (e: object) => this.editClickHandler(e as ReportCategory),
          icon: "edit",
          color: "primary",
        },
      },
      {
        visualName: "DELETE",
        click: {
          clickHandler: (e: object) => this.deleteClickHandler(e as ReportCategory),
          icon: "delete",
          color: "warn",
        },
      },
    ];
    this.applicationService.setLoading(false);
  }

  public async editClickHandler(reportCategory: ReportCategory): Promise<void> {
    this.selectedItem = reportCategory;
    this.form.setValue({ name: reportCategory.name, description: reportCategory.description, active: reportCategory.active });
    this.showForm = true;
  }

  public async deleteClickHandler(reportCategory: ReportCategory): Promise<void> {
    this.dialog.open({ template: this.deleteDialog, data: reportCategory });
  }

  public async addClickHandler(): Promise<void> {
    this.showForm = true;
  }

  public async cancelClickHandler(): Promise<void> {
    this.emptyForm();
    this.selectedItem = null;
    this.showForm = false;
  }

  public async submitHandler(event: Event): Promise<void> {
    event.preventDefault();

    if (this.selectedItem !== null) {
      await this.updateClickHandler();
    } else {
      await this.insertReportCategory();
    }
  }

  public async insertReportCategory(): Promise<void> {
    if (this.form.invalid) return;
    this.applicationService.setLoading(true);
    const rc = await this.reportCategoryService.addReportCategory({
      name: this.form.controls.name.value ?? "",
      description: this.form.controls.description.value ?? "",
      active: this.form.controls.active.value ?? false,
    });

    this.reportCategories.push(rc);
    this.table.reload();
    this.showForm = false;
    this.emptyForm();
    this.applicationService.setLoading(false);
    this.snack.open(this.translateService.instant("COMPONENTS.EVERYWHERE.SUCCESS.SAVE_DATA"));
  }

  public async updateClickHandler(): Promise<void> {
    if (this.form.invalid) return;

    this.applicationService.setLoading(true);

    const rc = await this.reportCategoryService.updateReportCategory({
      id: this.selectedItem?.id,
      name: this.form.controls.name.value ?? "",
      description: this.form.controls.description.value ?? "",
      active: this.form.controls.active.value ?? false,
      changes: this.selectedItem?.changes,
    });

    const index = this.reportCategories.findIndex((item) => item.id === this.selectedItem?.id);
    this.reportCategories[index] = rc;
    this.table.reload();

    this.showForm = false;
    this.selectedItem = null;
    this.emptyForm();
    this.applicationService.setLoading(false);
    this.snack.open(this.translateService.instant("COMPONENTS.EVERYWHERE.SUCCESS.SAVE_DATA"));
  }

  public async deleteReportCategory(data: ReportCategory): Promise<void> {
    if (data.id === undefined) return;
    this.applicationService.setLoading(true);

    await this.reportCategoryService.deleteReportCategory(data.id);
    this.reportCategories = this.reportCategories.filter((item) => item.id !== data.id);
    this.table.reload();
    this.applicationService.setLoading(false);
    this.snack.open(this.translateService.instant("COMPONENTS.EVERYWHERE.SUCCESS.SAVE_DATA"));
  }

  public emptyForm(): void {
    this.form.setValue({ name: null, description: null, active: false });
  }
}
