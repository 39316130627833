<div class="container d-flex justify-content-center" *ngIf="replacementClaim && replacementClaimApplianceOrder">
  <div class="d-inline-flex flex-column align-items-center">
    <h2 class="mb-3">{{ "FORMS.REPLACEMENT_CLAIM_SCHEDULE_FORM.TITLE" | translate }}</h2>
    <h5>{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.CONTACT" | translate }}</h5>
    <div class="w-100 d-flex flex-wrap justify-content-center">
      <table clas="d-flex overflow-auto flex-wrap">
        <div class="row">
          <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.NAME" | translate }}:</div>
          <div class="col-sm-12">{{ replacementClaim.resident.firstName + " " + replacementClaim.resident.lastName }}</div>
        </div>
        <div class="row">
          <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.EMAIL" | translate }}:</div>
          <div class="col-sm-12">{{ replacementClaim.resident.email }}</div>
        </div>
        <div class="row">
          <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.PHONENUMBER" | translate }}:</div>
          <div class="col-sm-12">{{ replacementClaim.resident.phoneNumber }}</div>
        </div>
        <div class="row">
          <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.ADRESS" | translate }}:</div>
          <div class="col-sm-12">
            {{ replacementClaim.streetName + " " + replacementClaim.houseNumber + (replacementClaim.houseNumberSuffix ? " " + replacementClaim.houseNumberSuffix : "") }}
          </div>
        </div>
        <div class="justify-content-center" *ngIf="replacementClaim?.postalCode">
          <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.POSTALCODE" | translate }}:</div>
          <div class="col-sm-12">{{ replacementClaim.postalCode | uppercase }}</div>
        </div>
      </table>
    </div>

    <h5>{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.DATE_TIME" | translate }}</h5>
    <form [formGroup]="scheduleForm" class="d-flex flex-column p-0 gap-3 w-100">
      <mat-form-field appearance="fill" class="w-100">
        <mat-label>{{ "FORMS.REPLACEMENT_CLAIM_SCHEDULE_FORM.DATE" | translate }}</mat-label>
        <input matInput formControlName="date" [readonly]="true" [min]="minDate" [max]="maxDate" [matDatepicker]="datepicker" />
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
        <app-error [control]="scheduleForm.controls.date"></app-error>
      </mat-form-field>
      <app-time-picker givenFormControlName="time" [givenFormGroup]="scheduleForm"></app-time-picker>
    </form>
    <div class="d-flex w-100 gap-2">
      <button mat-raised-button [disabled]="!scheduleForm.valid" color="primary" (click)="scheduleClaim()">
        {{ "FORMS.REPLACEMENT_CLAIM_SCHEDULE_FORM.SAVE" | translate }}
      </button>
      <a mat-raised-button [routerLink]="['/content']">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.BACK" | translate }}</a>
    </div>
  </div>
</div>
