import { Changes } from "../base/Changes";
import { Entity } from "../base/Entity";

export class AreaAction implements Entity {
  id?: number;
  changes?: Changes;
  municipality?: string;
  area?: string;
  actionType?: string;
  dateFrom: Date;
  dateTo: Date;
  zipCodeFrom: string;
  zipCodeTo: string;
  expired?: boolean;
  extraProperties?: AreaActionExtraProperties;

  constructor({
    id,
    changes,
    municipality,
    area,
    actionType,
    dateFrom,
    dateTo,
    zipCodeFrom,
    zipCodeTo,
    expired,
    extraProperties,
  }: {
    id?: number;
    changes?: Changes;
    municipality?: string;
    area?: string;
    actionType?: string;
    dateFrom: Date;
    dateTo: Date;
    zipCodeFrom: string;
    zipCodeTo: string;
    expired?: boolean;
    extraProperties?: any;
  }) {
    this.id = id;
    this.changes = changes;
    this.municipality = municipality;
    this.area = area;
    this.actionType = actionType;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.zipCodeFrom = zipCodeFrom;
    this.zipCodeTo = zipCodeTo;
    this.expired = expired;

    if (extraProperties) {
      this.extraProperties = {};
      if (extraProperties.lastChangeDate) {
        this.extraProperties.lastChangeDate = extraProperties.lastChangeDate;
      }
      if (extraProperties.coordinates) {
        this.extraProperties.coordinates = extraProperties.coordinates.map((coor: any) => {
          return { lat: Number(coor.lat), lng: Number(coor.lng) };
        });
      }
      if (extraProperties.areaCoordinates) {
        const lat = parseFloat(extraProperties.areaCoordinates.latitude);
        const lng = parseFloat(extraProperties.areaCoordinates.longitude);
        this.extraProperties.AreaCoordinates = { lat, lng };
      }
    }
  }
}

type AreaActionExtraProperties = {
  lastChangeDate?: string;
  coordinates?: { lat: number; lng: number }[];
  AreaCoordinates?: { lat: number; lng: number };
};
