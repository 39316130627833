import { Injectable } from "@angular/core";

export type usedStorageType = "localStorage" | "sessionStorage";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  /**
   * Stores a value under the given key
   * @param key The key
   * @param value The value to store
   */
  private storageMap: { [key in usedStorageType]: Storage } = {
    sessionStorage: sessionStorage,
    localStorage: localStorage,
  };

  public store<T>(key: string, value: T, storageType: usedStorageType) {
    const storage = this.storageMap[storageType];
    storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Fetches an object from the storage by key
   * @param key The key to fetch the value for
   * @returns The value, or undefined if there was no value found
   */
  public fetch<T>(key: string, storageType: usedStorageType): T | null {
    const storage = this.storageMap[storageType];
    return this.has(key, storageType) ? JSON.parse(storage.getItem(key)!) : null;
  }

  /**
   * Checks whether a value is present for the given key
   * @param key The key to check for
   * @returns True if a value exists for the key, false otherwise
   */
  public has(key: string, storageType: usedStorageType): boolean {
    const storage = this.storageMap[storageType];
    return !!storage.getItem(key);
  }

  /**
   * Removes all items from storage
   */
  public clear(storageType: usedStorageType) {
    const storage = this.storageMap[storageType];
    storage.clear();
  }

  public remove(key: string, storageType: usedStorageType) {
    const storage = this.storageMap[storageType];
    storage.removeItem(key);
  }

  public getAllKeys(storageType: usedStorageType): string[] {
    const storage = this.storageMap[storageType];
    const keys: string[] = [];

    for (let i = 0; i < storage.length; i++) {
      const key = storage.key(i);
      if (key !== null) keys.push(key);
    }
    return keys;
  }
}
