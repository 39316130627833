<form [formGroup]="formGroup">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>{{ "FORMS.RETAILER_PAYOUT.TITLE" | translate }}</h2>
      </div>
      <div class="col-12 mt-1">
        <mat-form-field appearance="outline">
          <mat-label>{{ "FORMS.RETAILER_PAYOUT.AMOUNT" | translate }}</mat-label>
          <input class="currencyField" currencyMask matInput formControlName="paymentAmount" [options]="{ prefix: '€ ', thousands: '.', decimal: ',' }" />
          <mat-hint class="hint" (click)="setAmountToMax()"> {{ "FORMS.RETAILER_PAYOUT.MAX" | translate }}{{ formatToEuro(balanceAvailable) }}</mat-hint>
          <app-error [control]="formGroup.controls['paymentAmount']"></app-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-12 mt-2">
      <mat-form-field appearance="outline">
        <mat-label>{{ "FORMS.RETAILER_PAYOUT.PAYMENT_REFERENCE" | translate }}</mat-label>
        <input matInput formControlName="paymentReference" />
        <mat-hint>{{ "FORMS.ERRORS.ALPHANUMERIC" | translate }}</mat-hint>
        <app-error [control]="formGroup.controls['paymentReference']"></app-error>
      </mat-form-field>
    </div>
    <button class="mt-3" mat-raised-button [disabled]="!this.formGroup.valid" (click)="savePaymentRequest()">{{ "FORMS.RETAILER_PAYOUT.NEW_PAYOUT" | translate }}</button>
  </div>
</form>
