import { Changes } from "../base/Changes";
import { Entity } from "../base/Entity";

export class ReplacementClaimApplianceOrder implements Entity {
  public id?: number;
  public orderStateId?: number;
  public retailerId?: number;
  public replacementClaimApplianceId?: number;
  public description?: string;
  public expired?: boolean;
  public expiricyReason?: string;
  public hOID?: string;
  public changes?: Changes;

  constructor(order: {
    id?: number;
    orderStateID?: number;
    retailerID?: number;
    replacementClaimApplianceID?: number;
    description?: string;
    expired?: boolean;
    expiricyReason?: string;
    hOID?: string;
    changes?: Changes;
  }) {
    this.id = order.id;
    this.orderStateId = order.orderStateID;
    this.retailerId = order.retailerID;
    this.replacementClaimApplianceId = order.replacementClaimApplianceID;
    this.description = order.description;
    this.expired = order.expired;
    this.expiricyReason = order.expiricyReason;
    this.hOID = order.hOID;
    this.changes = order.changes;
  }
}
