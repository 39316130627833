import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "localDate",
  pure: false,
})
export class LocalDatePipe implements PipeTransform {
  public constructor(private readonly translateService: TranslateService) {}

  public transform(value: string | number | Date, pattern = "mediumDate"): string {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    return datePipe.transform(value, pattern) ?? "";
  }
}
