<div *ngIf="question.active" class="container">
  <mat-accordion>
    <mat-expansion-panel class="mb-3">
      <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" class="mb-3"> -->
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ form.controls.name.value }}
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="ms-auto" cdkDragHandle>swap_vert</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="row">
        <div class="col-12">
          <form [formGroup]="form">
            <!-- <input style="display: none" formControlName="index" [value]="setIndex(question.id!, indexPrefix + (index + 1))" /> -->

            <div *ngIf="question.logic">
              <!-- If Parent Is (Multiple)Choice, Display options to pick from -->
              <div *ngIf="parentQuestion?.questionType?.prefix === 'sel' || parentQuestion?.questionType?.prefix === 'sem'; else parentIsNotChoice">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.SELECT_OPTION_CONDITION" | translate }}</mat-label>
                  <mat-select formControlName="compareOptionId">
                    <mat-option *ngFor="let option of parentQuestionOptions" [value]="option.id">
                      {{ option.value.length ? option.value : ("FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.FILL_IN_SELECT_OPTION" | translate) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <ng-template #parentIsNotChoice>
                <!-- If parent is text or other field, display operator and value -->
                <mat-form-field appearance="outline">
                  <mat-label>{{ "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.NEXT_ANSWER" | translate }}</mat-label>
                  <mat-select [compareWith]="compareEntities" formControlName="operatorType">
                    <mat-option *ngFor="let operatorType of operatorTypes" [value]="operatorType">{{
                      "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTION.CONDITIONS." + operatorType.name | uppercase | translate
                    }}</mat-option>
                  </mat-select>
                  <app-error [control]="form.controls.operatorType"></app-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>{{ "FORMS.VALUE" | translate }}</mat-label>
                  <input *ngIf="!parentIsDate" matInput formControlName="compareAnswer" />
                  <input *ngIf="parentIsDate" matInput formControlName="compareAnswer" type="date" />
                  <app-error [control]="form.controls.compareAnswer"></app-error>
                </mat-form-field>
              </ng-template>
            </div>

            <!-- Name -->
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{ "FORMS.QUESTIONNAIRE.QUESTION" | translate }}</mat-label>
              <input matInput formControlName="name" placeholder="{{ 'FORMS.QUESTIONNAIRE.GENERAL_QUESTION.QUESTION_PLACEHOLDER' | translate }}" />
              <mat-error *ngIf="form.controls.name.errors?.pattern">{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ERROR.SEMICOLON" | translate }}</mat-error>
            </mat-form-field>

            <!-- Description -->
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.DESCRIPTION" | translate }}</mat-label>
              <textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="15"></textarea>
              <mat-error *ngIf="form.controls.description.errors?.pattern">{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ERROR.SEMICOLON" | translate }}</mat-error>
            </mat-form-field>

            <!-- Field type -->
            <mat-form-field appearance="outline" class="me-3">
              <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.INPUT_FIELD.TYPE" | translate }}</mat-label>
              <mat-select [compareWith]="compareEntities" formControlName="questionType">
                <mat-option *ngFor="let questionType of questionTypes" [value]="questionType">{{
                  "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.INPUT_FIELD." + questionType.name | uppercase | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Field placeholder -->
            <mat-form-field appearance="outline" class="me-3">
              <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.TEMPORARY_DESIGNATION" | translate }}</mat-label>
              <input matInput formControlName="placeholder" />
              <mat-error *ngIf="form.controls.placeholder.errors?.pattern">{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ERROR.SEMICOLON" | translate }}</mat-error>
            </mat-form-field>

            <!-- Field is required? -->
            <mat-form-field appearance="outline" class="me-3">
              <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.ANSWER_REQUIRED" | translate }}</mat-label>
              <mat-select formControlName="required">
                <mat-option [value]="true">{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.REQUIRE_OPTIONS.REQUIRED" | translate }}</mat-option>
                <mat-option [value]="false">{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.REQUIRE_OPTIONS.NOT_REQUIRED" | translate }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Validation -->
            <mat-form-field appearance="outline" class="me-3">
              <mat-label>Controle</mat-label>
              <mat-select [compareWith]="compareEntities" formControlName="validation">
                <mat-option *ngFor="let validation of validations" [value]="validation">{{
                  "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.CHECK." + validation.name | uppercase | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- Question Options -->
            <ng-container *ngIf="optionsCanBeAdded">
              <br />

              <div *ngFor="let option of optionControls.controls; let optionIndex = index" class="div-form-field-padding">
                <mat-form-field appearance="outline" class="form-field-no-padding">
                  <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.SELECT_OPTION" | translate : { index: optionIndex + 1 } }}</mat-label>
                  <input matInput [formControl]="option" [disabled]="!editableState.questions" />
                </mat-form-field>
                <button
                  mat-stroked-button
                  color="primary"
                  (click)="saveSelectOption(optionIndex)"
                  [disabled]="option.invalid || question.options?.[optionIndex]?.value === option.value || !editableState.questions"
                >
                  {{ "FORMS.SAVE" | translate }}
                </button>
                <button
                  mat-stroked-button
                  color="warn"
                  (click)="deleteSelectOption(optionIndex)"
                  [disabled]="!editableState.questions || !(question.options && question.options.length > 2)"
                >
                  {{ "FORMS.DELETE" | translate }}
                </button>
              </div>

              <button
                mat-icon-button
                [disabled]="!editableState.questions"
                matTooltip="{{ 'FORMS.QUESTIONNAIRE.TOOLTIP.NEW_OPTION' | translate }}"
                (click)="addSelectOption()"
              >
                <mat-icon>playlist_add</mat-icon>
              </button>
            </ng-container>

            <!-- Unit price -->
            <ng-container *ngIf="showUnitPrice">
              <mat-form-field appearance="outline" class="form-field-no-padding">
                <mat-label>{{ "FORMS.QUESTIONNAIRE.GENERAL_QUESTION.UNITPRICE" | translate }}</mat-label>
                <input matInput currencyMask formControlName="unitPrice" [options]="{ prefix: '€ ', thousands: '.', decimal: ',', align: 'left' }" />
              </mat-form-field>
            </ng-container>
          </form>
          <!-- Report Category -->
          <ng-container *appModule="'REPORT_CATEGORIES'">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Report Category</mat-label>
              <mat-select [multiple]="true" [compareWith]="compareEntities" [formControl]="questionReportCategoriesForm">
                <mat-option *ngFor="let reportCategory of reportCategories" [value]="reportCategory">{{ reportCategory.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>

        <div *ngIf="question.followUpQuestions.length" class="col-12">
          <p>{{ "FORMS.QUESTIONNAIRE.FOLLOW_UP_QUESTIONS" | translate }}:</p>
          <div cdkDropList (cdkDropListDropped)="reorderQuestion($event)">
            <div [cdkDragData]="question.followUpQuestions" cdkDrag class="row" *ngFor="let followUpQuestion of question.followUpQuestions; let index = index">
              <app-question-edit
                (delete)="onDelete($event)"
                [questionnaireId]="questionnaireId"
                [question]="followUpQuestion"
                [questions]="questions"
                [questionIndex]="index"
                [validations]="validations"
                [operatorTypes]="operatorTypes"
                [questionTypes]="questionTypes"
                [indexPrefix]="newIndexPrefix"
                [reportCategories]="reportCategories"
                [editableState]="editableState"
              ></app-question-edit>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-between">
          <span>
            <button
              *ngIf="followUpQuestionCanBeAdded"
              mat-icon-button
              matTooltip="{{ 'FORMS.QUESTIONNAIRE.TOOLTIP.NEW_FOLLOW_UP_QUESTION' | translate }}"
              [disabled]="!editableState.questions"
              (click)="addFollowUpQuestion()"
            >
              <mat-icon>subdirectory_arrow_right</mat-icon>
            </button>
          </span>
          <button
            mat-icon-button
            color="warn"
            matTooltip="{{ 'FORMS.QUESTIONNAIRE.TOOLTIP.DELETE_QUESTION' | translate }}"
            [disabled]="!editableState.questions"
            (click)="onDelete(question)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
