import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AreaActionService } from "../../../../services/area-action.service";
import { AreaAction } from "../../../../classes/flow/AreaActions/AreaAction";
import { ApplicationService } from "../../../../services/application.service";
import { DialogService } from "../../../../services/dialog.service";
import { SnackbarService } from "../../../../services/snackbar.service";

@Component({
  selector: "app-area-actions",
  templateUrl: "./area-actions.component.html",
  styleUrls: ["./area-actions.component.less"],
})
export class AreaActionsComponent implements OnInit {
  public areaActions: AreaAction[];

  public areaActionToEdit?: AreaAction;
  public formVisible: boolean;

  @ViewChild("deleteDialog")
  public deleteDialog?: TemplateRef<unknown>;

  constructor(private areaActionService: AreaActionService, private application: ApplicationService, public dialog: DialogService, private snackbar: SnackbarService) {
    this.areaActions = [];
    this.formVisible = false;
  }

  async ngOnInit(): Promise<void> {
    await this.loadAreaActions();
  }

  async loadAreaActions() {
    this.application.setLoading(true);
    const allAreaActions = await this.areaActionService.all();

    // filter out expired ones
    this.areaActions = allAreaActions.filter((action) => !action.expired);
    this.application.setLoading(false);
  }

  // Called when edit button in overview is clicked, causes form to be visible and prefilled with the action to edit
  public editHandler(e: AreaAction) {
    this.areaActionToEdit = e;
    this.formVisible = true;
  }

  // Called when delete button in overview is clicked
  public deleteHandler(e: AreaAction) {
    this.dialog.open({
      template: this.deleteDialog!,
      data: e,
    });
  }

  // Called when add button in overview is clicked
  public addHandler() {
    this.formVisible = true;
    this.areaActionToEdit = undefined;
  }

  // Called when cancel button in form is clicked, hides the form
  public cancelHandler() {
    this.formVisible = false;
    this.areaActionToEdit = undefined;
  }

  // Called when form executed a submit and save succeeded, hides the form and reloads overview contents
  public async saveHandler() {
    this.formVisible = false;
    this.areaActionToEdit = undefined;
    this.loadAreaActions();
  }

  // Called when delete button in dialog is clicked
  public async deleteAreaAction(areaAction: AreaAction) {
    this.application.setLoading(true);
    try {
      // Hide form if the currently edited item is deleten
      if (this.areaActionToEdit?.id === areaAction.id) {
        this.formVisible = false;
        this.areaActionToEdit = undefined;
      }

      // Execute delete
      await this.areaActionService.delete(areaAction);
      // Reload overview
      await this.loadAreaActions();
    } catch (error) {
      this.snackbar.error();
    }
    this.application.setLoading(false);
  }
}
