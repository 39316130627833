import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { Observable, catchError } from "rxjs";
import { Router } from "@angular/router";
import { ENVIRONMENT } from "../../environments/environment";

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.url?.startsWith(ENVIRONMENT.API_URL) && error.status === 404) {
          this.router.navigate(["/under-construction"]);
        }
        throw new Error("api-error: => , " + error);
      })
    );
  }
}
