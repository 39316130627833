import { Component, Input } from "@angular/core";

export type Notion = "Normal" | "Warning" | "Error";

/**
 * A state that can be part of a flow within the progress bar 
 */
export interface State {
  name: string;
  notion?: Notion;
  /**
   * The weight is used to indicate the relative contribution of this state to the total flow.
   * A weight of 2 means that the progress gained when reaching this specific state, is twice as much as the progress gained from a state with weight 1.
   */
  weight?: number;
}

@Component({
  selector: "app-state-progress-bar[flow]",
  templateUrl: "./state-progress-bar.component.html",
  styleUrls: ["./state-progress-bar.component.less"]
})
/**
 * A component representing a progress bar that uses several states in a flow to automatically determine its progress.
 * A state can either be part of a normal flow, or define some unusal condition within the flow.
 */
export class StateProgressBarComponent {
  @Input("flow")
  public flow: State[] = [];
  @Input("currentState")
  public currentState?: string;

  /** 
   * The progress based on the current state of the progress bar compared to all states that still have to be completed. 
   * This also takes into account the weights of each state.
   */
  public get progress(): number {
    if(!this.flow.length || !this.currentState) {
      return 0;
    }
    const completedStates = this.flow.slice(0, this.flow.findIndex(state => state.name === this.currentState) + 1);
    return this.sumWeights(completedStates) / this.sumWeights(this.flow) * 100;
  }

  public get color(): string {
    const currentState = this.flow.find(state => state.name === this.currentState);
    return (!currentState || this.contributes(currentState)) ? "primary" : (currentState.notion === "Warning" ? "accent" : "warn");
  }

  /**
   * Sums all weights of the given state. Only a "normal" state contributes to the total.
   */
  private sumWeights(states: State[]) {
    const firstContributingState = this.flow.find(state => this.contributes(state));
    return states.reduce((sum, currentState) => {
      return this.getContributingWeight(currentState) + sum;
    }, firstContributingState ? -(firstContributingState.weight ?? 1) : 0);
  }

  /**
   * Gets the weight for a state, taking into account whether it contributes to the progress. If a state has no weight, it will default to 1.
   * @param state The state to get the contributing weight for.
   * @returns The weight a state contributes to the total progress.
   */
  private getContributingWeight(state: State) {
    return this.contributes(state) ? (state.weight ?? 1) : 0;
  }

  /**
   * Checks whether a given state contributes to the progress being made.
   * @param state The state to check for
   * @returns True if this state contributes to the progress, false otherwise
   */
  private contributes(state: State) {
    return !state.notion || state.notion === "Normal";
  }
}
