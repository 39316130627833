<div
  *ngIf="!toggleFormControl.value"
  (click)="toggleValue()"
  class="d-flex justify-content-start justify-content-md-center align-items-center gap-2 p-2 user-select-none button-hover"
  style="cursor: pointer"
  role="switch"
  [attr.aria-label]="falseAriaLabel"
  [matTooltip]="falseTooltip ?? ''"
  matRipple
>
  <mat-icon [fontIcon]="falseIconName" style="color: var(--brand-color); padding-right: 1rem"></mat-icon>
  <p class="m-0">{{ falseText }}</p>
</div>
<div
  *ngIf="toggleFormControl.value"
  (click)="toggleValue()"
  class="d-flex justify-content-start justify-content-md-center align-items-center gap-2 p-2 user-select-none button-hover"
  style="cursor: pointer"
  role="switch"
  [attr.aria-label]="trueAriaLabel"
  [matTooltip]="trueTooltip ?? ''"
  matRipple
>
  <mat-icon [fontIcon]="trueIconName" style="color: var(--brand-color); padding-right: 1rem"></mat-icon>
  <p class="m-0">{{ trueText }}</p>
</div>
