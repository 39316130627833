const mappings = {
  "en": [
    "en",
    "en-US"
  ],
  "nl": [
    "nl",
    "nl-NL"
  ]
};

export type Language = keyof typeof mappings;

/**
 * Maps the user's current language and returns a corresponding, predefined, Language
 * @returns The user's current language, or undefined if it is not a known language within the application
 */
export const getLanguage = (): Language => {
  return Object.entries(mappings).find(([, possibleAliases]) => possibleAliases.includes(navigator.language))?.[0] as Language ?? "nl";
};