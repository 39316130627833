import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { Module } from "../classes/flow/base/Module";
import { ENVIRONMENT } from "../../environments/environment";

@Directive({
  selector: "[appModule]",
})
export class ModuleDirective {
  constructor(private template: TemplateRef<unknown>, private container: ViewContainerRef) {}

  @Input() set appModule(module: Module) {
    if (ENVIRONMENT.MODULES.includes(module)) {
      this.container.createEmbeddedView(this.template);
    } else {
      this.container.clear();
    }
  }
}
