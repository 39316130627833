import { KeyValue } from "@angular/common";
import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ENVIRONMENT } from "../../../../environments/environment";
import { Questionnaire } from "../../../classes/flow/Questionnaire/Questionnaire";
import { Specialty } from "../../../classes/flow/Questionnaire/Specialty";
import { Header } from "../../../components/table/Header";
import { downloadFile } from "../../../helpers/downloadFile";
import { ApplicationService } from "../../../services/application.service";
import { DialogService } from "../../../services/dialog.service";
import { QuestionnaireService } from "../../../services/questionnaire.service";
import { SnackbarService } from "../../../services/snackbar.service";

@Component({
  selector: "app-all-questionnaires",
  templateUrl: "./all-questionnaires.component.html",
  styleUrls: ["./all-questionnaires.component.less"],
})
export class AllQuestionnairesComponent implements OnInit, AfterViewInit {
  @ViewChild("deleteDialog")
  public deleteDialog!: TemplateRef<unknown>;

  @ViewChild("activateDialog")
  public activateDialog!: TemplateRef<unknown>;

  @ViewChild("deactivateDialog")
  public deactivateDialog!: TemplateRef<unknown>;

  @ViewChild("copyDialog")
  public copyDialog!: TemplateRef<unknown>;

  @ViewChild("setOrderTemplate")
  public setOrderTemplate!: TemplateRef<unknown>;

  public questionnaires: Questionnaire[] = [];

  public splittedQuestionnaires: Map<string, Questionnaire[]> = new Map();
  public showTables: { name: string; editable: boolean }[] = [
    { name: "PUBLISHED", editable: false },
    { name: "INACTIVE_ANSWERED", editable: false },
    { name: "INACTIVE_UNANSWERED", editable: true },
  ];

  public headers: Header[];

  public data: Map<
    string,
    {
      editable: boolean;
      headers: Header[];
      questionnaires: Map<number, Questionnaire[]>;
    }
  > = new Map([
      ["PUBLISHED", { editable: false, headers: [], questionnaires: new Map() }],
      ["INACTIVE_ANSWERED", { editable: false, headers: [], questionnaires: new Map() }],
      ["INACTIVE_UNANSWERED", { editable: true, headers: [], questionnaires: new Map() }],
    ]);

  public constructor(
    private readonly questionnaireService: QuestionnaireService,
    private readonly translateService: TranslateService,
    public readonly dialogService: DialogService,
    private readonly applicationService: ApplicationService,
    private readonly snackService: SnackbarService,
    private router: Router
  ) {
    const order = ["TITLE", "DESCRIPTION", "SPECIALTIES", "VIEW", "EDIT", "PDF", "COPY", "PUBLISHED_TOGGLE", "SET_ORDER"];

    this.headers = [
      { visualName: "TITLE", mappedTo: "title", columnStyles: { class: "col-12" } },
      { visualName: "DESCRIPTION", mappedTo: "description", columnStyles: { class: "col-12" } },
      {
        visualName: "EDIT",
        tooltip: this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.EDIT.TOOLTIP"),
        click: {
          icon: "edit",
          color: "primary",
          clickHandler: (e: unknown) => {
            this.router.navigate(["/content/coordinator/questionnaire", (e as Questionnaire).id]);
          },
        },
        columnStyles: { class: "col-4" },
      },
      {
        visualName: "VIEW",
        tooltip: this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.VIEW.TOOLTIP"),
        click: {
          icon: "preview",
          color: "primary",
          clickHandler: (e: unknown) => {
            this.router.navigate(["/content/coordinator/questionnaire", (e as Questionnaire).id]);
          },
        },
        columnStyles: { class: "col-4" },
      },
      {
        visualName: "COPY",
        tooltip: this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.COPY.TOOLTIP"),
        click: { icon: "content_copy", clickHandler: this.openCopyDialog },
        columnStyles: { class: "col-4" },
      },
      {
        visualName: "PUBLISHED_TOGGLE",
        mappedTo: "active",
        click: {
          icon: "",
          clickHandler: (e: any) => {
            this.openStatusDialog(e);
          },
        },
      },
    ];

    // Push if empty pdf is in environment models
    if (ENVIRONMENT.MODULES.includes("EMPTY_PDF")) {
      this.headers.push({
        visualName: "PDF",
        tooltip: this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.PDF.TOOLTIP"),
        click: { icon: "picture_as_pdf", clickHandler: this.openPdfFile },
        columnStyles: { class: "col-4" },
      });
    }

    // Push if hide modules is not in environment models
    if (!ENVIRONMENT.MODULES.includes("HIDE_SPECIALTIES")) {
      this.headers.push({ visualName: "SPECIALTIES", resolver: this.specialtiesResolver, columnStyles: { class: "col-12" } });
    }

    // Sort headers
    this.headers.sort((a, b) => {
      return order.indexOf(a.visualName) - order.indexOf(b.visualName);
    });

    const published = this.data.get("PUBLISHED");
    if (published) published.headers = this.headers.filter((h) => h.visualName !== "EDIT");

    const answered = this.data.get("INACTIVE_ANSWERED");
    if (answered) answered.headers = this.headers.filter((h) => h.visualName !== "EDIT" && h.visualName !== "SET_ORDER");

    const unanswered = this.data.get("INACTIVE_UNANSWERED");
    if (unanswered) unanswered.headers = this.headers.filter((h) => h.visualName !== "VIEW" && h.visualName !== "SET_ORDER");
  }

  public async ngOnInit() {
    this.applicationService.setLoading(true);
    this.applicationService.updateTitle(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.TITLE"));

    await this.initQuestionnaires();
    this.applicationService.setLoading(false);
  }

  public ngAfterViewInit() {
    const published = this.data.get("PUBLISHED");
    if (published)
      published.headers.push({
        visualName: "SET_ORDER",
        customTemplate: this.setOrderTemplate,
      });
  }

  public openStatusDialog(questionnaire: Questionnaire) {
    if (!questionnaire.active) {
      this.dialogService.open({
        template: this.activateDialog,
        data: questionnaire as Questionnaire,
      });
    } else {
      this.dialogService.open({
        template: this.deactivateDialog,
        data: questionnaire as Questionnaire,
      });
    }
  }

  public async setIndex(questionnaire: Questionnaire) {
    this.applicationService.setLoading(true);
    const published = this.data.get("PUBLISHED")!;
    const specialties = Array.from(published.questionnaires.values()).filter((q) => q.includes(questionnaire));

    for (const specialtyArray of specialties) {
      if (!questionnaire.id || questionnaire.sortOrder === null || !questionnaire.changes) throw new Error();
      const index = specialtyArray.findIndex((q) => q.id === questionnaire.id);
      if (index === -1) return;

      const loweringQuestionnaire = specialtyArray[index - 1];
      if (!loweringQuestionnaire || loweringQuestionnaire.sortOrder === null || !loweringQuestionnaire.id || !loweringQuestionnaire.changes) return;
      loweringQuestionnaire.sortOrder++;
      questionnaire.sortOrder--;
      try {
        const LQChanges = await this.questionnaireService.setQuestionnaireSortOrder(loweringQuestionnaire.id, loweringQuestionnaire.sortOrder, loweringQuestionnaire.changes);
        loweringQuestionnaire.changes = LQChanges;
        const UQChanges = await this.questionnaireService.setQuestionnaireSortOrder(questionnaire.id, questionnaire.sortOrder, questionnaire.changes);
        questionnaire.changes = UQChanges;
        this.snackService.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.ORDER"));
      } catch {
        this.snackService.error();
      }
    }

    this.sortQuestionnairesByOrder("PUBLISHED");
    this.applicationService.setLoading(false);
  }

  public async updateQuestionnaireStatus(questionnaire: Questionnaire) {
    this.applicationService.setLoading(true);
    await this.questionnaireService.updateProperty(questionnaire, "active", !questionnaire.active);
    await this.initQuestionnaires();
    this.dialogService.close();
    this.applicationService.setLoading(false);
  }

  public sortQuestionnairesByOrder(key: string): void {
    const type = this.data.get(key)!;
    for (const [specialty, questionnaires] of type.questionnaires.entries()) {
      type.questionnaires.set(
        specialty,
        questionnaires
          .sort((a: Questionnaire, b: Questionnaire): number => {
            return (a.sortOrder ?? -1) - (b.sortOrder ?? -1);
          })
          .slice()
      );
    }
  }

  /**
   * Formats the given specialties in one string, listing all their names
   * @param specialties The collection of specialties to join together
   * @returns A string containing the names of the given specialties
   */
  public formatSpecialties(specialties: Specialty[]): string {
    if (!specialties.length) {
      return this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.NO_SPECIALTY");
    }
    return specialties.map((s) => s.name).join(",\n");
  }

  /**
   * Opens a dialog for deletion
   * @param questionnaire The questionnaire to delete
   */
  public openDeleteDialog: (questionnaire: object) => void = (questionnaire) => {
    this.dialogService.open({
      template: this.deleteDialog,
      data: questionnaire as Questionnaire,
    });
  };

  /**
   * Opens a pdf file of the questionnaire
   * @param questionnaire The questionnaire for creating a pdf file
   */
  public openPdfFile: (questionnaire: object) => void = async (questionnaire) => {
    const pdf = await this.questionnaireService.getPdfFile((questionnaire as Questionnaire).id!);
    downloadFile(pdf, "Checklist", "application/pdf");
  };

  /**
   * Tries to delete the specified questionnaire
   * @param id The id of the questionnaire to delete
   */
  public async deleteQuestionnaire(questionnaire: Questionnaire) {
    try {
      await this.questionnaireService.deleteQuestionnaire(questionnaire);
      this.questionnaires = this.questionnaires.filter((c) => c.id! !== questionnaire.id);
      this.snackService.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.SUCCESS_MESSAGE"));
    } catch (error) {
      this.snackService.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.FAILURE_MESSAGE"));
    }
  }

  /**
   * Try to fetch all questionnaires
   */
  private async initQuestionnaires() {
    try {
      const publishedMap = this.data.get("PUBLISHED")!;
      const answeredMap = this.data.get("INACTIVE_ANSWERED")!;
      const unansweredMap = this.data.get("INACTIVE_UNANSWERED")!;

      publishedMap.questionnaires = new Map();
      answeredMap.questionnaires = new Map();
      unansweredMap.questionnaires = new Map();

      this.questionnaires = await this.questionnaireService.getQuestionnaires();

      const addToMap = (questionnaire: Questionnaire, map: Map<number, Questionnaire[]>): void => {
        for (const specialty of questionnaire.specialties) {
          const questionnaires = map.get(specialty.id) || [];
          questionnaires.push(questionnaire);
          map.set(specialty.id, questionnaires.sort());
        }
      };

      for (const questionnaire of this.questionnaires) {
        if (questionnaire.active) {
          // Published
          addToMap(questionnaire, publishedMap.questionnaires);
        } else if (questionnaire.isAnswered) {
          // Inactive, Answered
          addToMap(questionnaire, answeredMap.questionnaires);
        } else {
          // Inactive, Unanswered
          addToMap(questionnaire, unansweredMap.questionnaires);
        }
      }

      /**
       * Remove empty specialties so no empty tables are shown
       */
      this.data.forEach((value, key) => {
        if (value.questionnaires.size <= 0) {
          this.data.delete(key);
        }
      });

      for (const questionnaires of publishedMap.questionnaires.values()) {
        if (
          questionnaires
            .map((q) => q.sortOrder)
            .filter((val) => val !== null)
            .sort()
            .reverse()
            .shift() !==
          questionnaires.length - 1
        ) {
          const questionnairesWithSortOrder = questionnaires.filter((q) => q.sortOrder !== null).sort((a, b) => a.sortOrder! - b.sortOrder!);
          const questionnairesWithoutSortOrder = questionnaires.filter((q) => q.sortOrder === null);

          for await (const [index, q] of questionnairesWithSortOrder.entries()) {
            if (q.sortOrder! !== index) {
              if (!q.id || !q.changes) return;
              try {
                q.changes = await this.questionnaireService.setQuestionnaireSortOrder(q.id, index, q.changes);
                q.sortOrder = index;
              } catch {
                this.snackService.error();
              }
            }
          }
          for await (const [index, q] of questionnairesWithoutSortOrder.entries()) {
            if (!q.id || !q.changes) return;

            try {
              q.changes = await this.questionnaireService.setQuestionnaireSortOrder(q.id, questionnairesWithSortOrder.length + index, q.changes);
              q.sortOrder = questionnairesWithSortOrder.length + index;
            } catch {
              this.snackService.error();
            }
          }
        }
      }
    } catch (error) {
      this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.RETRIEVE_DATA"));
    }
    this.sortQuestionnairesByOrder("PUBLISHED");
  }

  public openCopyDialog: (questionnaire: object) => void = (questionnaire) => {
    this.dialogService.open({
      template: this.copyDialog,
      data: {
        form: new FormGroup({
          title: new FormControl("", Validators.required),
          description: new FormControl("", Validators.required),
        }),
        questionnaire: questionnaire as Questionnaire,
      },
    });
  };

  public async copyQuestionnaire(data: { form: FormGroup; questionnaire: Questionnaire }) {
    this.applicationService.setLoading(true);
    try {
      data.questionnaire.description = data.form.controls["description"].value;
      data.questionnaire.title = data.form.controls["title"].value;
      await this.questionnaireService.copyQuestionnaire(data.questionnaire);
      await this.initQuestionnaires();
    } catch (error) {
      this.snackService.error();
    }
    this.dialogService.close();
    this.applicationService.setLoading(false);
  }

  public specialtiesResolver(questionnaire: Questionnaire): string {
    return questionnaire.specialties?.length ? questionnaire.specialties[0].name : this.translateService?.instant("COMPONENTS.ALL_QUESTIONNAIRES.NO_SPECIALTY") ?? "-";
  }

  public tableOrder(a: KeyValue<string, unknown>, b: KeyValue<string, unknown>): number {
    const sort = ["PUBLISHED", "INACTIVE_ANSWERED", "INACTIVE_UNANSWERED"];
    return sort.indexOf(a.key) - sort.indexOf(b.key);
  }
}
