import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogComponent } from "../components/dialog/dialog.component";

interface DialogInterface {
  template: TemplateRef<unknown>;
  data?: unknown;
}

@Injectable({
  providedIn: "root",
})
export class DialogService {
  private ref!: MatDialogRef<unknown>;

  public constructor(public dialog: MatDialog) {}

  /**
   * Opens the given dialog
   * @param dialog The dialog to show to the user
   */
  public open(dialog: DialogInterface) {
    this.ref = this.dialog.open(DialogComponent, {
      data: {
        template: dialog.template,
        data: dialog.data,
      },
    });
  }

  /**
   * Shows a template
   * @param template The template to show
   */
  public show(template: TemplateRef<unknown>) {
    this.ref = this.dialog.open(DialogComponent, {
      data: {
        template: template,
        data: {},
      },
    });
  }

  /**
   * Closes dialog
   */
  public close() {
    this.ref.close();
  }
}
