import { Component, OnInit, TemplateRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { firstValueFrom } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { bsnValidator } from "../../../../validators/bsn";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT } from "../../../../../environments/environment";
import sha256 from "crypto-js/sha256";
import { postalCodeValidator } from "../../../../validators/postalCode";
import { EnergyConsultService } from "../../../../services/energy-consult.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ReplacementService } from "../../../../services/replacement.service";
import { EnergyConsult } from "../../../../classes/flow/request/EnergyConsult";
import { ApplicationService } from "../../../../services/application.service";
import { DialogService } from "../../../../services/dialog.service";
import { specialCharValidator } from "../../../../validators/specialChars";

@Component({
  selector: "app-replacement-claim-new",
  templateUrl: "./replacement-claim-new.component.html",
  styleUrls: ["./replacement-claim-new.component.less"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ReplacementClaimNewComponent implements OnInit {
  public form: VerificationControl;

  public bsnResponse?: { name: string; value: string }[];
  public bsnChecked: boolean;
  private energyConsult?: EnergyConsult;

  constructor(
    public dialogService: DialogService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private http: HttpClient,
    private energyConsultService: EnergyConsultService,
    private replacementService: ReplacementService,
    private applicationService: ApplicationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    // Initialize form
    this.form = this.fb.group({
      postalCode: this.fb.control<string | null>({ value: null, disabled: true }, [Validators.required, postalCodeValidator]),
      houseNumber: this.fb.control<number | null>({ value: null, disabled: true }, [Validators.required]),
      houseNumberSuffix: this.fb.control<string | null>({ value: null, disabled: true }),
      bsn: this.fb.control<string | null>(null, [Validators.required, bsnValidator]),
      rejectReason: this.fb.control<string | null>("", [Validators.required, specialCharValidator]),
    });

    this.bsnChecked = false;
  }

  async ngOnInit(): Promise<void> {
    this.applicationService.setLoading(true);
    const energyConsultId = this.route.snapshot.params["energyConsultId"];
    if (!energyConsultId) this.router.navigate(["/content"]);
    this.energyConsult = await this.energyConsultService.loadById(Number(energyConsultId));
    if (!this.energyConsult) this.router.navigate(["/content"]);

    this.form.controls.postalCode.patchValue(this.energyConsult.postalCode);
    this.form.controls.houseNumber.patchValue(this.energyConsult.houseNumber);
    this.form.controls.houseNumberSuffix.patchValue(this.energyConsult.houseNumberSuffix ?? null);
    this.applicationService.setLoading(false);
  }

  public getBSNHash(): string | undefined {
    const control = this.form.controls.bsn;
    const value = control.value;
    if (!value) return undefined;
    return sha256(value).toString();
  }

  async checkBSN() {
    this.applicationService.setLoading(true);
    const control = this.form.controls.bsn;
    const hash = this.getBSNHash();
    const response: { values?: { name: string; value: string }[] } = await firstValueFrom(this.http.post(`${ENVIRONMENT.API_URL}graphql/endpoints/identify?hID=${hash}`, {}));
    control.disable();
    this.bsnChecked = true;
    this.bsnResponse = response?.values?.length ? response.values : undefined;
    this.applicationService.setLoading(false);
  }

  async addClaim(identified: boolean) {
    if (!this.energyConsult) return;
    this.applicationService.setLoading(true);
    const claimId = await this.replacementService.addReplacementClaim({
      state: identified ? 2 : 1,
      postalCode: this.energyConsult.postalCode,
      houseNumber: this.energyConsult.houseNumber,
      houseNumberSuffix: this.energyConsult.houseNumberSuffix,
      residentId: this.energyConsult.resident?.id,
      coachId: this.applicationService.session.activeRole.name === "coach" ? this.applicationService.session.user?.id : undefined,
      bsn: identified ? this.getBSNHash() : this.form.controls.bsn.value ?? undefined,
    });

    if (claimId) {
      this.router.navigate(["/content/coach/replacement-claim/edit", claimId]);
    }
    this.applicationService.setLoading(false);
  }

  public openDialog(dialog: TemplateRef<unknown>) {
    this.dialogService.open({
      template: dialog,
    });
  }
  public closeDialog() {
    this.dialogService.close();
  }

  async addRejectedClaim(rejectReason: string) {
    if (!this.energyConsult) return;
    this.applicationService.setLoading(true);
    const claimId = await this.replacementService.addReplacementClaim({
      state: 7,
      postalCode: this.energyConsult.postalCode,
      houseNumber: this.energyConsult.houseNumber,
      houseNumberSuffix: this.energyConsult.houseNumberSuffix,
      residentId: this.energyConsult.resident?.id,
      coachId: this.applicationService.session.activeRole.name === "coach" ? this.applicationService.session.user?.id : undefined,
      rejectReason: rejectReason,
      bsn: this.getBSNHash() ?? undefined,
    });

    if (claimId) {
      this.router.navigate(["/content"]);
    }
    this.closeDialog();
    this.applicationService.setLoading(false);
  }
}

export type VerificationControl = FormGroup<{
  postalCode: FormControl<string | null>;
  houseNumber: FormControl<number | null>;
  houseNumberSuffix: FormControl<string | null>;
  bsn: FormControl<string | null>;
  rejectReason: FormControl<string | null>;
}>;
