export class ReplacementClaimOrdered {
  public claimId?: number;
  public claimStateId?: number;
  public claimStateName?: string;
  public postalCode?: string;
  public houseNumber?: number;
  public houseNumberSuffix?: string;
  public coachId?: number;
  public message?: string;

  public orderId?: number;
  public orderStateId?: number;
  public orderStateName?: string;
  public retailerId?: number;

  public deliveryId?: number;
  public deliveryStateId?: number;
  public deliveryStateName?: string;
  public plannedDate?: string;
  public plannedTime?: string;

  public typeName?: string;
  public categoryName?: string;

  public residentId?: number;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public phoneNumber?: string;

  constructor(replacementClaimOrdered: {
    id?: number;
    claimStateId?: number;
    claimState?: string;
    postalCode?: string;
    houseNumber?: number;
    houseNumberSuffix?: string;
    coachID?: number;
    message?: string;
    orderId?: number;
    orderStateId?: number;
    orderState?: string;
    retailerId?: number;
    deliveryId?: number;
    deliveryStateId?: number;
    deliveryState?: string;
    datGepland?: string;
    tijGepland?: string;
    typeName?: string;
    categoryName?: string;
    residentId?: number;
    firstName?: string;
    lastName?: string;
    residentEmailZakelijk?: string;
    residentTelefoonnrZakelijk?: string;
  }) {
    this.claimId = replacementClaimOrdered.id;
    this.claimStateId = replacementClaimOrdered.claimStateId;
    this.claimStateName = replacementClaimOrdered.claimState;
    this.postalCode = replacementClaimOrdered.postalCode;
    this.houseNumber = replacementClaimOrdered.houseNumber;
    this.houseNumberSuffix = replacementClaimOrdered.houseNumberSuffix;
    this.coachId = replacementClaimOrdered.coachID;
    this.message = replacementClaimOrdered.message;
    this.orderId = replacementClaimOrdered.orderId;
    this.orderStateId = replacementClaimOrdered.orderStateId;
    this.orderStateName = replacementClaimOrdered.orderState;
    this.retailerId = replacementClaimOrdered.retailerId;
    this.deliveryId = replacementClaimOrdered.deliveryId;
    this.deliveryStateId = replacementClaimOrdered.deliveryStateId;
    this.deliveryStateName = replacementClaimOrdered.deliveryState;
    this.plannedDate = replacementClaimOrdered.datGepland;
    this.plannedTime = replacementClaimOrdered.tijGepland;
    this.typeName = replacementClaimOrdered.typeName;
    this.categoryName = replacementClaimOrdered.categoryName;
    this.residentId = replacementClaimOrdered.residentId;
    this.firstName = replacementClaimOrdered.firstName;
    this.lastName = replacementClaimOrdered.lastName;
    this.email = replacementClaimOrdered.residentEmailZakelijk;
    this.phoneNumber = replacementClaimOrdered.residentTelefoonnrZakelijk;
  }
}
