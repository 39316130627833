import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface FilterGroup<T> {
  name: keyof T;
  description: string;
  filters: Filter<T>[];
  activeFilters?: Filter<T>[];
  requestAmount?: number;
}

export class Filter<T> {
  public amount = 0;

  constructor(public id: number, public title: string, public filterFunction: (arg: T) => boolean) {}

  public setAmount(entities: T[]) {
    this.amount = entities.filter(this.filterFunction).length;
  }
}

@Component({
  selector: "app-badge-filters",
  templateUrl: "./badge-filters.html",
  styleUrls: ["./badge-filters.less"],
})
export class BadgeFiltersComponent {
  @Input("filters")
  public filters: FilterGroup<any>[] = [];

  @Input("tooltip")
  public tooltip?: string;

  @Input("labels")
  public labels?: string[];

  @Output()
  public filter = new EventEmitter();

  /**
   * Filters the requests based on activeFilters
   */
  public activateFilter(filter: Filter<any>, filterGroup: FilterGroup<any>) {
    const filterExists = filterGroup.activeFilters?.find((compareFilter) => compareFilter?.id === filter.id);
    if (filterExists) {
      filterGroup.activeFilters = filterGroup.activeFilters?.filter((filter) => filter !== filterExists);
    } else {
      filterGroup.activeFilters?.push(filter);
    }
    this.filter.emit();
  }
}
