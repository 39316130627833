import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import Chart, { ChartConfiguration, ChartConfigurationCustomTypesPerDataset } from "chart.js/auto";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.less"],
})
export class ChartComponent implements AfterViewInit {
  @ViewChild("chartElement")
  public chartElement!: ElementRef<HTMLCanvasElement>;

  @Input("data")
  public data!: (ChartConfiguration<"doughnut"> | ChartConfigurationCustomTypesPerDataset<"doughnut">)["data"];

  @Input("dimensions")
  public dimensions: { width: number; height: number };

  public chart?: Chart;

  constructor() {
    this.dimensions = { width: 160, height: 160 };
  }

  ngAfterViewInit(): void {
    const context = this.chartElement.nativeElement.getContext("2d");
    if (!context) return;
    this.chart = new Chart(context, {
      type: "doughnut",
      data: this.data,
      options: { responsive: true, maintainAspectRatio: true, events: [], plugins: { legend: { labels: { usePointStyle: true } } } },
    });
  }
}
