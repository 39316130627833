import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../services/application.service";
import { UserService } from "../../services/user.service";

export interface MenuItem {
  href?: string;
  route: string;
  label: string;
  icon: string;
  index: number;
}

@Component({
  selector: "app-menu",
  templateUrl: "./menu.html",
  styleUrls: ["./menu.less"],
})
export class MenuComponent implements OnChanges {
  public active = false;

  @Input("items")
  public items: MenuItem[] = [];
  @ViewChild("ulContainer", { static: false }) ulContainer: ElementRef<HTMLUListElement> | null = null;
  @ViewChild("closeButton") closeButton: ElementRef<HTMLAnchorElement> | null = null;
  @ViewChild("menuButton") menuButton: ElementRef<HTMLAnchorElement> | null = null;
  public constructor(public readonly applicationService: ApplicationService, public readonly userService: UserService, private readonly router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      this.ulContainer && this.ulContainer.nativeElement.getElementsByTagName("a")[0]?.focus();
    }
    this.closeButton && this.closeButton.nativeElement.addEventListener("keydown", (e) => this.focusHandler(e));
  }

  /**
   * Hides the menu
   */
  public hide(e?: KeyboardEvent) {
    if (e && !this.checkKeyEscapeEnter(e)) return;
    this.active = false;
    this.menuButton && this.menuButton.nativeElement.focus();
  }
  checkKeyEnter(e: KeyboardEvent) {
    if (e.key === "Enter") return true;
    return false;
  }
  checkKeyEscapeEnter(e: KeyboardEvent) {
    if (e.key === "Enter") return true;
    if (e.key === "Escape") return true;
    return false;
  }

  /**
   * Shows the menu
   */
  public show() {
    this.active = true;
    setTimeout(() => {
      if (!this.ulContainer) return;
      this.ulContainer.nativeElement.getElementsByTagName("a")[0].focus();
      this.ulContainer.nativeElement.addEventListener("keydown", (e) => this.focusHandler(e));
    }, 150);
  }

  /**
   * Navigates to a link.
   */
  public navigate(link: string, e?: KeyboardEvent) {
    if (e && !this.checkKeyEnter(e)) return;
    if (link) {
      if (link.includes("http")) {
        window.open(link, "_blank");
      } else {
        this.router.navigate([link]).then(() => {
          this.active = false;
        });
      }
    }
  }

  public focusHandler(e: KeyboardEvent) {
    const currentTabIndex = Number((e.target as HTMLAnchorElement).getAttribute("tabindex"));
    if (e.key === "Escape") {
      this.hide(e);
      return;
    }
    if (!this.closeButton) return;
    if (e.key === "Tab" && currentTabIndex === this.items.length + 1) {
      this.closeButton.nativeElement.focus();
    }
    if (!this.closeButton) return;
    if (e.shiftKey && e.key === "Tab" && currentTabIndex === 1) {
      this.closeButton.nativeElement.focus();
      setTimeout(() => {
        this.closeButton && this.closeButton.nativeElement.focus();
      }, 0);
    } else if (!e.shiftKey && e.key === "Tab" && currentTabIndex > this.items.length) {
      setTimeout(() => {
        this.ulContainer && this.ulContainer.nativeElement.getElementsByTagName("a")[0].focus();
      }, 0);
    }
  }
}
