import { Component, Input } from "@angular/core";
import { FormGroup, FormGroupDirective } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TimepickerDialogComponent } from "../timepicker-dialog/timepicker-dialog.component";
@Component({
  selector: "app-custom-timepicker",
  templateUrl: "./custom-timepicker.component.html",
  styleUrls: ["./custom-timepicker.component.less"],
})
export class CustomTimepickerComponent {
  @Input("givenFormGroup") form!: FormGroup;
  @Input("givenFormControlName") givenFormControlName!: string;

  constructor(public dialog: MatDialog, private rootFormGroup: FormGroupDirective) {}

  openTimePicker(): void {
    const dialogRef = this.dialog.open(TimepickerDialogComponent, {
      width: "300px",
      height: "500px",
    });
    const instance = dialogRef.componentInstance;
    instance.givenFormGroup = this.form;
    instance.givenFormControlName = this.givenFormControlName;
  }
}
