import { Entity } from "../base/Entity";

export enum RequestStates {
  NEW = "New",
  PICKED = "Picked",
  DATE = "Date",
  DONE = "Done",
  PENDING_DELETED = "PendingDeleted",
  CANCELED = "Canceled",
  PENDING_REJECT = "PendingReject",
  FILLED_IN = "FilledIn",
  DISAPPROVED = "Disapproved",
}

export interface RequestState extends Entity {
  name: RequestStates;
}
