import { Injectable } from "@angular/core";
import { Answer } from "../classes/flow/Questionnaire/Answer";
import { OperatorType } from "../classes/flow/Questionnaire/OperatorType";
import { QuestionType } from "../classes/flow/Questionnaire/QuestionType";
import { Validation } from "../classes/flow/Questionnaire/Validation";
import { GraphQLService } from "./graphql.service";
import { Option } from "../classes/flow/Questionnaire/Option";
import { Question } from "../classes/flow/Questionnaire/Question";
import { Entity } from "../classes/flow/base/Entity";

@Injectable({
  providedIn: "root",
})
export class QuestionService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  /**
   * Inserts an answer for a question of a specific energy consult
   * @param answer The given answer
   */
  public async insertAnswerForQuestion(answer: Answer) {
    let result: any = await this.graphqlService.query(
      `mutation {
        addQuestionAnswer(questionAnswer: {
          questionId: ${answer.questionId}
          energyConsultId: ${answer.energyConsultId}
          answer: "${answer.answer}"
        }) {
          value {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
    result = result.data.addQuestionAnswer.value;
    answer.changes = this.graphqlService.createChangesObject(result.changes);
    answer.id = result.id;
  }

  /**
   * Updates an answer for a question of a specific energy consult
   * @param answer The given answer
   */
  public async updateAnswerForQuestion(answer: Answer) {
    const result = await this.graphqlService.query(
      `mutation {
        updateQuestionAnswer(input: {
          id: ${answer.id}
          set: {
            answer: "${answer.answer}"
            changes: ${this.graphqlService.formatChangesObject(answer)}
          }
        }) {
          value {
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );

    answer.changes = this.graphqlService.createChangesObject(result.data.updateQuestionAnswer.value.changes);
  }

  /**
   * Delete all unused answers of the given reports for the specific energy consult
   * @param questionIds The id's of the questions
   * @param energyConsultId The id of the energy consult
   */
  public async deleteAllUnusedAnswers(questionIds: number[], energyConsultId: number) {
    if (!questionIds.length) return;
    await this.graphqlService.query(
      `mutation {
        deleteAllUnusedAnswers(energyConsultId: ${energyConsultId}, questionIds: [${questionIds}]) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`
    );
  }

  public async getAllQuestionTypes(): Promise<QuestionType[]> {
    const res = await this.graphqlService.query(
      `query {
        questionTypes {
          value {
            id
            name
            prefix
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }
    `
    );

    return res.data.questionTypes.value.map((questionType: any) => {
      return {
        id: questionType.id,
        name: questionType.name,
        prefix: questionType.prefix,
      };
    });
  }

  public async getAllOperatorTypes(): Promise<OperatorType[]> {
    const res = await this.graphqlService.query(
      `query {
        operatorTypes {
          value {
            id
            name
            operation
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
          }
        }
        messages{
          message
        }
      }
}`
    );

    return res.data.operatorTypes.value.map((operatorType: any) => {
      return {
        id: operatorType.id,
        name: operatorType.name,
        operation: operatorType.operation,
      };
    });
  }

  public async getAllValidations(): Promise<Validation[]> {
    const res = await this.graphqlService.query(
      `query {
        validations {
          value {
            id
            name
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }
`
    );

    return res.data.validations.value.map((validation: any) => {
      return {
        id: validation.id,
        name: validation.name,
      };
    });
  }

  // public async insertQuestion() {

  // }

  public async addQuestionOption(option: Option): Promise<Option> {
    const query = `
    mutation {
      addQuestionOption(questionOption: {
        questionId: ${option.questionId},
        questionReference: "${option.questionReference}",
        value: "${option.value}"
      }) {
        value {
          id,
          value,
          questionId,
          changes {
            fullDetails {
              key
              value {
                userId
                time
              }
            }
            lastChange {
              userId
              time
            }
          }
        }
      }
    }
    `;

    const result = await this.graphqlService.query(query);
    return {
      id: result.data.addQuestionOption.value.id,
      value: result.data.addQuestionOption.value.value,
      questionId: result.data.addQuestionOption.value.questionId,
      changes: this.graphqlService.createChangesObject(result.data.addQuestionOption.value.changes),
    };
  }

  public async updateQuestionOption(option: Option): Promise<Option> {
    const query = `
    mutation {
      updateQuestionOption(input: {
        id: ${option.id}
        set: {
          value: "${option.value}",
          questionId: ${option.questionId},
          changes: ${this.graphqlService.formatChangesObject(option)}
        }
      }) {
        value {
          id
          value
          questionId
          changes {
            fullDetails {
              key
              value {
                userId
                time
              }
            }
            lastChange {
              userId
              time
            }
          }
        }
      }
    }
    `;

    const result = await this.graphqlService.query(query);
    return {
      id: result.data.updateQuestionOption.value.id,
      value: result.data.updateQuestionOption.value.value,
      questionId: result.data.updateQuestionOption.value.questionId,
      changes: this.graphqlService.createChangesObject(result.data.updateQuestionOption.value.changes),
    };
  }

  public async deleteQuestionOption(id: number) {
    const query = `
    mutation {
      deleteQuestionOption(questionOptionId: ${id}) {
        value {
          id
        }
        messages {
          message
        }
      }
    }
    `;

    this.graphqlService.query(query);
  }

  public async addQuestion(question: Question, questionnaireId: number): Promise<Entity> {
    const extraProperties = JSON.stringify(question.extraProperties) !== "{}" ? JSON.stringify(question.extraProperties) : null;
    const query = `
    mutation {
      addQuestion(
        question: {
          active: ${question.active}
          description: "${question.description}"
          index: "${question.index}"
          name: "${question.name}"
          placeholder: "${question.placeholder}"
          questionnaireId: ${questionnaireId}
          questionTypeId: ${question.questionType.id}
          required: ${question.required}
          ${question.validation?.id ? `validationId: ${question.validation.id}` : ""}
          ${extraProperties ? `extraProperties: ${this.graphqlService.hasuraArrayObjectCleaner(extraProperties)}` : `extraProperties: ${null}`}
          ${question.logic?.operatorType?.id ? `operatorTypeId: ${question.logic.operatorType.id}` : ""}
          ${question.logic?.parentQuestionId ? `parentQuestionId: ${question.logic.parentQuestionId}` : ""}
          ${question.logic?.compareAnswer ? `compareAnswer: "${question.logic.compareAnswer}"` : ""}
          ${question.logic?.compareOptionId ? `compareOptionId: ${question.logic.compareOptionId}` : ""}
          ${question.logic?.compareOptionReference ? `compareOptionReference: "${question.logic.compareOptionReference}"` : ""}
        }
      ) {
        value {
          id
          changes {
            fullDetails {
              key
              value {
                userId
                time
              }
            }
            lastChange {
              userId
              time
            }
          }
        }
      }
    }
    `;

    const result = await this.graphqlService.query(query);
    const insertedQuestion = result.data.addQuestion.value;

    return {
      id: insertedQuestion.id,
      changes: this.graphqlService.createChangesObject(insertedQuestion.changes),
    };
  }

  public async updateQuestion(question: Question): Promise<Entity> {
    const extraProperties = JSON.stringify(question.extraProperties) !== "{}" ? JSON.stringify(question.extraProperties) : null;
    const query = `
    mutation {
      updateQuestion(
        input: {
          id: ${question.id}
          set: {
            active: ${question.active}
            ${question.logic?.compareAnswer ? `compareAnswer: "${question.logic.compareAnswer}"` : ""}
            ${question.logic?.compareOptionId ? `compareOptionId: ${question.logic.compareOptionId}` : ""}
            description: "${question.description}"
            ${extraProperties ? `extraProperties: ${this.graphqlService.hasuraArrayObjectCleaner(extraProperties)}` : `extraProperties: ${null}`}
            index: "${question.index}"
            name: "${question.name}"
            ${question.logic?.operatorType?.id ? `operatorTypeId: ${question.logic.operatorType.id}` : ""}
            ${question.logic?.parentQuestionId ? `parentQuestionId: ${question.logic.parentQuestionId}` : ""}
            placeholder: "${question.placeholder}"
            questionTypeId: ${question.questionType.id}
            required: ${question.required}
            ${question.validation?.id ? `validationId: ${question.validation.id}` : "validationId: null"}
            changes: ${this.graphqlService.formatChangesObject(question)}
          }
        }
      ) {
        value {
          id
          changes {
            fullDetails {
              key
              value {
                userId
                time
              }
            }
            lastChange {
              userId
              time
            }
          }
        }
        messages {
          message
        }
      }
    }
    `;
    const result = await this.graphqlService.query(query);
    const updatedQuestion = result.data.updateQuestion.value;

    return {
      id: updatedQuestion.id,
      changes: this.graphqlService.createChangesObject(updatedQuestion.changes),
    };
  }

  public async deleteQuestion(id: number) {
    const query = `
    mutation {
      deleteQuestion(questionId: ${id}) {
        value {
          id
        }
        messages {
          message
        }
      }
    }
    `;

    await this.graphqlService.query(query);
  }
}
