<ng-container *ngFor="let state of getStateKeys()">
  <div *ngIf="!isStateEmpty(state) && value[state].show" class="mt-3">
    <hr />
    <h5 class="">{{ "COMPONENTS.TILE.STATUSES." + state | translate }}</h5>
    <ng-container *ngFor="let specialty of getSpecialtyKeys(state)">
      <div *ngIf="getConsults(state, specialty).length > 0 && value[state].value[specialty]?.show" class="m-2">
        <h6 class="d-flex align-items-center mt-2"><mat-icon>chevron_right</mat-icon> {{ specialty }}</h6>
        <app-table #requestTable id="REQUESTS" [data]="getConsults(state, specialty)" [pageSizes]="[10, 20, 50, 100]" [headers]="energyConsultListHeaders"></app-table>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #noteColumnTemplate let-data="data">
  <mat-icon
    *ngIf="data.extraProperties?.note?.length > 0"
    [matTooltip]="
      (data.message.length > 0 ? ('COMPONENTS.DASHBOARD.NOTE_TOOLTIP_MESSAGE' | translate) + ': \n' + data.message + '\n' : '') +
      (data.extraProperties.note.length > 0 ? ('TABLES.REQUESTS.NOTE' | translate) + ': \n' + data.extraProperties.note : '')
    "
    matTooltipClass="multiline-tooltip"
  >
    message</mat-icon
  >
</ng-template>
