import { Component, Input, OnInit } from "@angular/core";
import { ApplicationService } from "../../services/application.service";
import { ReplacementService } from "../../services/replacement.service";
import { Header } from "../table/Header";
import { Router } from "@angular/router";
import { ReplacementClaimOrdered } from "../../classes/flow/replacementClaim/ReplacementClaimOrdered";
import { ReplacementClaim } from "../../classes/flow/replacementClaim/ReplacementClaim";
import { MatDialog } from "@angular/material/dialog";
import { ReplacementClaimRejectFormComponent } from "../replacement-claim-reject-form/replacement-claim-reject-form.component";
import { reverseDate } from "../../helpers/convertDate";
import { LocalDatePipe } from "../../pipes/date.pipe";

@Component({
  selector: "app-replacement-claim-overview",
  templateUrl: "./replacement-claim-overview.component.html",
  styleUrls: ["./replacement-claim-overview.component.less"],
})
export class ReplacementClaimOverviewComponent implements OnInit {
  @Input() filter = "owned";
  @Input() pageTitle = "Vervangings aanvragen";
  @Input() search = false;

  replacements: ReplacementClaimOrdered[] | ReplacementClaim[] = [];
  columns: Header[] = [];
  constructor(
    public localDatePipe: LocalDatePipe,
    public replacementService: ReplacementService,
    public applicationService: ApplicationService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.replacementService.claimsBehaviorSubject.subscribe(() => {
      this.setTableDate(this.filter);
    });
    this.retrieveReplacementClaims(this.filter);
  }

  retrieveReplacementClaims(filter: string) {
    this.applicationService.setLoading(true);
    switch (filter) {
      case "open":
        this.setReplacementClaimsOpen();
        this.columns = [
          { visualName: "CLAIMSTATENAME", status: true, mappedTo: "claimStateName", columnStyles: { width: "100px", class: "col-3" } },
          { visualName: "POSTALCODE", mappedTo: "postalCode", columnStyles: { width: "80px", class: "col-3" } },
          { visualName: "CATEGORY", resolver: this.stripApplianceCategory, columnStyles: { width: "200px", class: "col-12" } },
        ];
        break;
      case "rejectable":
        this.setReplacementClaimsOpen();
        this.columns = [
          { visualName: "CLAIMSTATENAME", status: true, mappedTo: "claimStateName", columnStyles: { width: "100px", class: "col-3" } },
          { visualName: "POSTALCODE", mappedTo: "postalCode", columnStyles: { width: "80px", class: "col-3" } },
          { visualName: "CATEGORY", resolver: this.stripApplianceCategory, columnStyles: { width: "200px", class: "col-12" } },
          {
            visualName: "ACTIONS",
            buttons: [{ key: "reject-claim", icon: "chevron_right", color: "", clickHandler: this.cancelClaim, label: "TABLES.REPLACEMENT_OVERVIEW.REJECT" }],
            columnStyles: { align: "right", class: "col-12", width: "350px" },
          },
        ];
        break;
      case "owned":
        this.setReplacementClaimsOwned();
        this.columns = [
          { visualName: "CLAIMSTATENAME", status: true, mappedTo: "claimStateName", columnStyles: { width: "100px", class: "col-3" } },
          { visualName: "POSTALCODE", mappedTo: "postalCode", columnStyles: { width: "80px", class: "col-3" } },
          { visualName: "APPLIANCE", resolver: this.displayCategoryAndType, columnStyles: { width: "200px", class: "col-12" } },
          { visualName: "DELIVERY_SCHEDULE", resolver: this.parsePlannedTime, columnStyles: { width: "200px", class: "col-12" } },
          {
            visualName: "ACTIONS",
            buttons: [
              { key: "schedule", icon: "schedule", color: "", clickHandler: this.scheduleReplacementClaim, label: "TABLES.REPLACEMENT_OVERVIEW.PLAN" },
              { key: "deliver", icon: "local_shipping", color: "", clickHandler: this.deliverReplacementClaim, label: "TABLES.REPLACEMENT_OVERVIEW.LEVEREN" },
              { key: "reject-order", icon: "clear", color: "warn", clickHandler: this.rejectReplacementClaimOrder, label: "TABLES.REPLACEMENT_OVERVIEW.REJECT" },
              { key: "reject-delivery", icon: "clear", color: "warn", clickHandler: this.rejectReplacementClaimDelivery, label: "TABLES.REPLACEMENT_OVERVIEW.REJECT" },
            ],
            columnStyles: { align: "end", class: "col-12", width: "350px" },
          },
        ];
        break;
      default:
        break;
    }
  }

  parsePlannedTime: (replacementClaim: ReplacementClaimOrdered) => string = (replacementClaim: ReplacementClaimOrdered) => {
    if (replacementClaim.claimStateId == 5) {
      return this.localDatePipe.transform(reverseDate(replacementClaim.plannedTime!), "d MMMM y, HH:mm");
    }
    return "-";
  };

  stripApplianceCategory: (replacementClaim: ReplacementClaim) => string = (replacementClaim: ReplacementClaim) => {
    return replacementClaim?.applianceCategory?.replaceAll("{", "").replaceAll("}", "") ?? "";
  };

  displayCategoryAndType: (replacementClaim: ReplacementClaimOrdered) => string = (replacementClaim: ReplacementClaimOrdered) => {
    return `${replacementClaim.categoryName} (${replacementClaim.typeName})`;
  };

  setTableDate(filter: string) {
    switch (filter) {
      case "open":
        this.replacements = this.replacementService.claims.replacementClaimsOpen;
        break;
      case "rejectable":
        this.replacements = this.replacementService.claims.replacementClaimsOpen;
        break;
      case "owned":
        this.replacements = this.replacementService.claims.replacementClaimsOwned;
        break;
      default:
        break;
    }
  }

  async setReplacementClaimsOpen() {
    await this.replacementService.queryReplacementClaimsOpen();
    this.applicationService.setLoading(false);
  }

  async setReplacementClaimsOwned() {
    await this.replacementService.queryReplacementClaimsOwned(this.applicationService.session.user!);
    this.applicationService.setLoading(false);
  }

  cancelClaim: (replacementClaim: ReplacementClaim) => void = async (replacementClaim: ReplacementClaim) => {
    this.dialog.open(ReplacementClaimRejectFormComponent, { data: { claim: replacementClaim, type: "claim" } });
  };

  scheduleReplacementClaim: (replacementClaim: ReplacementClaimOrdered) => void = async (replacementClaim: ReplacementClaimOrdered) => {
    this.router.navigate(["/content/retailer/replacement-claim", replacementClaim.claimId, "schedule", replacementClaim.orderId]);
  };

  deliverReplacementClaim: (replacementClaim: ReplacementClaimOrdered) => void = async (replacementClaim: ReplacementClaimOrdered) => {
    this.router.navigate(["/content/retailer/replacement-claim", replacementClaim.claimId, "deliver", replacementClaim.deliveryId]);
  };

  rejectReplacementClaimOrder: (replacementClaim: ReplacementClaimOrdered) => void = async (replacementClaim: ReplacementClaimOrdered) => {
    this.dialog.open(ReplacementClaimRejectFormComponent, { data: { order: replacementClaim, type: "order" } });
  };

  rejectReplacementClaimDelivery: (replacementClaim: ReplacementClaimOrdered) => void = async (replacementClaim: ReplacementClaimOrdered) => {
    this.dialog.open(ReplacementClaimRejectFormComponent, { data: { delivery: replacementClaim, type: "delivery" } });
  };
}
