import { AfterContentInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { ApplicationService } from "../../../services/application.service";

@Component({
  selector: "app-consult-grid-view",
  templateUrl: "./consult-grid-view.component.html",
  styleUrls: ["./consult-grid-view.component.less"],
})
export class ConsultGridViewComponent implements AfterContentInit {
  @Input("filteredRequests") set filteredRequestsSetter(filteredRequests: EnergyConsult[]) {
    this.filteredRequests = filteredRequests;
    if (this.scrollContainer) this.scrollContainer.nativeElement.scrollTo({ behavior: "smooth", top: 0, left: 0 }); //smooth
    this.shownRequestsStepper.stepsCounter = 1;
    this.showRequests = this.filteredRequests.slice(0, this.shownRequestsStepper.stepSize * this.shownRequestsStepper.stepsCounter);
  }

  @ViewChild("scrollContainer") scrollContainer?: ElementRef;

  filteredRequests: EnergyConsult[] = [];

  public showRequests: EnergyConsult[] = [];

  public shownRequestsStepper = {
    stepSize: 18,
    stepsCounter: 1,
  };
  public submitRequest = false;

  constructor(public readonly applicationService: ApplicationService) {}

  ngAfterContentInit(): void {
    this.submitRequest = this.applicationService.session.activeRole.name === "resident";
    return;
  }

  public showMoreConsults(event: Event) {
    const div = event.target as HTMLDivElement;

    const h = ((div.scrollTop + div.offsetHeight) / div.scrollHeight) * 100;

    if (h > 90) {
      this.shownRequestsStepper.stepsCounter++;
      this.showRequests = this.filteredRequests.slice(0, this.shownRequestsStepper.stepSize * this.shownRequestsStepper.stepsCounter);
    }
  }

  public resetAfterSort() {
    this.showRequests = this.filteredRequests.slice(0, this.shownRequestsStepper.stepSize * this.shownRequestsStepper.stepsCounter);
  }

  public trackByECId(index: number, ec: EnergyConsult) {
    return ec.id;
  }
}
