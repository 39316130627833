import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { RouteData } from "../app-routing.module";
import { ApplicationService } from "../services/application.service";
import { UserService } from "../services/user.service";

@Injectable({
  providedIn: "root",
})
export class SpecialtyGuard {
  constructor(private readonly router: Router, private readonly applicationService: ApplicationService, private readonly userService: UserService) {}

  public async canActivate(routeSnapshot: ActivatedRouteSnapshot) {
    return await this.checkForRequiredSpecialties(routeSnapshot);
  }

  public async canActivateChild(routeSnapshot: ActivatedRouteSnapshot) {
    return await this.checkForRequiredSpecialties(routeSnapshot);
  }

  private async checkForRequiredSpecialties(routeSnapshot: ActivatedRouteSnapshot) {
    //TODO more options for when to activate (difference between having specialty 1 or 11)
    await this.applicationService.initialize();
    const data = routeSnapshot.data as RouteData;

    const userSpecialties = this.applicationService.session.userSpecialties.map((sp) => sp.id);

    switch (this.compareArrays(data.allowedSpecialties!, userSpecialties)) {
      case "partial":
      case "full":
        return true;
        break;

      default:
        break;
    }

    await this.router.navigate(data.noAuthRedirectURL ? [data.noAuthRedirectURL] : ["error/403"]);
    return false;
  }

  /**
   *
   * @param A
   * @param B
   * @returns
   */
  private compareArrays(A: unknown[], B: unknown[]): "disjoint" | "partial" | "full" {
    const matches: boolean[] = [];
    for (const AE of A) {
      matches.push(B.includes(AE));
    }

    if (A.length === B.length && matches.every((bool) => bool)) return "full";

    if (matches.some((bool) => bool)) return "partial";

    return "disjoint";
  }
}
