<div id="profileContainer" class="container">
  <div class="row">
    <div id="actionsContainer" class="col-md">
      <span>
        <button *ngIf="applicationService.session.activeRole.name === 'coordinator'" mat-flat-button (click)="pageBack()">
          <mat-icon>arrow_back_ios_new</mat-icon>
          <span>{{ "PAGES.BACK" | translate }}</span>
        </button>
      </span>
    </div>

    <div class="col-md text-end" *ngIf="user.roles.length > 1">
      <mat-form-field>
        <mat-label>{{ (applicationService.session.user?.userId === user.userId ? "COMPONENTS.PROFILE.SELECTED_ROLE" : "COMPONENTS.PROFILE.VIEW_AS") | translate }}</mat-label>
        <mat-select [compareWith]="compareEntities" [value]="viewMode" (selectionChange)="changeRole($event)">
          <mat-option *ngFor="let i of user.roles" [value]="i">{{ "COMPONENTS.PROFILE.ROLES." + i.name.toUpperCase() | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md" *ngIf="user.status !== 'Deleted'; else deleted">
      <ng-container *ngIf="user.status !== 'PendingDeleted'; else pendingDeleted">
        <div id="infoContainer" class="row">
          <div class="col-md">
            <div class="row">
              <div class="col-md">
                <span class="title">{{ checkUsername() }}</span>
              </div>
            </div>

            <div class="row" *ngIf="user">
              <div class="col-md card">
                <ng-container [ngTemplateOutlet]="cardrow" [ngTemplateOutletContext]="{ icon: 'badge', title: 'FIRSTNAME', data: user.firstName }"> </ng-container>
                <ng-container [ngTemplateOutlet]="cardrow" [ngTemplateOutletContext]="{ icon: 'badge', title: 'LASTNAME', data: user.lastName }"> </ng-container>
                <ng-container [ngTemplateOutlet]="cardrow" [ngTemplateOutletContext]="{ icon: 'mail', title: 'EMAIL', data: user.email }"> </ng-container>
                <ng-container *ngIf="viewMode?.name === 'resident'">
                  <ng-container
                    [ngTemplateOutlet]="cardrow"
                    [ngTemplateOutletContext]="{ icon: 'call', title: 'PHONE', data: getResident()?.phoneNumber ?? ('COMPONENTS.PROFILE.NO_PHONE' | translate) }"
                  ></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="cardrow"
                    [ngTemplateOutletContext]="{
                      icon: 'maps_home_work',
                      title: 'POSTAL_CODE',
                      data: getResident()!.postalCode ?? ('COMPONENTS.PROFILE.NO_POSTAL_CODE' | translate)
                    }"
                  ></ng-container>
                  <ng-container
                    [ngTemplateOutlet]="cardrow"
                    [ngTemplateOutletContext]="{
                      icon: 'maps_home_work',
                      title: 'HOUSE_NUMBER',
                      data: getFormattedHouseNumber ?? ('COMPONENTS.PROFILE.NO_HOUSE_NUMBER' | translate)
                    }"
                  ></ng-container>
                </ng-container>
                <ng-container
                  [ngTemplateOutlet]="cardrow"
                  [ngTemplateOutletContext]="{
                    icon: 'verified',
                    title: 'STATUS',
                    data: user.status ? ('COMPONENTS.PROFILE.STATUSES.' + user.status.toUpperCase() | translate) : ''
                  }"
                ></ng-container>
                <ng-container
                  *ngIf="viewMode?.name === 'coach'"
                  [ngTemplateOutlet]="cardrow"
                  [ngTemplateOutletContext]="{
                    icon: 'mark_email_read',
                    title: 'EMAIL_SUBSCRIPTION',
                    data: getCoach()?.emailSubscription === true ? ('COMPONENTS.PROFILE.EMAIL_SUBBED' | translate) : ('COMPONENTS.PROFILE.EMAIL_NOT_SUBBED' | translate)
                  }"
                ></ng-container>
                <div class="row actionsContainer" style="flex-wrap: nowrap">
                  <div class="col">
                    <button mat-flat-button (click)="openDialog(editInfo)">
                      <span [matTooltip]="'COMPONENTS.PROFILE.ALTER_PROFILE' | translate">{{ "FORMS.EDIT" | translate }}</span>
                    </button>
                  </div>

                  <div class="col d-flex justify-content-end align-self-center">
                    <button mat-flat-button class="deleteButton" color="warn" (click)="openDialog(delete)">
                      <span [matTooltip]="'COMPONENTS.PROFILE.DELETE_PROFILE' | translate">{{ "FORMS.DELETE" | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md">
            <div class="row">
              <div class="col-md">
                <span class="title">{{ "COMPONENTS.PROFILE.ROLES.GENERIC_DENOTATION" | translate }}</span>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-md card">
                    <ng-container *ngFor="let i of user.roles">
                      <ng-container
                        [ngTemplateOutlet]="cardrow"
                        [ngTemplateOutletContext]="{ icon: 'supervisor_account', title: 'NONE', data: 'COMPONENTS.PROFILE.ROLES.' + i.name.toUpperCase() | translate }"
                      >
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <ng-container *appExcludeModule="'HIDE_SPECIALTIES'">
                  <div class="row" *ngIf="viewMode?.name === 'coach'">
                    <div class="col-md">
                      <span class="title">{{ "COMPONENTS.PROFILE.SPECIALTIES" | translate }}</span>
                    </div>
                  </div>
                  <div class="row" *ngIf="viewMode?.name === 'coach'">
                    <div class="col card">
                      <ul>
                        <li class="mb-1" *ngFor="let specialty of activeSpecialties">{{ specialty.specialty.name }}</li>
                      </ul>
                      <div>
                        <button mat-flat-button *appIsRole="'coordinator'" (click)="openDialog(editSpecialtiesDialog)">
                          {{ "COMPONENTS.PROFILE.EDIT_SPECIALTIES" | translate : { firstName: user.firstName } }}
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <ng-container *ngIf="getResident()?.phoneNumber && getResident()?.houseNumber && getResident()?.postalCode">
              <div class="row" *ngIf="viewMode?.name === 'resident'">
                <div class="col-md-12 col-lg-6 editContainer" *ngIf="applicationService.session.activeRole.name === 'coordinator'">
                  <div class="row">
                    <div class="col-md action">
                      <div class="row">
                        <div class="row">
                          <div class="col-md">
                            <mat-icon style="padding-top: 5px">swap_horiz</mat-icon>
                            <label style="margin-bottom: 12px">{{ "FORMS.NEW_ENERGY_CONSULT_EXISTING_RESIDENT.LABEL" | translate }}</label>
                          </div>
                        </div>
                        <div class="col-md" *ngIf="applicationService.session.activeRole.name === 'coordinator'">
                          <button
                            mat-flat-button
                            class="btn-block primary"
                            [routerLink]="['/content/coordinator/new-energy-consult-existing-resident/', this.route.snapshot.paramMap.get('id')]"
                            id="coachVisit"
                          >
                            <span>{{ "FORMS.NEW_ENERGY_CONSULT_EXISTING_RESIDENT.BUTTON" | translate }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div id="requestContainer" class="row" *ngIf="shouldIncludeRequests">
          <div class="col-md" id="profileFilter">
            <div class="col-md" id="profileFilter">
              <div class="row d-flex justify-content-between">
                <div class="w-auto justify-content-center align-self-center">
                  <span class="w-auto title">{{ "COMPONENTS.PROFILE.REQUESTS_TITLE." + viewMode!.name.toUpperCase() | translate }}</span>
                </div>

                <div class="col-md" *ngIf="requests.length; else noRequests">
                  <app-badge-filters [filters]="filters" (filter)="filterRequests()"></app-badge-filters>
                </div>
              </div>

              <div class="row">
                <ng-container *ngIf="requests.length; else nodata">
                  <div class="col-md-12">
                    <app-consult-grid-view [filteredRequests]="filteredRequests"></app-consult-grid-view>
                  </div>
                </ng-container>
                <ng-template #nodata>
                  <p>{{ "COMPONENTS.PROFILE.NO_REQUESTS" | translate }}</p>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #deleted>
      <p class="text-center">{{ "COMPONENTS.PROFILE.USER_DELETED" | translate }}</p>
    </ng-template>

    <ng-template #pendingDeleted>
      <p class="text-center">{{ "COMPONENTS.PROFILE.USER_PENDING_DELETED" | translate }}</p>
      <p class="text-center">
        <button mat-raised-button (click)="reactivateAccount()">
          <span>{{ "FORMS.UNDO" | translate }}</span>
        </button>
      </p>
    </ng-template>

    <ng-template #editSpecialtiesDialog>
      <h3>{{ "FORMS.PROFILE.EDIT_SPECIALTIES.TITLE" | translate }}</h3>
      <div *ngIf="activeSpecialties.length">
        <p class="d-flex align-content-center mb-0">
          {{ "FORMS.PROFILE.EDIT_SPECIALTIES.DESCRIPTION_WITH_SELECTED_SPECIALTIES.START" | translate }}
          <mat-icon color="warn">delete</mat-icon>
          {{ "FORMS.PROFILE.EDIT_SPECIALTIES.DESCRIPTION_WITH_SELECTED_SPECIALTIES.END" | translate }}
        </p>
        <p>{{ "FORMS.PROFILE.EDIT_SPECIALTIES.SAVE_REMARK" | translate }}</p>
      </div>
      <p *ngIf="!activeSpecialties.length">{{ "FORMS.PROFILE.EDIT_SPECIALTIES.DESCRIPTION_WITHOUT_SELECTED_SPECIALTIES" | translate }}</p>

      <ul>
        <li class="mb-1" *ngFor="let specialty of activeSpecialties">
          <div class="d-flex align-content-end no-wrap">
            {{ specialty.specialty.name }}
            <button class="ms-auto delete bg-transparent border-0" (click)="removeSpecialty(specialty)">
              <mat-icon color="warn" style="float: right">delete</mat-icon>
            </button>
          </div>
        </li>
      </ul>
      <mat-form-field appearance="fill" *ngIf="possibleSpecialties.length">
        <mat-label>{{ "COMPONENTS.PROFILE.SPECIALTIES" | translate }}</mat-label>
        <mat-select>
          <mat-option *ngFor="let specialty of possibleSpecialties" [value]="specialty" (click)="addNewSpecialty(specialty)">
            {{ specialty.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-template>
  </div>

  <ng-template #cardrow let-icon="icon" let-title="title" let-data="data">
    <div class="row" style="flex-wrap: nowrap">
      <div class="iconContainer">
        <span class="icon">
          <mat-icon>{{ icon }}</mat-icon>
        </span>
      </div>

      <div class="col data justify-content-center align-self-center">
        <span class="info">{{ "COMPONENTS.PROFILE." + title.toUpperCase() | translate }}</span>

        <span style="word-break: break-word">{{ !data ? "-" : data }}</span>
      </div>
    </div>
  </ng-template>

  <ng-template #editInfo>
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">{{ "FORMS.PROFILE.EDIT_USER.TITLE" | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <form [formGroup]="formGroup">
            <div class="row field">
              <div class="col-md">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.FIRST_NAME" | translate }}</mat-label>
                  <input matInput formControlName="firstName" />
                  <app-error [control]="formGroup.controls.firstName"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row field">
              <div class="col-md">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.LAST_NAME" | translate }}</mat-label>
                  <input matInput formControlName="lastName" />
                  <app-error [control]="formGroup.controls.lastName"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div *ngIf="viewMode?.name === 'resident'">
              <div *appExcludeModule="'PHONE_NUMBER_REQUIRED'" class="row field">
                <div class="col-md">
                  <mat-checkbox class="mb-3" color="primary" formControlName="usePhoneNumber">{{
                    "FORMS.NEW_ENERGY_CONSULT.FIELDS.USE_PHONE_NUMBER" | translate
                  }}</mat-checkbox>
                </div>
              </div>

              <div class="row field" *ngIf="formGroup.controls.usePhoneNumber.value || requiredPhonenumber">
                <div class="col-md">
                  <mat-form-field appearance="outline" hideRequiredMarker>
                    <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.PHONE_NUMBER" | translate }}</mat-label>
                    <input matInput formControlName="phoneNumber" />
                    <app-error [control]="formGroup.controls.phoneNumber"></app-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row field">
                <div class="col-md">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.POSTAL_CODE" | translate }}</mat-label>
                    <input matInput formControlName="postalCode" />
                    <app-error [control]="formGroup.controls.postalCode"></app-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row field">
                <div class="col-md">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.HOUSE_NUMBER" | translate }}</mat-label>
                    <input matInput formControlName="houseNumber" />
                    <app-error [control]="formGroup.controls.houseNumber"></app-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row field">
                <div class="col-md">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "FORMS.PROFILE.EDIT_USER.FIELDS.HOUSE_NUMBER_SUFFIX" | translate }}</mat-label>
                    <input matInput formControlName="houseNumberSuffix" />
                    <app-error [control]="formGroup.controls.houseNumberSuffix"></app-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row mb-2" *ngIf="viewMode?.name === 'coach'">
              <div class="col-md">
                <p style="max-width: 204px">{{ "FORMS.PROFILE.EDIT_USER.FIELDS.EMAIL_SUBSCRIPTION" | translate }}</p>
                <mat-slide-toggle [aria-label]="'COMPONENTS.EVERYWHERE.TOGGLE' | translate" class="text-wrap" formControlName="emailSubscription"></mat-slide-toggle>
              </div>
            </div>
          </form>

          <div class="row d-flex justify-content-start" style="row-gap: 0.5rem">
            <div class="col-sm d-flex justify-content-start justify-content-md-center">
              <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="saveInfo()">
                <span [matTooltip]="'COMPONENTS.PROFILE.SAVE' | translate">{{ "FORMS.SAVE" | translate }}</span>
              </button>
            </div>
            <div class="col-sm d-flex justify-content-start justify-content-md-center" *ngIf="user.status === 'New'">
              <button mat-flat-button color="warn" (click)="dialogService.close()">
                <span>{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</span>
              </button>
            </div>
          </div>
          <div class="row py-2" *ngIf="user.status === 'New'">
            <div class="col">
              <button mat-raised-button (click)="activateAccount()">
                <span [matTooltip]="'COMPONENTS.PROFILE.ACTIVATE_ACCOUNT' | translate">{{ "FORMS.PROFILE.EDIT_USER.ACTIVATE_ACCOUNT" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #editRole>
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">Aanpassen Rollen</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <form>
            <section>
              <mat-checkbox [(ngModel)]="roles[i]" *ngFor="let i of allRoles" class="example-margin" [name]="i" class="d-block">{{ i }}</mat-checkbox>
            </section>
          </form>

          <div class="row">
            <div class="col-md">
              <button mat-flat-button (click)="dialogService.close()">
                <span>{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</span>
              </button>

              <button mat-flat-button (click)="saveRole()">
                <span>{{ "COMPONENTS.DIALOG.SAVE" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #editSpecialty>
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">Aanpassen Specialiteiten</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <form>
            <div class="row field">
              <div class="col-md">
                <mat-form-field appearance="outline">
                  <mat-label>Voornaam</mat-label>
                  <input matInput placeholder="Placeholder" />
                </mat-form-field>
              </div>
            </div>

            <div class="row field">
              <div class="col-md">
                <mat-form-field appearance="outline">
                  <mat-label>Achternaam</mat-label>
                  <input matInput placeholder="Placeholder" />
                </mat-form-field>
              </div>
            </div>

            <div class="row field">
              <div class="col-md">
                <mat-form-field appearance="outline">
                  <mat-label>E-mailadres</mat-label>
                  <input matInput placeholder="Placeholder" />
                </mat-form-field>
              </div>
            </div>

            <div class="row field">
              <div class="col-md">
                <mat-form-field appearance="outline">
                  <mat-label>Status</mat-label>
                  <mat-select>
                    <mat-option>-</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col-md d-flex justify-content-end align-self-center">
              <button mat-flat-button (click)="dialogService.close()">
                <span>{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</span>
              </button>

              <button mat-flat-button (click)="saveSpecialty()">
                <span>{{ "COMPONENTS.DIALOG.SAVE" | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #delete>
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">{{ "COMPONENTS.PROFILE.DELETE_USER.TITLE" | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <p>{{ "COMPONENTS.PROFILE.DELETE_USER.DESCRIPTION" | translate }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-md d-flex justify-content-end align-self-center">
          <button mat-flat-button (click)="dialogService.close()">
            <span>{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</span>
          </button>

          <button mat-flat-button (click)="deleteUser()" color="warn">
            <span>{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #noRequests> {{ "COMPONENTS.PROFILE.NO_REQUESTS" | translate }} </ng-template>
