import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { RouteData } from "../app-routing.module";
import { ApplicationService } from "../services/application.service";

@Injectable({ providedIn: "root" })
export class HasRoleGaurd  {
  constructor(private readonly router: Router, private readonly applicationService: ApplicationService) {}

  public async canActivate(routeSnapshot: ActivatedRouteSnapshot) {
    return await this.checkForRequiredRoles(routeSnapshot);
  }

  public async canActivateChild(routeSnapshot: ActivatedRouteSnapshot) {
    return await this.checkForRequiredRoles(routeSnapshot);
  }

  private async checkForRequiredRoles(routeSnapshot: ActivatedRouteSnapshot) {
    await this.applicationService.initialize();
    const data = routeSnapshot.data as RouteData;
    if (data.allowedRoles?.includes(this.applicationService.session.activeRole.name)) {
      return true;
    }

    await this.router.navigate(data.noAuthRedirectURL ? [data.noAuthRedirectURL] : ["error/403"]);
    return false;
  }
}
