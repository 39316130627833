import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SnackbarService } from "../../services/snackbar.service";
import { TimeSlot } from "../../classes/flow/request/EnergyConsult";
import { dateAfterToday } from "../../validators/dateAfterToday";
import { timeAfterOther } from "../../validators/timeAfterOther";
import { TranslateService } from "@ngx-translate/core";
import { ENVIRONMENT } from "../../../environments/environment";
import { Subscription, debounceTime, distinctUntilChanged } from "rxjs";
import { TimepickerDialogComponent } from "../timepicker-dialog/timepicker-dialog.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-time-slots-input",
  templateUrl: "./time-slots-input.component.html",
  styleUrls: ["./time-slots-input.component.less"],
})
export class TimeSlotsInputComponent implements OnInit, OnDestroy {
  @Input() formgroup!: FormGroup;

  @Input() areaActionMessage: string | null;

  public timeSlotFormGroup: FormGroup<{
    date: FormControl<Date | null>;
    dayParts: FormGroup<{
      morning: FormControl<boolean | null>;
      afternoon: FormControl<boolean | null>;
    }>;
    timeInput: FormGroup<{
      startTime: FormControl<string | null>;
      endTime: FormControl<string | null>;
    }>;
  }>;
  public timeSlots: TimeSlot[] = [];
  public editingIndex: number | null = null;
  public minDate = new Date();
  public maxDate = new Date();
  public maxTimeSlots = 5;

  private specificDateSlotLimitSub?: Subscription;
  public specificDateSlotLimitCount = 0;
  public specificDateSlotLimitMax = 3;

  private dayPartHours = {
    morningStart: 9,
    morningEnd: 12,
    afternoonStart: 13,
    afternoonEnd: 17,
    fulldayStart: 9,
    fulldayEnd: 17,
  };

  public hoursetsDateObjects = {
    morningStart: new Date(new Date().setHours(this.dayPartHours.morningStart, 0)),
    morningEnd: new Date(new Date().setHours(this.dayPartHours.morningEnd, 0)),
    afternoonStart: new Date(new Date().setHours(this.dayPartHours.afternoonStart, 0)),
    afternoonEnd: new Date(new Date().setHours(this.dayPartHours.afternoonEnd, 0)),
  };

  constructor(private snackBarService: SnackbarService, private translateService: TranslateService, private dialog: MatDialog) {
    // init formgroup and set validators
    this.timeSlotFormGroup = new FormGroup({
      date: new FormControl<Date | null>(null, [Validators.required, dateAfterToday()]),
      dayParts: new FormGroup({
        morning: new FormControl(false),
        afternoon: new FormControl(false),
      }),
      timeInput: new FormGroup({
        startTime: new FormControl("", [Validators.required]),
        endTime: new FormControl("", [Validators.required, timeAfterOther("startTime")]),
      }),
    });

    this.areaActionMessage = null;

    this.enableForm();
    this.minDate.setDate(new Date().getDate() + 1);
    this.maxDate.setFullYear(new Date().getFullYear() + 1);
  }

  ngOnInit(): void {
    this.formgroup.addControl("timeSlots", new FormControl(""));
    this.overrideInitialTimepickerAccessibility();

    this.specificDateSlotLimitSub = this.timeSlotFormGroup.controls.date.valueChanges.pipe(debounceTime(150), distinctUntilChanged()).subscribe((value) => {
      if (value === null) {
        this.specificDateSlotLimitCount = 0;
        return;
      }
      try {
        const filledInDate = new Date(value);
        this.specificDateSlotLimitCount = 0;
        this.timeSlots.forEach((timeSlot) => {
          const datetime = new Date(timeSlot.startTime);
          if (new Date(datetime.setHours(0, 0, 0, 0)).getTime() === filledInDate.getTime()) this.specificDateSlotLimitCount++;
        });
      } catch {
        this.specificDateSlotLimitCount = 0;
        return;
      }
    });
  }

  ngOnDestroy(): void {
    this.specificDateSlotLimitSub?.unsubscribe();
  }

  /**
   * @returns timeslot interface with values from form
   */
  getTimeSlot(): TimeSlot {
    const date: Date = this.timeSlotFormGroup.controls["date"].value!;
    const [startInputHours, startInputMinutes] = this.timeSlotFormGroup.controls["timeInput"].controls["startTime"].value!.split(":");
    const [endInputHours, endInputMinutes] = this.timeSlotFormGroup.controls["timeInput"].controls["endTime"].value!.split(":");

    const startTime: Date = new Date(date.setHours(Number(startInputHours), Number(startInputMinutes)));
    const endTime: Date = new Date(date.setHours(Number(endInputHours), Number(endInputMinutes)));
    return { startTime: startTime, endTime: endTime };
  }

  /**
   * @returns timeslot interface with values based on chosen dayparts from form
   */
  getTimeSlotViaDayParts(): TimeSlot {
    const date: Date = this.timeSlotFormGroup.controls["date"].value!;

    const [morning, afternoon] = [
      this.timeSlotFormGroup.controls["dayParts"].controls["morning"].value ?? false,
      this.timeSlotFormGroup.controls["dayParts"].controls["afternoon"].value ?? false,
    ];

    let startTime: Date;
    let endTime: Date;
    switch ([morning, afternoon].join(",")) {
      case [true, true].join(","):
        startTime = new Date(date.setHours(this.dayPartHours.fulldayStart));
        endTime = new Date(date.setHours(this.dayPartHours.fulldayEnd));
        break;
      case [true, false].join(","):
        startTime = new Date(date.setHours(this.dayPartHours.morningStart));
        endTime = new Date(date.setHours(this.dayPartHours.morningEnd));
        break;
      case [false, true].join(","):
        startTime = new Date(date.setHours(this.dayPartHours.afternoonStart));
        endTime = new Date(date.setHours(this.dayPartHours.afternoonEnd));
        break;
      default:
        startTime = date;
        endTime = date;
        break;
    }

    return { startTime: startTime, endTime: endTime };
  }

  public pushTimeSlot(timeSlot: TimeSlot) {
    this.timeSlots.push(timeSlot);
    this.timeSlots.sort((a, b): number => {
      return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
    });
  }

  /**
   * takes and transforms value from inputs
   * adds them to time slot array
   */
  public addTimeSlot(givenTimeSlot: TimeSlot | null = null) {
    try {
      const timeSlot = givenTimeSlot === null ? (ENVIRONMENT.MODULES.includes("TIMESLOTS_PART_OF_DAY") ? this.getTimeSlotViaDayParts() : this.getTimeSlot()) : givenTimeSlot;

      if (this.matchOverlappingDate(timeSlot)) {
        return;
      }

      this.pushTimeSlot(timeSlot);

      this.timeSlotFormGroup.reset();
      this.updateParentForm();
    } catch {
      this.snackBarService.error();
    }
  }

  private matchOverlappingDate(timeSlotNew: TimeSlot): boolean {
    try {
      const filledInDate = new Date(new Date(timeSlotNew.startTime).setHours(0, 0, 0, 0));

      for (const [index, timeSlot] of this.timeSlots.entries()) {
        const datetime = new Date(timeSlot.startTime);
        if (new Date(datetime.setHours(0, 0, 0, 0)).getTime() === filledInDate.getTime()) {
          if (
            (new Date(timeSlotNew.startTime).getTime() >= new Date(timeSlot.startTime).getTime() &&
              new Date(timeSlotNew.startTime).getTime() <= new Date(timeSlot.endTime).getTime()) ||
            (new Date(timeSlotNew.endTime).getTime() >= new Date(timeSlot.startTime).getTime() &&
              new Date(timeSlotNew.endTime).getTime() <= new Date(timeSlot.endTime).getTime())
          ) {
            const mergedTS = this.mergeTimeSlots(timeSlot, timeSlotNew);
            this.timeSlots.splice(index, 1);

            this.addTimeSlot(mergedTS);
            return true;
          }
        }
      }
    } catch {
      return false;
    }
    return false;
  }

  private mergeTimeSlots(timeSlot1: TimeSlot, timeSlot2: TimeSlot): TimeSlot {
    return {
      startTime: timeSlot1.startTime > timeSlot2.startTime ? timeSlot2.startTime : timeSlot1.startTime,
      endTime: timeSlot1.endTime < timeSlot2.endTime ? timeSlot2.endTime : timeSlot1.endTime,
    };
  }

  /**
   * removes item from time slot array by index
   * @param index index of selected time slot in array
   */
  public removeTimeSlot(index: number) {
    this.timeSlots.splice(index, 1);
    if (this.editingIndex !== null && index < this.editingIndex) this.editingIndex--;
    this.updateParentForm();
  }

  /**
   * puts value from selected time slot in input fields
   * @param index index of selected time slot in array
   */
  public editTimeSlot(index: number) {
    this.editingIndex = index;

    this.timeSlotFormGroup.controls["date"].setValue(this.timeSlots[index].startTime);
    this.timeSlotFormGroup.controls["timeInput"].controls["startTime"].setValue(this.timeSlots[index].startTime.toLocaleTimeString("en-GB").substring(0, 5));
    this.timeSlotFormGroup.controls["timeInput"].controls["endTime"].setValue(this.timeSlots[index].endTime.toLocaleTimeString("en-GB").substring(0, 5));
  }

  /**
   * puts value from selected time slot in input fields
   * @param index index of selected time slot in array
   */
  public editTimeSlotViaDayParts(index: number) {
    this.editingIndex = index;

    this.timeSlotFormGroup.controls["date"].setValue(this.timeSlots[index].startTime);

    //TODO convert times into booleans for dayparts
    [this.timeSlots[index].startTime.getHours(), this.timeSlots[index].endTime.getHours()];
    this.timeSlotFormGroup.controls["dayParts"].controls["morning"].setValue(true);

    switch ([this.timeSlots[index].startTime.getHours(), this.timeSlots[index].endTime.getHours()].join(",")) {
      case [this.dayPartHours.fulldayStart, this.dayPartHours.fulldayEnd].join(","):
        this.timeSlotFormGroup.controls["dayParts"].controls["morning"].setValue(true);
        this.timeSlotFormGroup.controls["dayParts"].controls["afternoon"].setValue(true);
        break;
      case [this.dayPartHours.morningStart, this.dayPartHours.morningEnd].join(","):
        this.timeSlotFormGroup.controls["dayParts"].controls["morning"].setValue(true);
        this.timeSlotFormGroup.controls["dayParts"].controls["afternoon"].setValue(false);
        break;
      case [this.dayPartHours.afternoonStart, this.dayPartHours.afternoonEnd].join(","):
        this.timeSlotFormGroup.controls["dayParts"].controls["morning"].setValue(false);
        this.timeSlotFormGroup.controls["dayParts"].controls["afternoon"].setValue(true);
        break;
      default:
        break;
    }
  }

  /**
   * takes value from input fields
   * replaces data of selected time slot
   */
  public submitEdit() {
    if (this.editingIndex !== null) {
      const slot: TimeSlot = ENVIRONMENT.MODULES.includes("TIMESLOTS_PART_OF_DAY") ? this.getTimeSlotViaDayParts() : this.getTimeSlot();

      this.timeSlots.splice(this.editingIndex, 1);
      this.addTimeSlot(slot);

      this.timeSlotFormGroup.reset();
      this.editingIndex = null;
    } else {
      this.snackBarService.error();
    }
  }

  /**
   * clear form and exit editing state
   */
  public cancelEdit() {
    this.timeSlotFormGroup.reset();
    this.editingIndex = null;
  }

  /**
   * transforms timeslot array into json
   * set value in parent form
   */
  public updateParentForm() {
    this.formgroup.controls["timeSlots"].setValue(this.timeSlots[0] ? JSON.stringify(this.timeSlots) : null);
  }

  /**
   * updates form control of end time
   * (used on change of start time input)
   */
  public updateValidatorsOnChange() {
    this.timeSlotFormGroup.controls["timeInput"].controls["endTime"].updateValueAndValidity();
  }

  public disableForm() {
    this.timeSlotFormGroup.disable();
  }

  public enableForm() {
    this.timeSlotFormGroup.enable();
    ENVIRONMENT.MODULES.includes("TIMESLOTS_PART_OF_DAY") ? this.timeSlotFormGroup.controls.timeInput.disable() : this.timeSlotFormGroup.controls.dayParts.disable();
  }

  public clearForm() {
    this.timeSlotFormGroup.reset();
    this.timeSlots.length = 0;
  }

  /**
   * Overriding accessibility issues
   */
  public overrideInitialTimepickerAccessibility() {
    setTimeout(() => {
      /**
       * Timepicker Buttons
       */
      const timePickerButtons = document.getElementsByClassName("ngx-material-timepicker-toggle");
      for (let i = 0; i < timePickerButtons.length; i++) {
        timePickerButtons[i].setAttribute("aria-label", this.translateService.instant("FORMS.ADD_TIME_SLOT"));
      }
    }, 0);
  }

  public overrideAfterTimepickerAccessibility() {
    setTimeout(() => {
      /**
       * Time inputs
       */
      const timePickerButtons = document.getElementsByClassName("timepicker-dial__control");
      timePickerButtons[0].setAttribute("aria-label", this.translateService.instant("FORMS.SET_HOURS"));
      timePickerButtons[1].setAttribute("aria-label", this.translateService.instant("FORMS.SET_MINUTES"));
    }, 0);
  }

  openTimePicker(form: FormGroup, givenFormControlName: string): void {
    const dialogRef = this.dialog.open(TimepickerDialogComponent, {
      width: "300px",
      height: "500px",
    });
    const instance = dialogRef.componentInstance;
    instance.givenFormGroup = form;
    instance.givenFormControlName = givenFormControlName;
  }
}
