import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable, map, of, tap } from "rxjs";
import { RouteData } from "../app-routing.module";
import { ENVIRONMENT } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ModuleGuard  {
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return of(route.data).pipe(
      map((data: RouteData) => {
        if (!data.module) return true;

        return ENVIRONMENT.MODULES.includes(data.module);
      }),
      tap((access) => {
        if (!access) {
          console.error("No access to this page.");
        }
      })
    );
  }
}
