import { Component, Input } from "@angular/core";
import { EnergyConsult } from "../classes/flow/request/EnergyConsult";
import { CoachEnergyConsultService } from "../services/coach-energy-consult.service";
import { OnInit } from "@angular/core";
import Logger from "../classes/Logger";

interface MailRecord {
  type: string;
  received: Date;
  inserted: Date;
}

export interface FMMail {
  subject: string;
  events: MailEvent[];
}
export interface MailEvent {
  type: MailEventType;
  received: Date;
  inserted: Date;
  message?: string;
}

export type MailEventType = (typeof MailEventTypes)[number];
export const MailEventTypes = ["PROCESSED", "DELIVERED", "OPENED", "REJECTED", "ERROR"] as const;
export type MailEventErrorType = (typeof MailEventErrorTypes)[number];
export const MailEventErrorTypes = ["ERROR", "REJECT", "HARDBOUNCE", "SOFTBOUNCE"] as const;

@Component({
  selector: "app-mail-data-timeline",
  templateUrl: "./mail-data-timeline.component.html",
  styleUrls: ["./mail-data-timeline.component.less"],
})
export class MailDataTimelineComponent implements OnInit {
  mails: FMMail[] = [];

  MailEventIcons: Record<MailEventType, string> = {
    PROCESSED: "send",
    DELIVERED: "mark_email_unread",
    OPENED: "mark_email_read",
    ERROR: "warning",
    REJECTED: "cancel_schedule_send",
  };

  public constructor(private readonly coachEnergyConsultService: CoachEnergyConsultService) {}

  @Input("energyConsult")
  public energyConsult?: EnergyConsult;

  public ngOnInit(): void {
    if (this.energyConsult?.mailHistoryId === undefined) {
      // "No mail history id"
      return;
    }
    this.getMailEvents();
  }

  private async getMailEvents() {
    this.getMailData();
  }

  public async getMailData() {
    const res = await this.coachEnergyConsultService.postRequest("action", { FFWDActionID: this.energyConsult!.mailHistoryId });
    const data: { result: string; messages: { mails: { [key: string]: MailRecord[] } } } = JSON.parse(res.postActionAsJSON);

    if (data.result.toUpperCase() !== "OK") {
      //Error getting mail data
      Logger.error("Error getting mail data from api on actionID: ", this.energyConsult?.mailHistoryId);
      return;
    }
    const mailData = data.messages.mails;
    for (const [mailKey, mailEvents] of Object.entries(mailData)) {
      this.mails.push({
        subject: mailKey,
        events: mailEvents
          .map((mailRecord) => {
            return {
              type: MailEventErrorTypes.includes(mailRecord.type as MailEventErrorType) ? "ERROR" : (mailRecord.type as MailEventType),
              received: new Date(mailRecord.received),
              inserted: new Date(mailRecord.inserted),
            };
          })
          .filter((element) => MailEventTypes.includes(element.type))
          .sort((a, b) => a.received.getTime() - b.received.getTime()),
      });
    }
  }
}
