import { AfterViewInit, Component, ElementRef, ViewChild, Input, EventEmitter, Output } from "@angular/core";
import SignaturePad from "signature_pad";
import { ApplicationService } from "../../services/application.service";
import { EnergyConsult } from "../../classes/flow/request/EnergyConsult";
import { CoachEnergyConsultService } from "../../services/coach-energy-consult.service";
import { SnackbarService } from "../../services/snackbar.service";
import { Changes } from "../../classes/flow/base/Changes";
import { Question } from "../../classes/flow/Questionnaire/Question";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-signature-input",
  templateUrl: "./signature-input.component.html",
  styleUrls: ["./signature-input.component.less"],
})
export class SignatureInputComponent implements AfterViewInit {
  @ViewChild("signature")
    canvas?: ElementRef<HTMLCanvasElement>;

  @Input()
    energyConsult?: EnergyConsult;

  @Input()
    question?: Question;

  @Input()
    formControl?: FormControl;

  @Output()
    fileSave: EventEmitter<string>;

  signaturePad?: SignaturePad;

  hasDrawn: boolean;

  contentIds?: {
    changes: Changes;
    ffwdcontentid: string;
    ffwdcontentpartid: string;
  };

  filename: string;

  constructor(private application: ApplicationService, private coachEnergyConsultService: CoachEnergyConsultService, private snackbarService: SnackbarService) {
    this.hasDrawn = false;
    this.filename = "signature.png";
    this.fileSave = new EventEmitter<string>();
  }

  async ngAfterViewInit(): Promise<void> {
    this.application.setLoading(true);
    if (this.canvas) this.signaturePad = new SignaturePad(this.canvas.nativeElement);

    if (this.signaturePad)
      this.signaturePad.addEventListener("endStroke", () => {
        this.hasDrawn = true;
      });

    if (this.energyConsult) {
      this.contentIds = await this.coachEnergyConsultService.getEnergyConsultContentIds(this.energyConsult.id);
    }

    if (this.question) {
      this.filename = `${this.question.id!}-${this.filename}`;
    }

    this.loadSignature();

    this.application.setLoading(false);
  }

  saveSignature() {
    if (this.hasDrawn && this.signaturePad) {
      const base64 = this.signaturePad?.toDataURL();
      this.uploadSignature(base64);
    }
  }

  dataURLtoFile(dataUrl: string, filename: string): File | undefined {
    const arr = dataUrl.split(",");
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], "base64");
    return new File([buff], filename, { type: mime });
  }

  public async uploadSignature(dataUrl: string) {
    this.application.setLoading(true);
    try {
      const file = this.dataURLtoFile(dataUrl, this.filename);
      if (!file) throw new Error("Invalid Base64");
      if (!this.energyConsult) throw new Error("No EnergyConsult");
      if (!this.contentIds) throw new Error("No Content ID's");

      const formData = new FormData();
      formData.append("contentid", this.contentIds.ffwdcontentid);
      formData.append("contentpartid", this.contentIds.ffwdcontentpartid);
      formData.append("attachment", "true");
      formData.append("file", file);
      await this.coachEnergyConsultService.postRequest("content", formData, { observe: "response", withCredentials: true });
      this.fileSave.emit(this.filename);
      this.hasDrawn = false;
    } catch (error) {
      this.snackbarService.error();
    }
    this.application.setLoading(false);
  }

  public async loadSignature() {
    this.application.setLoading(true);
    try {
      if (!this.energyConsult) throw new Error("No Energy Consult");
      const attachments = await this.coachEnergyConsultService.getAttachmentList(this.energyConsult);
      const file = attachments.filter((a: any) => a.name === this.filename)[0];

      if (file) {
        const res = await this.coachEnergyConsultService.postRequest(
          "action",
          {
            FFWDActionID: file.actionIDGet,
          },
          {
            observe: "response",
            responseType: "blob",
          }
        );

        const base = await new Promise((resolve) => {
          this.application.setLoading(true);
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
            this.application.setLoading(false);
          };
          reader.readAsDataURL(res.body);
        });

        if (!(typeof base === "string")) throw new Error("Invalid file");

        const base64 = "data:image/png;base64," + base.split(",")[1]!;
        this.signaturePad?.clear();
        await this.signaturePad?.fromDataURL(base64, { width: 300, height: 150 });
      }
    } catch (error) {
      this.snackbarService.error();
    }
    this.application.setLoading(false);
  }
}
