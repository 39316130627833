import { Component, OnInit } from "@angular/core";
import { CsvService } from "../../services/csv.service";
import { RetailersService } from "../../services/retailers.service";
import { ApplicationService } from "../../services/application.service";
import { Retailer } from "../../classes/flow/session/impl/Retailer";
import { convertDate } from "../../helpers/convertDate";

@Component({
  selector: "app-retailer-pay-out-overview",
  templateUrl: "./retailer-pay-out-overview.component.html",
  styleUrls: ["./retailer-pay-out-overview.component.less"],
})
export class RetailerPayOutOverviewComponent implements OnInit {
  transactionsOpen!: any[];
  transactionsProcessed!: any[];

  creditsRemaining = 0;
  creditsProcessed = 0;

  constructor(private csvService: CsvService, private retailerService: RetailersService, private applicationService: ApplicationService) {
    const user = this.applicationService.session.user as Retailer;
    this.creditsRemaining = user.balance ? user.balance / 100 : 0;
  }

  public async ngOnInit() {
    const retailer = this.applicationService.session.user as Retailer;
    this.transactionsOpen = await this.retailerService.paymentRequests(retailer, false);
    this.transactionsProcessed = await this.retailerService.paymentRequests(retailer, true);

    if (this.transactionsProcessed.length > 5) {
      this.transactionsProcessed.sort((a, b) => b.paidOn - a.paidOn);
      this.transactionsProcessed.length = 5;
    }
  }

  async downloadCSVFile() {
    this.applicationService.setLoading(true);
    const processedRequests = await this.retailerService.paymentRequests(this.applicationService.session.user as Retailer, true);
    const csvData = processedRequests.map((req) => {
      return {
        Aanmaakdatum: convertDate(new Date(req.timeStamp)),
        Verwerkingsdatum: convertDate(new Date(req.paidOn)),
        Bedrag: this.numberToEuro(req.paymentAmount),
        Referentienummer: req.referenceNumber,
      };
    });

    const filename = new Date().getTime() + ".csv";

    this.csvService.exportToCsv(filename, csvData);
    this.applicationService.setLoading(false);
  }

  numberToEuro(amount: number) {
    return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR" }).format(amount);
  }
}
