<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col-12 flex-column text-center">
      <h2>{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.NEWCLAIM" | translate }}</h2>
      <form [formGroup]="form">
        <div class="row pt-2 mb-4 d-flex flex-column align-items-center gap-3">
          <div class="col-8">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.POSTALCODE" | translate }}</mat-label>
              <input matInput formControlName="postalCode" readonly="true" />
              <app-error [control]="form.controls.postalCode"></app-error>
            </mat-form-field>
          </div>

          <div class="col-8">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.HOUSENUMBER" | translate }}</mat-label>
              <input matInput formControlName="houseNumber" readonly="true" />
              <app-error [control]="form.controls.houseNumber"></app-error>
            </mat-form-field>
          </div>

          <div class="col-8">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.ADDITION" | translate }}</mat-label>
              <input matInput formControlName="houseNumberSuffix" readonly="true" />
              <app-error [control]="form.controls.houseNumberSuffix"></app-error>
            </mat-form-field>
          </div>

          <div class="col-8">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" floatLabel="always">
              <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.BSN" | translate }}</mat-label>
              <input matInput formControlName="bsn" />
              <app-error [control]="form.controls.bsn"></app-error>
            </mat-form-field>
          </div>

          <div class="col-8 d-flex flex-column gap-3 align-items-start" *ngIf="bsnChecked">
            <ul class="list-unstyled mb-4" *ngIf="bsnResponse">
              <li *ngFor="let record of bsnResponse">
                <strong>{{ record.name }}: </strong>
                {{ record.value }}
              </li>
            </ul>
            <span *ngIf="!bsnResponse" class="text-danger">{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.NOT_FOUND" | translate }}</span>
          </div>

          <div class="col-8 d-flex justify-content-start gap-3 flex-wrap">
            <button *ngIf="!bsnChecked" type="button" [disabled]="!form.controls.bsn.valid" mat-raised-button color="accent" (click)="checkBSN()">
              {{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.CHECK_BSN" | translate }}
            </button>
              <button class="text-wrap" style="height: auto; min-height: 36px;" *ngIf="bsnChecked && !bsnResponse" type="button" mat-raised-button color="warn" (click)="addClaim(false)">
                {{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.APPROVE" | translate }}
              </button>
            <button *ngIf="bsnChecked && !bsnResponse" type="button" mat-raised-button color="primary" (click)="openDialog(delete)">
              {{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.CANCEL" | translate }}
            </button>
            <button *ngIf="bsnChecked && bsnResponse" type="button" mat-raised-button color="primary" (click)="addClaim(true)">
              {{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.APPROVE_REQUEST" | translate }}
            </button>
            <button *ngIf="bsnChecked && bsnResponse" type="button" mat-raised-button color="warn" (click)="openDialog(delete)">
              {{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.CANCEL" | translate }}>
            </button>
          </div>
        </div>
        <ng-template #delete>
          <div class="container">
            <div class="row">
              <div class="col-md">
                <span class="title">{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.REJECT_TITLE" | translate }}</span>
              </div>
            </div>
            <div class="row">
              <mat-form-field class="example-full-width w-100" appearance="fill">
                <mat-label>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.REASON.NAME" | translate }}</mat-label>
                <textarea
                  matInput
                  placeholder="{{ 'COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.REASON.PLACEHOLDER' | translate }}"
                  formControlName="rejectReason"
                ></textarea>
                <app-error [control]="form.controls['rejectReason']"></app-error>
              </mat-form-field>

              <div class="row" style="flex-wrap: nowrap">
                <div class="col d-flex justify-content-end align-self-center">
                  <button mat-raised-button (click)="closeDialog()">
                    <span>{{ "COMPONENTS.DASHBOARD.CANCEL" | translate }}</span>
                  </button>
                </div>
                <div class="col d-flex justify-content-start align-self-center">
                  <button [disabled]="!form.controls.rejectReason.valid" mat-raised-button color="warn" (click)="addRejectedClaim(form.controls.rejectReason.value!)">
                    <span>{{ "COMPONENTS.REPLACEMENT_CLAIM_NEW.REJECT" | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </form>
    </div>
  </div>
</div>
