import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root"
})
export class QuestionnaireTypeService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  public async getQuestionnaireTypes() {
    const res = await this.graphqlService.query(
      `query {
        questionnaireTypes {
          value {
            id
            order
            name
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
    return res.data.questionnaireTypes.value.map((questionnaireType: any) => { 
      return {
        id: questionnaireType.id,
        changes: this.graphqlService.createChangesObject(questionnaireType.changes),
        order: questionnaireType.order,
        name: questionnaireType.name
      };
    });
  }
}
