<div id="allUserContainer" class="container">
  <div class="row">
    <div class="col-md text-center">
      <h2>{{ "COMPONENTS.TILE.RETAILERS_TITLE" | translate }}</h2>
    </div>
  </div>
  <div class="col-md text-center">
    <button class="retailerButton" mat-raised-button [routerLink]="['/content/coordinator/new-retailer']">{{ "COMPONENTS.RETAILERS.ADD_RETAILER_BUTTON" | translate }}</button>
  </div>
  <div class="d-flex justify-content-between flex-wrap gap-3 my-3" [formGroup]="filterToggles">
    <mat-slide-toggle class="text-start" formControlName="statusToggle" (change)="retailersTable.update()">{{ "COMPONENTS.USERS.STATUS_TOGGLE" | translate }}</mat-slide-toggle>
    <mat-slide-toggle class="text-start" formControlName="registeredToggle" (change)="retailersTable.update()">{{ "COMPONENTS.USERS.REGISTER_TOGGLE" | translate }}</mat-slide-toggle>
  </div>
  <div class="row">
    <div class="col-md justify-content-around">
      <app-table class="text-center" #retailersTable id="RETAILERS" [data]="retailers" [search]="true" [pageSizes]="[5, 10, 25]" [customFilter]="getFilterFunction()" [headers]="headers"></app-table>
    </div>
  </div>
</div>
