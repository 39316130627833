<div class="container" *ngIf="roles.length; else noRoles">
  <div class="row">
    <div class="col-md">
      <span class="title">{{ "COMPONENTS.CHOOSE_ROLE.TITLE" | translate }}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <p class="sub">{{ "COMPONENTS.CHOOSE_ROLE.DESCRIPTION" | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <ul>
        <li *ngFor="let role of roles">
          <button mat-raised-button (click)="activateRole(role)">{{ "COMPONENTS.CHOOSE_ROLE.LOGIN_BUTTON" | translate }} {{ "COMPONENTS.PROFILE.ROLES." + role.name.toUpperCase() | translate }}</button>
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #noRoles>
  <div class="container">
    <div class="row">
      <div class="col">{{ "COMPONENTS.CHOOSE_ROLE.NO_ROLES" | translate }}</div>
    </div>
  </div>
</ng-template>
