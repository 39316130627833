import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Specialty } from "../../../classes/flow/Questionnaire/Specialty";
import { SpecialtyService } from "../../../services/specialty.service";
import { EnergyConsultService } from "../../../services/energy-consult.service";
import { EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { ApplicationService } from "../../../services/application.service";
import { Resident } from "../../../classes/flow/session/impl/Resident";
import { RequestStates } from "../../../classes/flow/request/RequestStates";
import { ResidentService } from "../../../services/resident.service";
import { UserService } from "../../../services/user.service";
import { User } from "../../../classes/flow/session/impl/User";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "../../../services/snackbar.service";
import { DialogService } from "../../../services/dialog.service";
import { Router } from "@angular/router";
import { specialCharValidator } from "../../../validators/specialChars";

@Component({
  selector: "app-new-energy-consult-existing-resident",
  templateUrl: "./new-energy-consult-existing-resident.component.html",
  styleUrls: ["./new-energy-consult-existing-resident.component.less"],
})
export class NewEnergyConsultExistingResidentComponent implements OnInit {
  public allSpecialties: Specialty[] = [];
  public formGroup = new FormGroup({
    extraMessage: new FormControl("", [specialCharValidator, Validators.maxLength(250)]),
    specialties: new FormControl(),
  });

  @ViewChild("popup") popup!: TemplateRef<unknown>;

  public constructor(
    public readonly router: Router,
    private readonly energyConsultService: EnergyConsultService,
    private readonly applicationService: ApplicationService,
    private readonly residentService: ResidentService,
    private readonly translateService: TranslateService,
    private readonly dialogService: DialogService,
    private readonly snackService: SnackbarService,
    private readonly userService: UserService,
    private route: ActivatedRoute,
    private readonly specialtyService: SpecialtyService
  ) {}

  async ngOnInit() {
    await this.initForm();
  }
  public async saveNewCoachVisit() {
    const id = this.route.snapshot.paramMap.get("id") ?? "";
    const user: User | null = await this.userService.getUserById(+id);
    if (!user) {
      this.snackService.error();
      return;
    }
    const resident: Resident = (await this.residentService.getResidentByUser(user)) as Resident;
    const energyConsult: EnergyConsult = new EnergyConsult({
      id: 0,
      message: this.formGroup.value.extraMessage!,
      postalCode: resident.postalCode!,
      houseNumber: Number(resident.houseNumber!),
      requestDate: new Date(),
      state: { name: RequestStates.NEW },
      specialty: this.formGroup.value.specialties,
      resident: resident,
      houseNumberSuffix: resident.houseNumberSuffix,
    });

    const result = await this.energyConsultService.create(energyConsult);
    if (result.data.addEnergyConsult.messages.length == 0) {
      this.snackService.open(this.translateService.instant("FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE"));
      this.formGroup.reset();
      if (this.applicationService.session.authenticated) {
        this.applicationService.session.user = await this.userService.initialize(this.applicationService.session.activeRole);
        this.router.navigate(["/content"]);
      } else {
        this.openSuccessDialog();
      }
    }
  }

  openSuccessDialog() {
    this.dialogService.open({
      template: this.popup,
    });
  }

  public async initForm() {
    this.allSpecialties = await this.specialtyService.getSpecialties();

    this.formGroup.patchValue({
      specialties: this.allSpecialties.find((specialty) => specialty.order == 0),
    });
  }
}
