<div class="row">
  <div class="col-12">
    <form (submit)="submitHandler($event)" [formGroup]="form" class="w-100">
      <div class="row">

        <div class="col-md-3">
          <!-- Zipcode from -->
          <mat-form-field class="w-100">
            <mat-label>{{ "COMPONENTS.AREA_ACTIONS_FORM.ZIPCODEFROM" | translate }}</mat-label>
            <input matInput formControlName="zipCodeFrom" />
            <app-error [control]="form.controls.zipCodeFrom"></app-error>
          </mat-form-field>
          <!-- Zipcode to -->
          <mat-form-field class="w-100">
            <mat-label>{{ "COMPONENTS.AREA_ACTIONS_FORM.ZIPCODETO" | translate }}</mat-label>
            <input matInput formControlName="zipCodeTo" />
            <app-error [control]="form.controls.zipCodeTo"></app-error>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <!-- Date from -->
          <mat-form-field class="w-100">
            <mat-label>{{ "COMPONENTS.AREA_ACTIONS_FORM.DATEFROM" | translate }}</mat-label>
            <input matInput [matDatepicker]="pickerFrom" formControlName="dateFrom" />
            <app-error [control]="form.controls.dateFrom"></app-error>
            <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>
          <!-- Date to -->
          <mat-form-field class="w-100">
            <mat-label>{{ "COMPONENTS.AREA_ACTIONS_FORM.DATETO" | translate }}</mat-label>
            <input matInput [matDatepicker]="pickerTo" formControlName="dateTo" />
            <app-error [control]="form.controls.dateTo"></app-error>
            <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <!-- Area -->
          <mat-form-field class="w-100">
            <mat-label>{{ "COMPONENTS.AREA_ACTIONS_FORM.AREA" | translate }}</mat-label>
            <input matInput formControlName="area" />
            <app-error [control]="form.controls.area"></app-error>
          </mat-form-field>
          <!-- Municipality -->
          <mat-form-field class="w-100">
            <mat-label>{{ "COMPONENTS.AREA_ACTIONS_FORM.MUNICIPALITY" | translate }}</mat-label>
            <input matInput formControlName="municipality" />
            <app-error [control]="form.controls.municipality"></app-error>
          </mat-form-field>
        </div>

        <div class="col-md-3">
          <div class="w-100 h-100 d-flex flex-wrap flex-row gap-3">
            <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">{{ "FORMS.SAVE" | translate }}</button>
            <button mat-raised-button type="button" (click)="cancelClickHandler()">{{ "FORMS.CANCEL" | translate }}</button>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
