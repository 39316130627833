<form [formGroup]="form">
  <div class="row mb-4">
    <div class="col-12">
      <h5>{{ "FORMS.RETAILER_PAYOUT.PAYOUT_AMOUNT" | translate}}</h5>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ "FORMS.RETAILER_PAYOUT.AMOUNT" | translate }}</mat-label>
        <input type="number" step="0.01" matInput min="0" formControlName="amount">
        <span matPrefix>&nbsp;&nbsp;&nbsp;&euro;&nbsp;</span>
        <mat-hint (click)="setAmountToMax()"> {{ "FORMS.RETAILER_PAYOUT.MAX" | translate}}{{formatToEuro(maxAmount)}}</mat-hint>
        <app-error [control]="form.controls.amount"></app-error>
      </mat-form-field>
    </div>
  </div>
  <div class="d-flex">
    <button type="button" mat-raised-button color="primary" [disabled]="!this.form.valid" (click)="submitPayout()">{{ "FORMS.RETAILER_PAYOUT.SUBMIT" | translate }}</button>
  </div>
</form>
