<div class="container">
  <div class="row">
    <div class="col-12 d-flex flex-column align-items-center">
      <h2>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.DELIVERY" | translate }}</h2>
      <div class="w-100 d-flex flex-wrap justify-content-center">
        <table clas="d-flex overflow-auto flex-wrap">
          <div class="row">
            <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.NAME" | translate }}:</div>
            <div class="col-sm-12">{{ replacementClaim?.resident?.firstName + " " + replacementClaim?.resident?.lastName }}</div>
          </div>
          <div class="row">
            <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.EMAIL" | translate }}:</div>
            <div class="col-sm-12">{{ replacementClaim?.resident?.email }}</div>
          </div>
          <div class="row">
            <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.PHONENUMBER" | translate }}:</div>
            <div class="col-sm-12">{{ replacementClaim?.resident?.phoneNumber }}</div>
          </div>
          <div class="row">
            <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.ADRESS" | translate }}:</div>
            <div class="col-sm-12">
              {{ replacementClaim?.streetName + " " + replacementClaim?.houseNumber + (replacementClaim?.houseNumberSuffix ? " " + replacementClaim?.houseNumberSuffix : "") }}
            </div>
          </div>
          <div class="justify-content-center" *ngIf="replacementClaim?.postalCode">
            <div class="col-md-6" style="font-weight: bold">{{ "COMPONENTS.REPLACEMENT_CLAIM_SCHEDULE.POSTALCODE" | translate }}:</div>
            <div class="col-sm-12">{{ replacementClaim?.postalCode | uppercase }}</div>
          </div>
        </table>
      </div>

      <mat-vertical-stepper #stepper class="w-100" linear class="customized-stepper w-100">
        <mat-step [stepControl]="form.controls.newAppliance" *ngIf="replacementClaimAppliance">
          <form [formGroup]="form.controls.newAppliance">
            <ng-template matStepLabel>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.NEW_DEVICE" | translate }}</ng-template>
            <p class="d-block">
              <ng-container *ngIf="replacementClaimAppliance.applianceCategory">
                <strong>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.DEVICE" | translate }}</strong
                ><br />
                {{ stripCurlyBraces(replacementClaimAppliance.applianceCategory) }}<br />
              </ng-container>
              <ng-container *ngIf="replacementClaimAppliance.description">
                <strong>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.COMMENT" | translate }}</strong
                ><br />
                {{ replacementClaimAppliance.description }}<br />
              </ng-container>
            </p>

            <app-qr-input
              [label]="'COMPONENTS.QR_INPUT.LABEL_DELIVER_NEW'"
              [control]="form.controls.newAppliance"
              [disabled]="!!replacementClaimAppliance.hOID"
            ></app-qr-input>

            <div class="d-flex gap-3 flex-wrap" *ngIf="!replacementClaimAppliance.hOID">
              <button type="button" mat-raised-button color="primary" [disabled]="!form.controls.newAppliance.valid" (click)="deliverNewAppliance()">{{ "FORMS.SAVE" | translate }}</button>
            </div>
          </form>
        </mat-step>

        <mat-step *ngFor="let returnApplianceControl of form.controls.returnAppliances.controls; let i = index" [stepControl]="returnApplianceControl">
          <form [formGroup]="returnApplianceControl">
            <ng-template matStepLabel>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.RETURN_DEVICE" | translate }}</ng-template>
            <p class="d-block">
              <ng-container *ngIf="replacementClaimReturnAppliances[i].applianceCategory">
                <strong>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.DEVICE" | translate }}</strong
                ><br />
                {{ stripCurlyBraces(replacementClaimReturnAppliances[i].applianceCategory) }}<br />
              </ng-container>
              <ng-container *ngIf="replacementClaimReturnAppliances[i].description">
                <strong>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.COMMENT" | translate }}</strong
                ><br />
                {{ replacementClaimReturnAppliances[i].description }}<br />
              </ng-container>
            </p>

            <app-qr-input
              [label]="'COMPONENTS.QR_INPUT.LABEL_DELIVER_RETURN'"
              [control]="returnApplianceControl"
              [disabled]="!(replacementClaimReturnAppliances[i].returnStateId === 1 || replacementClaimReturnAppliances[i].returnStateId === 2)"
            ></app-qr-input>

            <div class="d-flex gap-3 flex-wrap" *ngIf="replacementClaimReturnAppliances[i].returnStateId === 1 || replacementClaimReturnAppliances[i].returnStateId === 2">
              <!-- <div class="d-flex gap-3"> -->
              <button
                *ngIf="returnApplianceControl.controls.qr.value"
                [disabled]="returnApplianceControl.invalid && returnApplianceControl.controls.qr.errors?.invalidQR !== 'FORMS.ERRORS.QR.NO_MATCH'"
                type="button"
                mat-raised-button
                color="primary"
                (click)="pickupReturnAppliance(i)"
              >
                {{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.SAVE" | translate }}
              </button>
              <button
                *ngIf="!returnApplianceControl.controls.qr.value || !returnApplianceControl.controls.qr.value.length"
                type="button"
                mat-raised-button
                color="primary"
                (click)="skipReturnAppliance()"
              >
                {{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.SKIP" | translate }}
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="form.controls.confirm">
          <form [formGroup]="form.controls.confirm">
            <ng-template matStepLabel>{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.COMPLETE_DELIVERY" | translate }}</ng-template>

            <ng-container
              *ngFor="let control of form.controls.confirm.controls.undeliveredReturnAppliances.controls; let i = index"
              formArrayName="undeliveredReturnAppliances"
            >
              <div *ngIf="control.enabled">
                <mat-checkbox color="primary" [formControl]="control"
                  >{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.RETURN" | translate }} {{ i + 1 }} {{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.FORGOT" | translate }}</mat-checkbox
                >
              </div>
            </ng-container>

            <mat-checkbox color="primary" formControlName="stickers">{{ "COMPONENTS.REPLACEMENT_CLAIM_DELIVER.STICKERS" | translate }}</mat-checkbox>

            <div class="d-flex gap-3 flex-wrap mt-4">
              <button type="button" mat-raised-button color="primary" [disabled]="!allMissingRetourAppliancesChecked()" (click)="finishDelivery()">{{ "FORMS.SAVE" | translate }}</button>
            </div>
          </form>
        </mat-step>
      </mat-vertical-stepper>
    </div>
  </div>
</div>
