import { Component, ViewChild, AfterViewInit } from "@angular/core";
import { NgxScannerQrcodeComponent, ScannerQRCodeConfig, ScannerQRCodeResult } from "ngx-scanner-qrcode";
import { MatDialogRef } from "@angular/material/dialog";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-qr-scanner",
  templateUrl: "./qr-scanner.component.html",
  styleUrls: ["./qr-scanner.component.less"],
})
export class QrScannerComponent implements AfterViewInit {
  @ViewChild("scanner")
    scanner!: NgxScannerQrcodeComponent;

  public scanResult?: string = undefined;
  public config: ScannerQRCodeConfig = {
    constraints: {
      audio: false,
      video: {
        advanced: [
          {
            facingMode: "environment",
          },
        ],
      },
    },
  };

  constructor(private readonly dialog: MatDialogRef<QrScannerComponent>) {
    this.dialog.afterClosed().subscribe(async () => await firstValueFrom(this.scanner.stop()));
  }

  ngAfterViewInit(): void {
    this.scanner.isReady.subscribe(() => {
      const canvas = this.scanner.canvas.nativeElement;
      canvas.setAttribute("style", "width: 100%; height: 100%; background: #000000;");
      const video = this.scanner.video.nativeElement;
      video.setAttribute("style", "width: 100%; height: 100%; object-fit: cover;");
    });
  }

  async saveQR(event: ScannerQRCodeResult[]) {
    if (event[0] && event[0].value !== null) {
      if (event[0].value !== this.scanResult) {
        this.scanResult = event[0].value;
      }
      await firstValueFrom(this.scanner.stop());
    }
  }

  async startCamera() {
    if (this.scanner.isStart) {
      await firstValueFrom(this.scanner.stop());
    }
    await firstValueFrom(this.scanner.start());
  }

  async closeCamera() {
    return this.dialog.close(this.scanResult ?? undefined);
  }
}
