import { Changes } from "../base/Changes";

export class ReplacementClaimReturnAppliance {
  public id: number;
  public replacementClaimId: number;
  public applianceCategoryId: number;
  public applianceCategory: string;
  public returnStateId: number;
  public description?: string;
  public hOID?: string;
  public changes: Changes;

  constructor(appliance: {
    id: number;
    replacementClaimId: number;
    applianceCategoryId: number;
    returnStateId: number;
    applianceCategory: string;
    description?: string;
    hOID?: string;
    changes: Changes;
  }) {
    this.id = appliance.id;
    this.replacementClaimId = appliance.replacementClaimId;
    this.applianceCategoryId = appliance.applianceCategoryId;
    this.applianceCategory = appliance.applianceCategory;
    this.returnStateId = appliance.returnStateId;
    this.description = appliance.description;
    this.hOID = appliance.hOID;
    this.changes = appliance.changes;
  }
}
