<ng-container *ngFor="let filterGroup of filters; let i = index">
  <div
    *ngIf="filterGroup.filters.length > 1 || (filterGroup.filters.length === 1 && !filterGroup.activeFilters?.length)"
    id="filterContainer"
    class="d-flex flex-row justify-content-center b-2"
  >
    <div class="d-flex justify-content-center align-items-center p-1">
      <mat-icon class="icon" *ngIf="labels && labels[i] && filters[i].requestAmount" [matTooltip]="labels[i] | translate">info</mat-icon>
    </div>

    <div class="filters d-flex scrollbar-styled-smaller px-1 py-1" style="overflow-x: auto">
      <ng-container *ngFor="let filter of filterGroup.filters">
        <button
          *ngIf="filter.amount > 0"
          (click)="activateFilter(filter, filterGroup)"
          class="mx-2 badge"
          mat-stroked-button
          [matBadge]="filter.amount > 99 ? '99+' : filter.amount"
          matBadgePosition="after"
          matBadgeColor="accent"
          [ngClass]="{'active': filterGroup.activeFilters?.includes(filter)}"
          style="min-width: max-content"
        >
          {{filter.title | translate}}
        </button>
      </ng-container>
    </div>
  </div>
</ng-container>

<!-- *ngIf="filter.amount > 0  && filter.amount !== filterGroup.requestAmount" -->
