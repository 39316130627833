import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";
import { AreaAction } from "../classes/flow/AreaActions/AreaAction";
import { convertDate } from "../helpers/convertDate";

@Injectable({
  providedIn: "root",
})
export class AreaActionService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  public async all(usePublic?: boolean, onlyActive?: boolean): Promise<AreaAction[]> {
    const response = await this.graphqlService.query(
      `
      query {
        areaActions {
          value
          ${onlyActive ? "(where: {expired: {eq: false}})" : ""}
          {
            id
            municipality
            area
            actionType
            dateFrom
            dateTo
            zipCodeFrom
            zipCodeTo
            expired
            extraProperties
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
        }
      }
    `,
      !!usePublic
    );

    const value = response.data.areaActions.value;

    return value.map((a: any) => {
      return new AreaAction({
        id: a.id,
        dateFrom: new Date(a.dateFrom),
        dateTo: new Date(a.dateTo),
        zipCodeFrom: a.zipCodeFrom,
        zipCodeTo: a.zipCodeTo,
        actionType: a.actionType,
        area: a.area,
        extraProperties: a.extraProperties ? JSON.parse(a.extraProperties) : {},
        changes: this.graphqlService.createChangesObject(a.changes),
        expired: a.expired,
        municipality: a.municipality,
      });
    });
  }

  public async add(
    value: Partial<{
      zipCodeFrom: string | null;
      zipCodeTo: string | null;
      dateFrom: Date | null;
      dateTo: Date | null;
      area: string | null;
      municipality: string | null;
    }>
  ): Promise<void> {
    const response = await this.graphqlService.query(`
      mutation {
        addAreaAction(
          areaAction: {
            ${value.zipCodeFrom ? `zipCodeFrom: "${value.zipCodeFrom}"` : ""}
            ${value.zipCodeTo ? `zipCodeTo: "${value.zipCodeTo}"` : ""}
            ${value.dateFrom ? `dateFrom: "${convertDate(value.dateFrom)}"` : ""}
            ${value.dateTo ? `dateTo: "${convertDate(value.dateTo)}"` : ""}
            ${value.area ? `area: "${value.area}"` : ""}
            ${value.municipality ? `municipality: "${value.municipality}"` : ""}
          }
        ) {
          value {
            id
          }
          messages {
            message
          }
        }
      }
  `);

    if (!response.data.addAreaAction.value.id) throw new Error("Insert Mutation Failed.");
  }

  public async update(areaAction: AreaAction): Promise<void> {
    const response = await this.graphqlService.query(`
      mutation {
        updateAreaAction(
          input: {
            id: ${areaAction.id}
            set: {
              ${areaAction.zipCodeFrom ? `zipCodeFrom: "${areaAction.zipCodeFrom}"` : ""}
              ${areaAction.zipCodeTo ? `zipCodeTo: "${areaAction.zipCodeTo}"` : ""}
              ${areaAction.dateFrom ? `dateFrom: "${convertDate(areaAction.dateFrom)}"` : ""}
              ${areaAction.dateTo ? `dateTo: "${convertDate(areaAction.dateTo)}"` : ""}
              ${areaAction.area ? `area: "${areaAction.area}"` : ""}
              ${areaAction.municipality ? `municipality: "${areaAction.municipality}"` : ""}
              changes: ${this.graphqlService.formatChangesObject(areaAction)}
            }
          }
        ) {
          value {
            id
          }
          messages {
            message
          }
        }
      }
  `);

    if (!response.data.updateAreaAction.value.id) throw new Error("Insert Mutation Failed.");
  }

  public async delete(areaAction: AreaAction): Promise<void> {
    const response = await this.graphqlService.query(`
    mutation {
      updateAreaAction(
        input: {
          id: ${areaAction.id}
          set: {
            expired: true
            changes: ${this.graphqlService.formatChangesObject(areaAction)}
          }
        }
      ) {
        value {
          id
        }
        messages {
          message
        }
      }
    }
`);

    if (!response.data.updateAreaAction.value.id) throw new Error("Insert Mutation Failed.");
  }

  public zipCodeInArea(zipCode: string, areaAction: AreaAction): boolean {
    if (!zipCode || !(zipCode.length > 0)) {
      return false;
    }

    const splittedZip = this.extractZipParts(zipCode.replace(/\s/g, "").toUpperCase());
    const splittedStartZip = this.extractZipParts(areaAction.zipCodeFrom.replace(/\s/g, "").toUpperCase());
    const splittedEndZip = this.extractZipParts(areaAction.zipCodeTo.replace(/\s/g, "").toUpperCase());

    // zipcode number is in between start & end
    if (splittedZip.number > splittedStartZip.number && splittedZip.number < splittedEndZip.number) {
      return true;
    }

    //splittedzip === start
    //check letters
    if (splittedZip.number === splittedStartZip.number) {
      if (splittedZip.firstChar > splittedStartZip.firstChar) {
        return true;
      } else if (splittedZip.firstChar === splittedStartZip.firstChar) {
        if (splittedZip.secondChar >= splittedStartZip.secondChar) {
          return true;
        }
      }
      return false;
    }

    //splittedzip === end
    //check letters
    if (splittedZip.number === splittedEndZip.number) {
      if (splittedZip.firstChar < splittedEndZip.firstChar) {
        return true;
      } else if (splittedZip.firstChar === splittedEndZip.firstChar) {
        if (splittedZip.secondChar <= splittedEndZip.secondChar) {
          return true;
        }
      }
      return false;
    }

    return false;
  }

  private extractZipParts(zipCode: string): { number: number; firstChar: number; secondChar: number } {
    return {
      number: Number(zipCode.slice(0, 4)),
      firstChar: this.alphaToNumber(zipCode[4]),
      secondChar: this.alphaToNumber(zipCode[5]),
    };
  }

  private alphaToNumber(char: string): number {
    return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".indexOf(char.toUpperCase());
  }
}
