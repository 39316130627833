import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AddressService {
  constructor(private http: HttpClient) {}

  public async lookup(postalCode: string, houseNumber: string, houseNumberSuffix: string | null): Promise<AddressDetails | null> {
    const queryString = `${postalCode} ${houseNumber} ${houseNumberSuffix ?? ""}`;

    const result: any = await firstValueFrom(
      this.http.get("https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest", {
        params: {
          wt: "json",
          q: queryString,
        },
      })
    );

    if (!result?.response?.docs?.[0]?.weergavenaam) return null;

    const displayName: string = result?.response?.docs?.[0]?.weergavenaam;
    const streetNameAndHouseNumber = displayName.split(", ")[0];
    const postalCodeAndTown = displayName.split(", ")[1];

    const resStreetName = streetNameAndHouseNumber.split(" ").slice(0, -1).join(" ");
    const resTown = postalCodeAndTown.split(" ")[1];

    return {
      streetName: resStreetName,
      town: resTown,
    };
  }

  public async lookupAreaByPostal(postalCode: string): Promise<{ gemeentenaam: string | null; provincienaam: string | null }> {
    const queryString = `${postalCode}`;

    const result: any = await firstValueFrom(
      this.http.get("https://api.pdok.nl/bzk/locatieserver/search/v3_1/suggest", {
        params: {
          wt: "json",
          q: queryString,
          fl: "provincienaam gemeentenaam",
        },
      })
    );

    return { provincienaam: result?.response?.docs?.[0]?.provincienaam.toUpperCase() ?? null, gemeentenaam: result?.response?.docs?.[0]?.gemeentenaam.toUpperCase() ?? null };
  }
}

export interface AddressDetails {
  streetName: string;
  town: string;
}
