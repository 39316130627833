import { NgModule } from "@angular/core";
import { RouterModule, Route } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";
import { UsersComponent } from "./screens/content/coordinator/user/users.component";
import { AllQuestionnairesComponent } from "./screens/content/all-questionnaires/all-questionnaires.component";
import { NewQuestionnaireComponent } from "./screens/new-questionnaire/new-questionnaire.component";
import { ContentComponent } from "./screens/content/content";
import { CoachComponent } from "./screens/content/coach/coach.component";
import { CoordinatorComponent } from "./screens/content/coordinator/Coordinator.component";
import { UserAllComponent } from "./screens/content/coordinator/user/all/all.component";
import { ChooseRoleComponent } from "./screens/choose-role/choose-role.component";
import { CoachEnergyConsultComponent } from "./screens/content/coach/coach-energy-consult/coach-energy-consult.component";
import { NewCoachComponent } from "./screens/content/coordinator/new-coach/new-coach.component";
import { NewRetailerComponent } from "./screens/content/coordinator/new-retailer/new-retailer.component";
import { NewEnergyConsultComponent } from "./screens/guest/new-energy-consult/new-energy-consult.component";
import { DashboardComponent } from "./screens/content/shared/dashboard/dashboard";
import { Error_403Component } from "./screens/error/403/403";
import { Error_404Component } from "./screens/error/404/404";
import { ErrorComponent } from "./screens/error/error";
import { ProfileComponent } from "./screens/content/shared/profile/profile.component";
import { ReportComponent } from "./screens/report/report.component";
import { LoggedOutComponent } from "./screens/guest/logged-out/logged-out.component";
import { QuestionnaireComponent } from "./screens/content/all-questionnaires/questionnaire/questionnaire.component";
import { ViewReportComponent } from "./screens/view-report/view-report.component";
import { IsLoggedInGuard } from "./guards/isLoggedIn";
import { FAQComponent } from "./screens/faq/faq.component";
import { LoginFailedComponent } from "./screens/login-failed/login-failed.component";
import { RoleName } from "./classes/flow/session/Session";
import { HasRoleGaurd } from "./guards/hasRole";
import { IsActiveGuard } from "./guards/isActive";
import { ResidentComponent } from "./screens/content/resident/Resident.component";
import { RetailersComponent } from "./screens/content/coordinator/retailers/retailers.component";
import { RetailerComponent } from "./screens/content/retailer/retailer.component";
import { ReplacementClaimNewComponent } from "./screens/content/coach/replacement-claim-new/replacement-claim-new.component";

import { DashboardRetailerComponent } from "./screens/content/shared/dashboard/dashboard-retailer/dashboard-retailer.component";
import { NewEnergyConsultExistingResidentComponent } from "./screens/guest/new-energy-consult-existing-resident/new-energy-consult-existing-resident.component";
import { RetailerLandingComponent } from "./screens/retailer-landing/retailer-landing.component";
import { QrcodeLandingComponent } from "./screens/qrcode-landing/qrcode-landing.component";
import { ReplacementClaimScheduleComponent } from "./screens/content/retailer/replacement-claim-schedule/replacement-claim-schedule.component";
import { RetailerPayoutComponent } from "./screens/content/retailer/retailer-payout/retailer-payout.component";
import { ReplacementClaimRejectComponent } from "./screens/content/retailer/replacement-claim-reject/replacement-claim-reject.component";
import { ReplacementClaimDeliverComponent } from "./screens/content/retailer/replacement-claim-deliver/replacement-claim-deliver.component";
import { PaymentrequestNewComponent } from "./screens/content/retailer/paymentrequest-new/paymentrequest-new.component";
import { ReplacementClaimEditComponent } from "./screens/content/coach/replacement-claim-edit/replacement-claim-edit.component";
import { Module } from "./classes/flow/base/Module";
import { ModuleGuard } from "./guards/Module";
import { AreaActionsComponent } from "./screens/content/coordinator/area-actions/area-actions.component";
import { UnderConstructionComponent } from "./screens/under-construction/under-construction.component";
import { ENVIRONMENT } from "../environments/environment";
import { SpecialtyGuard } from "./guards/Specialty";
import { DashboardStatisticsComponent } from "./screens/content/shared/dashboard-statistics/dashboard-statistics.component";
import { ReportCategoriesComponent } from "./screens/content/coordinator/report-categories/report-categories.component";

export interface RouteData {
  allowedSpecialties?: number[];
  allowedRoles?: RoleName[];
  noAuthRedirectURL?: string;
  module?: Module;
}

interface AppRoute extends Route {
  data?: RouteData;
  children?: AppRoute[];
}

const routes: AppRoute[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "request",
  },
  {
    path: "under-construction",
    component: UnderConstructionComponent,
  },
  {
    path: "content",
    component: ContentComponent,

    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ContentComponent,
        canActivate: [IsActiveGuard],
      },
      {
        path: "coordinator",
        component: CoordinatorComponent,
        canActivate: [HasRoleGaurd, IsActiveGuard],
        data: { allowedRoles: ["coordinator"] },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "dashboard",
          },
          {
            path: "dashboard",
            component: DashboardComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13], noAuthRedirectURL: "/content/coordinator//dashboard-statistics" },
          },
          {
            path: "dashboard-statistics",
            component: DashboardStatisticsComponent,
            canActivate: [SpecialtyGuard, ModuleGuard],
            data: { allowedSpecialties: [12], module: "STATISTICS_DASHBOARD" },
          },
          {
            path: "dashboard/:id",
            component: DashboardComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13] },
          },
          {
            path: "new-coach",
            component: NewCoachComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13] },
          },
          {
            path: "new-retailer",
            component: NewRetailerComponent,
            canActivate: [ModuleGuard, SpecialtyGuard],
            data: { module: "RETAILER", allowedSpecialties: [13] },
          },
          {
            path: "new-energy-consult-existing-resident/:id",
            component: NewEnergyConsultExistingResidentComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13] },
          },
          {
            path: "users",
            component: UsersComponent,
            children: [
              {
                path: "",
                pathMatch: "full",
                redirectTo: "all",
              },
              {
                path: "all",
                component: UserAllComponent,
                canActivate: [SpecialtyGuard],
                data: { allowedSpecialties: [13] },
              },
            ],
          },
          {
            path: "retailers",
            component: RetailersComponent,
            canActivate: [ModuleGuard, SpecialtyGuard],
            data: { module: "RETAILER", allowedSpecialties: [13] },
          },
          {
            path: "area-actions",
            component: AreaActionsComponent,
            canActivate: [ModuleGuard, SpecialtyGuard],
            data: { module: "AREA_ACTIONS", allowedSpecialties: [13] },
          },
          {
            path: "report-categories",
            component: ReportCategoriesComponent,
            canActivate: [ModuleGuard, SpecialtyGuard],
            data: { module: "REPORT_CATEGORIES", allowedSpecialties: [13] },
          },
          {
            path: "all-questionnaires",
            component: AllQuestionnairesComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13] },
          },
          {
            path: "new-questionnaire",
            component: NewQuestionnaireComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13] },
          },
          {
            path: "questionnaire/:id",
            component: QuestionnaireComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13] },
          },
          {
            path: "request/:id",
            component: CoachEnergyConsultComponent,
            canActivate: [SpecialtyGuard],
            data: { allowedSpecialties: [13] },
          },
          {
            path: "**",
            redirectTo: "error/404",
          },
        ],
      },
      {
        path: "coach",
        component: CoachComponent,
        canActivate: [HasRoleGaurd, IsActiveGuard],
        data: { allowedRoles: ["coach"] },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "dashboard",
          },
          {
            path: "dashboard",
            component: DashboardComponent,
          },
          {
            path: "new-energy-consult-existing-resident/:id",
            component: NewEnergyConsultExistingResidentComponent,
          },
          {
            path: "request/:id",
            component: CoachEnergyConsultComponent,
          },
          {
            path: "request/:id/report",
            component: ReportComponent,
          },
          {
            path: "replacement-claim/new/:energyConsultId",
            component: ReplacementClaimNewComponent,
            canActivate: [ModuleGuard],
            data: { module: "REPLACEMENT_CLAIMS" },
          },
          {
            path: "replacement-claim/edit/:replacementClaimId",
            component: ReplacementClaimEditComponent,
            canActivate: [ModuleGuard],
            data: { module: "REPLACEMENT_CLAIMS" },
          },
          {
            path: "**",
            redirectTo: "error/404",
          },
        ],
      },
      {
        path: "resident",
        component: ResidentComponent,
        canActivate: [HasRoleGaurd, IsActiveGuard],
        data: { allowedRoles: ["resident"] },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "dashboard",
          },
          {
            path: "dashboard",
            component: DashboardComponent,
          },
          {
            path: "report/:id",
            component: ViewReportComponent,
          },
          {
            path: "**",
            redirectTo: "error/404",
          },
        ],
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
      {
        path: "profile/:id",
        component: ProfileComponent,
        canActivate: [IsActiveGuard],
      },
      {
        path: "retailer",
        component: RetailerComponent,
        canActivate: [ModuleGuard, HasRoleGaurd, IsActiveGuard],
        data: { allowedRoles: ["retailer"], module: "RETAILER" },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "dashboard",
          },
          {
            path: "dashboard",
            component: DashboardRetailerComponent,
            canActivate: [ModuleGuard],
            data: { module: "RETAILER" },
          },
          {
            path: "payout",
            component: RetailerPayoutComponent,
            canActivate: [ModuleGuard],
            data: { module: "RETAILER" },
          },
          {
            path: "paymentrequest-new",
            component: PaymentrequestNewComponent,
            canActivate: [ModuleGuard],
            data: { module: "RETAILER" },
          },
          {
            path: "new-energy-consult-existing-resident",
            component: NewEnergyConsultExistingResidentComponent,
            canActivate: [ModuleGuard],
            data: { module: "RETAILER" },
          },
          {
            path: "report/:id",
            component: ViewReportComponent,
            canActivate: [ModuleGuard],
            data: { module: "RETAILER" },
          },
          {
            path: "replacement-claim/:replacementClaimId/schedule/:replacementClaimApplianceOrderId",
            component: ReplacementClaimScheduleComponent,
            canActivate: [ModuleGuard],
            data: { module: "REPLACEMENT_CLAIMS" },
          },
          {
            path: "replacement-claim/:replacementClaimId/deliver/:replacementClaimApplianceDeliveryId",
            component: ReplacementClaimDeliverComponent,
            canActivate: [ModuleGuard],
            data: { module: "REPLACEMENT_CLAIMS" },
          },
          {
            path: "replacement-claim/:id/reject-order/:orderId",
            component: ReplacementClaimRejectComponent,
            canActivate: [ModuleGuard],
            data: { module: "REPLACEMENT_CLAIMS" },
          },
          {
            path: "replacement-claim/:id/reject-delivery/:deliveryId",
            component: ReplacementClaimRejectComponent,
            canActivate: [ModuleGuard],
            data: { module: "REPLACEMENT_CLAIMS" },
          },
          {
            path: "**",
            redirectTo: "error/404",
          },
        ],
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
    ],
  },
  {
    path: "error",
    component: ErrorComponent,
    children: [
      {
        path: "404",
        component: Error_404Component,
      },
      {
        path: "403",
        component: Error_403Component,
      },
    ],
  },
  {
    path: "login",
    component: NewEnergyConsultComponent,
  },
  {
    path: "login-failed",
    component: LoginFailedComponent,
  },
  {
    path: "faq",
    component: FAQComponent,
  },
  {
    path: "loggedout",
    canActivate: [IsLoggedInGuard],
    component: LoggedOutComponent,
  },
  {
    path: "role-init",
    pathMatch: "full",
    component: ChooseRoleComponent,
  },
  {
    path: "request",
    canActivate: [HasRoleGaurd],
    data: {
      allowedRoles: [
        "resident",
        "guest",
        "user",
        ...(Object(ENVIRONMENT.MODULES.includes("NEW_REQUEST_COACH") ? ["coach"] : []) as RoleName[]),
        ...(Object(ENVIRONMENT.MODULES.includes("NEW_REQUEST_COORDINATOR") ? ["coordinator"] : []) as RoleName[]),
      ],
      noAuthRedirectURL: "/content",
    },
    component: NewEnergyConsultComponent,
  },
  {
    path: "request/:specialty",
    canActivate: [HasRoleGaurd],
    data: {
      allowedRoles: ["resident", "guest", "user"],
      noAuthRedirectURL: "/content",
    },
    component: NewEnergyConsultComponent,
  },
  {
    path: "retailer",
    canActivate: [HasRoleGaurd],
    data: {
      allowedRoles: ["guest", "user"],
      noAuthRedirectURL: "/content",
    },
    component: RetailerLandingComponent,
  },
  {
    path: "qrcode",
    component: QrcodeLandingComponent,
  },
  { path: "**", redirectTo: "error/404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? "enabledBlocking" : "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
