import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Header } from "../table/Header";
import { AreaAction } from "../../classes/flow/AreaActions/AreaAction";
import { LocalDatePipe } from "../../pipes/date.pipe";

@Component({
  selector: "app-area-actions-overview",
  templateUrl: "./area-actions-overview.component.html",
  styleUrls: ["./area-actions-overview.component.less"],
})
export class AreaActionsOverviewComponent {
  @Input()
  public data: AreaAction[];

  @Output()
  public edit: EventEmitter<AreaAction>;

  @Output()
  public delete: EventEmitter<AreaAction>;

  public tableColumns: Header[];

  constructor(private localDatePipe: LocalDatePipe) {
    this.data = [];
    this.tableColumns = [
      { visualName: "DATERANGE", resolver: (e) => this.dateRangeResolver(e as AreaAction) },
      { visualName: "ZIPCODERANGE", resolver: (e) => this.zipCodeRangeResolver(e as AreaAction) },
      { visualName: "AREA", mappedTo: "area" },
      { visualName: "MUNICIPALITY", mappedTo: "municipality" },
      {
        visualName: "EDIT",
        click: {
          clickHandler: (e: object) => this.editClickHandler(e as AreaAction),
          icon: "edit",
          color: "primary",
        },
      },
      {
        visualName: "DELETE",
        click: {
          clickHandler: (e: object) => this.deleteClickHandler(e as AreaAction),
          icon: "delete",
          color: "warn",
        },
      },
    ];

    this.edit = new EventEmitter();
    this.delete = new EventEmitter();
  }

  private dateRangeResolver(e: AreaAction): string {
    return `${this.localDatePipe.transform(e.dateFrom, "dd-MM-YYYY")} - ${this.localDatePipe.transform(e.dateTo, "dd-MM-YYYY")}`;
  }

  private zipCodeRangeResolver(e: AreaAction): string {
    return `${e.zipCodeFrom} - ${e.zipCodeTo}`;
  }

  public editClickHandler(e: AreaAction) {
    this.edit.emit(e);
  }

  public deleteClickHandler(e: AreaAction) {
    this.delete.emit(e);
  }
}
