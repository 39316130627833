import { Component } from "@angular/core";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-global-loading",
  templateUrl: "./global-loading.component.html",
  styleUrls: ["./global-loading.component.less"],
})
export class GlobalLoadingComponent {
  constructor(public applicationService: ApplicationService) {}
}
