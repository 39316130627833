<div class="container">
  <mat-tab-group mat-stretch-tabs="true" class="col-xl-6 col-md-10" [color]="showWarningText || (control.invalid && control.touched) ? 'warn' : 'primary'">
    <mat-tab label="{{ this.startTime }}">
      <div class="mt-2">
        <app-custom-timepicker #timeStart givenFormControlName="timeStart" [givenFormGroup]="timeGroup"></app-custom-timepicker>
        <div class="text-danger" *ngIf="showWarningText">{{ "COMPONENTS.ALL_QUESTIONNAIRES.TIME_PICKER_ERROR" | translate }}</div>
      </div>
    </mat-tab>

    <mat-tab label="{{ this.endTime }}">
      <div class="mt-2">
        <app-custom-timepicker #timeEnd givenFormControlName="timeEnd" [givenFormGroup]="timeGroup"></app-custom-timepicker>
        <div class="text-danger" *ngIf="showWarningText">{{ "COMPONENTS.ALL_QUESTIONNAIRES.TIME_PICKER_ERROR" | translate }}</div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <app-error [control]="control"></app-error>
</div>
