import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "postalcode",
})
export class PostalcodePipe implements PipeTransform {
  /**
   * @param value postalcode like 1234ab or 1234 ab
   * @returns formatted like 1234 AB
   */
  transform(value: string): unknown {
    return value.substring(0, 4) + " " + value.toUpperCase().replace(" ", "").substring(4, 6);
  }
}
