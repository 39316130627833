<div class="container mt-1">
  <mat-accordion [multi]="true">
    <ng-container *ngIf="mails.length !== 0">
      <ng-container *ngFor="let mail of mails">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <div class="d-flex flex-column">
              <div>
                {{ mail.subject }}
              </div>
              <div class="text-muted">
                {{ "COMPONENTS.COACH_REQUEST.MAIL_TIMELINE.STATUS." + mail.events[mail.events.length - 1].type | translate }}
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="timeline">
            <div *ngFor="let event of mail.events" class="timeline-item">
              <div class="timeline-icon d-flex justify-content-center align-items-center">
                <mat-icon>{{ MailEventIcons[event.type] }}</mat-icon>
              </div>
              <div class="timeline-content">
                <div class="timeline-date">
                  <p class="timeline-event" [ngStyle]="{ color: event.type !== 'ERROR' ? 'black' : 'red' }">
                    {{ "COMPONENTS.COACH_REQUEST.MAIL_TIMELINE.STATUS." + event.type | translate }}
                  </p>
                  <div class="text-muted">{{ event.received | date : "mediumDate" }} - {{ event.received | date : "HH:mm" }}</div>
                </div>
                <p class="text-muted">{{ event.message ?? ("COMPONENTS.COACH_REQUEST.MAIL_TIMELINE.MESSAGE." + event.type | translate) }}</p>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="mails.length === 0">
      <span class="text-muted">
        {{ "COMPONENTS.COACH_REQUEST.MAIL_TIMELINE.NO_MAILS" | translate }}
      </span>
    </ng-container>
  </mat-accordion>
</div>
