import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-under-construction",
  templateUrl: "./under-construction.component.html",
  styleUrls: ["./under-construction.component.less"],
})
export class UnderConstructionComponent implements OnInit, OnDestroy {
  private titleSubscription?: Subscription;

  constructor(title: Title, translateService: TranslateService, private router: Router, private application: ApplicationService) {
    this.titleSubscription = translateService.get("COMPONENTS.UNDER_CONSTRUCTION.TITLE").subscribe((value) => {
      title.setTitle(value);
    });
  }

  ngOnInit(): void {
    this.application.setLoading(false);
  }

  ngOnDestroy(): void {
    this.titleSubscription?.unsubscribe();
  }

  public goToDashboard() {
    this.application.loading = true;
    this.router.navigate(["/request"]);
  }
}
