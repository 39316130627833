const convertBase64ToByteArray = (value: string): Buffer => {
  return Buffer.from(value, "base64");
};

export const downloadFile = (base64Data: string, name: string, type: string) => {  
  const blob = new Blob([convertBase64ToByteArray(base64Data)], { type: type });
  const a = document.createElement("a");
  const url = window.URL.createObjectURL(blob as any);
  a.href = url;
  a.download = name;
  document.body.append(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};