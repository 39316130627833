<div id="qr-code">
  <ngx-scanner-qrcode #scanner="scanner" (event)="saveQR($event)" [config]="config"></ngx-scanner-qrcode>
  <div class="qr-overlay prescan" *ngIf="!scanner.isStart && !scanResult">
    <div class="actions">
      <button type="button" mat-raised-button color="primary" (click)="startCamera()">{{ "FORMS.SCAN" | translate }}</button>
      <button type="button" mat-raised-button (click)="closeCamera()">{{ "FORMS.CLOSE" | translate }}</button>
    </div>
  </div>
  <div class="qr-overlay duringscan" *ngIf="scanner.isStart">
    <div class="actions">
      <button type="button" mat-raised-button (click)="closeCamera()">{{ "COMPONENTS.QR_SCANNER.CLOSE" | translate}}</button>
    </div>
  </div>
  <div class="qr-overlay postscan" *ngIf="!scanner.isStart && scanResult">
    <h2>{{ "FORMS.SCANNED" | translate }}!</h2>
    <div class="actions">
      <button type="button" mat-raised-button (click)="startCamera()">{{ "FORMS.RESCAN" | translate }}</button>
      <button type="button" mat-raised-button color="primary" (click)="closeCamera()">{{ "FORMS.SAVE_AND_CLOSE" | translate }}</button>
    </div>
  </div>
</div>
