<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column text-center">
      <h2>{{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.REPLACEMENTCLAIM" | translate}}</h2>

      <mat-vertical-stepper linear #stepper class="customized-stepper">
        <mat-step [stepControl]="form.controls.newAppliance" label="{{ 'COMPONENTS.REPLACEMENT_CLAIM_EDIT.NEW_DEVICE' | translate }}">
          <form [formGroup]="form.controls.newAppliance">
            <div class="row row-cols-md-4 row-cols-sm-2 row-cols-2">
              <app-radio-image *ngFor="let cat of categoryOptions" [id]="cat.id" [label]="cat.name" [imagePath]="'/assets/images/appliancecategories/'+cat.id+'.png'" [disabled]="this.form.controls.newAppliance.controls.category.disabled" [selected]="this.form.controls.newAppliance.controls.category.value === cat.id" (applianceSelect)="selectAppliance($event)"></app-radio-image>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.DESCRIPTION" | translate}}</mat-label>
                  <textarea matInput formControlName="description"></textarea>
                  <app-error [control]="form.controls.newAppliance.controls.description"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="d-flex gap-3 flex-wrap" *ngIf="editMode">
              <button type="button" *ngIf="!this.form.controls.newAppliance.controls.category.disabled" [disabled]="!this.form.controls.newAppliance.valid" mat-raised-button color="primary" (click)="submitReplacementClaimAppliance()">
                {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.SAVE_DEVICE" | translate}}
              </button>
              <button type="button" class="text-break" *ngIf="form.controls.returnAppliances.length === 0" (click)="addReturnApplianceControl()" mat-raised-button>
                {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.RETURN_DEVICE" | translate}}
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step *ngFor="let returnApplianceControl of form.controls.returnAppliances.controls; let i = index" [stepControl]="returnApplianceControl" [label]="'Retour apparaat ' + i + 1" D completed="false">
          <form [formGroup]="returnApplianceControl">
            <ng-template matStepLabel> {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.RETURN" | translate}} {{ i + 1 }}</ng-template>

            <div class="row pt-2">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.TYPE" | translate}}</mat-label>
                  <mat-select formControlName="type">
                    <mat-option *ngFor="let option of typeOptions" [value]="option.id">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                  <app-error [control]="returnApplianceControl.controls.type"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row" *ngIf="returnApplianceControl.controls.type.value">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.CATEGORY" | translate}}</mat-label>
                  <mat-select formControlName="category">
                    <mat-option *ngFor="let option of applianceReturnCategoryFilteredOptions[i] | async" [value]="option.id">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                  <app-error [control]="returnApplianceControl.controls.category"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.DESCRIPTION" | translate}}</mat-label>
                  <textarea matInput formControlName="description"></textarea>
                  <app-error [control]="returnApplianceControl.controls.description"></app-error>
                </mat-form-field>
              </div>
            </div>

            <app-qr-input [label]="'COMPONENTS.QR_INPUT.LABEL_EDIT'"[control]="returnApplianceControl.controls.qr"></app-qr-input>
            <div class="d-flex justify-content-start gap-3 flex-wrap" *ngIf="editMode">
              <button type="button" *ngIf="!returnApplianceControl.controls.type.disabled" [disabled]="!returnApplianceControl.valid" mat-raised-button color="primary" (click)="submitReplacementClaimReturnAppliance(i)">
                {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.SAVE_RETURN" | translate}}
              </button>
              <button type="button" *ngIf="form.controls.returnAppliances.length < maxReturnAppliances" (click)="addReturnApplianceControl()" mat-raised-button>
                {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.ADD_RETURN_DEVICE" | translate}}
              </button>
              <button type="button" *ngIf="form.controls.returnAppliances.length > minReturnAppliances && !returnApplianceControl.controls.type.disabled" (click)="removeReturnAppliance(i)" mat-raised-button color="warn">
                {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.DELETE_DEVICE" | translate}}
              </button>
            </div>
          </form>
        </mat-step>

        <mat-step [stepControl]="form.controls.retailer" label="{{ 'COMPONENTS.REPLACEMENT_CLAIM_EDIT.RETAILER_PREFERENCE' | translate }}" completed="false">
          <form [formGroup]="form.controls.retailer">
            <div class="row pt-2">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.RETAILER" | translate}}</mat-label>
                  <mat-select formControlName="retailerIdPreferred">
                    <mat-option *ngFor="let retailer of retailerOptions" [value]="retailer.id">
                      {{ retailer.firstName + " " + retailer.lastName }}
                    </mat-option>
                  </mat-select>
                  <app-error [control]="form.controls.retailer.controls.retailerIdPreferred"></app-error>
                </mat-form-field>
              </div>
            </div>

            <div class="d-flex justify-content-start gap-3 flex-wrap" *ngIf="editMode">
              <button type="button" *ngIf="!this.form.controls.retailer.controls.retailerIdPreferred.disabled" [disabled]="!this.form.controls.retailer.valid" mat-raised-button color="primary" (click)="submitReplacementClaimRetailer()">
                {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.SAVE_PREFERENCE" | translate}}
              </button>
              <button type="button" *ngIf="!this.form.controls.retailer.controls.retailerIdPreferred.disabled" [disabled]="this.form.controls.retailer.valid" mat-raised-button color="warn" (click)="submitReplacementClaimRetailer()">
                {{ "COMPONENTS.REPLACEMENT_CLAIM_EDIT.WITHOUT_PREFERENCE" | translate}}
              </button>
            </div>
          </form>
        </mat-step>

        <ng-template matStepperIcon="done">
          <mat-icon>done</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="error">
          <mat-icon>priority_high</mat-icon>
        </ng-template>
      </mat-vertical-stepper>
    </div>
  </div>
</div>
