<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column text-center">
      <div class="col-md">
        <h2>{{ "COMPONENTS.ALL_QUESTIONNAIRES.TITLE" | translate }}</h2>
        <button mat-raised-button [routerLink]="['/content/coordinator/new-questionnaire']">{{ "COMPONENTS.ALL_QUESTIONNAIRES.ADD_QUESTIONNAIRE" | translate }}</button>
      </div>
      <div class="row">
        <div class="col-md">
          <ng-container *ngFor="let table of data | keyvalue : tableOrder">
            <h6 class="d-flex align-items-center mt-2">{{ "COMPONENTS.ALL_QUESTIONNAIRES.TABLEHEADINGS." + table.key | translate }}</h6>
            <div style="margin: 1.5rem 0" *ngFor="let questionnaires of table.value.questionnaires | keyvalue">
              <app-table id="QUESTIONNAIRES" [data]="questionnaires.value" [pageSizes]="[10]" [headers]="table.value.headers"></app-table>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteDialog let-questionnaire="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="warn" (click)="deleteQuestionnaire(questionnaire); dialogService.close()">{{ "FORMS.DELETE" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #activateDialog let-questionnaire="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.ALL_QUESTIONNAIRES.PUBLISH_QUESTIONNAIRE.TITLE" | translate }}</h3>
        <button mat-raised-button (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="updateQuestionnaireStatus(questionnaire); dialogService.close()">{{ "FORMS.PUBLISH" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deactivateDialog let-questionnaire="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.ALL_QUESTIONNAIRES.DEACTIVATE_QUESTIONNAIRE.TITLE" | translate }}</h3>
        <button mat-raised-button (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="warn" (click)="updateQuestionnaireStatus(questionnaire); dialogService.close()">{{ "FORMS.DEACTIVATE" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #copyDialog let-data="data">
  <div class="container-fluid copyDialog">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.ALL_QUESTIONNAIRES.COPY.DIALOGTITLE" | translate }}</h3>

        <mat-form-field appearance="fill">
          <mat-label>{{ "COMPONENTS.ALL_QUESTIONNAIRES.COPY.TITLE" | translate }}</mat-label>
          <input type="text" matInput [formControl]="data.form.controls.title" />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "COMPONENTS.ALL_QUESTIONNAIRES.COPY.DESCRIPTION" | translate }}</mat-label>
          <textarea matInput [formControl]="data.form.controls.description"></textarea>
        </mat-form-field>

        <div class="d-flex flex-column gap-2">
          <span class="d-inline-flex gap-2">
            <mat-icon class="text-warning">warning</mat-icon>
            <strong>{{ "FORMS.NEW_QUESTIONNAIRE.WARNING" | translate }}</strong>
          </span>
          <p>{{ "FORMS.NEW_QUESTIONNAIRE.WARNING_COPY_COMPARE" | translate }}</p>
        </div>

        <button mat-raised-button (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="copyQuestionnaire(data)" [disabled]="data.form.invalid">
          {{ "FORMS.COPY" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #setOrderTemplate let-data="data">
  <button
    mat-icon-button
    (click)="setIndex(data)"
    [disabled]="data.sortOrder === 0"
    [matTooltip]="'COMPONENTS.ALL_QUESTIONNAIRES.SET_ORDER_TOOLTIP' | translate"
    [attr.aria-label]="'COMPONENTS.ALL_QUESTIONNAIRES.SET_ORDER_TOOLTIP' | translate"
  >
    <mat-icon>north</mat-icon>
  </button>
</ng-template>
