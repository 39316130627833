import { Coach } from "../session/impl/Coach";
import { Resident } from "../session/impl/Resident";
import { Specialty } from "../Questionnaire/Specialty";
import { Changes } from "../base/Changes";
import { RequestState, RequestStates } from "./RequestStates";
import { Request } from "./Request";
import { ApplicationService } from "../../../services/application.service";
import { ENVIRONMENT } from "../../../../environments/environment";
import { AreaAction } from "../AreaActions/AreaAction";

export class EnergyConsult extends Request {
  public message: string;
  public postalCode: string;
  public houseNumber: number;
  public specialty: Specialty;
  public resident?: Resident;
  public houseNumberSuffix?: string;
  public rejectReason?: string;
  public coach?: Coach;
  public appointmentDate?: Date;
  public disapprovalReason?: string;
  public ffwdContentId?: string;
  public ffwdContentPartId?: string;
  public extraProperties?: {
    preferredTimeSlots?: TimeSlot[];
    houseCoordinates?: Coordinates;
    postalcodeBounds?: Coordinates[];
    postalcodeCoordinates?: Coordinates;
    streetname?: string;
    addressdetails?: {
      town?: string;
      municipality?: string;
      province?: string;
    };
    note?: string;
  };
  public areaActions?: AreaAction[];
  public ckd?: boolean;
  public ckdInfo?: string;
  public mailHistoryId?: string;
  public bookmarked?: boolean;

  public constructor({
    id,
    message,
    postalCode,
    houseNumber,
    requestDate,
    state,
    specialty,
    resident,
    houseNumberSuffix,
    lastStateChangeDate,
    rejectReason,
    coach,
    appointmentDate,
    disapprovalReason,
    changes,
    ffwdContentId,
    ffwdContentPartId,
    extraProperties,
    areaActions,
    ckd,
    ckdInfo,
    mailHistoryId,
    bookmarked,
  }: {
    id: number;
    message: string;
    postalCode: string;
    houseNumber: number;
    requestDate: Date;
    state: RequestState;
    specialty: Specialty;
    resident?: Resident;
    houseNumberSuffix?: string;
    lastStateChangeDate?: Date;
    rejectReason?: string;
    coach?: Coach;
    appointmentDate?: Date;
    disapprovalReason?: string;
    changes?: Changes;
    ffwdContentId?: string;
    ffwdContentPartId?: string;
    extraProperties?: {
      preferredTimeSlots?: TimeSlot[];
      houseCoordinates?: Coordinates;
      postalcodeBounds?: Coordinates[];
      postalcodeCoordinates?: Coordinates;
      streetname?: string;
      addressdetails?: {
        town?: string;
        municipality?: string;
        province?: string;
      };
      note?: string;
    };
    areaActions?: AreaAction[];
    ckd?: boolean;
    ckdInfo?: string;
    mailHistoryId?: string;
    bookmarked?: boolean;
  }) {
    super(id, requestDate, state, lastStateChangeDate, changes);
    this.message = message;
    this.postalCode = postalCode;
    this.houseNumber = houseNumber;
    this.specialty = specialty;
    this.resident = resident;
    this.houseNumberSuffix = houseNumberSuffix;
    this.rejectReason = rejectReason;
    this.coach = coach;
    this.appointmentDate = appointmentDate;
    this.disapprovalReason = disapprovalReason;
    this.ffwdContentId = ffwdContentId;
    this.ffwdContentPartId = ffwdContentPartId;
    this.extraProperties = extraProperties;
    this.areaActions = areaActions;
    this.ckd = ckd;
    this.ckdInfo = ckdInfo;
    this.mailHistoryId = mailHistoryId;
    this.bookmarked = bookmarked;
  }

  public get canBeClaimed(): boolean {
    return !(this.coach && this.state.name != RequestStates.NEW) && super.canBeClaimed;
  }

  /**
   * Gets the minimum date for an appointment
   * @returns The minimum date
   */
  public get minimalAppointmentDate(): Date {
    return ENVIRONMENT.MODULES.includes("ALLOW_HISTORIC_APPOINTMENTS") ? new Date(new Date().setFullYear(new Date().getFullYear() - 20)) : new Date();
  }

  public get canCoachBeChanged(): boolean {
    return this.state.name === RequestStates.NEW || this.state.name === RequestStates.DATE || this.state.name === RequestStates.PICKED;
  }

  public getLocation(applicationService: ApplicationService) {
    let location = "";
    if (
      applicationService.session.activeRole.name === "coordinator" ||
      this.state.name !== RequestStates.NEW ||
      ENVIRONMENT.MODULES.includes("SHOW_ADDRESSDETAILS_TO_COACH_ROLE")
    ) {
      location +=
        (this.extraProperties?.streetname ? this.extraProperties.streetname + " " : "") +
        this.houseNumber +
        (this.houseNumberSuffix?.toUpperCase() ?? "") +
        ", " +
        this.formatPostalCode(this.postalCode) +
        (this.extraProperties?.addressdetails?.town ? " " + this.extraProperties.addressdetails?.town : "");
    } else {
      location += this.formatPostalCode(this.postalCode);
    }
    return location;
  }

  private formatPostalCode(postal: string) {
    return postal.substring(0, 4) + " " + postal.substring(postal.length - 2, postal.length).toUpperCase();
  }

  public isActiveCoach(coachId: number) {
    return this.coach?.id === coachId;
  }
}

export interface TimeSlot {
  startTime: Date;
  endTime: Date;
}

export interface Coordinates {
  Latitude: string;
  Longitude: string;
}
