<div class="container my-3">
  <div class="row">
    <div class="col">
      <div class="row d-flex align-items-end">
        <div class="col-sm-6">
          <h3 class="m-0">{{ "TABLES.PAYOUTOVERVIEW.PAYMENTREQUESTS" | translate }}</h3>
        </div>
        <div class="col-sm-4">
          <p class="m-0">{{ "TABLES.PAYOUTOVERVIEW.NEW_PAYMENTREQUEST" | translate }}</p>
        </div>
        <div class="col-auto text-end">
          <button mat-raised-button color="primary" [routerLink]="['/content/retailer/paymentrequest-new']">{{ "TABLES.PAYOUTOVERVIEW.PAYMENTREQUEST" | translate }}</button>
        </div>
      </div>
      <hr />
      <p>
        {{ "TABLES.PAYOUTOVERVIEW.CREDITSREMAINING" | translate }}<b> {{ creditsRemaining | currency : "EUR" : "symbol" : ".2-2" : "nl" }}</b>
        <!-- , {{ "TABLES.PAYOUTOVERVIEW.CREDITSPROCESSED" | translate }}<b> {{ creditsProcessed | currency : "EUR" : "symbol" : ".2-2" : "nl" }}</b> -->
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <p>
        <b>{{ "TABLES.PAYOUTOVERVIEW.OPEN_PAYMENTREQUEST" | translate }}</b>
      </p>
      <app-table
        class="text-center"
        #payOutTable
        id="PAYOUTOVERVIEW"
        [data]="transactionsOpen"
        [headers]="[
          { visualName: 'DATE', mappedTo: 'timeStamp', date: true, columnStyles: { width: '120px', class: 'col-6' } },
          { visualName: 'REFERENCENUMBER', mappedTo: 'referenceNumber', columnStyles: { class: 'col-12' } },
          { visualName: 'PAYMENTAMOUNT', mappedTo: 'paymentAmount', currency: true, columnStyles: { width: '150px', align: 'right', class: 'col-12' } }
        ]"
      ></app-table>

      <p>
        <b>{{ "TABLES.PAYOUTOVERVIEW.PROCESSED_PAYMENTREQUEST" | translate }}</b>
      </p>
      <app-table
        class="text-center"
        #payOutTable
        id="PAYOUTOVERVIEW"
        [data]="transactionsProcessed"
        [headers]="[
          { visualName: 'DATE', mappedTo: 'paidOn', date: true, columnStyles: { width: '200px', class: 'col-6' } },
          { visualName: 'REFERENCENUMBER', mappedTo: 'referenceNumber', columnStyles: { class: 'col-12' } },
          { visualName: 'PAYMENTAMOUNT', mappedTo: 'paymentAmount', currency: true, columnStyles: { width: '150px', align: 'right', class: 'col-12' } }
        ]"
      ></app-table>
      <!-- <p>
        <i>{{ "TABLES.PAYOUTOVERVIEW.MAX_PAYMENTREQUESTS" | translate }}</i>
      </p> -->
      <p>
        <i>{{ "TABLES.PAYOUTOVERVIEW.OLD_PAYMENTREQUESTS" | translate }}</i>
      </p>

      <p>
        {{ "TABLES.PAYOUTOVERVIEW.DOWNLOAD_CSV" | translate }}
        <br />
        <button class="mt-2" mat-raised-button color="primary" (click)="downloadCSVFile()">{{ "TABLES.PAYOUTOVERVIEW.DOWNLOAD_CSV_BUTTON" | translate }}</button>
      </p>
    </div>
  </div>
</div>
