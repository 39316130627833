import { Injectable } from "@angular/core";
import { MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { GraphQLService } from "./graphql.service";
import { AccountInfo, RedirectRequest } from "@azure/msal-browser";
import { Resident } from "../classes/flow/session/impl/Resident";
import { Role } from "../classes/flow/session/Session";
import { User } from "../classes/flow/session/impl/User";
import { MutationResult } from "apollo-angular";
import { Language } from "../helpers/determineLanguage";
import { ResidentService } from "./resident.service";
import { CoachService } from "./coach.service";
import { CoordinatorService } from "./coordinator.service";
import { RetailersService } from "./retailers.service";
import { StorageService } from "./storage.service";
import { Coach } from "../classes/flow/session/impl/Coach";
import { Retailer } from "../classes/flow/session/impl/Retailer";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private msalGuardConfig?: MsalGuardConfiguration;

  public constructor(
    private readonly graphqlService: GraphQLService,
    private readonly residentService: ResidentService,
    private readonly coachService: CoachService,
    private readonly coordinatorService: CoordinatorService,
    private readonly retailersService: RetailersService,
    private readonly storageService: StorageService,
    public readonly authService: MsalService
  ) {}

  private getTableNameByUser(user: User) {
    return user instanceof Retailer ? "Retailer" : user instanceof Resident ? "Resident" : user instanceof Coach ? "Coach" : "Coordinator";
  }

  /**
   * Retrieves the account information from MsalService
   * @returns AccountInfo
   */
  public getActiveAccount(): AccountInfo {
    return this.authService.instance.getActiveAccount()!;
  }

  public async initialize(role?: Role): Promise<User> {
    const user = await this.getCurrentUser();
    return role ? await this.getUserByRole(role, user) : user;
  }

  private async getCurrentUser(): Promise<User> {
    const result = await this.graphqlService.query(
      `query {
        currentAccount {
          value {
            id
            email
            toegangDatumVanaf
            toegangDatumTM
            roles {
              id
              name
            }
            changes {
              lastChange {
                userId
                time
              }
              fullDetails{
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );

    const user = result.data["currentAccount"]["value"];
    return new User({
      id: user.id,
      email: user.email,
      accessStartingDate: user.toegangDatumVanaf,
      accessEndDate: user.toegangDatumTM,
      roles: user.roles.map((role: any) => {
        return {
          id: role.id,
          name: role.name,
        } as Role;
      }),
      changes: this.graphqlService.createChangesObject(user.changes),
    });
  }

  public async changeRole(role: Role) {
    await this.graphqlService.query(`
      mutation {
        changeRole(input: {activeRoleId: ${role.id}}) {
          messages {
            message
          }
        }
      }
    `);
  }

  /**
   * Gets a derived user object by a specific role, i.e. a Resident
   * @param role The role to fetch the data for
   * @param user The user to fetch the data for
   * @returns An object of one of the classes derived from User
   */
  public async getUserByRole(role: Role, user: User): Promise<User> {
    try {
      let result: User;
      switch (role.name) {
        case "resident":
          result = await this.residentService.getResidentByUser(user);
          break;
        case "coach":
          result = await this.coachService.getCoachByUser(user);
          break;
        case "coordinator":
          result = await this.coordinatorService.getCoordinatorByUser(user);
          break;
        case "retailer":
          result = await this.retailersService.getRetailerByUser(user);
          break;
        default:
          result = user;
      }
      return result;
    } catch (error) {
      console.error("An error occurred while getting user by role:", error);
      console.error("logging the user out");
      this.logout();
      return user;
    }
  }

  /**
   * Returns the user with the specified id
   * @param id The id of the user
   * @returns Promise of the user
   */
  public async getUserById(id: number): Promise<User | null> {
    const user = new User({ id: id, email: "" });
    user.roles = await this.getUserRoles(user);
    if (user.roles.length > 0) return await this.getUserByRole(user.roles[0], user);
    return null;
  }

  public async getUserRoles(user: User): Promise<Role[]> {
    const result = await this.graphqlService.query(
      `query {
        users {
          value (where: {id: {eq: ${user.id}}}){
            statistics {
              role {
                id
                name
              }
            }
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
    try {
      return result.data["users"].value[0].statistics.map((statistic: any) => {
        const role = statistic.role;
        return {
          id: role.id,
          name: role.name,
        };
      });
    } catch {
      return [];
    }
  }

  /**
   * Gets data of all registered users
   * Gets non registered users per role -> makes them into users
   * Concats both arrays
   * @returns An array of all users
   */
  public async getAllUsers(): Promise<User[]> {
    const result = await this.getAllUserData();
    const registeredUsers: User[] = result.data["users"].value.map((user: any) => {
      return new User({
        id: user.id,
        email: user.email,
        registered: true,
        roles: user.statistics.map((statistic: any) => {
          return {
            id: statistic.role.id,
            name: statistic.role.name,
          };
        }),
        statistics: user.statistics.map((statistic: any) => {
          return {
            requestCount: statistic.requestCount,
            role: {
              id: statistic.role.id,
              name: statistic.role.name,
            },
          };
        }),
        accessStartingDate: user.toegangDatumVanaf ? new Date(user.toegangDatumVanaf) : undefined,
        accessEndDate: user.toegangDatumTM ? new Date(user.toegangDatumTM) : undefined,
      });
    });

    const uniqueRegisteredUsers: User[] = [];
    for (const registeredUser of registeredUsers) {
      if (!uniqueRegisteredUsers.some((uniqueUser) => uniqueUser.id === registeredUser.userId)) {
        uniqueRegisteredUsers.push(registeredUser);
      }
    }

    const registrationRoles: { role: Role; queryName: string }[] = [
      { role: { id: 108, name: "coach" }, queryName: "coaches" },
      { role: { id: 113, name: "retailer" }, queryName: "retailers" },
    ];

    for (const element of registrationRoles) {
      const res: any[] = (await this.getUnregisteredUserPerRole(element.queryName)).data[element.queryName].value;
      for (const user of res) {
        if (!uniqueRegisteredUsers.some((item) => item.id === user.userId)) {
          uniqueRegisteredUsers.push(new User({ id: user.userId, email: user.email, roles: [element.role], registered: false }));
        }
      }
    }

    return uniqueRegisteredUsers;
  }

  /**
   * Gets data of all users
   * @returns data of all users
   */
  private async getAllUserData(): Promise<MutationResult<any>> {
    return await this.graphqlService.query(
      `query {
        users {
          value {
            id
            email
            toegangDatumVanaf
            toegangDatumTM
            statistics {
              role {
                id
                name
              }
              requestCount
            }
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * checks if user is unregistered
   * @param role has the value of one of the roles containing the registered value
   * @returns unregistered users
   */
  private async getUnregisteredUserPerRole(role: string) {
    return await this.graphqlService.query(`
  query {
    ${role} {
      value (where: { registered: {eq: false}}){
        userId
        email
      }
    }
  }
  `);
  }

  /**
   * Updates the data of the given user
   * @param user The user to update
   */
  public async updateUser(user: User) {
    const table = this.getTableNameByUser(user);
    const result = await this.graphqlService.query(`
      mutation {
        update${table}(input: {
          id: ${user.id}
          set: {
            firstName: "${user.firstName}"
            lastName: "${user.lastName}"
            ${table === "Coach" ? `emailSubscription: ${(user as Coach).emailSubscription}` : ""}
            changes: ${this.graphqlService.formatChangesObject(user)}
            ${
  table == "Resident"
    ? `postalCode: "${(user as Resident).postalCode}"
              phoneNumber: "${(user as Resident).phoneNumber}"
              houseNumber: ${(user as Resident).houseNumber}
              ${(user as Resident).houseNumberSuffix ? `houseNumberSuffix: "${(user as Resident).houseNumberSuffix}"` : ""}`
    : ""
}
          }
        }) {
          value {
            id
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages {
            message
          }
        }
      }`);
    user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
  }

  /**
   * Deletes the given user
   * @param user The user to delete
   */
  public async deleteUser(user: User) {
    const table = this.getTableNameByUser(user);
    const result = await this.graphqlService.query(`
      mutation {
        delete${table + "(" + table.toLocaleLowerCase() + "Id: " + user.id}) {
          value {
            toegangDatumTM
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`);

    const value = result.data["delete" + table].value;
    user.changes = this.graphqlService.createChangesObject(value.changes);
    user.accessEndDate = new Date(value.toegangDatumTM);
  }

  /**
   * Reactivates the account of the given user
   * @param user The user
   */
  public async reactivateAccount(user: User) {
    if (user.accessEndDate && new Date() < user.accessEndDate) {
      const table = this.getTableNameByUser(user);
      user.accessEndDate = undefined;

      const result = await this.graphqlService.query(`
        mutation {
          update${table}(input: {
            id: ${user.id}
            set: {
              toegangDatumTM: ${null}
              changes: ${this.graphqlService.formatChangesObject(user)}
            }
          }) {
            value {
              changes {
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
                lastChange {
                  userId
                  time
                }
              }
            }
            messages{
              message
            }
          }
        }`);
      user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
    }
  }

  /**
   * Activates the account of the given user
   * @param user The user to activate
   */
  public async activateAccount(user: User) {
    const table = this.getTableNameByUser(user);
    user.accessStartingDate = new Date();
    const result = await this.graphqlService.query(`
      mutation {
        update${table}(input: {
          id: ${user.id}
          set: {
            toegangDatumVanaf: "${user.accessStartingDate.toISOString()}"
            changes: ${this.graphqlService.formatChangesObject(user)}
          }
        }) {
          value {
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages {
            message
          }
        }
      }`);
    user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
  }

  public async registerLanguageForUser(user: User, language: Language) {
    const table = this.getTableNameByUser(user);
    const result = await this.graphqlService.query(`
      mutation {
        update${table}(input: {
          id: ${user.id}
          set: {
            languageId: ${await this.getLanguageId(language)}
            changes: ${this.graphqlService.formatChangesObject(user)}
          }
        }) {
          value {
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`);
    user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
  }

  public async getLanguageId(language: Language): Promise<number> {
    const result = await this.graphqlService.query(
      `query {
        languages {
          value(where: {code: {eq: "${language.toUpperCase()}"}}) {
            id
          }
          messages{
            message
          }
        }
      }`
    );
    return result.data.languages.value[0].id;
  }

  /**
   * Login the user
   */
  public login() {
    if (this.msalGuardConfig?.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  /**
   * Logs the current user out
   */
  public async logout() {
    await this.graphqlService.query(`
      mutation {
        logOff {
          messages {
            message
          }
        }
      }
    `);
    this.clearSessionStorageTokens();
    this.authService.logoutRedirect();
  }

  /**
   * clears SESSIONSTORAGE tokens that dont include "usersettings"
   */
  public clearSessionStorageTokens() {
    for (const key of this.storageService.getAllKeys("sessionStorage")) {
      if (!key.includes("usersettings.")) this.storageService.remove(key, "sessionStorage");
    }
  }
}
