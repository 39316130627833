<div id="headerContainer" class="container-fluid sticky-top">
  <div class="row">
    <div class="col-md">
      <div class="row flex-nowrap">
        <div class="col justify-content-start d-flex align-items-center">
          <button mat-flat-button (click)="openMenu.emit('menu')" #menuButton>
            <mat-icon>menu</mat-icon>
            <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.MENU" | translate }}</span>
          </button>
        </div>

        <ng-container *ngIf="ENV.MODULES.includes('CUSTOM_SPLIT_HEADER_LOGO'); else defaultLogo">
          <div class="col d-flex justify-content-center">
            <ng-container *ngFor="let logo of ENV.CUSTOM_HEADER_LOGOS">
              <ng-container *ngIf="customLogoURLRoleCheck(logo.urls); else defaultUrl">
                <ng-container *ngFor="let url of logo.urls">
                  <ng-container *ngIf="url.roles.includes(applicationService.session.activeRole.name)">
                    <ng-container *ngIf="url.urlType === 'internal'">
                      <div
                        class="align-items-center img-fluid justify-content-center d-flex imageContainer"
                        style="cursor: pointer"
                        [routerLink]="url.url"
                        [matTooltip]="url.name ? ('COMPONENTS.HEADER.CUSTOM_TOOLTIP.' + url.name | translate) : url.name"
                      >
                        <img [src]="logo.imageRoute" alt="Logo" />
                      </div>
                    </ng-container>

                    <ng-container *ngIf="url.urlType === 'external'">
                      <a
                        class="align-items-center img-fluid justify-content-center d-flex imageContainer"
                        style="cursor: pointer"
                        [href]="url.url"
                        [matTooltip]="url.name ? ('COMPONENTS.HEADER.CUSTOM_TOOLTIP.' + url.name | translate) : url.name"
                      >
                        <img [src]="logo.imageRoute" alt="Logo" />
                      </a>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-template #defaultUrl>
                <ng-container *ngFor="let url of logo.urls">
                  <ng-container *ngIf="url.default">
                    <ng-container *ngIf="url.urlType === 'internal'">
                      <div
                        class="align-items-center img-fluid justify-content-center d-flex imageContainer"
                        style="cursor: pointer"
                        [routerLink]="url.url"
                        [matTooltip]="url.name ? ('COMPONENTS.HEADER.CUSTOM_TOOLTIP.' + url.name | translate) : url.name"
                      >
                        <img [src]="logo.imageRoute" alt="Logo" />
                      </div>
                    </ng-container>

                    <ng-container *ngIf="url.urlType === 'external'">
                      <a
                        class="align-items-center img-fluid justify-content-center d-flex imageContainer"
                        style="cursor: pointer"
                        [href]="url.url"
                        [matTooltip]="url.name ? ('COMPONENTS.HEADER.CUSTOM_TOOLTIP.' + url.name | translate) : url.name"
                      >
                        <img [src]="logo.imageRoute" alt="Logo" />
                      </a>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>

        <ng-template #defaultLogo>
          <div
            class="col align-items-center img-fluid justify-content-end d-flex imageContainer"
            style="cursor: pointer"
            [routerLink]="
              applicationService.session.activeRole.name === 'resident' ||
              applicationService.session.activeRole.name === 'guest' ||
              applicationService.session.activeRole.name === 'coordinator'
                ? '/'
                : '/content'
            "
          >
            <img src="assets/organisation/images/logo.png" alt="Logo" />
          </div>
        </ng-template>

        <div class="col justify-content-end d-flex align-items-center">
          <button class="bg-transparent" mat-flat-button routerLink="/faq">
            <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.HELP" | translate }}</span>
            <mat-icon>help</mat-icon>
          </button>

          <ng-container *ngIf="applicationService.session.authenticated; else login">
            <button mat-flat-button (click)="logout()">
              <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.LOG_OUT" | translate }}</span>
              <mat-icon>logout</mat-icon>
            </button>
          </ng-container>

          <ng-template #login>
            <button mat-flat-button [routerLink]="'content'">
              <span class="label d-none d-md-inline">{{ "COMPONENTS.HEADER.LOGIN" | translate }}</span>
              <mat-icon>login</mat-icon>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
