import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RetailersService } from "../../services/retailers.service";
import { ApplicationService } from "../../services/application.service";
import { Retailer } from "../../classes/flow/session/impl/Retailer";
import { formatCurrency } from "@angular/common";
import { SnackbarService } from "../../services/snackbar.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { alphaNumeric } from "../../validators/alphaNumeric";

@Component({
  selector: "app-retailer-paymentrequest-new",
  templateUrl: "./retailer-paymentrequest-new.component.html",
  styleUrls: ["./retailer-paymentrequest-new.component.less"],
})
export class RetailerPaymentrequestNewComponent {
  private user = this.applicationService.session.user as Retailer;
  public balanceAvailable = this.user.balance ? this.user.balance / 100 : 0;

  public formGroup = new FormGroup({
    paymentAmount: new FormControl("", [Validators.required, Validators.min(0.01), Validators.max(this.balanceAvailable)]),
    paymentReference: new FormControl("", [Validators.required, alphaNumeric]),
  });
  constructor(
    private retailersService: RetailersService,
    private applicationService: ApplicationService,
    private snack: SnackbarService,
    private readonly translateService: TranslateService,
    private router: Router
  ) {}

  async savePaymentRequest() {
    this.applicationService.setLoading(true);
    try {
      if (!this.formGroup.valid) throw new Error();
      const user = this.applicationService.session.user;
      if (!user) throw new Error();
      const value = this.formGroup.value;
      if (!value.paymentAmount || !value.paymentReference) throw new Error();

      await this.retailersService.addPaymentRequest(user, value.paymentAmount, value.paymentReference);
      this.snack.open(this.translateService.instant("FORMS.RETAILER_PAYOUT.SUCCESS"));
      this.router.navigate(["/content"]);
    } catch (error) {
      this.snack.error();
    }
    this.applicationService.setLoading(false);
  }

  setAmountToMax() {
    this.formGroup.controls.paymentAmount.patchValue(this.balanceAvailable.toString());
  }

  formatToEuro(amountInCents: number): string {
    return formatCurrency(amountInCents, "nl", "€");
  }

  maxValue() {
    return parseInt(this.balanceAvailable.toString());
  }
}
