<div class="container">
  <div class="row">
    <div class="col-12 p-1 text-center">
      <h1>{{ "COMPONENTS.REPORT_CATEGORIES.HEADING" | translate }}</h1>
    </div>
    <div class="col-12 p-1 d-flex justify-content-center align-items-center">
      <ng-container *ngIf="!showForm">
        <button mat-raised-button type="button" color="primary" (click)="addClickHandler()">{{ "COMPONENTS.REPORT_CATEGORIES.ADD_BUTTON" | translate }}</button>
      </ng-container>
      <ng-container *ngIf="showForm">
        <form (submit)="submitHandler($event)" [formGroup]="form" class="w-100">
          <div class="row">
            <div class="col-md-4">
              <!-- name -->
              <mat-form-field class="w-100">
                <mat-label>{{ "COMPONENTS.REPORT_CATEGORIES.NAME" | translate }}</mat-label>
                <input matInput formControlName="name" />
                <app-error [control]="form.controls.name"></app-error>
              </mat-form-field>
              <!-- description -->
              <mat-form-field class="w-100">
                <mat-label>{{ "COMPONENTS.REPORT_CATEGORIES.DESCRIPTION" | translate }}</mat-label>
                <input matInput formControlName="description" />
                <app-error [control]="form.controls.description"></app-error>
              </mat-form-field>
            </div>

            <div class="col-md-4 d-flex justify-content-center align-items-center p-4">
              <!-- active -->
              <mat-slide-toggle formControlName="active">{{ "COMPONENTS.REPORT_CATEGORIES.ACTIVE" | translate }}</mat-slide-toggle>
            </div>

            <div class="col-md-4 d-flex justify-content-center align-items-center flex-column gap-3">
              <button mat-raised-button class="w-100" color="primary" type="submit" [disabled]="form.invalid">{{ "FORMS.SAVE" | translate }}</button>
              <button mat-raised-button class="w-100" type="button" (click)="cancelClickHandler()">{{ "FORMS.CANCEL" | translate }}</button>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
    <div class="col-12 p-1">
      <app-table #table id="REPORT_CATEGORIES" [data]="reportCategories" [headers]="tableColumns"></app-table>
    </div>
  </div>
</div>

<ng-template #deleteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.REPORT_CATEGORIES.DELETE.HEADING" | translate }}</h3>
        <p>{{ "COMPONENTS.REPORT_CATEGORIES.DELETE.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "FORMS.CANCEL" | translate }}</button>
        <button mat-raised-button color="warn" (click)="deleteReportCategory(data); dialog.close()">
          {{ "FORMS.DELETE" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
