<div id="dashboardContainer" class="container">
  <div class="row">
    <div id="hintContainer" class="col-md">
      <div class="row justify-content-center align-self-center">
        <div class="col hint justify-content-md-center">
          <mat-icon>do_not_disturb_on</mat-icon>
          <label style="color: black">= {{ "COMPONENTS.DASHBOARD.CANCEL" | translate }}</label>
        </div>

        <div class="col hint justify-content-md-center">
          <mat-icon>delete</mat-icon>
          <label style="color: black">= {{ "COMPONENTS.DASHBOARD.DELETE" | translate }}</label>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div id="filterContainer" class="col-md text-center my-1">
      <app-badge-filters
        [filters]="dataService.filterGroups"
        (filter)="filterRequests()"
        [labels]="['COMPONENTS.DASHBOARD.FILTERS.STATE', 'COMPONENTS.DASHBOARD.FILTERS.SPECIALTY']"
      ></app-badge-filters>

      <mat-form-field appearance="outline" class="col-md-4">
        <input [formControl]="filterForm.controls.filterInputControl" matInput (keyup)="filterRequests()" placeholder="{{ 'TABLES.GENERAL.FILTER' | translate }}..." />
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="this.viewMode !== 'map'">
    <div id="sorterContainer" class="col-md text-md-center my-1">
      <div (click)="sortRequests('date')" class="sortHolder d-inline-flex align-items-center">
        <mat-radio-button id="sortButton" [checked]="currentSortMethod === 'date'" [aria-label]="'COMPONENTS.DASHBOARD.SORT_REQUESTDATE' | translate"></mat-radio-button>
        <label>{{"COMPONENTS.DASHBOARD.SORT_REQUESTDATE" | translate}}</label>
        <span [style.visibility]="currentSortMethod === 'date' ? 'visible' : 'hidden'">
          <mat-icon *ngIf="sortAscending">south</mat-icon>
          <mat-icon *ngIf="!sortAscending">north</mat-icon>
        </span>
      </div>
      <div (click)="sortRequests('lastStateChangeDate')" class="sortHolder d-inline-flex align-items-center">
        <mat-radio-button
          id="sortButton2"
          [checked]="currentSortMethod === 'lastStateChangeDate'"
          [aria-label]="'COMPONENTS.DASHBOARD.SORT_REQUESTCHANGED' | translate"
        ></mat-radio-button>
        <label>{{"COMPONENTS.DASHBOARD.SORT_REQUESTCHANGED" | translate}}</label>
        <span [style.visibility]="currentSortMethod === 'lastStateChangeDate' ? 'visible' : 'hidden'">
          <mat-icon *ngIf="sortAscending">south</mat-icon>
          <mat-icon *ngIf="!sortAscending">north</mat-icon>
        </span>
      </div>
    </div>
  </div>

  <div class="row align-items-baseline">
    <div class="col-md">
      <div class="title d-flex align-items-center">
        <button mat-icon-button (click)="refresh()" [attr.aria-label]="'COMPONENTS.EVERYWHERE.RELOADING' | translate" class="d-flex align-items-center">
          <mat-icon>refresh</mat-icon>
        </button>
        <p class="m-0">
          {{ applicationService.session.activeRole.name === 'coordinator' || applicationService.session.activeRole.name === 'coach' ? ("COMPONENTS.DASHBOARD.ALL_REQUESTS" |
          translate) : ("COMPONENTS.DASHBOARD.YOUR_REQUESTS" | translate) }}:
        </p>
      </div>
    </div>
    <ng-container *appModule="'SHOW_ANOTHER_COACHES_ECS'">
      <app-icon-toggle
        *ngIf="applicationService.session.activeRole.name === 'coach'"
        class="col-md-6"
        [toggleFormControl]="filterForm.controls.otherCoaches"
        [falseIconName]="'person'"
        [trueIconName]="'groups'"
        [falseText]="'COMPONENTS.DASHBOARD.SHOW_ONLY_OWNED_EC' | translate"
        [trueText]="'COMPONENTS.DASHBOARD.SHOW_OTHER_COACHES_EC' | translate"
        [falseAriaLabel]="'COMPONENTS.DASHBOARD.SHOW_ONLY_OWNED_EC_ARIA_LABEL' | translate"
        [trueAriaLabel]="'COMPONENTS.DASHBOARD.SHOW_OTHER_COACHES_EC_ARIA_LABEL' | translate"
        [falseTooltip]="'COMPONENTS.DASHBOARD.SHOW_ONLY_OWNED_EC_TOOLTIP' | translate"
        [trueTooltip]="'COMPONENTS.DASHBOARD.SHOW_OTHER_COACHES_EC_TOOLTIP' | translate"
      ></app-icon-toggle>
    </ng-container>
    <div class="col-md" *ngIf="applicationService.session.activeRole.name === 'coordinator'">
      <div class="card">
        <button mat-flat-button (click)="openDialog(requestStatistics)">{{ "COMPONENTS.PROFILE.REQUESTSTATS.OPENREQUESTDETAILS" | translate }}</button>
      </div>
    </div>
    <div class="col-md" *ngIf="applicationService.session.activeRole.name === 'coordinator'">
      <div class="card">
        <button mat-flat-button (click)="openDialog(csvDialog)">{{ "COMPONENTS.DASHBOARD.CSV_DIALOG.HEADING" |translate }}</button>
      </div>
    </div>
    <div
      class="col-md text-md-end title d-flex justify-content-md-end"
      *ngIf="applicationService.session.activeRole.name === 'coordinator' || applicationService.session.activeRole.name === 'coach'"
    >
      <button mat-icon-button [disabled]="viewMode === 'grid'" (click)="setViewMode('grid')" [attr.aria-label]="'COMPONENTS.EVERYWHERE.GRIDVIEW' | translate">
        <mat-icon>grid_view</mat-icon>
      </button>
      <button mat-icon-button [disabled]="viewMode === 'list'" (click)="setViewMode('list')" [attr.aria-label]="'COMPONENTS.EVERYWHERE.LISTVIEW' | translate">
        <mat-icon>view_list</mat-icon>
      </button>
      <button mat-icon-button [disabled]="viewMode === 'map'" (click)="setViewMode('map')" [attr.aria-label]="'COMPONENTS.EVERYWHERE.MAPVIEW' | translate">
        <mat-icon>pin_drop</mat-icon>
      </button>
      <!-- <button mat-icon-button [disabled]="viewMode === 'planning'" (click)="setViewMode('planning')" [attr.aria-label]="'COMPONENTS.EVERYWHERE.MAPVIEW' | translate">
        <mat-icon>work_history</mat-icon>
      </button> -->
    </div>
  </div>

  <div *ngIf="dataService.filteredConsults; else noRequests" class="d-flex flex-column mt-2" id="requestContainer">
    <div *ngIf="viewMode === 'grid'" class="col-md-12">
      <app-consult-grid-view [filteredRequests]="dataService.filteredConsults"></app-consult-grid-view>
    </div>

    <div *ngIf="viewMode === 'list'" class="col-md-12">
      <app-consult-list-view
        [consults]="dataService.allConsults"
        [showOtherCoaches]="filterForm.controls.otherCoaches"
        [filters]="dataService.filterGroups"
        [filterString]="filterForm.controls.filterInputControl.value"
      ></app-consult-list-view>
    </div>

    <div *ngIf="viewMode === 'map'" class="col">
      <app-consult-map-view [filteredRequests]="dataService.filteredConsults"></app-consult-map-view>
    </div>

    <div *ngIf="viewMode === 'planning'" class="col">
      <app-consult-planning-view></app-consult-planning-view>
    </div>
  </div>

  <ng-template #noRequests>
    <div class="row">
      <div class="col">
        <div class="text-center my-3">{{ "COMPONENTS.DASHBOARD.NO_REQUESTS" | translate}}</div>
      </div>
    </div>
  </ng-template>

  <div class="row" *appModule="'REPLACEMENT_CLAIMS'">
    <div class="col pt-5">
      <app-replacement-claim-overview [filter]="'rejectable'" [pageTitle]="'Open vervangsaanvragen'"></app-replacement-claim-overview>
    </div>
  </div>
</div>

<ng-template #requestStatistics>
  <div class="container">
    <div class="row">
      <div class="col-md">
        <span>{{ "COMPONENTS.PROFILE.REQUESTSTATS.TOTAL" | translate }}{{ getNewRequest(dataService.consults).length }}</span>
        <br />
        <div class="col-md">
          <style>
            .mat-header-cell {
              justify-content: center !important;
            }

            .mat-cell {
              justify-content: center !important;
              text-align: center;
            }
          </style>
          <mat-table [dataSource]="getNewRequestByType()">
            <ng-container matColumnDef="specialiteit">
              <mat-header-cell *matHeaderCellDef> {{ "COMPONENTS.PROFILE.REQUESTSTATS.SPECIALTIES" | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let entry"> {{ entry.name }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="aantal" style="text-align: right">
              <mat-header-cell *matHeaderCellDef> {{ "COMPONENTS.PROFILE.REQUESTSTATS.TOTALCOUNT" | translate }} </mat-header-cell>
              <mat-cell *matCellDef="let entry"> {{ entry.value }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['specialiteit', 'aantal']"></mat-header-row>
            <mat-row *matRowDef="let row; columns: ['specialiteit', 'aantal']"></mat-row>
          </mat-table>
          <br />
          <button class="align-middle w-100" mat-flat-button (click)="coachReminder()">{{ "COMPONENTS.PROFILE.REQUESTSTATS.SENDREMINDER" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #csvDialog>
  <div class="container-md">
    <div class="row">
      <h3 class="d-block text-center mb-5">{{"COMPONENTS.DASHBOARD.CSV_DIALOG.HEADING" | translate}}</h3>

      <form class="d-flex flex-column" [formGroup]="csvDownloadForm" (submit)="downloadCSV($event)">
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{"COMPONENTS.DASHBOARD.CSV_DIALOG.LABEL_STATUS" | translate}}</mat-label>
          <mat-select #requestStatesCSVInput formControlName="states" multiple>
            <mat-option *ngFor="let requestState of allRequestStates" [value]="requestState.id"> {{ "COMPONENTS.TILE.STATUSES." + requestState.name | translate}} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox color="primary" class="mb-3" formControlName="allStates">{{"COMPONENTS.DASHBOARD.CSV_DIALOG.ALL_STATES" | translate}}</mat-checkbox>

        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{"COMPONENTS.DASHBOARD.CSV_DIALOG.LABEL_DATES" | translate}}</mat-label>
          <mat-date-range-input #csvDateRange [rangePicker]="picker" [max]="todaysDate" [min]="minimalDate" required>
            <input formControlName="dateFrom" matStartDate [readonly]="true" />
            <input formControlName="dateTo" matEndDate [readonly]="true" />
          </mat-date-range-input>
          <mat-hint>{{"COMPONENTS.DASHBOARD.CSV_DIALOG.HINT_DATES" | translate}}</mat-hint>
          <mat-error>{{"COMPONENTS.DASHBOARD.CSV_DIALOG.ERROR_DATES" | translate}}</mat-error>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-checkbox color="primary" class="mb-3" formControlName="allDates">{{"COMPONENTS.DASHBOARD.CSV_DIALOG.ALL_DATES" | translate}}</mat-checkbox>

        <div class="w-100">
          <button class="d-inline" mat-raised-button color="primary" type="submit" [disabled]="csvDownloadForm.invalid">
            {{"COMPONENTS.DASHBOARD.CSV_DIALOG.BUTTON" | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
