<div class="container d-flex justify-content-center">
  <div class="d-flex flex-column w-100" *ngIf="!hashIsEntered">
    <div class="flex-column text-center">
      <h2>{{ "COMPONENTS.QRCODE_LANDING.QRCODE" | translate}}</h2>
      <p>{{ "COMPONENTS.QRCODE_LANDING.NOT_VALID" | translate}}</p>
      <br />
    </div>
  </div>

  <div class="d-flex flex-column w-100" *ngIf="hashIsEntered && !response">
    <div class="flex-column text-center mb-4">
      <h2>{{ "COMPONENTS.QRCODE_LANDING.QRCODE" | translate}}</h2>
      <p>{{ "COMPONENTS.QRCODE_LANDING.ENTER_PIN" | translate}}</p>
    </div>
    <div class="d-flex justify-content-center">
      <form class="d-inline-flex flex-column text-center">
        <mat-form-field appearance="outline">
          <mat-label>{{ "COMPONENTS.QRCODE_LANDING.PIN" | translate}}</mat-label>
          <input matInput [formControl]="pincode" type="text" />
          <app-error [control]="pincode"></app-error>
        </mat-form-field>
        <button type="button" mat-raised-button color="primary" [disabled]="!pincode.valid" (click)="sendQR()">{{ "COMPONENTS.QRCODE_LANDING.CONTINUE" | translate}}</button>
      </form>
    </div>
  </div>

  <div class="d-flex flex-column w-100" *ngIf="hashIsEntered && response?.length">
    <div class="flex-column text-center mb-4">
      <h2>{{ "COMPONENTS.QRCODE_LANDING.QRCODE" | translate}}</h2>
    </div>
    <div class="d-flex justify-content-center">
      <div class="row">
        <div class="col">
          <table class="resultTable">
            <tr *ngFor="let record of response">
              <th>{{ record.name | titlecase }}</th>
              <td>{{ record.value | titlecase }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column w-100" *ngIf="hashIsEntered && response && !response.length">
    <div class="flex-column text-center mb-4">
      <h2>{{ "COMPONENTS.QRCODE_LANDING.QRCODE" | translate}}</h2>
      <p>{{ "COMPONENTS.QRCODE_LANDING.INCORRECT" | translate}}</p>
    </div>
  </div>
</div>
