import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { v4 as generateUUID } from "uuid";
import { ApplicationService } from "../../../../../services/application.service";
import { UserService } from "../../../../../services/user.service";
import { User } from "../../../../../classes/flow/session/impl/User";
import { FormControl, FormGroup } from "@angular/forms";
import { Header } from "../../../../../components/table/Header";

@Component({
  selector: "app-screen-user-all",
  templateUrl: "./all.component.html",
  styleUrls: ["./all.component.less"],
})
export class UserAllComponent implements OnDestroy, OnInit {
  public users: User[] = [];
  private id: string = generateUUID();
  public onlyActiveUsers = true;
  public filterToggles = new FormGroup({
    statusToggle: new FormControl(false),
    rolesToggle: new FormControl(false),
    registeredToggle: new FormControl(false),
  });

  public headers: Header[];

  public constructor(private readonly application: ApplicationService, private readonly translate: TranslateService, private readonly userService: UserService) {
    this.headers = [
      { visualName: "EMAIL", mappedTo: "email", columnStyles: { class: "col-12" } },
      { visualName: "STATUS", resolver: this.displayStatus, columnStyles: { class: "col-12" } },
      { visualName: "ROLE", resolver: this.displayRoles, columnStyles: { class: "col-12" } },
      { visualName: "REGISTER", resolver: this.displayRegister, columnStyles: { class: "col-12" } },
      { visualName: "OPEN", link: { baseUrl: "/content/profile", params: ["userId"] }, columnStyles: { class: "col-12" } },
    ];
  }

  public async ngOnInit() {
    this.application.updateTitle("Gebruikers");
    this.application.setLoading(true);
    await this.initTable();
    this.application.setLoading(false);
  }

  /**
   * initializes the all users table
   */
  private async initTable() {
    this.users = await this.userService.getAllUsers();
  }

  /**
   * Translates the status of the user
   * @param user The user that includes the status
   * @returns A translation of the status
   */
  public translateStatus: (user: User) => string = (user) => {
    return this.translate.instant(`COMPONENTS.PROFILE.STATUSES.${user.status!.toUpperCase()}`);
  };

  public makeDashboardLink(linkId: string, linkRole: string): string {
    return "/content/coordinator/dashboard/" + linkId + "_" + linkRole;
  }
  /**
   * Displays roles of the user
   * @param user The user that includes the roles
   * @returns A string with all roles of the user
   */
  public displayRoles: (user: User) => string = (user) => {
    if (!user.statistics) return "-";
    const roles: string[] = user.statistics.map((statistic) => {
      return (
        this.translate.instant("COMPONENTS.PROFILE.ROLES." + statistic.role.name.toUpperCase()) +
        (statistic.requestCount != undefined ? " (" + statistic.requestCount + ")" : "")
      );
    });
    if (roles.length == 1) {
      return roles[0];
    }
    return roles.slice(0, -1).join(", ") + " " + this.translate.instant("GENERAL.ADDITIVE_CONJUNCTION") + " " + roles[roles.length - 1];
  };

  /**
   * Uses the AccessDates to determine which information to display
   * @param user The user that includes the dates to aquire the status
   * @returns "Active" - "Activates on: date" - "Expired on: date" - "New"
   */
  public displayStatus: (user: User) => string = (user) => {
    switch (user.status) {
      case "Active": {
        return this.translate.instant("COMPONENTS.PROFILE.STATUSES.ACTIVE");
      }
      case "New": {
        return this.translate.instant("COMPONENTS.PROFILE.STATUSES.NEW");
      }
      case "PendingDeleted": {
        return this.translate.instant("TABLES.USERS.STATUS_WILL_BE_EXPIRED") + user.accessEndDate?.toLocaleDateString();
      }
      case "Deleted": {
        return this.translate.instant("TABLES.USERS.STATUS_EXPIRED") + user.accessEndDate?.toLocaleDateString();
      }
    }
  };

  public displayRegister: (user: User) => string = (user) => {
    if (user.registered == true) {
      return this.translate.instant("COMPONENTS.PROFILE.REGISTER.REGISTERED");
    } else {
      return this.translate.instant("COMPONENTS.PROFILE.REGISTER.UNREGISTERED");
    }
  };

  public ngOnDestroy(): void {
    this.application.unsubscribe(this.id);
  }

  /**
   * Makes a filter function depending on toggles
   * @returns a function that can be used as a filter in material table component
   */
  public getFilterFunction = () => {
    const customTableFilter: (data: unknown, filter: string) => boolean = (data, filter) => {
      let activeCheck = false;
      let searchCheck = false;
      let rolesCheck = false;
      let registeredCheck = false;

      if (data instanceof User) {
        activeCheck = this.filterToggles.value["statusToggle"] ? true : data?.status?.toLowerCase() === "active";
        rolesCheck = this.filterToggles.value["rolesToggle"] ? data.roles.filter((e) => e.name === "coach" || e.name === "coordinator").length > 0 : true;
        registeredCheck = this.filterToggles.value["registeredToggle"] ? true : data?.registered === true;

        if (filter === "emptyValueCheck") {
          return activeCheck && rolesCheck && registeredCheck;
        }
        searchCheck = data?.email?.toLowerCase().includes(filter) ? true : false;
      }
      return activeCheck && searchCheck && rolesCheck && registeredCheck;
    };
    return customTableFilter;
  };
}
