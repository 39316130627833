<!--          
          
[icon]="'assets/images/mapIcons/empty.svg'"


[icon]="{
              fillColor: set.color,
              fillOpacity: 1,
              strokeColor: 'white',
              anchor: anchorPoint,
              labelOrigin: anchorPoint,
              path: 'M18 45C11.9625 39.8625 7.45313 35.0906 4.47188 30.6844C1.49063 26.2781 0 22.2 0 18.45C0 12.825 1.80938 8.34375 5.42813 5.00625C9.04688 1.66875 13.2375 0 18 0C22.7625 0 26.9531 1.66875 30.5719 5.00625C34.1906 8.34375 36 12.825 36 18.45C36 22.2 34.5094 26.2781 31.5281 30.6844C28.5469 35.0906 24.0375 39.8625 18 45Z'
            }"
 -->
<div>
  <div>
    <google-map
      #planningMapID
      [center]="mapSettings.center"
      [zoom]="mapSettings.zoom"
      width="100%"
      [options]="mapSettings.options"
      (zoomChanged)="zoomChangedEventHandler()"
      (centerChanged)="centerChangedEventHandler()"
    >
      <ng-container *ngFor="let set of sets">
        <ng-container *ngFor="let consult of set.consults; index as i">
          <map-marker
            *ngIf="consult.marker"
            [position]="consult.marker.position"
            [icon]="'assets/images/mapIcons/empty.svg'"
            [label]="{ text: (i + 1).toString(), color: 'white', fontSize: '18px', fontWeight: 'bold',  }"
            [visible]="set.visible"
          ></map-marker>
        </ng-container>
        <map-polyline [path]="set.lines.path" [options]="{ visible: set.visible, icons: set.lines.icons, strokeColor: set.color }"></map-polyline>
      </ng-container>
    </google-map>
  </div>
  <div class="filterCardContainer">
    <ng-container *ngFor="let set of sets; index as i">
      <mat-card class="filterCard">
        <div class="card-row">
          <div style="display: flex; flex-direction: column; justify-content: space-around">
            <div *ngFor="let coach of set.coaches">
              <b>
                {{ coach.fullName }}
              </b>
            </div>
            <div>{{ set.date | date : "dd-MM-YYYY" }}</div>
          </div>
          <div style="display: flex; flex-direction: column; justify-content: center">
            <button mat-icon-button [disabled]="false" (click)="removeSet(i)">
              <mat-icon>clear</mat-icon>
            </button>
            <button mat-icon-button (click)="set.visible = !set.visible">
              <mat-icon *ngIf="set.visible">visibility</mat-icon>
              <mat-icon *ngIf="!set.visible">visibility_off</mat-icon>
            </button>
          </div>
        </div>
        <ng-container *ngFor="let consult of set.consults; index as i">
          <div class="card-row">
            <p>
              <span>{{ i + 1 + ". " }}</span
              >{{ consult.location }}
            </p>
            <p>{{ consult.appointmentDate | date : "HH:mm" }}</p>
            <mat-icon
              *ngIf="consult.marker === undefined"
              [color]="'warn'"
              style="cursor: pointer"
              [matTooltip]="'COMPONENTS.DASHBOARD.PLANNING_LOCATION_NOT_FOUND' | translate"
              >warning</mat-icon
            >
          </div>
        </ng-container>
      </mat-card>
    </ng-container>
    <mat-card class="filterCard">
      <div class="card-row">
        <div style="display: flex; flex-direction: column">
          <mat-form-field>
            <mat-label>{{ "COMPONENTS.DASHBOARD.PLANNING_FORM_LABELS.COACH" | translate }}</mat-label>
            <mat-select [formControl]="form.controls.coaches" multiple>
              <mat-option *ngFor="let coach of coaches" [value]="coach">
                {{ coach.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "COMPONENTS.DASHBOARD.PLANNING_FORM_LABELS.DATE" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="form.controls.date" />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-around">
        <button mat-icon-button [disabled]="false" (click)="clearForm()">
          <mat-icon>clear</mat-icon>
        </button>
        <button mat-icon-button [disabled]="form.invalid || loading" (click)="addSet()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </mat-card>
  </div>
</div>
