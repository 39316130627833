<div class="container">
  <div class="row">
    <h4>
      <div style="color: var(--brand-color)" class="col-md">{{ "COMPONENTS.ERROR_PAGES.403.MESSAGE" | translate }}</div>
    </h4>
    <h5>
      <div class="row"><a routerLink="/">{{ "COMPONENTS.ERROR_PAGES.404.ACTION" | translate }}</a></div>
    </h5>
  </div>
</div>
