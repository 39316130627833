<div class="row align-items-start" style="display: flex; row-gap: 0.5rem" [formGroup]="timeSlotFormGroup">
  <!-- Date picker -->
  <mat-form-field class="col-md-6 col-lg-3" appearance="outline" [hideRequiredMarker]="true">
    <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.PREFERRED_DATE" | translate }}</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="date" [min]="minDate" [max]="maxDate" />
    <mat-error
      *ngIf="
        timeSlotFormGroup.controls['date']?.hasError('dateAfterToday') &&
        !timeSlotFormGroup.controls['date']?.hasError('required') &&
        !timeSlotFormGroup.controls['date']?.untouched
      "
      >{{ "FORMS.ERRORS.PREFERRED_TIME_SLOTS.DATE_AFTER_TODAY" | translate }}</mat-error
    >

    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <!-- time inputs -->
  <ng-container *appModule="'TIMESLOTS_PART_OF_DAY'" formGroupName="dayParts">
    <div class="col-md-3">
      <div class="row">
        <mat-card class="col-md-11">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column pl-1">
              <div>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.MORNING" | translate }}</div>
              <div class="d-flex flex-wrap" style="column-gap: 0.25rem">
                <div class="text-muted">
                  {{ ("GENERAL.FROM" | translate) + " " + hoursetsDateObjects.morningStart.toLocaleTimeString("en-GB").slice(0, 5) }}
                </div>
                <div class="text-muted">
                  {{ ("GENERAL.UNTILL" | translate) + " " + hoursetsDateObjects.morningEnd.toLocaleTimeString("en-GB").slice(0, 5) }}
                </div>
              </div>
            </div>
            <mat-checkbox formControlName="morning" [aria-label]="'FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.MORNING_ARIA_LABEL' | translate"></mat-checkbox>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row">
        <mat-card class="col-md-11">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-column pl-1">
              <div>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.AFTERNOON" | translate }}</div>
              <div class="d-flex flex-wrap" style="column-gap: 0.25rem">
                <div class="text-muted">
                  {{ ("GENERAL.FROM" | translate) + " " + hoursetsDateObjects.afternoonStart.toLocaleTimeString("en-GB").slice(0, 5) }}
                </div>
                <div class="text-muted">
                  {{ ("GENERAL.UNTILL" | translate) + " " + hoursetsDateObjects.afternoonEnd.toLocaleTimeString("en-GB").slice(0, 5) }}
                </div>
              </div>
            </div>

            <mat-checkbox formControlName="afternoon" [aria-label]="'FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.AFTERNOON_ARIA_LABEL' | translate"></mat-checkbox>
          </div>
        </mat-card>
      </div>
    </div>
  </ng-container>

  <ng-container *appExcludeModule="'TIMESLOTS_PART_OF_DAY'" formGroupName="timeInput">
    <mat-form-field appearance="outline" class="col-md-3 col-lg-3">
      <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.START_TIME" | translate }}</mat-label>
      <input matInput [formControlName]="'startTime'" readonly />
      <button mat-icon-button matSuffix (click)="openTimePicker(timeSlotFormGroup.controls.timeInput, 'startTime')"><mat-icon>access_time</mat-icon></button>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-3 col-lg-3">
      <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.END_TIME" | translate }}</mat-label>
      <input matInput [formControlName]="'endTime'" readonly />
      <button mat-icon-button matSuffix (click)="openTimePicker(timeSlotFormGroup.controls.timeInput, 'endTime')"><mat-icon>access_time</mat-icon></button>
      <mat-error
        *ngIf="
          timeSlotFormGroup.controls['timeInput'].controls['endTime']?.hasError('timeAfterOther') && !timeSlotFormGroup.controls['timeInput'].controls['endTime']?.untouched
        "
      >
        {{ "FORMS.ERRORS.PREFERRED_TIME_SLOTS.TIME_AFTER_OTHER" | translate }}</mat-error
      >
    </mat-form-field>
  </ng-container>

  <!-- action buttons -->
  <div class="col-md-12 col-lg-3">
    <div class="d-flex gap-2 flex-wrap py-lg-2">
      <button
        mat-raised-button
        color="accent"
        *ngIf="editingIndex === null"
        [disabled]="
          !timeSlotFormGroup.valid ||
          timeSlots.length >= maxTimeSlots ||
          (timeSlotFormGroup.controls['dayParts'].controls['morning'].value !== true &&
            timeSlotFormGroup.controls['dayParts'].controls['afternoon'].value !== true &&
            timeSlotFormGroup.controls['dayParts'].disabled === false) ||
          specificDateSlotLimitCount >= specificDateSlotLimitMax
        "
        (click)="addTimeSlot()"
      >
        <span>{{ "FORMS.ADD" | translate }}</span>
      </button>
      <ng-container *ngIf="editingIndex !== null">
        <button mat-raised-button color="accent" [disabled]="!timeSlotFormGroup.valid || specificDateSlotLimitCount >= specificDateSlotLimitMax" (click)="submitEdit()">
          {{ "FORMS.EDIT" | translate }}
        </button>
        <button mat-raised-button [disabled]="!timeSlotFormGroup.valid" (click)="cancelEdit()">{{ "FORMS.DONT_EDIT" | translate }}</button>
      </ng-container>
    </div>
  </div>
</div>
<mat-error *ngIf="specificDateSlotLimitCount >= specificDateSlotLimitMax">{{
  "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.MAX_LIMIT_REACHED_FOR_DATE" | translate
}}</mat-error>
<mat-error *ngIf="timeSlots.length >= maxTimeSlots">{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PREFERRED_TIME_SLOTS.MAX_LIMIT_REACHED" | translate }}</mat-error>

<ng-container *appModule="'AREA_ACTIONS'">
  <p class="mt-3 mt-lg-0" [class.text-muted]="formgroup.status === 'DISABLED'" *ngIf="areaActionMessage">{{ areaActionMessage | translate }}</p>
</ng-container>

<!-- list of material cards showing time slots -->
<div style="display: flex; flex-direction: row; overflow-x: auto; gap: 0.25rem; margin-bottom: 0.5rem">
  <mat-card *ngFor="let timeSlot of timeSlots; index as slotIndex" style="margin: 0.25rem">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; min-width: max-content">
        <div style="display: flex; flex-direction: column; justify-content: space-between; margin-right: 0.5rem">
          <div>{{ timeSlot.startTime | localDate : "fullDate" }}</div>
          <div>
            {{
              ("GENERAL.FROM" | translate) +
                " " +
                timeSlot.startTime.toLocaleTimeString("en-GB").slice(0, 5) +
                " " +
                ("GENERAL.UNTILL" | translate) +
                " " +
                timeSlot.endTime.toLocaleTimeString("en-GB").slice(0, 5)
            }}
          </div>
        </div>

        <!-- action buttons on card -->
        <ng-container *appModule="'TIMESLOTS_PART_OF_DAY'">
          <button mat-icon-button (click)="editTimeSlotViaDayParts(slotIndex)" class="centered-icon-button">
            <mat-icon style="color: rgba(0, 0, 0, 0.54)">edit</mat-icon>
          </button>
        </ng-container>
        <ng-container *appExcludeModule="'TIMESLOTS_PART_OF_DAY'">
          <button mat-icon-button (click)="editTimeSlot(slotIndex)" class="centered-icon-button">
            <mat-icon style="color: rgba(0, 0, 0, 0.54)">edit</mat-icon>
          </button>
        </ng-container>

        <button mat-icon-button (click)="removeTimeSlot(slotIndex)" class="centered-icon-button" [disabled]="slotIndex === editingIndex">
          <mat-icon [ngStyle]="{ color: slotIndex !== editingIndex ? 'rgb(185, 5, 5)' : 'unset' }">delete</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
