<div class="container">
  <h2>{{ "FORMS.OVERVIEW" | translate }}</h2>

  <div class="row">
    <div class="col-sm-12 my-3">
      <app-replacement-claim-overview [filter]="'owned'" [search]="false" pageTitle="TABLES.REPLACEMENT_OVERVIEW.CLAIMED"></app-replacement-claim-overview>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 my-3">
      <app-replacement-claim-overview [filter]="'open'" [search]="false" pageTitle="TABLES.REPLACEMENT_OVERVIEW.OPEN"></app-replacement-claim-overview>
    </div>
    <div class="col-sm-12 my-3">
      <app-retailer-pay-out-overview></app-retailer-pay-out-overview>
    </div>
  </div>
</div>
