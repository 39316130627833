<div id="footerContainer">
  <div class="row align-items-center">
    <div class="col-md-2">
      <div id="imageContainer">
        <img src="assets/organisation/images/logo.png" style="max-width: 150px" alt="Logo" />
      </div>
    </div>
    <div class="col-md-2 text-center">
      <label
        ><b>{{ domainName }}</b></label
      >
    </div>

    <div class="col-md-8 text-center">
      <div class="d-flex flex-row-reverse text-center justify-content-center justify-content-md-start">
        <ul *ngIf="privacyURL">
          <li><a target="_blank" href="{{ privacyURL }}">Privacy statement</a></li>
        </ul>
        <ul *ngIf="disclaimerURL">
          <li><a target="_blank" href="{{ disclaimerURL }}">Disclaimer</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
