import { Entity } from "../flow/base/Entity";
import { Message } from "./Message";

export interface Result<T extends Entity> {
    value?: T;
    messages: Message[];
}

export interface EmptyResult {
    messages: Message[];
}