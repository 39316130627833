export const mapDefaults: {
  zoom: number;
  center: google.maps.LatLngLiteral;
  options: google.maps.MapOptions;
} = {
  zoom: 9,
  center: { lat: 52.84951333699208, lng: 6.643457242508901 },
  options: {
    fullscreenControl: false,
    streetViewControl: false,
    disableDefaultUI: false,
    mapTypeControl: false,
  },
};
